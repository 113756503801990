import { ThrottledResource, ThrottledResourceBalance, ThrottledResourceLimits } from '@monax/types';
import { selectOrganizationResourceBalance } from 'containers/Organization/ResourceBalance/state/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../App/state/actions';
import { useThrottledResourceRestrictedMessage } from './useThrottledResourceRestrictedMessage';

export type ThrottleOnClick = (onClick: () => void, used?: number) => () => void;

// For buttons that perform actions creating throttled resources
export const useThrottleResourceOnClick = (
  throttledResources: ThrottledResource[],
  throttledResourceBalance?: ThrottledResourceBalance,
  throttledResourceLimits?: ThrottledResourceLimits,
): ThrottleOnClick => {
  const { showError } = useThrottledResourceError(throttledResourceLimits);

  const organizationResourceBalance = useSelector(selectOrganizationResourceBalance);
  const resourceBalance = throttledResourceBalance ? throttledResourceBalance : organizationResourceBalance;

  return (onClick: () => void, used = 0) => {
    return () => {
      if (throttledResources.some((resource) => isWithInBalance(resourceBalance[resource], used))) {
        // Perform action if balance is null or positive (null indicating no limit)
        onClick();
        return;
      }
      showError(throttledResources[0]);
    };
  };
};

export const useThrottledResourceError = (throttledResourceLimits?: ThrottledResourceLimits) => {
  const dispatch = useDispatch();
  const { getMessage } = useThrottledResourceRestrictedMessage(throttledResourceLimits);

  return {
    showError: (resource: ThrottledResource) => {
      dispatch(
        addNotification({
          open: true,
          message: getMessage(resource),
          className: 'error',
          autoHideDuration: 5000,
        }),
      );
    },
  };
};

export const isWithInBalance = (balance: number | undefined | null, used = 0): boolean => {
  if (balance === null || balance === undefined) return true;
  return balance - used > 0;
};
