import { PaletteKey, Shade } from 'containers/Theme/types';
import { useTheme } from 'containers/Theme/useTheme';
import { randomColor } from 'containers/Theme/util';
import { useMemo } from 'react';

// Returns [initials, bgColor, textColor, border]
export const useAvatarProps = (
  name: string,
  bgColor?: PaletteKey | 'white',
  bgShade?: Shade,
  textColor?: PaletteKey | 'white',
  textShade?: Shade,
  borderColor?: PaletteKey | 'white',
  borderShade?: Shade,
): [string, string, string, string] => {
  const { palette } = useTheme();
  return useMemo(
    () => [
      getInitials(name),
      bgColor === 'white' ? 'white' : bgColor ? palette[bgColor][bgShade] : randomColor(name),
      textColor === 'white' ? 'white' : textColor ? palette[textColor][textShade] : 'white',
      `0.5px solid ${
        borderColor === 'white' ? 'white' : borderColor ? palette[borderColor][borderShade] : 'transparent'
      }`,
    ],
    [name, palette],
  );
};

export const getInitials = (name: string) => {
  if (!name) return '';
  return name
    .split(' ')
    .map((char) => char[0])
    .join('')
    .toUpperCase()
    .slice(0, 1);
};
