import { TimestampOffset } from '@monax/types';
import { FormOffset } from '../types';

export function formOffsetToTimestampOffset(offset: FormOffset): TimestampOffset {
  if (offset) {
    const { direction, amount, unit } = offset;
    const duration = {
      [unit]: Math.abs(amount),
    };
    return {
      direction,
      duration,
    };
  }
  return {
    direction: 'BEFORE',
    duration: {},
  };
}
