import { selectLocale } from 'containers/Preferences/Locale/state/selectors';
import { SupportedLocale } from 'containers/Preferences/Locale/types';
import { format as formatDate, Locale } from 'date-fns';
import { enGB, enUS, es } from 'date-fns/locale';
import React from 'react';
import { FormatDateOptions, FormattedDate as IntlFormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';
import { longIfDevMode } from 'utils/dateDisplayOptions';

type Props = {
  date: string | number | Date; // unix if number!!!
  format?: string;
  jsDateFormat?: FormatDateOptions;
};

const localeMap: Record<SupportedLocale, Locale> = {
  'en-US': enUS,
  'en-GB': enGB,
  'es-ES': es,
};

export const FormattedDate: React.FC<Props> = ({ date, format, jsDateFormat }) => {
  const locale = useSelector(selectLocale);
  const jsDate = new Date(typeof date === 'number' ? date * 1000 : date);
  if (format) {
    return <>{formatDate(jsDate, format, { locale: localeMap[locale] })}</>;
  }
  return <IntlFormattedDate value={jsDate} {...(jsDateFormat || longIfDevMode)} />;
};
