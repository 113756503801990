import { defineMessages } from 'react-intl';

const scope = `app.containers.User.Current.ForeignAddresses`;

export const messages = defineMessages({
  addForeignAddressHeading: {
    id: `${scope}.addForeignAddressHeading`,
    defaultMessage: 'Add Foreign Address',
  },
  addNewForeignAddress: {
    id: `${scope}.addNewForeignAddress`,
    defaultMessage: 'Add New Foreign Address',
  },
  addressName: {
    id: `${scope}.addressName`,
    defaultMessage: 'Address Name',
  },
  addressOrganization: {
    id: `${scope}.addressOrganization`,
    defaultMessage: 'Address Organization',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  connectedForeignAddress: {
    id: `${scope}.connectedForeignAddress`,
    defaultMessage: 'Connected Foreign Address',
  },
  foreignAddressAdded: {
    id: `${scope}.foreignAddressAdded`,
    defaultMessage: 'Foreign Address Added',
  },
  foreignAddressUpdated: {
    id: `${scope}.foreignAddressUpdated`,
    defaultMessage: 'Foreign Address Updated',
  },
  foreignAddressAlreadyAdded: {
    id: `${scope}.foreignAddressAlreadyAdded`,
    defaultMessage: 'This foreign address has already been added.',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  addForeignAddress: {
    id: `${scope}.addForeignAddress`,
    defaultMessage: 'Add Foreign Address',
  },
  connectWallet: {
    id: `${scope}.connectWallet`,
    defaultMessage: 'Please connect your wallet first in order to add a new foreign address',
  },
});
