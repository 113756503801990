import { ThrottledResource, ThrottledResourceLimits } from '@monax/types';
import { selectOrganizationResourceLimits } from 'containers/Organization/ResourceBalance/state/selectors';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { messages } from '../messages';
import { isPerAgreement, throttledResourceToMessage } from '../util';

export const useThrottledResourceRestrictedMessage = (throttledResourceLimits?: ThrottledResourceLimits) => {
  const intl = useIntl();
  const organizationResourceLimits = useSelector(selectOrganizationResourceLimits);

  const resourceLimits = throttledResourceLimits ? throttledResourceLimits : organizationResourceLimits;

  return {
    getMessage: (throttledResource: ThrottledResource) => {
      const period = getPeriodMessage(intl, resourceLimits, throttledResource);
      const limit = resourceLimits?.[throttledResource]?.limit;

      return intl.formatMessage(messages.throttledResourceError_snackbar, {
        resource: intl.formatMessage(throttledResourceToMessage(throttledResource)),
        period: period,
        limit,
      });
    },
  };
};

const getPeriodMessage = (
  intl: IntlShape,
  resourceLimits: ThrottledResourceLimits,
  throttledResource: ThrottledResource,
) => {
  if (isPerAgreement(throttledResource)) return intl.formatMessage(messages.throttledResourceError_perAgreement);

  const period = resourceLimits?.[throttledResource]?.period;

  if (period === 'per_month') return intl.formatMessage(messages.throttledResourceError_perMonth);

  if (period === 'per_year') return intl.formatMessage(messages.throttledResourceError_perYear);

  return '';
};
