/*
 * App constants
 */

// Redux action types- UI
export const ADD_NOTIFICATION = 'app/App/ADD_NOTIFICATION';
export type ADD_NOTIFICATION = 'app/App/ADD_NOTIFICATION';
export const ADD_FORMATTED_NOTIFICATION = 'app/App/ADD_FORMATTED_NOTIFICATION';
export type ADD_FORMATTED_NOTIFICATION = 'app/App/ADD_FORMATTED_NOTIFICATION';

export const TOGGLE_UI_COMPONENT = 'app/App/TOGGLE_UI_COMPONENT';
export type TOGGLE_UI_COMPONENT = 'app/App/TOGGLE_UI_COMPONENT';

// UI component names
// Register new components with the `UI_COMPONENTS` object below
// (Some of) the values of the components names will be used for analytics, so try to be short but descriptive with the names
export const SIDEBAR_EXPANDED = 'sidebar-expanded';
export type SIDEBAR_EXPANDED = 'sidebar-expanded';

export type UIComponent = SIDEBAR_EXPANDED;

export const UI_COMPONENTS = {
  [SIDEBAR_EXPANDED]: true,
};

export const INBOX_PATHS = {
  '/agreements': true,
  '/agreements/': true,
  '/templates': true,
  '/templates/': true,
  '/drafts': true,
  '/drafts/': true,
  '/overview': true,
  '/overview/': true,
  '/overview/agreements-list': true,
  '/settings': true,
  '/settings/': true,
  '/settings/profile': true,
  '/settings/organization': true,
  '/settings/billing': true,
  '/settings/notifications': true,
  '/settings/messaging': true,
  '/settings/integrations': true,
  '/settings/memberships': true,
};
