import { defineMessages } from 'react-intl';

const scope = `app.containers.Theme`;

export const messages = defineMessages({
  palettePrimaryLabel: {
    id: `${scope}.palettePrimaryLabel`,
    defaultMessage: 'Action Color',
  },
  paletteSecondaryLabel: {
    id: `${scope}.paletteSecondaryLabel`,
    defaultMessage: 'Primary Color',
  },
  paletteShadeMainlabel: {
    id: `${scope}.paletteShadeMainlabel`,
    defaultMessage: 'Color',
  },
  paletteShadeContrastTextlabel: {
    id: `${scope}.paletteShadeContrastTextlabel`,
    defaultMessage: 'Contrast Text',
  },
  logoSizeFull: {
    id: `${scope}.logoSizeFull`,
    defaultMessage: 'Logo - Primary',
  },
  logoSizeFullContrast: {
    id: `${scope}.logoSizeFullContrast`,
    defaultMessage: 'Logo - Primary Contrast',
  },
  logoSizeFavicon: {
    id: `${scope}.logoSizeFavicon`,
    defaultMessage: 'Favicon - Primary',
  },
  logoSizeFaviconContrast: {
    id: `${scope}.logoSizeFaviconContrast`,
    defaultMessage: 'Favicon - Primary Contrast',
  },
  logoSizeFullDescription: {
    id: `${scope}.logoSizeFullDescription`,
    defaultMessage: 'Your full-sized logo',
  },
  logoSizeFaviconDescription: {
    id: `${scope}.logoSizeFaviconDescription`,
    defaultMessage: 'Your square, icon-only logo',
  },
});
