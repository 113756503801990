import produce from 'immer';
import { TaskListState } from '../types';
import { HomePageHeaderAction } from './actions';

export const initialState: TaskListState = {
  tasks: [],
};

const reducer = (state = initialState, action: HomePageHeaderAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Task/List/LOAD_TASKS_SUCCESS': {
        draftState.tasks = action.tasks;
        return draftState;
      }
      default:
        return draftState;
    }
  });

export default reducer;
