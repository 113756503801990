import { useField } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { OptionsType } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Option } from 'types/form';
import { FormItem } from '../FormItem';
import { useInputState } from '../Hooks';
import { InputConfig } from '../types';
import { getSelectedValue, onSelectedValueChange, useGetStyles } from './helpers';
import { messages } from './messages';

type Props = {
  name: string;
  label?: string;
  labelAddon?: React.ReactNode;
  infoText?: string;
  isMulti?: boolean;
  placeholder?: string;
  options: OptionsType<Option>;
  disabled?: boolean;
  isLoading?: boolean;
  inputConfig?: InputConfig;
  portal?: boolean;
};

export const CreateableSelect: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { label, labelAddon, options, infoText, isMulti, disabled, isLoading, inputConfig, portal } = props;
  const [input, meta, helpers] = useField(props);

  const inputState = useInputState(inputConfig, meta);

  const selectedValue = getSelectedValue(isMulti, options, input.value);
  const styles = useGetStyles(inputState.showError);
  return (
    <FormItem label={label} labelAddon={labelAddon} inputState={inputState} infoText={infoText}>
      <CreatableSelect
        {...input}
        isClearable
        isMulti={isMulti}
        isDisabled={disabled}
        isLoading={isLoading}
        closeMenuOnSelect={!isMulti}
        styles={styles}
        value={selectedValue || (input.value ? { label: input.value, value: input.value } : null)}
        formatCreateLabel={(value) => intl.formatMessage(messages.addItem, { item: value })}
        placeholder={props.placeholder || intl.formatMessage(messages.defaultPlaceholder)}
        noOptionsMessage={() => intl.formatMessage(messages.creatableNoOptions)}
        onChange={onSelectedValueChange(isMulti, helpers.setValue)}
        onBlur={() => helpers.setTouched(true)}
        options={props.options}
        classNamePrefix="react-select"
        // render menu in portal so it renders over parent boundaries
        menuPortalTarget={portal ? document.body : undefined}
      />
    </FormItem>
  );
};
