import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';
import { InfoCircle } from 'react-bootstrap-icons';
import { Tooltip } from './Tooltip';

type Props = {
  content: React.ReactNode;
  className?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

export const InfoTooltip: React.FC<Props> = (props) => {
  const { palette } = useTheme();
  return (
    <Tooltip title={props.content} placement={props.placement}>
      {/* TODO- color won't work here */}
      <InfoCircle className={`${props.className || ''}`} color={palette.gray[400]} />
    </Tooltip>
  );
};
