/*
 * LoginPage Messages
 *
 * This contains all the text for the LoginPage component.
 */
import { defineMessages } from 'react-intl';

const scope = 'app.containers.Auth';

export default defineMessages({
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  pleaseAcceptTerms: {
    id: `${scope}.pleaseAcceptTerms`,
    defaultMessage: 'Please read our terms of service',
    description: 'Label for checkbox to accept terms',
  },
  acceptTermsOfService: {
    id: `${scope}.acceptTermsOfService`,
    defaultMessage: "I understand and accept Suscribo's {termsLink}",
    description: 'Label for checkbox to accept terms',
  },
  termsOfService: {
    id: `${scope}.termsOfService`,
    defaultMessage: 'Terms of Service',
    description: 'Terms of service label',
  },
  mustAcceptTerms: {
    id: `${scope}.mustAcceptTerms`,
    defaultMessage: 'Please accept our terms of service to continue using the platform.',
    description: 'Accept terms error message',
  },
  accept: {
    id: `${scope}.accept`,
    defaultMessage: 'Accept',
    description: 'Accept button',
  },
  termsHaveChanged: {
    id: `${scope}.termsHaveChanged`,
    defaultMessage:
      'Our terms of service have changed. Please accept our new terms before continuing to use our platform.',
    description: 'Terms changed message',
  },
  thankYouForAccepting: {
    id: `${scope}.thankYouForAccepting`,
    defaultMessage: 'Thank you for accepting our terms',
    description: 'Terms changed message',
  },
  termsAcceptanceFailed: {
    id: `${scope}.termsAcceptanceFailed`,
    defaultMessage: "Sorry, we couldn't submit your request to accept our terms of service.",
    description: 'Terms acceptance failed message',
  },
  auth0ErrorCode_access_denied: {
    id: `${scope}.auth0ErrorCode_access_denied`,
    defaultMessage: 'Invalid username or password',
  },
  auth0ErrorCode_user_exists: {
    id: `${scope}.auth0ErrorCode_user_exists`,
    defaultMessage: 'The user you are attempting to sign up has already signed up',
  },
  auth0ErrorCode_username_exists: {
    id: `${scope}.auth0ErrorCode_username_exists`,
    defaultMessage: 'The username you are attempting to sign up with is already in use',
  },
  auth0ErrorCode_password_strength_error: {
    id: `${scope}.auth0ErrorCode_password_strength_error`,
    defaultMessage:
      'Password must :\n - be at least 8 characters long\n - have special characters (!@#$%^&*)\n - have lower case (a-z), upper case (A-Z) and numbers (0-9)',
  },
  auth0ErrorCode_password_dictionary_error: {
    id: `${scope}.auth0ErrorCode_password_dictionary_error`,
    defaultMessage: 'The chosen password is too common',
  },
  auth0ErrorCode_password_history_error: {
    id: `${scope}.auth0ErrorCode_password_history_error`,
    defaultMessage: 'The password has already been used',
  },
  auth0ErrorCode_too_many_attempts: {
    id: `${scope}.auth0ErrorCode_too_many_attempts`,
    defaultMessage: 'The account is blocked due to too many attempts to sign in',
  },
  auth0ErrorCode_user_is_blocked: {
    id: `${scope}.auth0ErrorCode_user_is_blocked`,
    defaultMessage: 'We unfortunately cannot find your user',
  },
  auth0ErrorCode_reset_password_request_used: {
    id: `${scope}.auth0ErrorCode_reset_password_request_used`,
    defaultMessage: `Password request ticket already used`,
  },
  auth0ErrorCode_verify_email_before_login: {
    id: `${scope}.auth0ErrorCode_verify_email_before_login`,
    defaultMessage: `Please verify your email before logging in`,
  },
  auth0ErrorCode_unknown: {
    id: `${scope}.auth0ErrorCode_unknown`,
    defaultMessage: `An unknown error occurred`,
  },
  reset_password_request_expired: {
    id: `${scope}.reset_password_request_expired`,
    defaultMessage: `Password reset window expired, please request new password reset`,
  },
  change_password_old_password_invalid: {
    id: `${scope}.change_password_old_password_invalid`,
    defaultMessage: `Password invalid`,
  },
  enterYourEmailUsername: {
    id: `${scope}.enterYourEmailUsername`,
    defaultMessage: 'Enter your email / username',
  },
  authenticationFailed: {
    id: `${scope}.authenticationFailed`,
    defaultMessage: 'Authentication Failed',
  },
  enterYourPassword: {
    id: `${scope}.enterYourPassword`,
    defaultMessage: 'Enter password',
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Log in',
  },
  forgotPassword: {
    id: `${scope}.forgotPassword`,
    defaultMessage: 'Forgot Password?',
  },
  signup: {
    id: `${scope}.signup`,
    defaultMessage: 'Sign up',
  },
  orLoginWith: {
    id: `${scope}.orLoginWith`,
    defaultMessage: 'or login with',
  },
  yourEmailWasVerified: {
    id: `${scope}.yourEmailWasVerified`,
    defaultMessage: 'Your email was verified! Please log in to your account',
  },
  welcomeBackToMonax: {
    id: `${scope}.welcomeBackToMonax`,
    defaultMessage: 'Welcome back',
  },
  welcomeBackTo: {
    id: `${scope}.welcomeBackTo`,
    defaultMessage: 'Welcome back to {name}',
  },
  loginToYourAccount: {
    id: `${scope}.loginToYourAccount`,
    defaultMessage: 'Log in to your account',
  },
  slide1Tag: {
    id: `${scope}.slide1Tag`,
    defaultMessage: 'Smart',
  },
  slide1Title: {
    id: `${scope}.slide1Title`,
    defaultMessage: 'Effortlessly integrate with your tools and automate your obligations via Zapier.',
  },
  slide2Tag: {
    id: `${scope}.slide2Tag`,
    defaultMessage: 'Simple',
  },
  slide2Title: {
    id: `${scope}.slide2Title`,
    defaultMessage: 'Comfortably use our templating language to pull key terms from your contracts.',
  },
  slide3Tag: {
    id: `${scope}.slide3Tag`,
    defaultMessage: 'Secure',
  },
  slide3Title: {
    id: `${scope}.slide3Title`,
    defaultMessage: 'Easily implement proper access controls for your agreements with teams.',
  },
  google: {
    id: `${scope}.google`,
    defaultMessage: 'Google',
  },
  microsoft: {
    id: `${scope}.microsoft`,
    defaultMessage: 'Microsoft',
  },
  apple: {
    id: `${scope}.apple`,
    defaultMessage: 'Apple',
  },
  linkedIn: {
    id: `${scope}.linkedIn`,
    defaultMessage: 'LinkedIn',
  },
  github: {
    id: `${scope}.github`,
    defaultMessage: 'Github',
  },
  orSignupHere: {
    id: `${scope}.orSignupHere`,
    defaultMessage: 'or sign up here',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Username',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  ethereumAddress: {
    id: `${scope}.ethereumAddress`,
    defaultMessage: 'Ethereum Address',
  },
  exampleEmail: {
    id: `${scope}.exampleEmail`,
    defaultMessage: 'yours@example.com',
  },
  password: {
    id: `${scope}.Password`,
    defaultMessage: 'Password',
  },
  confirmPassword: {
    id: `${scope}.confirmPassword`,
    defaultMessage: 'Confirm password',
  },
  finalizeAccount: {
    id: `${scope}.finalizeAccount`,
    defaultMessage: 'To finalize your account, please verify your email at:',
  },
  noEmailReceivedResend: {
    id: `${scope}.noEmailReceivedResend`,
    defaultMessage:
      'Should you not have received an email to verify your account after five minutes, please click on the button below.',
  },
  thanksForJoining: {
    id: `${scope}.thanksForJoining`,
    defaultMessage: 'Thanks for joining!',
  },
  createAnAccount: {
    id: `${scope}.createAnAccount`,
    defaultMessage: 'Create an account',
  },
  verifyEmail: {
    id: `${scope}.verifyEmail`,
    defaultMessage: 'Verify your email address',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  acceptTOS: {
    id: `${scope}.acceptTOS`,
    defaultMessage: "I agree to the {termsOfService} and {privacyPolicy}.",
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: 'Privacy Policy',
  },
  resendEmail: {
    id: `${scope}.resendEmail`,
    defaultMessage: 'Resend email',
  },
  sendEmail: {
    id: `${scope}.sendEmail`,
    defaultMessage: 'Send email',
  },
  errorSendingEmail: {
    id: `${scope}.errorSendingEmail`,
    defaultMessage: 'Error sending email.',
  },
  emailSentSucessfully: {
    id: `${scope}.emailSentSucessfully`,
    defaultMessage: 'Your email has been sent!',
  },
  passwordsMustBeTheSame: {
    id: `${scope}.passwordsMustBeTheSame`,
    defaultMessage: 'Passwords must be the same',
  },
  youMustAcceptTheTermsOfService: {
    id: `${scope}.youMustAcceptTheTermsOfService`,
    defaultMessage: 'You must accept the terms of service',
  },
  resetYourPassword: {
    id: `${scope}.resetYourPassword`,
    defaultMessage: 'Reset your password',
  },
  setYourPassword: {
    id: `${scope}.setYourPassword`,
    defaultMessage: 'Set your password',
  },
  pleaseCheckYourInbox: {
    id: `${scope}.pleaseCheckYourInbox`,
    defaultMessage: 'Please check your inbox',
  },
  forgotSomething: {
    id: `${scope}.forgotSomething`,
    defaultMessage: 'Forgot something?',
  },
  enterEmailToResetPassword: {
    id: `${scope}.enterEmailToResetPassword`,
    defaultMessage: 'Please enter your email address. We will send you an email to reset your password shortly.',
  },
  ifValidEmailResetEmailSent: {
    id: `${scope}.ifValidEmailResetEmailSent`,
    defaultMessage: 'If we recognise your email address, we will send you an email with a link to reset your password.',
  },
  resendIfEmailNotRecieved: {
    id: `${scope}.resendIfEmailNotRecieved`,
    defaultMessage:
      'Should you not have received your email after five minutes, please check your email address or click on the button below.',
  },
  confirmNewPassword: {
    id: `${scope}.confirmNewPassword`,
    defaultMessage: 'Confirm new password',
  },
  errorResettingPassword: {
    id: `${scope}.errorResettingPassword`,
    defaultMessage: 'Error resetting password',
  },
  passwordResetSuccess: {
    id: `${scope}.passwordResetSuccess`,
    defaultMessage: 'Congrats! Your password has been reset successfully.',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  enterAndConfirmNewPassword: {
    id: `${scope}.enterAndConfirmNewPassword`,
    defaultMessage: 'Please enter and confirm your new password for: {email}',
  },
  authorizationError: {
    id: `${scope}.enterAndConfirmNewPassword`,
    defaultMessage: 'Please enter and confirm your new password for: {email}',
  },
  invitationError: {
    id: `${scope}.invitationError`,
    defaultMessage: 'Error accepting invitation',
  },
  inviteWelcomeHeader2: {
    id: `${scope}.inviteWelcomeHeader2`,
    defaultMessage: 'Welcome, {name} 🎉',
  },
  inviteCard1_byCounterparty: {
    id: `${scope}.inviteCard1_byCounterparty`,
    defaultMessage: "You've been invited to Suscribo by {counterparty}.",
  },
  inviteCard1_byOrganization: {
    id: `${scope}.inviteCard1_byOrganization`,
    defaultMessage: "You've been invited by {organization} to join their team.",
  },
  inviteCard1_default: {
    id: `${scope}.inviteCard1_default`,
    defaultMessage: "You've been invited to join Suscribo.",
  },
  inviteCard2_byAgreement: {
    id: `${scope}.inviteCard2_byAgreement`,
    defaultMessage: 'Please create an account with your {email} email to securely view, sign and manage the agreement.',
  },
  inviteCard2_default: {
    id: `${scope}.inviteCard2_default`,
    defaultMessage: 'Please create an account with your {email} email to join the team.',
  },
  inviteCard3_default: {
    id: `${scope}.inviteCard3_default`,
    defaultMessage: 'Once finished, please enjoy Suscribo by creating, managing and signing many more agreements!',
  },
  failedToAuthenticate: {
    id: `${scope}.failedToAuthenticate`,
    defaultMessage: 'Failed to get authenticate',
  },
  metaMaskConnectedWithIncorrectAddress: {
    id: `${scope}.metaMaskConnectedWithIncorrectAddress`,
    defaultMessage:
      'You have connected with the incorrect ethereum address, please switch to the above address and connect again',
  },
  unauthorizedAccess: {
    id: `${scope}.unauthorizedAccess`,
    defaultMessage: 'Unauthorized Access, you have been signed out.',
  },
});
