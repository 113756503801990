import { ForeignChainAssetType, ForeignChainId } from '@monax/types';
import { addBreadcrumb, Severity } from '@sentry/react';
import configs from 'configs';

const getAccountApi = async () => {
  const { AccountApi } = await import(/* webpackChunkName: "moralis" */ './moralis/api');
  return new AccountApi({
    apiKey: configs.integrations.moralisApiKey,
  });
};

export const getNfts = async (
  address: string,
  foreignChainId: ForeignChainId,
  foreignChainAssetType?: ForeignChainAssetType,
) => {
  const api = await getAccountApi();

  const chainList = await foreignChainIdToChainList(foreignChainId);

  if (!chainList) {
    console.warn(`unable to get nfts, foreign chain id not handled ${foreignChainId}`);
    return [];
  }

  try {
    const collection = await api.getNFTs(address, chainList);

    let results = collection.result;

    if (foreignChainAssetType) {
      const contractType = foreignChainAssetTypeToContractType(foreignChainAssetType);
      results = results.filter((r) => r.contract_type === contractType);
    }

    return results;
  } catch (error) {
    console.error('failed to get nfts', { error });
    addBreadcrumb({
      level: Severity.Warning,
      category: 'foreign-chain-data',
      message: 'failed to get nfts',
      data: {
        error,
      },
    });
  }

  return [];
};

const foreignChainIdToChainList = async (chainId: ForeignChainId) => {
  const { ChainList } = await import(/* webpackChunkName: "moralis" */ './moralis/api');
  switch (chainId) {
    case 'ethereum_mainnet':
      return ChainList.Eth;
    case 'ethereum_ropsten':
      return ChainList.Ropsten;
    default:
      return undefined;
  }
};

const foreignChainAssetTypeToContractType = (foreignChainAssetType: ForeignChainAssetType): string => {
  if (foreignChainAssetType === 'non_fungible_token') return 'ERC721';

  addBreadcrumb({
    level: Severity.Warning,
    category: 'foreign-chain-data',
    message: `unhandled foreign chain asset type ${foreignChainAssetType}`,
    data: {
      foreignChainAssetType,
    },
  });
  console.error(`unhandled foreign chain asset type ${foreignChainAssetType}`);
  return foreignChainAssetType;
};
