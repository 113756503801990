import { makeStyles } from '@material-ui/core';

export const useTableCellStyles = makeStyles((theme) => ({
  root: {
    padding: '14px 0 14px 20px',
    borderColor: theme.palette.grey[300],
    borderCollapse: 'collapse',
    '&:nth-of-type(2)': {
      fontWeight: 'normal',
    },
    '&:last-of-type': {
      paddingRight: '20px',
    },
    fontSize: '14px',
    height: '48px',
  },
  head: {
    padding: '12px 0 12px 20px',
    color: theme.palette.grey[700],
    textTransform: 'uppercase',
    border: 'none',
    borderCollapse: 'collapse',
    fontWeight: 'normal',
    height: '56px',
  },
  stickyHeader: {
    color: theme.palette.info.main,
    border: 'none',
    borderCollapse: 'collapse',
    fontWeight: 500,
  },
}));

export const useTableHeaderCellStyles = makeStyles({
  root: {
    padding: '14px 20px',
  },
});
