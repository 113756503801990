import { ResourceFilter, UpdateResourceFilter } from '@monax/types';

export type LoadResourceFiltersAction = ReturnType<typeof loadResourceFilters>;
export type LoadResourceFiltersSuccessAction = ReturnType<typeof loadResourceFiltersSuccess>;
export type AddResourceFilterAction = ReturnType<typeof addResourceFilter>;
export type AddResourceFilterSuccessAction = ReturnType<typeof addResourceFilterSuccess>;
export type UpdateResourceFilterAction = ReturnType<typeof updateResourceFilter>;
export type UpdateResourceFilterSuccessAction = ReturnType<typeof updateResourceFilterSuccess>;
export type RemoveResourceFilterAction = ReturnType<typeof removeResourceFilter>;
export type RemoveResourceFilterSuccessAction = ReturnType<typeof removeResourceFilterSuccess>;

export type ResourceFilterAction =
  | LoadResourceFiltersAction
  | UpdateResourceFilterAction
  | UpdateResourceFilterSuccessAction
  | LoadResourceFiltersSuccessAction
  | AddResourceFilterAction
  | AddResourceFilterSuccessAction
  | RemoveResourceFilterAction
  | RemoveResourceFilterSuccessAction;

export const loadResourceFilters = (onSuccess?: () => void) => {
  return <const>{
    type: 'app/User/Current/ResourceFilters/LOAD_RESOURCE_FILTERS',
    onSuccess,
  };
};

export const loadResourceFiltersSuccess = (resourceFilters: ResourceFilter[]) => {
  return <const>{
    type: 'app/User/Current/ResourceFilters/LOAD_RESOURCE_FILTERS_SUCCESS',
    resourceFilters,
  };
};

export const addResourceFilter = (resourceFilter: UpdateResourceFilter, onSuccess: () => void) => {
  return <const>{
    type: 'app/User/Current/ResourceFilters/ADD_RESOURCE_FILTER',
    resourceFilter,
    onSuccess,
  };
};

export const addResourceFilterSuccess = () => {
  return <const>{
    type: 'app/User/Current/ResourceFilters/ADD_RESOURCE_FILTER_SUCCESS',
  };
};

export const updateResourceFilter = (id: string, resourceFilter: UpdateResourceFilter, onSuccess: () => void) => {
  return <const>{
    type: 'app/User/Current/ResourceFilters/UPDATE_RESOURCE_FILTER',
    id,
    resourceFilter,
    onSuccess,
  };
};

export const updateResourceFilterSuccess = () => {
  return <const>{
    type: 'app/User/Current/ResourceFilters/UPDATE_RESOURCE_FILTER_SUCCESS',
  };
};

export const removeResourceFilter = (id: string, onSuccess: () => void) => {
  return <const>{
    type: 'app/User/Current/ResourceFilters/REMOVE_RESOURCE_FILTER',
    id,
    onSuccess,
  };
};

export const removeResourceFilterSuccess = () => {
  return <const>{
    type: 'app/User/Current/ResourceFilters/REMOVE_RESOURCE_FILTER_SUCCESS',
  };
};
