import React, { ReactNode } from 'react';
import { Layout, Size } from './types';
import { prefixSize } from './util';

// Important! If using the 3-2 layout, make sure that no child components are nested in fragments, otherwise our child mapping will not work!

type Props = {
  layout: Layout; // base layout, will be overridden by below if screen size is over any of their breakpoints
  gapX?: Size;
  gapY?: Size;
  smLayout?: Layout;
  mdLayout?: Layout;
  lgLayout?: Layout;
  xlLayout?: Layout;
  children: ReactNode;
  className?: string;
};
export const ResponsiveGrid: React.FC<Props> = ({
  gapX,
  gapY,
  layout,
  smLayout,
  mdLayout,
  lgLayout,
  xlLayout,
  children,
  className = '',
}) => {
  const layouts = [layout, smLayout, mdLayout, lgLayout, xlLayout];
  return (
    <div
      className={`grid ${getGapClassName(gapX, 'x')} ${getGapClassName(gapY, 'y')} ${getLayoutClassName(
        layouts,
      )} ${getLayoutClassName(layouts, 'sm')} ${getLayoutClassName(layouts, 'md')} ${getLayoutClassName(
        layouts,
        'lg',
      )} ${getLayoutClassName(layouts, 'xl')} ${className}`}
    >
      {layouts.some((l) => l === '2-3' || l === '3-2')
        ? React.Children.toArray(children).map((child, i) => (
          <div
            key={i}
            className={`${getChildClassName(layouts)} ${getChildClassName(layouts, 'sm')} ${getChildClassName(
              layouts,
              'md',
            )} ${getChildClassName(layouts, 'lg')} ${getChildClassName(layouts, 'xl')}`}
          >
            {child}
          </div>
        ))
        : children}
    </div>
  );
};

function getLayoutClassName(layouts: Layout[], size?: Size): string {
  const layout = getLayout(layouts, size);
  switch (layout) {
    case '1':
      return prefixSize(size, 'grid-cols-1');
    case '1-1':
      return prefixSize(size, 'grid-cols-2');
    case '2-3':
    case '3-2':
    case '1-1-1':
      return prefixSize(size, 'grid-cols-3');
    case '1-1-1-1':
      return prefixSize(size, 'grid-cols-4');
    default:
      return '';
  }
}

function getChildClassName(layouts: Layout[], size?: Size): string {
  const layout = getLayout(layouts, size);
  switch (layout) {
    case '2-3':
      return prefixSize(size, 'even:col-span-2');
    case '3-2':
      return prefixSize(size, 'odd:col-span-2');
    default:
      return prefixSize(size, 'odd:col-span-1');
  }
}

function getGapClassName(gap: Size, axis: 'x' | 'y'): string {
  switch (gap) {
    case 'sm':
      return `gap-${axis}-2`;
    case 'md':
      return `gap-${axis}-4`;
    case 'lg':
      return `gap-${axis}-8`;
    case 'xl':
      return `gap-${axis}-12`;
    default:
      return `gap-${axis}-0`;
  }
}

const sizes: Size[] = ['sm', 'md', 'lg', 'xl'];
function getLayout(layouts: Layout[], size?: Size) {
  // Use highest defined layout for the size
  let idx = sizes.indexOf(size) + 1;
  let layout: Layout;
  while (!layout && idx >= 0) {
    layout = layouts[idx];
    idx--;
  }
  return layout;
}
