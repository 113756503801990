import { Button } from 'components/Common';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectAssetList } from '../List/state/selectors';
import { messages } from '../messages';
import { CreateOptions } from './CreateOptions';

type Props = {
  setShowAssetList: (show: boolean) => void;
};

export const HeaderTablet: React.FC<Props> = ({ setShowAssetList }) => {
  const intl = useIntl();
  const assets = useSelector(selectAssetList);

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <span className="text-xl md:text-2xl text-secondary-main font-bold">{intl.formatMessage(messages.wallet)}</span>
      </div>
      <div className="flex items-center">
        <div>
          <CreateOptions />
        </div>
        {assets.length != 0 && (
          <div className="pl-4">
            <Button type="primary" size="s" onClick={() => setShowAssetList(true)}>
              {intl.formatMessage(messages.menu)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
