/*
 *
 * Users reducer
 *
 */
import produce from 'immer';
import { merge } from 'lodash';
import { UserAction } from './actions';
import { UsersListState } from './types';

export const initialState: UsersListState = {
  users: {},
};

export type UserListState = typeof initialState;

const userReducer = (state = initialState, action: UserAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/UserAccount/LOAD_USERS_SUCCESS':
        if (Array.isArray(action.users)) {
          action.users.forEach((user) => {
            draftState.users[user.address] = merge(draftState.users[user.address] || {}, user);
          });
        } else {
          merge(draftState.users, action.users);
        }
        return draftState;

      // From outside containers
      // Manage
      case 'app/Organization/Manage/REMOVE_MEMBER_SUCCESS':
        draftState.users[action.address].organizations = draftState.users[action.address]?.organizations.filter(
          (org) => org !== action.organizationAddress,
        );
        return draftState;

      default:
        return draftState;
    }
  });

export default userReducer;
