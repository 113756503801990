import React from 'react';
import { ButtonSize } from './types';
import { getIconSize } from './style';

type IconProps = {
  size: ButtonSize;
  icon: React.ReactNode;
  position: 'start' | 'end';
};

export const Icon: React.FC<IconProps> = ({ icon, size, position }) => {
  const style: React.CSSProperties = {
    marginTop: -2,
  };

  if (position === 'start') style.marginRight = 6;

  if (position === 'end') style.marginLeft = 6;

  return <span style={style}>{React.cloneElement(icon as React.ReactElement<any>, { size: getIconSize(size) })}</span>;
};
