import { selectAPIClient } from 'containers/App/state/selectors';
import { put, takeLatest } from 'redux-saga/effects';
import { all, call, select } from 'typed-redux-saga';
import {
  AddResourceFilterAction,
  addResourceFilterSuccess,
  LoadResourceFiltersAction,
  loadResourceFiltersSuccess,
  RemoveResourceFilterAction,
  removeResourceFilterSuccess,
  UpdateResourceFilterAction,
  updateResourceFilterSuccess,
} from './actions';

/**
 * Worker Sagas
 */
export function* loadResourceFilters({ onSuccess }: LoadResourceFiltersAction) {
  const client = yield* select(selectAPIClient);
  const response = yield* call(client.getResourceFilters);
  yield put(loadResourceFiltersSuccess(response.data));
  onSuccess?.();
}

export function* addResourceFilter({ resourceFilter, onSuccess }: AddResourceFilterAction) {
  const client = yield* select(selectAPIClient);
  yield* call(client.createResourceFilter, resourceFilter);
  yield put(addResourceFilterSuccess());
  onSuccess();
}

export function* removeResourceFilter({ id, onSuccess }: RemoveResourceFilterAction) {
  const client = yield* select(selectAPIClient);
  yield* call(client.deleteResourceFilter, id);
  yield put(removeResourceFilterSuccess());
  onSuccess();
}

export function* updateResourceFilter({ id, resourceFilter, onSuccess }: UpdateResourceFilterAction) {
  const client = yield* select(selectAPIClient);
  yield* call(client.updateResourceFilter, id, resourceFilter);
  yield put(updateResourceFilterSuccess());
  onSuccess();
}

/**
 * Watcher Sagas
 */
export function* watchLoadResourceFilters() {
  yield all([
    yield takeLatest('app/User/Current/ResourceFilters/LOAD_RESOURCE_FILTERS', loadResourceFilters),
    yield takeLatest('app/User/Current/ResourceFilters/ADD_RESOURCE_FILTER', addResourceFilter),
    yield takeLatest('app/User/Current/ResourceFilters/REMOVE_RESOURCE_FILTER', removeResourceFilter),
    yield takeLatest('app/User/Current/ResourceFilters/UPDATE_RESOURCE_FILTER', updateResourceFilter),
  ]);
}
