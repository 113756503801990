import { selectAPIClient } from 'containers/App/state/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { LoadAssets, loadAssetsSuccess } from './actions';

export function* loadAssets(action: LoadAssets) {
  const client = yield* select(selectAPIClient);
  const { data: assets } = yield* call(client.getAssets);
  yield* put(loadAssetsSuccess(assets));
  action.onLoaded?.();
}

export function* watchAssets() {
  yield* takeLatest('app/Assets/List/LOAD_ASSETS', loadAssets);
}
