import { UserTask, UserTaskTypeEnum } from '@monax/types';
import { approval, blockchain, connection, fulfillObligation, party, sign } from 'components/IconButton/icons';
import { useTaskMessage, useTaskTitle } from 'containers/Task/hooks';
import { Palette } from 'containers/Theme/types';
import { useTheme } from 'containers/Theme/useTheme';
import React, { MouseEvent } from 'react';

const icon = (task: UserTask) => {
  if (task.type === UserTaskTypeEnum.signingActivity) {
    return sign;
  }
  if (task.type === UserTaskTypeEnum.activity) {
    return fulfillObligation;
  }
  if (task.type === UserTaskTypeEnum.blockchainSignature) {
    return blockchain;
  }
  if (task.type === UserTaskTypeEnum.connection) {
    return connection;
  }
  if (task.type === UserTaskTypeEnum.renewal) {
    return fulfillObligation;
  }
  if (task.type === UserTaskTypeEnum.partyConfirmation) {
    return party;
  }
  return approval;
};

const iconColor = (task: UserTask, palette: Palette) => {
  if (task.type === UserTaskTypeEnum.signingActivity) {
    return palette.primary.main;
  }
  if (task.type === UserTaskTypeEnum.activity) {
    return palette.accent1.main;
  }
  if (task.type === UserTaskTypeEnum.blockchainSignature) {
    return palette.primary.main;
  }
  if (task.type === UserTaskTypeEnum.connection) {
    return palette.secondary.main;
  }
  if (task.type === UserTaskTypeEnum.renewal) {
    return palette.accent1.main;
  }
  if (task.type === UserTaskTypeEnum.partyConfirmation) {
    return palette.accent2.main;
  }
  return palette.success.main;
};

type Props = {
  task: UserTask;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};
function Task({ task, onClick }: Props) {
  const { getMessage } = useTaskMessage();
  const { getTitle } = useTaskTitle();
  const { palette } = useTheme();
  return (
    <button
      type="button"
      className="text-left flex items-center w-full h-auto text-xs px-3 pt-3 pb-2 text-gray-500 hover:bg-gray-200"
      onClick={onClick}
    >
      {icon(task)(iconColor(task, palette))}
      <div className="ml-2" style={{ width: 'calc(100% - 32px)' }}>
        <p className="truncate font-semibold mb-1 text-secondary-main text-sm">{getTitle(task)}</p>
        <p className="truncate text-gray-400 text-xs">{getMessage(task)}</p>
      </div>
    </button>
  );
}

export default Task;
