import configs from 'configs';
import { selectWebAuth } from 'containers/Auth/Auth/state/selectors';
import { selectCurrentUser } from 'containers/User/Current/Profile/state/selectors';
import graphic from 'images/error/error.svg';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { track } from 'utils/analytics';
import { Action, ErrorLayout } from './controls';
import { messages } from './messages';

export const NoOrganizationError: React.FC = () => {
  const intl = useIntl();
  const { email, userid } = useSelector(selectCurrentUser);
  const webAuth = useSelector(selectWebAuth);
  useEffect(() => {
    track({ event: 'user.no-organization-error' });
  }, []);
  return (
    <ErrorLayout
      graphic={graphic}
      title={intl.formatMessage(messages.noOrganizationErrorHeader)}
      description={intl.formatMessage(messages.noOrganizationErrorDescription, {
        email: <span className="font-semibold">{email}</span>,
      })}
      actions={
        <>
          <Action
            href={`mailto:${configs.emails.support}?subject=${encodeURIComponent(
              `Trouble creating an account with User ${userid} on Suscribo`,
            )}&body=${encodeURIComponent(`Hi Suscribo,
            I've had an issue creating my account on Suscribo. I tried using this email: ${email} and this is my User ID: ${userid}. 
            Is there anything you can do to help?`)}`}
          >
            {intl.formatMessage(messages.contactUs)}
          </Action>
          <Action
            onClick={() => {
              webAuth.logout({
                returnTo: `${window.location.origin}/register`,
              });
            }}
          >
            {intl.formatMessage(messages.register)}
          </Action>
        </>
      }
    />
  );
};
