import { requesterMayCancel, requesterMayDelete } from '@monax/aeger/dist/agreement/agreement';
import { AgreementFilter, AgreementListItem, AgreementStatusEnum, LegalState } from '@monax/types';
import { selectAgreementResourceFilters } from 'containers/User/Current/ResourceFilters/state/selectors';
import { filterIsEmpty } from 'containers/User/Current/ResourceFilters/utils';
import { createSelector } from 'reselect';
import { ApplicationState } from 'types';
import { SelectedState } from '../../types';
import { isSelected } from '../util/utils';

const MAX_BULK_ACTIONS = 15;

const rootSelector = (state: ApplicationState) => state.agreement.list.agreement;

export const selectAgreementList = createSelector(rootSelector, (state) => state.agreements);

export const selectTotalAgreements = createSelector(rootSelector, (state) => state.totalAgreements);

export const selectLoadingAgreements = createSelector(rootSelector, (state) => state.loading);

export const selectSortAgreements = createSelector(rootSelector, (state) => state.sort);

export const selectSearchAgreements = createSelector(rootSelector, (state) => state.search);

export const selectFilterAgreementAddresses = createSelector(rootSelector, (state) => state.filterAgreementAddresses);

export const selectAgreementResourceFilterId = createSelector(rootSelector, (state) => state.resourceFilterId);

export const selectDefaultAgreementResourceIsSelected = createSelector(selectAgreementResourceFilterId, (id) => !id);

export const selectDefaultAgreementResourceFilter = createSelector(rootSelector, (state) => state.filter);

export const selectDefaultAgreementResourceFilterIsEmpty = createSelector(
  selectDefaultAgreementResourceFilter,
  (filter) => filterIsEmpty(filter),
);

export const selectAgreementResourceFilter = createSelector(
  selectAgreementResourceFilters,
  selectAgreementResourceFilterId,
  (filters, id) => filters.find((f) => f.id === id),
);

export const selectCurrentAgreementResourceFilterFilter = createSelector(
  selectDefaultAgreementResourceIsSelected,
  selectDefaultAgreementResourceFilter,
  selectAgreementResourceFilter,
  (defaultIsSelected, defaultFilter, resourceFilter): AgreementFilter =>
    defaultIsSelected ? defaultFilter : (resourceFilter?.filter as AgreementFilter),
);

export const selectSelectedAgreements = createSelector(rootSelector, (state) => state.selectedAgreements);

export const selectSelectedAgreementListItems = createSelector(
  selectAgreementList,
  selectSelectedAgreements,
  (agreements, selected) => agreements.filter((a) => isSelected(selected, a)),
);

export const selectSelectedAgreementsState = createSelector(
  selectAgreementList,
  selectSelectedAgreements,
  (agreementList, selectedAddress): SelectedState => {
    if (selectedAddress.length === 0) return 'none-selected';
    if (selectedAddress.length === agreementList.length) return 'all-selected';
    return 'indeterminate-selected';
  },
);

export const selectSelectedAgreementsAgreements = createSelector(selectSelectedAgreements, (agreements) =>
  agreements.filter((a) => !!a.address),
);
export const selectSelectedAgreementsDrafts = createSelector(selectSelectedAgreements, (agreements) =>
  agreements.filter((a) => !!a.id),
);

export const selectCanDownload = createSelector(
  selectSelectedAgreementsAgreements,
  (agreements) => !!agreements.length,
);

export const selectCanDelete = createSelector(selectSelectedAgreementListItems, (agreements) => {
  return (
    agreements.length > 0 &&
    agreements.length <= MAX_BULK_ACTIONS &&
    agreements.every((a) => !!a.address && requesterMayDelete(a.status as LegalState, a.isOwner, a.parties.length))
  );
});

export const selectCanCancel = createSelector(selectSelectedAgreementListItems, (agreements) => {
  return (
    agreements.length > 0 &&
    agreements.length <= MAX_BULK_ACTIONS &&
    agreements.every((a) => !!a.address && requesterMayCancel(a.status as LegalState, a.mayActAsParty, a.canceled_by))
  );
});

export const selectCanDeleteAgreementsDrafts = createSelector(
  selectSelectedAgreementsAgreements,
  selectSelectedAgreementsDrafts,
  (agreements, drafts) => agreements.length === 0 && drafts.length > 0,
);

export const selectCanCancelDraft = createSelector(
  selectSelectedAgreements,
  selectAgreementList,
  (selected, agreements) => {
    if (selected.length !== 1) {
      return false;
    }
    const draft = agreements.find((agr) => agr.id === selected[0].id);
    return draft?.status === AgreementStatusEnum.PENDING_PARTY_CONFIRMATION;
  },
);

const draftIsPendingApproval = (draft: AgreementListItem): boolean => {
  return draft?.status === AgreementStatusEnum.PENDING_APPROVAL && !!draft.task;
};

export const selectCanApproveDraft = createSelector(
  selectSelectedAgreementsDrafts,
  selectAgreementList,
  (selectedDrafts, agreements) => {
    if (selectedDrafts.length === 0 || selectedDrafts.length <= MAX_BULK_ACTIONS) {
      return false;
    }
    return selectedDrafts.map((d) => agreements.find((a) => a.id === d.id)).every(draftIsPendingApproval);
  },
);
