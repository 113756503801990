import React from 'react';
import { useIntl } from 'react-intl';
import { messages } from './messages';

export const AssetTableHeader: React.FC = () => {
  const intl = useIntl();

  return (
    <div className="flex justify-between">
      <div>{intl.formatMessage(messages.yourWallet)}</div>
    </div>
  );
};
