import { useBreakpoint } from 'lib/hooks/useBreakpoint';
import React from 'react';

type Props = {
  className?: string;
};

export const Container: React.FC<Props> = (props) => {
  const { isAtLeast } = useBreakpoint();

  let padding = 'px-4';
  if (isAtLeast('lg')) padding = 'px-24';
  else if (isAtLeast('md')) padding = 'px-12';
  else if (isAtLeast('sm')) padding = 'px-8';

  return <div className={`w-full ${padding} ${props.className}`}>{props.children}</div>;
};
