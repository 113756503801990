import { CustomerTypeEnum } from '@monax/types';
import { LazyLoader } from 'components/LazyLoader';
import { Layout } from 'containers/App/components/Layout';
import { Route } from 'containers/App/components/Route';
import { selectCurrentOrganizationCustomerType } from 'containers/Organization/Current/state/selectors';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import adminPortalReducer from './reducer';

const Home = LazyLoader(() => import('./Home/Home'));
const UserRecord = LazyLoader(() => import('./User/User'));
const OrganizationRecord = LazyLoader(() => import('./Organization/Organization'));

const AdminPortalRoutes: React.FC = () => {
  useInjectReducer({ key: 'adminPortal', reducer: adminPortalReducer });

  const customerType = useSelector(selectCurrentOrganizationCustomerType);
  return (
    <React.Fragment>
      <Helmet>
        <title>AdminPortal</title>
      </Helmet>

      {(customerType === CustomerTypeEnum.PLATFORM_ADMIN_CLM ||
        customerType === CustomerTypeEnum.MANAGED_CLM ||
        customerType === CustomerTypeEnum.ENTERPRISE_CLM) && (
        <Route
          pageCategory="Admin"
          pageName="Admin Portal"
          layout={Layout}
          exact
          path="/admin-portal"
          component={Home}
        />
      )}
      {customerType === CustomerTypeEnum.PLATFORM_ADMIN_CLM && (
        <Route
          pageCategory="Admin"
          pageName="User Record"
          layout={Layout}
          exact
          path="/admin-portal/users/:userAddress"
          component={UserRecord}
        />
      )}
      {(customerType === CustomerTypeEnum.PLATFORM_ADMIN_CLM || customerType === CustomerTypeEnum.MANAGED_CLM) && (
        <Route
          pageCategory="Admin"
          pageName="Organization Record"
          layout={Layout}
          exact
          path="/admin-portal/organizations/:organizationAddress"
          component={OrganizationRecord}
        />
      )}
    </React.Fragment>
  );
};

export { AdminPortalRoutes };
