import { AutoOverflow } from 'components/Layout/AutoOverflow';
import { Layout } from 'containers/App/components/Layout';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { HeaderMobile } from './components';
import { loadAssets } from './List/state/actions';
import { watchAssets } from './List/state/saga';

export const AssetLayoutMobile: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  useInjectSaga({
    key: 'load-assets',
    saga: watchAssets,
  });

  useEffect(() => {
    dispatch(loadAssets());
  }, []);

  return (
    <Layout>
      <div className="flex h-full w-full flex-col">
        <div className="pb-3">
          <HeaderMobile />
        </div>
        <AutoOverflow>{children}</AutoOverflow>
      </div>
    </Layout>
  );
};
