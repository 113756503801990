/**
 * REDUX-SAGA
 */
export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

/**
 * REDUX-FORM
 */

// FORM ACTIONS
export const INITIALIZE_FORM = '@@redux-form/INITIALIZE';
export const CHANGE_FORM = '@@redux-form/CHANGE';
