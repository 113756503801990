import { useBreakpoint } from 'lib/hooks/useBreakpoint';
import React from 'react';
import { Container } from './Container';
import { NavbarDesktop } from './Nav/NavbarDesktop';
import { NavbarMobile } from './Nav/NavbarMobile';

type Props = {};

export const Layout: React.FC<Props> = (props) => {
  const { isAtLeast } = useBreakpoint();

  return (
    <div id="main-layout" className="flex h-full w-full flex-col">
      {isAtLeast('lg') ? <NavbarDesktop /> : <NavbarMobile />}
      <div className="h-full w-full flex-1 overflow-hidden pt-4 sm:py-7 bg-gray-100">
        <Container className="flex h-full w-full flex-col">{props.children}</Container>
      </div>
    </div>
  );
};
