// This is a .js file so tailwind config can read it

// mobile-first system; classes without specified screen size will apply to all screens and the following should be used to apply styling to anything larger:
// Maybe think about custom queries here?: https://tailwindcss.com/docs/breakpoints#custom-media-queries
// because some layouts are height-dependent

const screens = {
  widths: {
    sm: '600px',
    md: '800px',
    lg: '1200px',
    xl: '1440px',
  },
};

module.exports = screens;
