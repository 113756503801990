import { useField } from 'formik';
import { useMobile } from 'lib/hooks';
import React, { FocusEventHandler } from 'react';
import { useIntl } from 'react-intl';
import ReactSelect, { components as allComponents, OptionsType } from 'react-select';
import { Option } from 'types/form';
import { FormItem } from '../FormItem';
import { useInputState } from '../Hooks';
import { InputConfig } from '../types';
import { getSelectedValue, onSelectedValueChange, useGetStyles } from './helpers';
import { messages } from './messages';

type Props = {
  name: string;
  label?: string;
  labelAddon?: React.ReactNode;
  infoText?: string;
  isMulti?: boolean;
  placeholder?: string;
  options: OptionsType<Option>;
  disabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  inputConfig?: InputConfig;
  onBlur?: FocusEventHandler;
  noOptionsMessage?: React.FC;
  portal?: boolean;
};

export const Select: React.FC<Props> = (props) => {
  const { label, infoText, labelAddon, inputConfig, ...rest } = props;
  const [input, meta, helpers] = useField(props);

  const inputState = useInputState(inputConfig, meta);

  return (
    <FormItem label={label} labelAddon={labelAddon} inputState={inputState} infoText={infoText}>
      <SelectControl
        {...rest}
        showError={inputState.showError}
        value={input.value}
        onChange={helpers.setValue}
        onBlur={() => helpers.setTouched(true)}
      />
    </FormItem>
  );
};

type SelectControlProps = Omit<Props, 'labelAddon' | 'inputConfig' | 'name' | 'label'> & {
  name?: string;
  value: unknown;
  onChange: (value: unknown) => void;
  onBlur?: (event: FocusEventHandler) => void;
  showError?: boolean;
  className?: string;
  NoOptionsMessage?: React.FC;
};

export const SelectControl: React.FC<SelectControlProps> = (props) => {
  const intl = useIntl();
  const {
    options,
    value,
    onChange,
    onBlur,
    className,
    isMulti,
    disabled,
    isLoading,
    showError,
    placeholder,
    isClearable,
    noOptionsMessage,
    portal,
    ...input
  } = props;

  const { isMobile } = useMobile();

  const styles = useGetStyles(showError);
  const components: { NoOptionsMessage?: typeof allComponents.NoOptionsMessage } = {};
  if (noOptionsMessage) {
    components.NoOptionsMessage = noOptionsMessage;
  }

  return (
    <ReactSelect
      {...input}
      isClearable={isClearable}
      isMulti={isMulti}
      isDisabled={disabled}
      className={className}
      isLoading={isLoading}
      closeMenuOnSelect={!isMulti}
      styles={styles}
      value={getSelectedValue(isMulti, options, value)}
      placeholder={placeholder || intl.formatMessage(messages.defaultPlaceholder)}
      noOptionsMessage={() => intl.formatMessage(messages.noOptions)}
      onChange={onSelectedValueChange(isMulti, onChange)}
      options={options}
      onBlur={onBlur}
      classNamePrefix="react-select"
      components={components}
      isSearchable={!isMobile} // prevent opening up the keyboard
      // render menu in portal so it renders over parent boundaries
      menuPortalTarget={portal ? document.body : undefined}
    />
  );
};
