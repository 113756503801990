import { BaseTextFieldProps, TextField } from '@material-ui/core';
import { useField } from 'formik';
import React, { ChangeEventHandler, CSSProperties } from 'react';
import { InputEndAdornment } from './common';
import { FormItem } from './FormItem';
import { useInputState } from './Hooks';
import { InputConfig } from './types';

type Props = {
  name: string; // make name required
  id?: string;
  label?: React.ReactNode;
  labelAddon?: React.ReactNode;
  infoText?: string;
  autoFocus?: boolean;
  endAdornment?: React.ReactNode;
  inputConfig?: InputConfig;
  placeholder?: string;
  disabled?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  min?: number;
  className?: string;
  step?: string;
};

export const Input: React.FC<Props> = (props) => {
  const [input, meta] = useField(props.name);

  const { label, labelAddon, infoText, endAdornment, inputConfig, ...rest } = props;

  const inputState = useInputState(inputConfig, meta);

  return (
    <FormItem label={label} labelAddon={labelAddon} inputState={inputState} infoText={infoText}>
      <TextField
        {...input}
        {...rest}
        value={input.value ?? ''}
        id={input.name}
        error={inputState.showError}
        size="small"
        variant="outlined"
        InputProps={{
          endAdornment: (
            <React.Fragment>
              {endAdornment}
              <InputEndAdornment inputState={inputState} />
            </React.Fragment>
          ),
        }}
      />
    </FormItem>
  );
};

type InputControlProps = Omit<Props, 'labelAddon' | 'inputConfig' | 'name'> & {
  name?: string;
  value: unknown;
  onChange: ChangeEventHandler<HTMLInputElement>;
  style?: CSSProperties;
} & BaseTextFieldProps;

export const InputControl: React.FC<InputControlProps> = ({ ...input }) => {
  return <TextField {...input} id={input.name} size="small" variant="outlined" />;
};
