import { createSelector } from 'reselect';
import { ApplicationState } from 'types';

const rootSelector = (state: ApplicationState) => state.asset.list;

export const selectAssetList = createSelector(rootSelector, (state) => state.assets);

export const selectLoadingAssets = createSelector(rootSelector, (state) => state.loading);

export const selectAssetSearchText = createSelector(rootSelector, (state) => state.searchText);

export const selectAssetListFiltered = createSelector(selectAssetList, selectAssetSearchText, (list, searchText) =>
  !searchText
    ? list
    : list.filter((a) => a.body.name.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) != -1),
);

export const selectAnyAssets = createSelector(selectAssetList, (assets) => assets.length > 0);
