import { UserTask } from '@monax/types';
import { selectUserTasks } from 'containers/Task/List/selectors';
import NoNotification from 'images/task/womanLookingAtEmptyTaskList.svg';
import React, { MouseEvent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import messages from '../messages';
import Task from './Task';

type Props = {
  tasks: UserTask[];
  closeMenu: (e: MouseEvent<HTMLButtonElement>) => void;
};

function PendingList({ tasks, closeMenu, history }: Props & RouteComponentProps) {
  const getOnClick = useCallback(
    (task: UserTask) => (e: MouseEvent<HTMLButtonElement>) => {
      closeMenu(e);
      history.push(task.redirectURL);
    },
    [],
  );

  if (!tasks.length) {
    return (
      <div className="p-6 text-center">
        <img className="mx-auto" src={NoNotification} alt="No Notifications graphic" />
        <p className="font-medium mt-2 text-base text-secondary-main">
          <FormattedMessage {...messages.emptyNotificationsListContent} />
        </p>
      </div>
    );
  }
  return (
    <React.Fragment>
      {tasks.map((task, i) => (
        <Task key={`${task.redirectURL}-${i.toString()}`} task={task} onClick={getOnClick(task)} />
      ))}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  tasks: selectUserTasks(state),
});
const withConnect = connect(mapStateToProps);

export default withRouter(withConnect(PendingList));
