import { AnchorButton } from 'components/Common';
import React, { MouseEventHandler } from 'react';

type Props = {
  href?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
};
export const Action: React.FC<Props> = (props) => {
  return (
    <AnchorButton onClick={props.onClick} className="mr-6 last:mr-0" href={props.href}>
      {props.children}
    </AnchorButton>
  );
};
