import React, { useEffect } from 'react';
import { Route as ReactRoute, RouteProps } from 'react-router-dom';
import { page, PageCategory } from 'utils/analytics';

type Props = {
  layout?: React.FC;
  pageCategory?: PageCategory;
  pageName?: string;
};

export const Route: React.FC<Props & RouteProps> = ({
  layout: Layout = React.Fragment,
  component: Component,
  pageCategory,
  pageName,
  ...routeProps
}) => {
  useEffect(() => {
    if (pageCategory && pageName) page(pageCategory, pageName);
  }, []);
  return (
    <ReactRoute
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
      {...routeProps}
    />
  );
};
