import { Dialog as MuiDialog, DialogActions, DialogContent } from '@material-ui/core';
import { Button } from 'components/Common';
import { PaletteKey } from 'containers/Theme/types';
import React from 'react';
import { useIntl } from 'react-intl';
import { DialogTitle } from './DialogTitle';
import messages from './messages';

type Props = {
  title: React.ReactNode;
  content: React.ReactNode;
  isOpen: boolean;
  className?: PaletteKey;
  onClose?: () => void;
  disableEscape?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'lg' | 'md';
  transparentBackdrop?: boolean;
  ChildrenWrapper?: React.FC;
  cancel?: React.ReactNode; // custom cancel button; will fall back default "Cancel" button if onClose is provided
  submit?: React.ReactNode;
};

export const Dialog2: React.FC<Props> = (props) => {
  const {
    className = 'primary',
    title,
    content,
    isOpen,
    onClose,
    maxWidth,
    fullWidth,
    disableEscape,
    cancel,
    submit,
    transparentBackdrop,
    ChildrenWrapper,
  } = props;
  const intl = useIntl();
  const children = (
    <>
      <DialogTitle className={className} title={title} cancel={onClose} disabled={disableEscape}></DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      <DialogActions>
        {cancel}
        {!cancel && onClose && (
          <Button onClick={onClose} type="gray-link" buttonType="button" disabled={disableEscape}>
            {intl.formatMessage(messages.cancel)}
          </Button>
        )}
        {submit}
      </DialogActions>
    </>
  );

  return (
    <MuiDialog
      disableBackdropClick={disableEscape}
      disableEscapeKeyDown={disableEscape}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      BackdropProps={{
        style: { background: `rgba(255, 255, 255, ${transparentBackdrop ? '0' : '0.8'})` },
      }}
      open={isOpen}
    >
      {ChildrenWrapper ? <ChildrenWrapper>{children}</ChildrenWrapper> : children}
    </MuiDialog>
  );
};
