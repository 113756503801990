import produce from 'immer';
import { ForeignAddressesState } from '../types';
import { ForeignAddressAction } from './actions';

export const initialState: ForeignAddressesState = {
  addresses: [],
  requesting: false,
  error: null,
};

const reducer = (state: ForeignAddressesState = initialState, action: ForeignAddressAction) =>
  produce(
    state,
    (draftState): ForeignAddressesState => {
      switch (action.type) {
        case 'app/User/Current/ForeignAddresses/LOAD_FOREIGN_ADDRESSES':
          draftState.requesting = true;
          draftState.error = null;
          return draftState;
        case 'app/User/Current/ForeignAddresses/LOAD_FOREIGN_ADDRESSES_SUCCESS':
          draftState.addresses = action.addresses;
          draftState.requesting = false;
          return draftState;
        case 'app/User/Current/ForeignAddresses/ADD_FOREIGN_ADDRESS':
        case 'app/User/Current/ForeignAddresses/UPDATE_FOREIGN_ADDRESS':
        case 'app/User/Current/ForeignAddresses/REMOVE_FOREIGN_ADDRESS':
          draftState.requesting = true;
          draftState.error = null;
          return draftState;
        case 'app/User/Current/ForeignAddresses/ADD_FOREIGN_ADDRESS_SUCCESS':
        case 'app/User/Current/ForeignAddresses/UPDATE_FOREIGN_ADDRESS_SUCCESS':
        case 'app/User/Current/ForeignAddresses/REMOVE_FOREIGN_ADDRESS_SUCCESS':
          draftState.requesting = false;
          return draftState;
        case 'app/User/Current/ForeignAddresses/RECEIVE_FOREIGN_ADDRESS_ERROR':
          draftState.requesting = false;
          draftState.error = action.error;
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;
