import { LazyLoader } from 'components/LazyLoader';
import { Layout } from 'containers/App/components/Layout';
import { Route } from 'containers/App/components/Route';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';

const Overview = LazyLoader(() => import('./Overview'));

const OverviewRoutes: React.FC = () => (
  <>
    <Helmet>
      <title>Overview</title>
    </Helmet>
    <Switch>
      <Route path="/overview" component={Overview} layout={Layout} />
    </Switch>
  </>
);

export { OverviewRoutes };
