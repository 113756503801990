import React, { HTMLProps } from 'react';
import { track as trackEvent, TrackProps } from 'utils/analytics';
import { Icon } from './Icon';
import { getClasses } from './style';
import { ButtonSize, ButtonType, TextTransform } from './types';

export type AnchorButtonProps = {
  type?: ButtonType;
  size?: ButtonSize;
  textTransform?: TextTransform;
  icon?: React.ReactNode;
  iconEnd?: React.ReactNode;
  className?: string;
  track?: TrackProps;
  disableResponsiveness?: boolean;
  anchorRef?: React.Ref<HTMLAnchorElement>;
};

export const AnchorButton: React.FC<HTMLProps<HTMLAnchorElement> & AnchorButtonProps> = (props) => {
  const {
    type = 'primary',
    size = 'm',
    textTransform = 'normal-case',
    icon,
    iconEnd,
    className = '',
    track,
    disableResponsiveness,
    onClick,
    anchorRef,
    ...anchorProps
  } = props;

  const classes =
    getClasses(type, size, className, disableResponsiveness, textTransform, !!icon) + ' inline-flex items-center';

  return (
    <a
      ref={anchorRef}
      className={classes}
      onClick={(e) => {
        onClick?.(e);
        if (track) trackEvent(track);
      }}
      {...anchorProps}
    >
      <>
        {icon && <Icon icon={icon} size={size} position={'start'} />}
        <span>{props.children}</span>
        {iconEnd && <Icon icon={iconEnd} size={size} position={'end'} />}
      </>
    </a>
  );
};
