import { createMuiTheme } from '@material-ui/core/styles';
import screens from 'containers/Theme/screens';
import { parsePixelString } from 'utils/style';
import { Palette } from './types';

export default function getMuiTheme(palette: Palette) {
  let theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: parsePixelString(screens.widths.sm),
        sm: parsePixelString(screens.widths.sm),
        md: parsePixelString(screens.widths.md),
        lg: parsePixelString(screens.widths.lg),
        xl: parsePixelString(screens.widths.lg),
      },
    },
    palette,
    typography: {
      fontFamily: ['Encode Sans Semi Expanded', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  });

  // Add overrides so we have access to theme object within
  theme = createMuiTheme({
    ...theme,
    overrides: {
      MuiButton: {
        root: {
          textTransform: 'none',
        },
      },
      MuiAvatar: {
        root: {
          boxShadow: '0.5px 0.5px 2px rgba(0, 0, 0, 0.15)',
          position: 'static',
        },
      },
      MuiList: {
        padding: {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },
      MuiListItem: {
        root: {
          color: palette.gray[600],
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '40px',
        },
      },
      MuiInputBase: {
        input: {
          backgroundColor: 'white',
        },
        root: {
          '> MuiSelect': {
            root: {},
          },
        },
      },
      MuiListItemText: {
        primary: {
          fontSize: '12px',
          fontWeight: 600,
          color: 'inherit',
        },
      },
      MuiFormControl: {
        root: {
          // backgroundColor: 'white',
          width: '100%',
        },
      },
      MuiBadge: {
        badge: {
          whiteSpace: 'nowrap',
        },
      },
      MuiFormControlLabel: {
        root: {
          marginRight: '0',
          marginLeft: '-9px',
        },
        label: {
          fontSize: '14px',
          fontWeight: 'normal',
          color: palette.info.main,
        },
      },
      MuiFormGroup: {
        root: {
          '&[role=radiogroup]>.MuiFormControlLabel-root': {
            padding: '6px 0',
          },
        },
        row: {
          '&>.MuiFormControlLabel-root': {
            marginLeft: '24px',
          },
          '&>.MuiFormControlLabel-root:first-of-type': {
            marginLeft: '-11px',
          },
        },
      },
      MuiChip: {
        root: {
          height: '20px',
          padding: '0',
          maxWidth: '200px',
          fontSize: '10px',
          fontWeight: 600,
          textTransform: 'uppercase',
        },
      },
      MuiSnackbar: {
        root: {
          borderRadius: '7px',
          overflow: 'hidden',
          height: 'auto',
          boxShadow: '1px 2px 8px rgba(0, 0, 0, 0.1)',
          [theme.breakpoints.up('sm')]: {
            maxWidth: '290px',
          },
        },
      },
      MuiSnackbarContent: {
        root: {
          height: '100%',
          textAlign: 'center',
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-start',
          fontSize: '15px',
          fontWeight: 600,
          borderBottomLeftRadius: '0px',
          borderBottomRightRadius: '0px',
          padding: 0,
        },
        message: {
          height: '100%',
          padding: 0,
          width: '98%',
          display: 'flex',
          flex: 1,
        },
      },
      MuiPopover: {
        paper: {
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: palette.gray[300],
        },
      },
      MuiPaper: {
        rounded: {
          borderRadius: '6px',
        },
      },
      MuiSwitch: {
        root: {
          width: 46,
          height: 25,
          padding: 0,
          margin: 10,
        },
        switchBase: {
          padding: 2,
          '&$checked': {
            transform: 'translateX(20px)',
            color: palette.primary.contrastText,
            '& + $track': {
              backgroundColor: `${palette.primary.main} !important`,
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 21,
          height: 21,
          boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
          backgroundColor: `${'white'} !important`,
        },
        track: {
          borderRadius: 25 / 2,
          backgroundColor: palette.gray[300],
          opacity: 1,
        },
        checked: {},
      },
      MuiTooltip: {
        tooltip: {
          color: palette.info.main,
          backgroundColor: palette.gray[100],
          fontWeight: 600,
          fontSize: '12px',
          padding: '6px 12px',
        },
      },
      MuiTypography: {
        root: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
        subtitle1: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
        h1: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
        h2: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
        h3: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
        h4: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
        h5: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
        h6: {
          color: 'inherit',
          fontSize: 'inherit',
          fontWeight: 'inherit',
        },
      },
      MuiMenuItem: {
        root: {
          color: palette.info.main,
          fontSize: '0.875rem',
        },
      },
      MuiCircularProgress: {
        colorPrimary: {
          color: palette.primary.main,
        },
        colorSecondary: {
          color: palette.primary.contrastText,
        },
      },
      MuiDivider: {
        root: {
          width: '100%',
          // TODO- check this color is being applied
          color: palette.gray[300],
        },
      },
      MuiLinearProgress: {
        root: {
          height: '3px',
        },
        colorPrimary: {
          backgroundColor: palette.primary.extraLight,
        },
      },
      MuiRadio: {
        root: {
          '&$checked': {
            color: palette.primary.main,
            fontWeight: '600',
          },
        },
      },
      MuiTableRow: {
        root: {
          '&$selected': {
            backgroundColor: palette.secondary.extraLight,
          },
          '&$selected:hover': {
            backgroundColor: palette.secondary.extraLight,
          },
        },
      },
      MuiTableCell: {
        stickyHeader: {
          backgroundColor: theme.palette.grey[100],
        },
      },
      MuiTablePagination: {
        root: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
      MuiTabs: {
        root: {
          borderBottom: `1px solid ${palette.gray[200]}`,
        },
        indicator: {
          backgroundColor: palette.primary.main, // this is the underline
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
        },
      },
      MuiTab: {
        root: {
          textTransform: 'none',
          fontWeight: 400,
          fontSize: '15px',
          minHeight: '60px',
          minWidth: '40px',
          '&:hover': {
            color: palette.primary.dark,
            opacity: 1,
          },
          '&$selected': {
            color: palette.primary.main,
          },
          '&:focus': {
            color: palette.primary.main,
            outline: 'none !important',
            '--tw-ring-shadow': 'none !important',
          },
          [theme.breakpoints.up('sm')]: {
            minWidth: '40px',
          },
          [theme.breakpoints.up('md')]: {
            minWidth: '64px',
          },
          [theme.breakpoints.up('lg')]: {
            minWidth: '100px',
          },
        },
        textColorInherit: {
          opacity: 1,
          color: palette.info.main,
        },
        selected: {},
      },
      MuiAccordion: {
        root: {
          fontSize: '14px',
          border: '1px solid rgba(0, 0, 0, .125)',
          '&:not(:last-child)': {
            borderBottom: 0,
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
          },
        },
        expanded: {},
      },
      MuiAccordionSummary: {
        root: {
          fontWeight: 500,
          borderBottom: '1px solid rgba(0, 0, 0, .125)',
          marginBottom: -1,
          minHeight: 46,
          '&$expanded': {
            minHeight: 46,
          },
        },
        content: {
          '&$expanded': {
            margin: '12px 0',
          },
        },
        expanded: {},
      },
      MuiAccordionDetails: {
        root: {
          padding: '16px',
          display: 'inherit',
        },
      },
      MuiAccordionActions: {
        root: {
          padding: 0,
        },
      },
      MuiOutlinedInput: {
        input: {
          fontSize: '14px',
          paddingLeft: '14px',
          color: '#292A2A',
        },
        notchedOutline: {
          border: '1px solid #94A0AD',
          borderRadius: '5px',
          borderWidth: '1px !important',
        },
        multiline: {
          '&.MuiOutlinedInput-marginDense': {
            paddingTop: 9.4,
            paddingBottom: 9.4,
          },
        },
      },
      MuiBackdrop: {
        root: {
          background: 'rgba(255, 255, 255, 0.8)',
        },
      },
      MuiDialog: {
        paperWidthMd: {
          maxWidth: 'calc(100% - 24px)',
          margin: 0,
          [theme.breakpoints.up('md')]: {
            maxWidth: '768px',
          },
        },
        paperWidthLg: {
          maxWidth: 'calc(100% - 24px)',
          margin: 0,
          [theme.breakpoints.up('md')]: {
            maxWidth: '768px',
          },
          [theme.breakpoints.up('lg')]: {
            maxWidth: '1194px',
          },
        },
      },
      MuiDialogTitle: {
        root: {
          padding: '30px 24px',
          display: 'flex',
          flexDirection: 'column',
          fontWeight: 600,
          '& h2': {
            width: '100%',
            fontSize: '16px',
          },
          '& svg': {
            marginRight: '15px',
          },
          [theme.breakpoints.up('md')]: {
            padding: '30px 48px',
            height: '84px',
            maxHeight: '84px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-start',
            '& h2': {
              fontWeight: 600,
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
            },
            '& svg': {
              marginRight: '12px',
            },
          },
        },
      },
      MuiDialogContent: {
        dividers: {
          overflow: 'visible',
          fontSize: '13px',
          color: palette.info.main,
          [theme.breakpoints.up('sm')]: {
            fontWeight: 'normal',
            fontSize: '16px',
            width: '100%',
            lineHeight: '26px',
            whiteSpace: 'pre-wrap',
          },
          padding: '34px 24px',
          [theme.breakpoints.up('sm')]: {
            fontSize: '14px',
          },
          [theme.breakpoints.up('md')]: {
            padding: '30px 48px',
            fontSize: '16px',
          },
        },
      },
      MuiDialogActions: {
        root: {
          flexWrap: 'wrap',
          padding: '22px 33px',
          maxHeight: '84px',
        },
      },
      MuiTextField: {
        root: {
          backgroundColor: 'white',
          '& .MuiOutlinedInput-input': {
            color: palette.info.main,
            borderRadius: '5px',
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input': {
            color: palette.error.dark,
          },
          '& .MuiOutlinedInput-input.Mui-disabled': {
            backgroundColor: palette.gray[200],
            borderRadius: '5px',
          },
          '& .MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: palette.gray[200],
            borderRadius: '5px',
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.gray[500],
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.gray[200],
          },
          '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.primary.main,
          },
          '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.error.light,
          },
          '& .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.error.main,
          },
          '& .MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.gray[200],
          },
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          '&.Mui-checked': {
            color: palette.primary.main,
          },
        },
        indeterminate: {
          color: palette.primary.main,
        },
      },
    },
  });

  return theme;
}
