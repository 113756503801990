import { Capability } from '@monax/types';
import { selectCurrentUserCapabilities } from 'containers/User/Current/Profile/state/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

export const permissionsRequired = (requiredCapabilities: Capability[]) => (Component: React.FC): React.FC => () => {
  const capabilities = useSelector(selectCurrentUserCapabilities);
  if (!requiredCapabilities.every((c) => capabilities.includes(c))) {
    return <Redirect to="/404" />; // don't have /403 page yet
  }
  return <Component />;
};
