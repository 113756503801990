/*
 *
 * LanguageProvider reducer
 *
 */

import { getSupportedLocale } from 'configs';
import produce from 'immer';
import { track } from 'utils/analytics';
import { LocaleState } from '../types';
import { LocaleAction } from './actions';

// get user's locale from browser
export const initialState: LocaleState = {
  locale: getSupportedLocale(navigator.language),
  locales: {
    'en-GB': 'English (UK)',
    'en-US': 'English (US)',
    'es-ES': 'Español (ES)',
  },
};

const languageProviderReducer = (state = initialState, action: LocaleAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Preferences/Locale/CHANGE_LOCALE':
        draftState.locale = action.locale;
        track({
          event: 'user.language-changed',
          payload: {
            language: action.locale,
          },
        });
        return draftState;
      default:
        return draftState;
    }
  });

export default languageProviderReducer;
