import { AuthErrorCode } from '@monax/types';
import { LinkButton } from 'components/Common';
import { ContentLoader } from 'components/Loader';
import { addNotification } from 'containers/App/state/actions';
import { INIT_CONFIG } from 'containers/Auth/Auth/state/constants';
import { AuthLayout, SlidesPanel } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { DEFAULT_NAME } from 'containers/Theme/types';
import { useTheme } from 'containers/Theme/useTheme';
import { Formik } from 'formik';
import { unregister } from 'lib/service-worker';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { ApplicationState } from 'types';
import { track, TrackProps } from 'utils/analytics';
import { parseQueryString } from 'utils/queryString';
import { loginSchema } from 'validation/login';
import { validator } from 'validation/yup';
import { selectWebAuth } from '../Auth/state/selectors';
import { SUPPORTED_SSO_AUTH_PROVIDERS } from '../constants';
import { InvitePanel } from '../controls/InvitePanel';
import { WhiteLabelAuthLayout } from '../controls/WhiteLabelAuthLayout';
import { getAuth0ErrorMessage, getState } from '../utils';
import { FormPanel } from './controls';
import { LoginForm, LoginQueryParameters } from './types';

const validateForm = validator(loginSchema);

const LoginComponent: React.FC<PropsFromState> = ({ webAuth }) => {
  const intl = useIntl();
  const { themeName } = useTheme();
  const dispatch = useDispatch();
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const queryString: LoginQueryParameters = parseQueryString(window.location.search);

  const ssoConnection = SUPPORTED_SSO_AUTH_PROVIDERS.find((ap) => ap === queryString.authProvider);

  useEffect(unregister, []);

  useEffect(() => {
    // If we have an sso connection then auto sign in
    if (ssoConnection) {
      const trackProps: TrackProps = {
        event: 'application.login-with-sso',
        payload: {
          authProvider: ssoConnection,
        },
      };
      track(trackProps);
      webAuth.authorize({
        connection: ssoConnection,
        state: getState(),
      });
    }

    if (queryString.verified) {
      dispatch(
        addNotification({
          message: intl.formatMessage(messages.yourEmailWasVerified),
        }),
      );
    }
    if (queryString.error) {
      dispatch(
        addNotification({
          className: 'error',
          message: queryString.error,
        }),
      );
    }
  }, []);

  const login = (values: LoginForm) => {
    if (loggingIn) return;

    setLoggingIn(true);

    console.log('attempting login');

    webAuth.login(
      {
        username: values.username,
        password: values.password,
        realm: INIT_CONFIG.connection,
        state: getState(),
      },
      (error) => {
        if (error) {
          const message = getAuth0ErrorMessage(intl, error.code as AuthErrorCode, error.description);
          dispatch(addNotification({ className: 'error', message }));
        }
        setLoggingIn(false);
      },
    );
  };

  if (ssoConnection) return <ContentLoader />;

  const signupButton = (
    <LinkButton to={`/register${window.location.search}`} size="s" type="outlined">
      {intl.formatMessage(messages.signup)}
    </LinkButton>
  );

  const Wrapper = themeName === DEFAULT_NAME ? AuthLayout : WhiteLabelAuthLayout;
  //This should change to a subdomain check probably.
  return (
    <>
      <Wrapper
        header={intl.formatMessage(messages.welcomeBackTo, { name: themeName })}
        title={intl.formatMessage(messages.loginToYourAccount)}
        headerAddon={signupButton}
        rightPanel={!!queryString.invitationCode && !!queryString.email ? <InvitePanel /> : <SlidesPanel />}
      >
        <Formik<LoginForm>
          initialValues={{ username: queryString.email || '', password: '' }}
          onSubmit={login}
          validate={validateForm}
          validateOnMount={true}
        >
          <FormPanel
            loading={loggingIn}
            email={queryString.email}
            fixedEmail={!!queryString.invitationCode && !!queryString.email}
          />
        </Formik>
      </Wrapper>
    </>
  );
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
  webAuth: selectWebAuth(state),
});

export const Login = connect(mapStateToProps)(LoginComponent);
