/**
 * The global state selectors
 */

import { APIClient } from '@monax/types/dist/client';
import { selectAuthToken } from 'containers/Auth/Auth/state/selectors';
import { selectCurrentOrganizationAddress } from 'containers/Organization/Current/state/selectors';
import { createSelector, OutputSelector } from 'reselect';
import { ApplicationState } from 'types';
import { generateAPIClient } from 'utils/apiClient';

export const globalSelector = (state: ApplicationState) => state.global;

export const locationSelector = (state: ApplicationState) => state.router.location;

export const searchSelector = (state: ApplicationState) => state.router.location.search;

export const selectAPIClient: OutputSelector<
  ApplicationState,
  APIClient,
  (token: string, organizationAddress: string) => APIClient
> = createSelector(selectAuthToken, selectCurrentOrganizationAddress, (token, organizationAddress) =>
  generateAPIClient(token, organizationAddress),
);

export const selectTimeZoneOffset = (state: ApplicationState) => state.global.timeZoneOffset;

export const selectSnackbar = createSelector(globalSelector, (globalState) => globalState.snackbar);

export const makeSelectUI = (component) =>
  createSelector(globalSelector, (globalState) =>
    component ? globalState.UIComponents[component] : globalState.UIComponents,
  );

export const makeSelectSearch = () => createSelector(searchSelector, (searchString) => searchString);
