/**
 *
 * Snackbar
 *
 */

import { IconButton } from '@material-ui/core';
import MuiSnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { withStyles } from '@material-ui/core/styles';
import { addNotification } from 'containers/App/state/actions';
import { selectSnackbar } from 'containers/App/state/selectors';
import { useTheme } from 'containers/Theme/useTheme';
import React, { ReactNode } from 'react';
import { CheckCircleFill, ExclamationCircleFill, X } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationClassName } from '../types';
import messages from './messages';

export const Snackbar: React.FC = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector(selectSnackbar);
  const intl = useIntl();
  const { palette } = useTheme();

  const clearNotifications = () => {
    dispatch(
      addNotification({
        open: false,
        className: snackbar.className,
      }),
    );
  };

  const getMessage = () => {
    if (snackbar.message) return snackbar.message;
    if (snackbar.messageDescriptor) return intl.formatMessage(snackbar.messageDescriptor, snackbar.messageValues);
    return '';
  };

  const getIcon = (type: NotificationClassName) => {
    switch (type) {
      case 'success':
        return <CheckCircleFill size={22} color={palette.success.main} />;
      case 'error':
        return <ExclamationCircleFill size={22} color={palette.error.main} />;
      case 'warning':
        return <ExclamationCircleFill size={22} color={palette.warning.main} />;
      case 'info':
        return <ExclamationCircleFill size={22} color={palette.info.main} />;
      default:
        return <ExclamationCircleFill size={22} color={palette.info.main} />;
    }
  };

  const getContentWrapper = (type: NotificationClassName) => {
    switch (type) {
      case 'success':
        return SuccessContent;
      case 'error':
        return ErrorContent;
      case 'warning':
        return WarningContent;
      case 'info':
        return SecondaryContent;
      default:
        return SecondaryContent;
    }
  };

  const selectContent = (type: NotificationClassName, content: ReactNode) => {
    const icon = getIcon(type);
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          style={{
            padding: '19px 0px 19px 16px',
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div className="bg-white rounded-xl flex align-center "> {icon}</div>
        </div>
        <div style={{ textAlign: 'left', padding: '16px 0px 16px 16px' }}>{content}</div>
      </div>
    );
  };

  const snackbarMessage = getMessage();
  let message;
  if (!snackbar.open) {
    message = '';
  } else if (snackbarMessage) {
    message = selectContent(snackbar.className, snackbarMessage);
  } else if (snackbar.className === 'error' || snackbar.className === 'warning') {
    message = selectContent(snackbar.className, intl.formatMessage(messages.genericErrorMessage));
  } else {
    message = '';
  }

  const ContentWrapper = getContentWrapper(snackbar.className);

  return (
    <MuiSnackbar
      open={snackbar.open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={clearNotifications}
      autoHideDuration={snackbar.autoHideDuration}
    >
      <ContentWrapper
        message={message}
        action={
          <IconButton key="close" onClick={clearNotifications} style={{ marginRight: '22px', padding: '5px' }}>
            <X fontSize={24} color={snackbar.className ? palette[snackbar.className].main : palette.gray[400]} />
          </IconButton>
        }
      />
    </MuiSnackbar>
  );
};

/**
 * Subcomponents
 */

const ErrorContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.extraLight,
    color: theme.palette.error.main,
  },
}))(SnackbarContent);
const SuccessContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.success.extraLight,
    color: theme.palette.success.main,
  },
}))(SnackbarContent);
const WarningContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.warning.extraLight,
    color: theme.palette.warning.main,
  },
}))(SnackbarContent);
const SecondaryContent = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.info.main,
  },
}))(SnackbarContent);
