import { showSnackbarErrorFormattedMessage, showSnackbarSuccessFormattedMessage } from 'containers/App/state/saga';
import { selectAPIClient } from 'containers/App/state/selectors';
import messages from 'containers/Auth/messages';
import { call, select, takeLatest } from 'typed-redux-saga';
import { ResetPassword } from './actions';

export function* resetPassword({ token, password, onSuccess, onError, onAlways }: ResetPassword) {
  try {
    const client = yield* select(selectAPIClient);
    const { data: result } = yield* call(client.resetPassword, { token, password });

    if (result.success) {
      yield showSnackbarSuccessFormattedMessage(messages.passwordResetSuccess);
      onSuccess();
    } else {
      onError(result.errorCode, result.errorMessage);
    }
  } catch {
    yield showSnackbarErrorFormattedMessage(messages.errorResettingPassword);
  }
  onAlways();
}

export function* watchResetPassword() {
  yield takeLatest<ResetPassword>('app/Auth/RESET_PASSWORD/RESET_PASSWORD', resetPassword);
}
