import produce from 'immer';
import { ObligationState, Step } from '../types';
import { ObligationSubmitAction } from './actions';

export const initialState: ObligationState = {
  visible: false,
  obligationId: null,
  requesting: null,
  obligation: null,
  currentStep: Step.fulfill,
};

const reducer = (state: ObligationState = initialState, action: ObligationSubmitAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Agreement_v4/PostChain/Task/Obligation/SHOW_OBLIGATION':
        draftState.visible = true;
        draftState.obligationId = action.obligationId;
        draftState.requesting = null;
        draftState.currentStep = Step.fulfill;
        return draftState;
      case 'app/Agreement_v4/PostChain/Task/Obligation/HIDE_OBLIGATION':
        draftState.visible = false;
        draftState.obligationId = null;
        return draftState;
      case 'app/Agreement_v4/PostChain/Task/Obligation/LOAD_OBLIGATION':
        draftState.requesting = 'load';
        draftState.obligation = null;
        draftState.currentStep = Step.fulfill;
        return draftState;
      case 'app/Agreement_v4/PostChain/Task/Obligation/LOAD_OBLIGATION_SUCCESS':
        draftState.requesting = null;
        draftState.obligation = action.obligation;
        return draftState;
      case 'app/Agreement_v4/PostChain/Task/Obligation/RECEIVE_CURRENT_STEP':
        draftState.currentStep = action.step;
        return draftState;
      case 'app/Agreement_v4/PostChain/Task/Obligation/SUBMIT_OBLIGATION':
        draftState.requesting = 'submit';
        return draftState;
      case 'app/Agreement_v4/PostChain/Task/Obligation/SUBMIT_OBLIGATION_SUCCESS':
        draftState.requesting = null;
        return draftState;
      case 'app/Agreement_v4/PostChain/Task/Obligation/CLEAR_OBLIGATION':
        draftState.obligation = null;
        return draftState;
      default:
        return draftState;
    }
  });

export default reducer;
