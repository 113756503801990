/*
 * Agreement.Document Messages
 *
 * This contains all the text for the Agreement.Document container.
 */
import { defineMessages } from 'react-intl';

const scope = 'app.containers.Agreement.Document';

export default defineMessages({
  errorGettingDocument: {
    id: `${scope}.errorGettingDocument`,
    defaultMessage: "Sorry, we couldn't load this document.",
  },
  noProseDocuments: {
    id: `${scope}.noProseDocuments`,
    defaultMessage: 'No prose documents found.',
  },
  noPreviewForFileType: {
    id: `${scope}.noPreviewForFileType`,
    defaultMessage: "Sorry, we can't generate a preview for this file type.",
  },
  clickHereToDownload: {
    id: `${scope}.clickHereToDownload`,
    defaultMessage: 'Please click here to download the file.',
  },
  failedToUploadDocuments: {
    id: `${scope}.failedToUploadDocuments`,
    defaultMessage: 'Failed to upload documents.',
  },
  failedToDownloadDocument: {
    id: `${scope}.failedToDownloadDocument`,
    defaultMessage: 'Failed to download document.',
  },
  apiError_fileTooLarge: {
    id: `${scope}.apiError_fileTooLarge`,
    defaultMessage: 'File exceeded max upload size',
  },
  apiError_unsupportedElements: {
    id: `${scope}.apiError_unsupportedElements`,
    defaultMessage: 'Document contains unsupported elements',
  },
  apiError_documenHeaders: {
    id: `${scope}.apiError_documenHeaders`,
    defaultMessage: 'Document header levels are out of order',
  },
  apiError_unprocessable: {
    id: `${scope}.apiError_unprocessable`,
    defaultMessage: 'Cannot process document; please ensure content is formatted properly.',
  },
  addDocument: {
    id: `${scope}.addDocument`,
    defaultMessage: 'Upload document',
  },
  removeDocument: {
    id: `${scope}.removeDocument`,
    defaultMessage: 'Remove file: {fileName}',
  },
  editDocuments: {
    id: `${scope}.editDocuments`,
    defaultMessage: 'Edit documents',
  },
  documentToggleMenuButton: {
    id: `${scope}.documentToggleMenuButton`,
    defaultMessage: 'View your documents',
  },
  documentToggleMenuAddButton: {
    id: `${scope}.documentToggleMenuAddButton`,
    defaultMessage: 'Add more documents',
  },
  assemblingDocument: {
    id: `${scope}.assemblingDocument`,
    defaultMessage: 'Assembling document...',
  },
  reviewDocument: {
    id: `${scope}.reviewDocument`,
    defaultMessage: 'Document requires your review.',
  },
  documentReviewed: {
    id: `${scope}.documentReviewed`,
    defaultMessage: 'Document has been reviewed.',
  },
  failedToLoadDocumentInfo: {
    id: `${scope}.failedToLoadDocumentInfo`,
    defaultMessage: 'Failed to load document info',
  },
});
