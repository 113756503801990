import { getSettingsBillingRoute } from '@monax/aeger/dist/webapp-routes';
import { ThrottledResource } from '@monax/types';
import { addNotification } from 'containers/App/state/actions';
import { selectOrganizationResourceBalance } from 'containers/Organization/ResourceBalance/state/selectors';
import { selectCurrentUserIsAdmin } from 'containers/User/Current/Profile/state/selectors';
import React from 'react';
import { LockFill } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { messages } from '../messages';
import { throttledResourceToMessage } from '../util';

// To prevent loading a page unless user has remaining balance for any of the given resources
// (All customer types are allowed to create these resources, but creation is throttled)

export const throttleResources = (throttledResources: ThrottledResource[]) => (Component: React.FC): React.FC => () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const resourceBalance = useSelector(selectOrganizationResourceBalance);
  const isAdmin = useSelector(selectCurrentUserIsAdmin);
  if (throttledResources.some((resource) => resourceBalance[resource] ?? true)) {
    // Show page if balance is null or positive (null indicating no limit)
    return <Component />;
  }
  if (isAdmin) {
    dispatch(
      addNotification({
        open: true,
        message: intl.formatMessage(messages.throttledResourceError_snackbar_list, {
          resource: intl.formatList(
            throttledResources.map((resource) => intl.formatMessage(throttledResourceToMessage(resource))),
          ),
        }),
        className: 'error',
        autoHideDuration: 5000,
      }),
    );
    // Redirect to payment page if the user is an admin
    return <Redirect to={getSettingsBillingRoute()} />;
  }

  // Show info error message (TODO- messaging and design)
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="max-w-2xl p-16 text-info-main text-center">
        <LockFill className="text-6xl mb-8 text-center" />
        <div>
          {intl.formatMessage(messages.throttledResourceError_page, {
            resource: intl.formatList(
              throttledResources.map((resource) => intl.formatMessage(throttledResourceToMessage(resource))),
            ),
          })}
        </div>
      </div>
    </div>
  );
};
