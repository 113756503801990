import { Role, RoleEnum } from '@monax/types';
import userMessages from 'containers/User/List/messages';
import { useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Option } from 'types';

export type RoleOption = Option & { tooltip: string };

export const useRoleOptions = (): RoleOption[] => {
  const intl = useIntl();
  return useMemo(
    () => [
      {
        label: getRoleLabel(intl, RoleEnum.ACCOUNT_ADMIN),
        value: RoleEnum.ACCOUNT_ADMIN,
        tooltip: intl.formatMessage(userMessages.roleACCOUNT_ADMINDescription),
      },
      {
        label: getRoleLabel(intl, RoleEnum.STANDARD_USER),
        value: RoleEnum.STANDARD_USER,
        tooltip: intl.formatMessage(userMessages.roleSTANDARD_USERDescription),
      },
      {
        label: getRoleLabel(intl, RoleEnum.RESTRICTED_USER),
        value: RoleEnum.RESTRICTED_USER,
        tooltip: intl.formatMessage(userMessages.roleRESTRICTED_USERDescription),
      },
    ],
    [],
  );
};

export const getRoleLabel = (intl: IntlShape, role: Role): string => {
  switch (role) {
    case RoleEnum.ACCOUNT_ADMIN:
      return intl.formatMessage(userMessages.roleACCOUNT_ADMIN);
    case RoleEnum.SIGNING_AUTHORITY:
      return intl.formatMessage(userMessages.roleSIGNING_AUTHORITY);
    case RoleEnum.RESTRICTED_USER:
      return intl.formatMessage(userMessages.roleRESTRICTED_USER);
    case RoleEnum.STANDARD_USER:
      return intl.formatMessage(userMessages.roleSTANDARD_USER);
    default:
      return role;
  }
};
