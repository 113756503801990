import { Asset, AssetBody } from '@monax/types';
import { useNotifications } from 'containers/App/hooks/useNotifications';
import { selectAPIClient } from 'containers/App/state/selectors';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { messages } from '../messages';

export const useCreateAsset = (): {
  creating: boolean;
  create: (asset: AssetBody) => Promise<{ success: boolean; asset?: Asset }>;
} => {
  const [creating, setCreating] = useState<boolean>(false);

  const { showError } = useNotifications();
  const client = useSelector(selectAPIClient);

  const create = useCallback(async (assetBody: AssetBody) => {
    let success = false;
    let asset: Asset | undefined = undefined;
    setCreating(true);
    try {
      const response = await client.createAsset(assetBody);
      asset = response.data;
      success = true;
    } catch (error) {
      console.error(`Failed to create asset`, error);
      showError(messages.failedToCreateAsset);
    } finally {
      setCreating(false);
    }
    return {
      success,
      asset,
    };
  }, []);

  return {
    create,
    creating,
  };
};
