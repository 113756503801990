import { addNotification } from 'containers/App/state/actions';
import { useTheme } from 'containers/Theme/useTheme';
import graphic from 'images/agreement/adding-contract-to-folder.svg';
import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import messages from './messages';

type Props = {
  acceptMimes: string[];
  acceptExts: string[];
  uploadFiles: (files: File[]) => void;
  maxFiles: number;
};

export const Dropzone: React.FC<Props> = ({ acceptMimes, acceptExts, uploadFiles, maxFiles = 10 }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { palette } = useTheme();

  const onDrop: DropzoneOptions['onDrop'] = (accepted, rejected) => {
    if (rejected.some(({ errors }) => errors.some(({ code }) => code === 'too-many-files'))) {
      dispatch(
        addNotification({
          className: 'warning',
          message: intl.formatMessage(messages.maxFileCountExceeded, { max: maxFiles }),
        }),
      );
    } else if (rejected.some(({ errors }) => errors.some(({ code }) => code === 'file-invalid-type'))) {
      dispatch(
        addNotification({
          className: 'warning',
          message: intl.formatMessage(messages.fileTypeNotSupported),
        }),
      );
    } else if (rejected.some(({ errors }) => errors.some(({ code }) => code === 'file-too-large'))) {
      dispatch(
        addNotification({
          className: 'warning',
          message: intl.formatMessage(messages.maxFileSizeXExceeded, {
            size: Number(process.env.MAX_UPLOAD_SIZE) / 1000000,
          }),
        }),
      );
    }
    uploadFiles(accepted);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptMimes,
    maxSize: Number(process.env.MAX_UPLOAD_SIZE),
    maxFiles,
  });

  return (
    <div className="flex justify-center items-center relative" {...getRootProps()}>
      {/* dotted border */}
      <svg
        className="absolute top-0 left-0"
        width="99%"
        height="99%"
        viewBox="0 0 99% 99%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.5%"
          y="0.5%"
          width="99%"
          height="99%"
          rx="9.5"
          fill="white"
          stroke={palette.gray[400]}
          strokeDasharray="10 14"
        />
      </svg>
      <input {...getInputProps()} />
      <div className="py-8 md:py-12 lg:py-18 xl:py-24 text-center z-10">
        <img src={graphic} className="w-full mb-16" />
        <div className="text-sm font-medium text-info-main mb-3">{intl.formatMessage(messages.dropLabel)}</div>
        <div className="text-xs font-normal text-gray-700">
          {intl.formatMessage(messages.dropAcceptedTypes, {
            extensions: intl.formatList(acceptExts, { type: 'disjunction' }),
          })}
        </div>
      </div>
    </div>
  );
};
