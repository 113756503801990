import { FormItem, Input } from 'components/Form';
import { SubmitButton } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { ResetPasswordRequestForm } from '../types';

type Props = {
  loading: boolean;
};

export const FormPanel: React.FC<Props> = ({ loading }) => {
  const intl = useIntl();
  const { handleSubmit } = useFormikContext<ResetPasswordRequestForm>();

  return (
    <form onSubmit={handleSubmit}>
      <FormItem>
        <div className="text-gray-400">{intl.formatMessage(messages.enterEmailToResetPassword)}</div>
      </FormItem>
      <Input
        name="usernameOrEmail"
        placeholder={intl.formatMessage(messages.exampleEmail)}
        inputConfig={{ errorMessageDisplayMode: 'tooltip', showValidAdornment: true }}
      />
      <div className="pt-4">
        <SubmitButton disabled={loading}>
          {intl.formatMessage(messages.sendEmail)}
        </SubmitButton>
      </div>
    </form>
  );
};
