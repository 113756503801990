import { parse } from '@monax/aeger/dist/schema';
import { AuthErrorCode, RegisterQueryParameters } from '@monax/types';
import { ContentLoader } from 'components/Loader';
import messages from 'containers/Auth/messages';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from 'types';
import { useInjectSaga } from 'utils/injectSaga';
import { handleLoginSuccess, receiveAuthToken } from '../Auth/state/actions';
import { watchHandleLoginSuccess } from '../Auth/state/saga';
import { selectWebAuth } from '../Auth/state/selectors';
import { getAuth0ErrorMessage } from '../utils';

const CallbackComponent: React.FC<PropsFromState> = ({ webAuth }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  useInjectSaga({
    key: 'handle-auth-state',
    saga: watchHandleLoginSuccess,
  });

  useEffect(() => {
    webAuth.parseHash((error, result) => {
      if (error) {
        const message = getAuth0ErrorMessage(
          intl,
          error.errorDescription as AuthErrorCode,
          error.errorDescription || intl.formatMessage(messages.auth0ErrorCode_unknown),
        );
        history.push(`/login?error=${encodeURIComponent(message)}`);
        return;
      }
      dispatch(receiveAuthToken(result.accessToken, result.idTokenPayload));
      const state = parse(RegisterQueryParameters, JSON.parse(result.state));
      dispatch(handleLoginSuccess(state));
    });
  }, []);

  return <ContentLoader />;
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
  webAuth: selectWebAuth(state),
});

export const Callback = connect(mapStateToProps)(CallbackComponent);
