import { Address } from '@monax/types';
import { isLeft } from 'fp-ts/lib/Either';

export const parseRepresentativeAddress = (
  representativeAddress: string,
): { userAddress: null | Address; organizationAddress: null | Address } => {
  let [userAddress, organizationAddress] = (representativeAddress || '').split('.') as Address[];
  if (isLeft(Address.decode(userAddress))) {
    userAddress = null;
  }
  if (isLeft(Address.decode(organizationAddress))) {
    organizationAddress = null;
  }
  return { userAddress, organizationAddress };
};
