import { combineReducers } from 'redux';
import agreementListReducer from './Agreement/state/reducer';
import templateListReducer from './Template/state/reducer';
import { ListState } from './types';

const reducer = combineReducers<ListState>({
  agreement: agreementListReducer,
  template: templateListReducer,
});

export default reducer;
