import { CustomerType, RestrictedFeature, ThrottledResource } from '@monax/types';
import { IntlShape, MessageDescriptor } from 'react-intl';
import { messages } from '../messages';

export function customerTypeToMessage(customerType: CustomerType): MessageDescriptor {
  switch (customerType) {
    case 'COUNTERPARTY_CLM':
      return messages.customerType_COUNTERPARTY_CLM;
    case 'SME_CLM':
      return messages.customerType_SME_CLM;
    case 'ENTERPRISE_CLM':
      return messages.customerType_ENTERPRISE_CLM;
    case 'MANAGED_CLM':
      return messages.customerType_MANAGED_CLM;
    case 'PLATFORM_ADMIN_CLM':
      return messages.customerType_PLATFORM_ADMIN_CLM;
  }
}

export function featureToMessage(feature: RestrictedFeature): MessageDescriptor {
  switch (feature) {
    // SME level
    case 'add_events':
      return messages.feature_addEvents;
    case 'add_dates':
      return messages.feature_addDates;
    case 'configure_events':
      return messages.feature_configureEvents;
    case 'update_organization_structure':
      return messages.feature_updateOrganizationStructure;
    // Enterprise level
    case 'view_dashboard':
      return messages.feature_viewDashboard;
    case 'add_white_labeling':
      return messages.feature_addWhiteLabeling;
    case 'add_api_key':
      return messages.feature_addApiKey;
    case 'add_signing_url':
      return messages.feature_addSigningURL;
    // Management level
    case 'admin_create_organization':
      return messages.feature_createOrganizations;
    case 'admin_get_organization':
      return messages.feature_adminGetOrganization;
    case 'admin_update_organization':
      return messages.feature_manageOrganizations;
    case 'admin_archive_organization':
      return messages.feature_archiveOrganizations;
    case 'admin_retrieve_billing_reports':
      return messages.feature_getBillingReports;
    // Platform Admin Level
    case 'admin_add_user_to_organization':
      return messages.feature_addUserToOrganization;
    case 'admin_assign_role_to_user':
      return messages.feature_addUserRolesOnOrganization;
    case 'admin_get_user':
      return messages.feature_adminGetUser;
    case 'admin_merge_user_accounts':
      return messages.feature_mergeAccounts;
    case 'admin_block_user':
      return messages.feature_blockAccounts;
    case 'admin_update_beacon_operators':
      return messages.feature_adminUpdateBeaconOperators;
  }
}

export function featureRestrictedNotification(intl: IntlShape, feature: RestrictedFeature): string {
  return intl.formatMessage(messages.restrictedFeatureError_snackbar, {
    feature: intl.formatMessage(featureToMessage(feature)),
  });
}

export function restrictedFeatureCTA(intl: IntlShape, feature: RestrictedFeature): string {
  return intl.formatMessage(messages.featureCTA_description, {
    featureDescription: intl.formatMessage(featureCTADescriptionMessage(feature)),
  });
}

export function featureCTADescriptionMessage(feature: RestrictedFeature): MessageDescriptor {
  switch (feature) {
    // SME level
    case 'add_events':
      return messages.featureCTA_description_addEvents;
    case 'add_dates':
      return messages.featureCTA_description_addDates;
    case 'configure_events':
      return messages.featureCTA_description_configureEvents;
    case 'update_organization_structure':
      return messages.featureCTA_description_updateOrganizationStructure;
    // Enterprise level
    case 'view_dashboard':
      return messages.featureCTA_description_viewDashboard;
    case 'add_white_labeling':
      return messages.featureCTA_description_addWhiteLabeling;
    case 'add_api_key':
      return messages.featureCTA_description_addApiKey;
    case 'add_signing_url':
      return messages.featureCTA_description_addSigningURL;
    // Management level
    case 'admin_create_organization':
      return messages.featureCTA_description_createOrganizations;
    case 'admin_get_organization':
      return messages.featureCTA_description_adminGetOrganization;
    case 'admin_update_organization':
      return messages.featureCTA_description_manageOrganizations;
    case 'admin_archive_organization':
      return messages.featureCTA_description_archiveOrganizations;
    case 'admin_retrieve_billing_reports':
      return messages.featureCTA_description_getBillingReports;
    // Platform Admin Level
    case 'admin_add_user_to_organization':
      return messages.featureCTA_description_addUserToOrganization;
    case 'admin_assign_role_to_user':
      return messages.featureCTA_description_addUserRolesOnOrganization;
    case 'admin_get_user':
      return messages.featureCTA_description_adminGetUser;
    case 'admin_merge_user_accounts':
      return messages.featureCTA_description_mergeAccounts;
    case 'admin_block_user':
      return messages.featureCTA_description_blockAccounts;
    case 'admin_update_beacon_operators':
      return messages.featureCTA_description_adminUpdateBeaconOperators;
  }
}

export function throttledResourceToMessage(throttledResource: ThrottledResource): MessageDescriptor {
  switch (throttledResource) {
    case 'template':
      return messages.throttledResource_template;
    case 'agreement':
      return messages.throttledResource_agreement;
    case 'draft':
      return messages.throttledResource_draft;
    case 'user':
      return messages.throttledResource_user;
    case 'asset':
      return messages.throttledResource_asset;
    case 'agreement_date':
      return messages.throttledResource_agreement_date;
    case 'agreement_document':
      return messages.throttledResource_agreement_document;
    case 'agreement_event':
      return messages.throttledResource_agreement_event;
    case 'agreement_party':
      return messages.throttledResource_agreement_party;
    case 'agreement_term':
      return messages.throttledResource_agreement_term;
  }
}

export function isPerAgreement(throttledResource: ThrottledResource): boolean {
  switch (throttledResource) {
    case 'agreement_date':
    case 'agreement_document':
    case 'agreement_event':
    case 'agreement_party':
    case 'agreement_term':
      return true;
    default:
      return false;
  }
}
