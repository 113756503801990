import { debounce, TextField } from '@material-ui/core';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Search as SearchIcon } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { messages } from './messages';
import { receiveAssetsSearchText } from './state/actions';
import { selectAssetSearchText } from './state/selectors';

type Props = {
  autofocus?: boolean;
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
};

export const Search: React.FC<Props> = ({ autofocus, onKeyPress }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const searchText = useSelector(selectAssetSearchText);

  const [value, setValue] = useState(searchText || '');

  // Search name has been cleared by outside component, keep in sync
  useEffect(() => {
    if (value && !searchText) setValue('');
  }, [searchText]);

  const debouncedDispatch = useCallback(
    debounce((text: string) => {
      dispatch(receiveAssetsSearchText(text));
    }, 400),
    [],
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedDispatch(e.target.value);
  };

  return (
    <TextField
      autoFocus={autofocus}
      id={'templates-search'}
      size="small"
      variant="outlined"
      placeholder={intl.formatMessage(messages.search)}
      value={value}
      onChange={onChange}
      InputProps={{
        onKeyPress: onKeyPress,
        style: { width: '100%' },
        startAdornment: (
          <div className="pr-3 text-gray-500">
            <SearchIcon />
          </div>
        ),
      }}
    />
  );
};
