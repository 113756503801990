import { AuthProvider, RoleEnum } from '@monax/types';
import { OrganizationAvatar, UserAvatar } from 'components/Avatar';
import { Button } from 'components/Common';
import { Tab, TabHeader, TabPanel, Tabs } from 'components/Tab';
import { selectAuthHistoryOrdered } from 'containers/Auth/Auth/state/selectors';
import { SUPPORTED_SSO_AUTH_PROVIDERS } from 'containers/Auth/constants';
import { LogoutQueryParameters } from 'containers/Auth/Logout/types';
import {
  selectCurrentOrganization,
  selectCurrentOrganizationAddress,
} from 'containers/Organization/Current/state/selectors';
import { getRoleLabel } from 'containers/Organization/Manage/hooks';
import { switchOrganization } from 'containers/Organization/Switch/state/actions';
import { getSettingsRoute } from 'containers/Settings/routes';
import { selectCurrentUser, selectMemberOrganizations } from 'containers/User/Current/Profile/state/selectors';
import { selectUserInvitations } from 'containers/User/Current/UserInvitations/state/selectors';
import React, { useState } from 'react';
import { CheckCircleFill, ExclamationCircleFill, GearFill, PlusCircle } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatUrl } from 'utils/queryString';
import messages from './messages';

const ACCOUNT_LIMIT = 3;

type Props = {
  onClose: () => void;
};

export const AccountSwitcherForm: React.FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);
  const userInvitations = useSelector(selectUserInvitations);
  const currentOrganizationAddress = useSelector(selectCurrentOrganizationAddress);

  const currentUserOrganizations = useSelector(selectMemberOrganizations);
  const currentUserOrganization = useSelector(selectCurrentOrganization);
  const authHistory = useSelector(selectAuthHistoryOrdered);

  const [index, setIndex] = useState<number>(0);
  const [limitDisplayUsers, setLimitDisplayedUsers] = useState<boolean>(true);
  const [limitDisplayOrganizations, setLimitDisplayedOrganizations] = useState<boolean>(true);

  const handleClose = () => {
    setIndex(0);
    onClose();
  };

  const logout = () => {
    const queryString: LogoutQueryParameters = {
      ...(currentUserOrganization?.domain && { subdomain: currentUserOrganization.domain }),
    };
    history.push(formatUrl('/logout', queryString));
  };

  const switchAccount = (email: string, authProviders: string[]) => {
    const queryString: LogoutQueryParameters = {
      loginEmail: email,
      loginAuthProvider: getAuthProvider(authProviders),
    };
    history.push(formatUrl('/logout', queryString));
  };

  const getAuthProvider = (authProviders: string[]): AuthProvider => {
    // Basically see if we have an auth provider that supports sso and use that (best case for 'auto' account switch)
    return authProviders.find((ap) => SUPPORTED_SSO_AUTH_PROVIDERS.find((sap) => sap === ap)) as AuthProvider;
  };

  const goToSettings = () => {
    history.push('/settings');
  };

  return (
    <>
      <div className="px-4">
        <TabHeader>
          <Tabs
            value={index}
            // @ts-ignore
            onChange={(_e, i) => {
              setIndex(i);
            }}
            color="info"
          >
            <Tab label="Organizations" size="s" color="info" />
            <Tab label="Accounts" size="s" color="info" />
          </Tabs>
        </TabHeader>
        <TabPanel index={0} selectedIndex={index} noPadding className="py-2">
          {currentUserOrganizations
            .slice(0, limitDisplayOrganizations ? ACCOUNT_LIMIT : undefined)
            .map((organization) => {
              return (
                <AccountOption
                  key={organization.address}
                  icon={<OrganizationAvatar organizationAddress={organization.address} />}
                  onClick={() => {
                    dispatch(switchOrganization(organization.address));
                  }}
                  name={organization.name}
                  details={getRoleLabel(
                    intl,
                    currentUser.permissions[organization.address].roles.find(
                      (role) => role !== RoleEnum.SIGNING_AUTHORITY,
                    ),
                  )}
                  selected={organization.address === currentOrganizationAddress}
                />
              );
            })}
          {userInvitations.map((invitation) => {
            return (
              <AccountOption
                key={invitation.sender.organization.address}
                icon={<OrganizationAvatar organizationAddress={invitation.sender.organization.address} />}
                onClick={() => {
                  history.push(getSettingsRoute('memberships'));
                }}
                name={intl.formatMessage(messages.organizationInvited, {
                  organizationName: invitation.sender.organization.name,
                })}
                details={getRoleLabel(intl, invitation.role)}
                alert={true}
              />
            );
          })}
          {limitDisplayOrganizations && currentUserOrganizations.length > ACCOUNT_LIMIT && (
            <div
              className="w-full pb-3 pt-2 text-xs text-center text-gray-600 cursor-pointer"
              onClick={() => setLimitDisplayedOrganizations(false)}
            >
              {intl.formatMessage(messages.showMore)}
            </div>
          )}
        </TabPanel>
        <TabPanel index={1} selectedIndex={index} noPadding className="py-2">
          {authHistory.slice(0, limitDisplayUsers ? ACCOUNT_LIMIT : undefined).map((history) => {
            const { idTokenPayload } = history;
            const { email } = idTokenPayload;
            const isCurrentUser = email === currentUser.email;
            return (
              <AccountOption
                key={email}
                icon={<UserAvatar userEmail={email} userIconSrc={idTokenPayload.picture} />}
                onClick={() => {
                  if (!isCurrentUser) switchAccount(email, idTokenPayload['https://login.suscribo.com/identity_providers']);
                }}
                name={idTokenPayload.name}
                details={idTokenPayload.email}
                selected={isCurrentUser}
                action={
                  isCurrentUser && (
                    <Button type="gray-outlined" size="xs" onClick={logout}>
                      {intl.formatMessage(messages.accountSwitcherLogout)}
                    </Button>
                  )
                }
              />
            );
          })}
          {limitDisplayUsers && authHistory.length > ACCOUNT_LIMIT && (
            <div
              className="w-full pb-3 pt-2 text-xs text-center text-gray-600 cursor-pointer"
              onClick={() => setLimitDisplayedUsers(false)}
            >
              {intl.formatMessage(messages.showMore)}
            </div>
          )}
        </TabPanel>
      </div>
      <MenuOption
        label={'Settings'}
        onClick={() => {
          handleClose();
          goToSettings();
        }}
        icon={<GearFill />}
      />
      {index === 1 && (
        <MenuOption
          label={intl.formatMessage(messages.accountSwitcherLogIntoAnother)}
          onClick={() => {
            handleClose();
            logout();
          }}
          icon={<PlusCircle />}
        />
      )}
    </>
  );
};

type AccountOptionProps = {
  onClick: () => void;
  icon: React.ReactNode;
  name: string;
  details: string;
  selected?: boolean;
  action?: React.ReactNode;
  alert?: boolean;
};

const AccountOption: React.FC<AccountOptionProps> = ({ name, details, onClick, icon, selected, alert, action }) => {
  return (
    <div onClick={onClick} className="w-full cursor-pointer">
      <div className="flex flex-wrap sm:flex-nowrap py-4">
        <div className="flex-none flex items-center pr-4">{icon}</div>
        <div className="flex-grow pr-4 md:pr-8 lg:pr-12 truncate">
          <div className="text-sm flex truncate">
            <div className="pr-2 truncate">{name}</div>
            <div>{selected && <CheckCircleFill className="text-success-main" style={{ marginTop: -5 }} />}</div>
            <div>{alert && <ExclamationCircleFill className="text-warning-main" style={{ marginTop: -5 }} />}</div>
          </div>
          <div className="text-sm text-left text-gray-500 truncate">{details}</div>
        </div>
        {action && <div className="flex-none flex items-center">{action}</div>}
      </div>
    </div>
  );
};

type MenuOptionProps = {
  label: string;
  onClick: () => void;
  icon: React.ReactNode;
};

const MenuOption: React.FC<MenuOptionProps> = ({ label, onClick, icon }) => {
  return (
    <div onClick={onClick} className={`w-full ${onClick ? 'cursor-pointer' : ''}`}>
      <div className="flex flex-wrap w-full p-4 border-t border-gray-300">
        <div className="flex-none flex items-center pr-4">
          <div className="bg-primary-extraLight rounded-full h-8 w-8 flex items-center justify-center">{icon}</div>
        </div>
        <div className="text-sm flex-grow pr-2 flex items-center truncate">{label}</div>
      </div>
    </div>
  );
};
