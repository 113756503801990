import { Asset } from '@monax/types';

export type LoadAssets = ReturnType<typeof loadAssets>;
export type LoadAssetsSuccess = ReturnType<typeof loadAssetsSuccess>;
export type ReceiveAssetsSearchText = ReturnType<typeof receiveAssetsSearchText>;

export type AssetAction = LoadAssets | LoadAssetsSuccess | ReceiveAssetsSearchText;

/**
 * LOAD_ASSETS
 */
export function loadAssets(onLoaded?: () => void) {
  return <const>{
    type: 'app/Assets/List/LOAD_ASSETS',
    onLoaded,
  };
}
export function loadAssetsSuccess(assets: Asset[]) {
  return <const>{
    type: 'app/Assets/List/LOAD_ASSETS_SUCCESS',
    assets,
  };
}

/**
 * RECEIVE_ASSET_SEARCH_TEXT
 */
export function receiveAssetsSearchText(searchText: string) {
  return <const>{
    type: 'app/Assets/List/RECEIVE_ASSET_SEARCH_TEXT',
    searchText,
  };
}
