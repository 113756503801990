import React, { ReactNode } from 'react';

type Props = {
  buttons: ReactNode[];
  className?: string;
  allowWrap?: boolean;
  responsiveGap?: boolean;
};

export const ButtonRow: React.FC<Props> = ({ buttons, className = '', allowWrap, responsiveGap }) => (
  <div className={`flex items-center ${className} ${allowWrap ? 'flex-wrap' : ''}`}>
    {buttons.map((button, i) => (
      <div
        key={i}
        className={`mr-4 ${responsiveGap ? 'md:mr-8 lg:mr-12' : ''} last:mr-0 ${allowWrap ? 'pb-2 md:pb-0' : ''}`}
      >
        {button}
      </div>
    ))}
  </div>
);
