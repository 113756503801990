import { createStyles, Tabs as MuiTabs, withStyles } from '@material-ui/core';
import React from 'react';
import { TabColor } from './Tab';

type Props = {
  color?: TabColor;
  value?: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
};

const TabsSecondary = withStyles((theme) =>
  createStyles({
    indicator: {
      backgroundColor: theme.palette.secondary.main, // this is the underline
    },
    root: {
      '& button:focus': {
        '--tw-ring-color': theme.palette.secondary.main,
      },
    },
  }),
)((props: Props) => <MuiTabs {...props} />);

export const Tabs: React.FC<Props> = ({ color = 'primary', ...rest }) => {
  if (color === 'primary') return <MuiTabs {...rest} />;

  return <TabsSecondary {...rest} />;
};
