import { getInitialAgreementFilter } from 'containers/User/Current/ResourceFilters/utils';
import produce from 'immer';
import { mergeShallow } from 'utils/merge';
import { ListAgreementSort, ListAgreementState } from '../types';
import { equal, isSelected } from '../util/utils';
import { HomePageAgreementsAction } from './actions';

export const defaultSort: ListAgreementSort = {
  column: 'createdAt',
  direction: 'desc',
};

export const initialState: ListAgreementState = {
  loading: false,
  agreements: [],
  totalAgreements: 0,
  sort: defaultSort,
  selectedAgreements: [],
  search: {
    textSearch: null,
  },
  filter: getInitialAgreementFilter(),
  filterAgreementAddresses: undefined,
};

const agreementsReducer = (state = initialState, action: HomePageAgreementsAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Agreement_v4/List/Agreement/LOAD_AGREEMENTS':
        draftState.loading = true;
        return draftState;

      case 'app/Agreement_v4/List/Agreement/LOAD_MORE_AGREEMENTS':
        draftState.loading = true;
        return draftState;

      case 'app/Agreement_v4/List/Agreement/LOAD_AGREEMENTS_SUCCESS':
        draftState.loading = false;
        draftState.totalAgreements = action.totalAgreements;
        draftState.agreements = action.agreements;
        return draftState;

      case 'app/Agreement_v4/List/Agreement/LOAD_MORE_AGREEMENTS_SUCCESS':
        draftState.loading = false;
        draftState.totalAgreements = action.totalAgreements;
        draftState.agreements = draftState.agreements.concat(action.agreements);
        return draftState;

      case 'app/Agreement_v4/List/Agreement/RECEIVE_LIST_AGREEMENT_SORT':
        draftState.sort = action.sort;
        return draftState;

      case 'app/Agreement_v4/List/Agreement/RECEIVE_LIST_AGREEMENT_SEARCH': {
        mergeShallow(draftState.search, action.search);
        return draftState;
      }

      case 'app/Agreement_v4/List/Agreement/TOGGLE_SELECTED_AGREEMENT':
        if (isSelected(draftState.selectedAgreements, action.agreement))
          draftState.selectedAgreements = draftState.selectedAgreements.filter((s) => !equal(s, action.agreement));
        else draftState.selectedAgreements.push(action.agreement);
        return draftState;

      case 'app/Agreement_v4/List/Agreement/CLEAR_ALL_SELECTED_AGREEMENTS':
        draftState.selectedAgreements = [];
        return draftState;

      case 'app/Agreement_v4/List/Agreement/SELECT_ALL_AGREEMENTS':
        draftState.selectedAgreements = draftState.agreements.map((a) => ({
          address: a.address,
          id: a.id,
        }));
        return draftState;

      case 'app/Agreement_v4/List/Agreement/RECEIVE_RESOURCE_FILTER_ID':
        draftState.loading = true;
        draftState.resourceFilterId = action.id;
        return draftState;

      case 'app/Agreement_v4/List/Agreement/RECEIVE_DEFAULT_RESOURCE_FILTER':
        draftState.loading = true;
        draftState.filter = action.filter;
        return draftState;

      case 'app/Agreement_v4/List/Agreement/RECEIVE_AGREEMENT_ADDRESSES_FILTER':
        draftState.loading = true;
        draftState.filterAgreementAddresses = action.agreementAddresses;
        draftState.agreements = [];
        draftState.totalAgreements = 0;
        return draftState;

      case 'app/Agreement_v4/List/Agreement/CLEAR_AGREEMENT_ADDRESSES_FILTER':
        draftState.filterAgreementAddresses = undefined;
        draftState.agreements = [];
        draftState.totalAgreements = 0;
        return draftState;

      default:
        return draftState;
    }
  });

export default agreementsReducer;
