/*
 *
 * AppReducer
 *
 */
import produce from 'immer';
import {
  ADD_FORMATTED_NOTIFICATION,
  // UI
  ADD_NOTIFICATION,

  // UI Components
  SIDEBAR_EXPANDED,
  TOGGLE_UI_COMPONENT,
} from '../constants';
import { AppState } from '../types';
import { AppAction } from './actions';

// The initial state of the App
export const initialState: AppState = {
  snackbar: {
    action: null,
    className: null,
    message: null,
    messageDescriptor: null,
    open: false,
  },
  UIComponents: {
    [SIDEBAR_EXPANDED]: false,
  },
  timeZoneOffset: new Date().getTimezoneOffset() / 60,
};

export type GlobalState = typeof initialState;

const appReducer = (state = initialState, action: AppAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      // UI
      case ADD_NOTIFICATION:
      case ADD_FORMATTED_NOTIFICATION:
        draftState.snackbar = action.snackbar;
        return draftState;

      case TOGGLE_UI_COMPONENT:
        draftState.UIComponents[action.component] =
          action.value === undefined ? !draftState.UIComponents[action.component] : action.value;
        return draftState;

      default:
        return draftState;
    }
  });

export default appReducer;
