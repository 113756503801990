import { IDToken, RegisterQueryParameters } from '@monax/types';
import { Auth0Error } from 'auth0-js';

export type ReceiveAuthToken = ReturnType<typeof receiveAuthToken>;
export type ClearAuthTokens = ReturnType<typeof clearAuthTokens>;
export type ReceiveAuthError = ReturnType<typeof receiveAuthError>;
export type SendEmailVerification = ReturnType<typeof sendEmailVerification>;
export type HandleLoginSuccess = ReturnType<typeof handleLoginSuccess>;

export type AuthAction =
  | ReceiveAuthToken
  | ReceiveAuthError
  | SendEmailVerification
  | HandleLoginSuccess
  | ClearAuthTokens;

export const receiveAuthToken = (token: string, idTokenPayload: IDToken) => {
  return <const>{
    type: 'app/Auth/RECEIVE_AUTH_TOKEN',
    token,
    idTokenPayload,
  };
};

export const clearAuthTokens = () => {
  return <const>{
    type: 'app/Auth/CLEAR_AUTH_TOKENS',
  };
};

export const receiveAuthError = (error: Auth0Error) => {
  return <const>{
    type: 'app/Auth/RECEIVE_AUTH_ERROR',
    error,
  };
};

export const sendEmailVerification = (email: string) => {
  return <const>{
    type: 'app/Auth/SEND_VERIFICATION_EMAIL',
    email,
  };
};

export const handleLoginSuccess = (state: RegisterQueryParameters) => {
  return <const>{
    type: 'app/Auth/HANDLE_LOGIN_SUCCESS',
    state,
  };
};
