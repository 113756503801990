import manAndLadyWithCheckmark from 'images/login/man-and-lady-with-checkmark.svg';
import React from 'react';

export const FormImagePanel: React.FC = () => {
  return (
    <div className="w-full flex justify-center">
      <img src={manAndLadyWithCheckmark} style={{ width: '45%' }} />
    </div>
  );
};
