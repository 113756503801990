import { FieldMetaProps } from 'formik';
import { useMemo } from 'react';
import { InputConfig } from '../types';

export type ErrorMessageDisplayMode = 'none' | 'text' | 'tooltip';

export type InputState = {
  showError?: boolean;
  errorMessage?: string;
  errorTextVisible?: boolean;
  errorTooltipVisible?: boolean;
  errorAdornmentVisible?: boolean;
  errorAdornmentMessage?: string;
  validAdornmentVisible?: boolean;
  noFormItemMargin?: boolean;
  optional?: boolean;
};

export const useInputState = (inputConfig: InputConfig | undefined, meta: FieldMetaProps<unknown>): InputState =>
  useMemo(() => {
    const showError = meta.touched && !!meta.error;

    return {
      showError,
      errorMessage: meta.error || '',
      errorTooltipVisible: showError && inputConfig?.errorMessageDisplayMode === 'tooltip',
      errorTextVisible:
        showError && (!inputConfig?.errorMessageDisplayMode || inputConfig?.errorMessageDisplayMode === 'text'),
      errorAdornmentVisible: showError,
      errorAdornmentMessage: meta.error,
      validAdornmentVisible: inputConfig?.showValidAdornment && meta.touched && !meta.error,
      noFormItemMargin: !!inputConfig?.noFormItemMargin,
      optional: !!inputConfig?.optional,
    };
  }, [inputConfig, meta]);
