/*
 *
 * Organization actions
 *
 */
import { OrganizationInfo, OrganizationInfoByAddress } from '@monax/types';
import { UpdateOrganizationSettingsSuccess } from '../../Manage/state/actions';

export type LoadOrganizations = ReturnType<typeof loadOrganizations>;
export type LoadOrganizationsSuccess = ReturnType<typeof loadOrganizationsSuccess>;

export type OrganizationAction = LoadOrganizations | LoadOrganizationsSuccess | UpdateOrganizationSettingsSuccess;

/**
 * LOAD_ORGANIZATIONS
 */
export function loadOrganizations() {
  return <const>{
    type: 'app/Organization/List/LOAD_ORGANIZATIONS',
  };
}
export function loadOrganizationsSuccess(organizations: OrganizationInfo[] | OrganizationInfoByAddress) {
  return <const>{
    type: 'app/Organization/List/LOAD_ORGANIZATIONS_SUCCESS',
    organizations,
  };
}
