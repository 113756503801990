import { getPersistStorage } from 'configs';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import agreementReducer from './Agreement/reducer';
import { ActivityAgreementState } from './Agreement/types';
import { ActivityState } from './types';

const agreementsConfig: PersistConfig<ActivityAgreementState> = {
  key: 'activity-agreements',
  storage: getPersistStorage(),
};

const reducer = combineReducers<ActivityState>({
  agreement: persistReducer(agreementsConfig, agreementReducer),
});

export default reducer;
