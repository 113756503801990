import React from 'react';
import { ChevronDown, ChevronRight } from 'react-bootstrap-icons';
import { Collapse } from './Collapse';

type Props = {
  header?: React.ReactNode;
  open: boolean;
  onOpenToggle: () => void;
};

export const CollapsePanel: React.FC<Props> = (props) => {
  return (
    <div>
      <div onClick={props.onOpenToggle} className="flex">
        <div className="flex-1">{props.header}</div>
        <div className="flex-initial pl-4 flex items-center cursor-pointer">
          {props.open ? <ChevronDown /> : <ChevronRight />}
        </div>
      </div>
      <Collapse in={props.open} timeout="auto">
        {props.children}
      </Collapse>
    </div>
  );
};
