import { AgreementRecipient } from '@monax/types';
import produce from 'immer';
import { AgreementRecordRequest, AgreementRecordState } from '../types';
import { AgreementRecordAction } from './actions';

export const initialState: AgreementRecordState = {
  agreement: null,
  resourceLimits: {},
  themeOverrides: null,
  requests: [],
  signatures: null,
  blockchainTransactions: { transactions: [], log: [] },
  section: 'documents',
  onSave: null,
  signatureDialog: null,
  signNudgeDialog: false,
  visibilityDialog: false,
  cancelDialog: false,
  deleteDialog: false,
  nameDialog: false,
  obligationDialog: null,
  blockchainTransactionDialog: null,
  blockchainLogDialog: null,
};

const reducer = (state: AgreementRecordState = initialState, action: AgreementRecordAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case AgreementRecordRequest.ADD_EVENTS:
      case AgreementRecordRequest.ADD_PARAMETERS:
      case AgreementRecordRequest.CANCEL_AGREEMENT:
      case AgreementRecordRequest.DELETE_AGREEMENT:
      case AgreementRecordRequest.DOWNLOAD_AGREEMENT:
      case AgreementRecordRequest.LOAD_AGREEMENT:
      case AgreementRecordRequest.UPDATE_DOCUMENTS:
      case AgreementRecordRequest.UPDATE_NAME:
      case AgreementRecordRequest.UPDATE_REPRESENTATIVE:
      case AgreementRecordRequest.UPDATE_TEAMS:
        draftState.requests.push(action.type);
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/ADD_EVENTS_SUCCESS':
        draftState.requests = draftState.requests.filter((request) => request !== AgreementRecordRequest.ADD_EVENTS);
        draftState.agreement.notifications = draftState.agreement.notifications.concat(action.notifications);
        draftState.agreement.obligations = draftState.agreement.obligations.concat(action.obligations);
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/ADD_PARAMETERS_SUCCESS':
        draftState.requests = draftState.requests.filter(
          (request) => request !== AgreementRecordRequest.ADD_PARAMETERS,
        );
        draftState.agreement.parameters = draftState.agreement.parameters.concat(action.parameters);
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/CANCEL_AGREEMENT_SUCCESS':
        draftState.requests = draftState.requests.filter(
          (request) => request !== AgreementRecordRequest.CANCEL_AGREEMENT,
        );
        if (draftState.agreement) {
          draftState.agreement.legalState = action.legalState;
          draftState.agreement.parties = action.parties;
        }
        draftState.cancelDialog = false;
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/DELETE_AGREEMENT_SUCCESS':
        draftState.requests = draftState.requests.filter(
          (request) => request !== AgreementRecordRequest.DELETE_AGREEMENT,
        );
        draftState.agreement = null;
        draftState.resourceLimits = null;
        draftState.themeOverrides = null;
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/DOWNLOAD_AGREEMENT_SUCCESS':
        draftState.requests = draftState.requests.filter(
          (request) => request !== AgreementRecordRequest.DOWNLOAD_AGREEMENT,
        );
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/LOAD_AGREEMENT_SUCCESS': {
        draftState.requests = draftState.requests.filter(
          (request) => request !== AgreementRecordRequest.LOAD_AGREEMENT,
        );
        draftState.agreement = action.agreement;
        draftState.resourceLimits = action.resourceLimits;
        draftState.themeOverrides = action.themeOverrides;
        if (action.signatures) {
          draftState.signatures = action.signatures;
        }
        return draftState;
      }

      case 'app/Agreement_v4/PostChain/Record/UPDATE_DOCUMENTS_SUCCESS':
        draftState.requests = draftState.requests.filter(
          (request) => request !== AgreementRecordRequest.UPDATE_DOCUMENTS,
        );
        draftState.agreement.documents = action.documents;
        draftState.onSave = null;
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/UPDATE_NAME_SUCCESS':
        draftState.requests = draftState.requests.filter((request) => request !== AgreementRecordRequest.UPDATE_NAME);
        draftState.agreement.name = action.name;
        draftState.nameDialog = false;
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/UPDATE_REPRESENTATIVE_SUCCESS':
        draftState.requests = draftState.requests.filter(
          (request) => request !== AgreementRecordRequest.UPDATE_REPRESENTATIVE,
        );
        draftState.agreement.representatives[action.organizationAddress] = action.userAddress;
        draftState.onSave = null;
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/UPDATE_TEAMS_SUCCESS':
        draftState.requests = draftState.requests.filter((request) => request !== AgreementRecordRequest.UPDATE_TEAMS);
        if (draftState.agreement.owner === action.organizationAddress) {
          draftState.agreement.ownerScope = action.team ? [action.team] : [];
        } else {
          const recipient = draftState.agreement.recipients.find(
            (recipient): recipient is AgreementRecipient & { type: 'organization' } =>
              recipient.address === action.organizationAddress,
          );
          if (recipient) {
            recipient.teamId = action.team;
          } else {
            draftState.agreement.recipients.push({
              type: 'organization',
              address: action.organizationAddress,
              teamId: action.team,
            });
          }
        }
        draftState.visibilityDialog = false;
        return draftState;

      case 'app/Agreement_v4/PostChain/Record/RECEIVE_BLOCKCHAIN_TRANSACTIONS':
        draftState.blockchainTransactions = action.data;
        return draftState;

      // Parameters
      case 'app/Agreement_v4/PostChain/Record/RECEIVE_PARAMETERS':
        if (draftState.agreement)
          draftState.agreement.parameters = draftState.agreement.parameters.map((parameter) => {
            const updated = action.parameters.find((p) => p.name === parameter.name);
            if (updated) return updated;
            return parameter;
          });
        return draftState;

      // Error
      case 'app/Agreement_v4/PostChain/Record/RECEIVE_ERROR':
        draftState.requests = draftState.requests.filter((request) => request !== action.request);
        return draftState;

      // Reset
      case 'app/Agreement_v4/PostChain/Record/RESET_PAGE':
        return initialState;

      // Section
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_SECTION':
        draftState.section = action.section;
        draftState.onSave = null;
        return draftState;

      // Editing
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_EDITING':
        draftState.onSave = action.onSave;
        return draftState;

      // Dialogs
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_SIGNATURE_DIALOG':
        draftState.signatureDialog = action.party;
        return draftState;
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_SIGN_NUDGE_DIALOG':
        draftState.signNudgeDialog = action.isOpen;
        return draftState;
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_VISIBILITY_DIALOG':
        draftState.visibilityDialog = action.isOpen;
        return draftState;
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_CANCEL_DIALOG':
        draftState.cancelDialog = action.isOpen;
        return draftState;
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_DELETE_DIALOG':
        draftState.deleteDialog = action.isOpen;
        return draftState;
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_NAME_DIALOG':
        draftState.nameDialog = action.isOpen;
        return draftState;
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_OBLIGATION_DIALOG':
        draftState.obligationDialog = action.obligation;
        return draftState;
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_BLOCKCHAIN_TRANSACTION_DIALOG':
        draftState.blockchainTransactionDialog = action.id;
        return draftState;
      case 'app/Agreement_v4/PostChain/Record/TOGGLE_BLOCKCHAIN_LOG_DIALOG':
        draftState.blockchainLogDialog = action.id;
        return draftState;

      default:
        return draftState;
    }
  });

export default reducer;
