import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Option } from 'types';
import { RESTART_ON_REMOUNT } from 'utils/constants';
import { useInjectSaga } from 'utils/injectSaga';
import { loadForeignAddresses } from '../state/actions';
import { watchUserForeignAddresses } from '../state/saga';
import { selectActionInProgress, selectForeignAddresses } from '../state/selectors';

type ForeignAddressOptions = {
  options: Option[];
  loading: boolean;
};

export const useForeignAddressOptions = (): ForeignAddressOptions => {
  const dispatch = useDispatch();
  const foreignAddresses = useSelector(selectForeignAddresses);
  const loading = useSelector(selectActionInProgress);

  useInjectSaga({
    key: 'edit-foreign-address',
    saga: watchUserForeignAddresses,
    mode: RESTART_ON_REMOUNT,
  });

  useEffect(() => {
    dispatch(loadForeignAddresses());
  }, []);

  const options = useMemo(() => {
    return foreignAddresses.map((a) => ({
      label: a.name || a.address,
      value: a.address,
    }));
  }, [foreignAddresses]);

  return {
    options,
    loading,
  };
};
