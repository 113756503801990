import produce from 'immer';
import { CreateDraftState } from '../types';
import { DraftAction } from './actions';

export const initialState: CreateDraftState = {
  // Keep template/draft for metadata (eg id, approval jobs, published state, ...)
  // and help ensure state is in sync with route (eg if we are on /agreement/:draftId/edit, our loaded draft's id must match)
  template: null,
  draft: null,
  resourceLimits: {},
  request: null,
  agreementAddress: null,
  outOfDateDialog: false,
};

const reducer = (state: CreateDraftState = initialState, action: DraftAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Agreement_v4/PreChain/Draft/LOAD_DRAFT':
      case 'app/Agreement_v4/PreChain/Draft/LOAD_TEMPLATE':
      case 'app/Agreement_v4/PreChain/Draft/SAVE_DRAFT':
      case 'app/Agreement_v4/PreChain/Draft/SUBMIT_DRAFT_FOR_APPROVAL':
      case 'app/Agreement_v4/PreChain/Draft/APPROVE_DRAFT':
      case 'app/Agreement_v4/PreChain/Draft/UPDATE_PARTY':
      case 'app/Agreement_v4/PreChain/Draft/ADD_DOCUMENTS':
      case 'app/Agreement_v4/PreChain/Draft/CANCEL_DRAFT':
        draftState.request = action.type;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/LOAD_DRAFT_SUCCESS':
        draftState.draft = action.draft;
        draftState.resourceLimits = action.resourceLimits;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/LOAD_TEMPLATE_SUCCESS':
        draftState.template = action.template;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/SAVE_DRAFT_SUCCESS':
        draftState.draft = action.draft;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/SUBMIT_DRAFT_FOR_APPROVAL_SUCCESS':
        draftState.draft = action.draft;
        draftState.agreementAddress = action.agreementAddress;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/APPROVE_DRAFT_SUCCESS':
        draftState.draft = action.draft;
        draftState.agreementAddress = action.agreementAddress;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/UPDATE_PARTY_SUCCESS':
        draftState.draft.body.signatories.find((s) => s.label === action.partyLabel).value = action.organizationAddress;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/ADD_DOCUMENTS_SUCCESS':
        draftState.draft.body.documents.push(...action.documents);
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/CANCEL_DRAFT_SUCCESS':
        delete draftState.draft;
        delete draftState.template;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/RECEIVE_DRAFT_ERROR':
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Draft/TOGGLE_OUT_OF_DATE_DIALOG':
        draftState.outOfDateDialog = action.shouldOpen;
        return draftState;
      default:
        return draftState;
    }
  });

export default reducer;
