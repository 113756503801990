import { TextField } from '@material-ui/core';
import React, { InputHTMLAttributes } from 'react';
import { FormItem } from './FormItem';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'color' | 'onChange'> & {
  name: string; // make name required
  label?: string;
  labelAddon?: React.ReactNode;
  noFormItemMargin?: boolean;
};

export const ReadonlyInput: React.FC<Props> = (props) => {
  const { label, labelAddon, ...input } = props;

  return (
    <FormItem label={label} labelAddon={labelAddon} inputState={{ noFormItemMargin: props.noFormItemMargin }}>
      <TextField {...input} disabled size="small" variant="outlined" />
    </FormItem>
  );
};
