import produce from 'immer';
import { ResourceFiltersState } from '../types';
import { ResourceFilterAction } from './actions';

export const initialState: ResourceFiltersState = {
  resourceFilters: [],
  requesting: false,
};

const reducer = (state: ResourceFiltersState = initialState, action: ResourceFilterAction) =>
  produce(
    state,
    (draftState): ResourceFiltersState => {
      switch (action.type) {
        case 'app/User/Current/ResourceFilters/LOAD_RESOURCE_FILTERS':
          draftState.requesting = true;
          return draftState;
        case 'app/User/Current/ResourceFilters/LOAD_RESOURCE_FILTERS_SUCCESS':
          draftState.resourceFilters = action.resourceFilters;
          draftState.requesting = false;
          return draftState;
        case 'app/User/Current/ResourceFilters/ADD_RESOURCE_FILTER':
        case 'app/User/Current/ResourceFilters/UPDATE_RESOURCE_FILTER':
        case 'app/User/Current/ResourceFilters/REMOVE_RESOURCE_FILTER':
          draftState.requesting = true;
          return draftState;
        case 'app/User/Current/ResourceFilters/ADD_RESOURCE_FILTER_SUCCESS':
        case 'app/User/Current/ResourceFilters/UPDATE_RESOURCE_FILTER_SUCCESS':
        case 'app/User/Current/ResourceFilters/REMOVE_RESOURCE_FILTER_SUCCESS':
          draftState.requesting = false;
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;
