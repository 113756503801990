import { AgreementAddress, AgreementFilter, AgreementListItem } from '@monax/types';
import { ListAgreementSearch, ListAgreementSort, SelectedAgreement } from '../types';
import { initialState } from './reducer';

export type LoadAgreements = ReturnType<typeof loadAgreements>;
export type LoadAgreementsSuccess = ReturnType<typeof loadAgreementsSuccess>;
export type LoadMoreAgreements = ReturnType<typeof loadMoreAgreements>;
export type LoadMoreAgreementsSuccess = ReturnType<typeof loadMoreAgreementsSuccess>;
export type ReceiveListAgreementSort = ReturnType<typeof receiveListAgreementSort>;
export type ReceiveListAgreementSearch = ReturnType<typeof receiveListAgreementSearch>;
export type ToggleSelectedAgreement = ReturnType<typeof toggleSelectedAgreement>;
export type ClearAllSelectedAgreements = ReturnType<typeof clearAllSelectedAgreements>;
export type SelectAllAgreements = ReturnType<typeof selectAllAgreements>;
export type ReceiveAgreementResourceFilterId = ReturnType<typeof receiveAgreementResourceFilterId>;
export type ReceiveDefaultAgreementResourceFilter = ReturnType<typeof receiveDefaultAgreementResourceFilter>;
export type ReceiveAgreementAddressesFilter = ReturnType<typeof receiveAgreementAddressesFilter>;
export type ClearAgreementAddressesFilter = ReturnType<typeof clearAgreementAddressesFilter>;

export type HomePageAgreementsAction =
  | LoadAgreements
  | LoadAgreementsSuccess
  | LoadMoreAgreements
  | LoadMoreAgreementsSuccess
  | ReceiveListAgreementSort
  | ReceiveListAgreementSearch
  | ToggleSelectedAgreement
  | ClearAllSelectedAgreements
  | SelectAllAgreements
  | ReceiveAgreementResourceFilterId
  | ReceiveDefaultAgreementResourceFilter
  | ReceiveAgreementAddressesFilter
  | ClearAgreementAddressesFilter;

/**
 * LOAD_AGREEMENTS
 */
export function loadAgreements() {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/LOAD_AGREEMENTS',
  };
}
export function loadAgreementsSuccess(agreements: AgreementListItem[], totalAgreements: number) {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/LOAD_AGREEMENTS_SUCCESS',
    agreements,
    totalAgreements,
  };
}

/**
 * LOAD_MORE_AGREEMENTS
 */
export function loadMoreAgreements() {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/LOAD_MORE_AGREEMENTS',
  };
}
export function loadMoreAgreementsSuccess(agreements: AgreementListItem[], totalAgreements: number) {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/LOAD_MORE_AGREEMENTS_SUCCESS',
    agreements,
    totalAgreements,
  };
}

/**
 * RECEIVE_LIST_AGREEMENT_SORT
 */
export function receiveListAgreementSort(sort: ListAgreementSort) {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/RECEIVE_LIST_AGREEMENT_SORT',
    sort,
  };
}

/**
 * RECEIVE_LIST_AGREEMENT_SORT
 * @param search Optional. If not provided, all search values will be reset
 */
export function receiveListAgreementSearch(search: Partial<ListAgreementSearch> = initialState.search) {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/RECEIVE_LIST_AGREEMENT_SEARCH',
    search,
  };
}

/**
 * TOGGLE_SELECTED_AGREEMENT
 */
export function toggleSelectedAgreement(agreement: SelectedAgreement) {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/TOGGLE_SELECTED_AGREEMENT',
    agreement,
  };
}

/**
 * CLEAR_ALL_SELECTED_AGREEMENTS
 */
export function clearAllSelectedAgreements() {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/CLEAR_ALL_SELECTED_AGREEMENTS',
  };
}

/**
 * SELECT_ALL_AGREEMENTS
 */
export function selectAllAgreements() {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/SELECT_ALL_AGREEMENTS',
  };
}

/**
 * RECEIVE_RESOURCE_FILTER_ID
 */
export function receiveAgreementResourceFilterId(id?: string) {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/RECEIVE_RESOURCE_FILTER_ID',
    id,
  };
}

/**
 * RECEIVE_DEFAULT_RESOURCE_FILTER
 */
export function receiveDefaultAgreementResourceFilter(filter: AgreementFilter) {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/RECEIVE_DEFAULT_RESOURCE_FILTER',
    filter,
  };
}

/**
 * RECEIVE_AGREEMENT_ADDRESSES_FILTER
 */
export function receiveAgreementAddressesFilter(agreementAddresses: AgreementAddress[]) {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/RECEIVE_AGREEMENT_ADDRESSES_FILTER',
    agreementAddresses,
  };
}

/**
 * CLEAR_AGREEMENT_ADDRESSES_FILTER
 */
export function clearAgreementAddressesFilter() {
  return <const>{
    type: 'app/Agreement_v4/List/Agreement/CLEAR_AGREEMENT_ADDRESSES_FILTER',
  };
}
