import { Button } from 'components/Common';
import React, { MouseEventHandler } from 'react';

type Props = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  type?: 'button' | 'submit';
};

export const SubmitButton: React.FC<Props> = (props) => {
  return (
    <Button
      type="primary"
      className="w-full"
      size="l"
      buttonType={props.type || 'submit'}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
};
