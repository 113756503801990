/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */
import { getEnvironment } from 'configs';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectLocale } from './state/selectors';
import { SupportedLocale } from './types';

type Props = {
  messages: Record<SupportedLocale, Record<string, string>>;
};

export const LanguageProvider: React.FC<Props> = ({ messages, children }) => {
  const locale = useSelector(selectLocale);
  const noop = () => {};

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={messages[locale]}
      // Dont spam errors while in local
      onError={getEnvironment() === 'local' ? noop : undefined}
    >
      {React.Children.only(children)}
    </IntlProvider>
  );
};
