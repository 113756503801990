import { MessageDescriptor } from '@formatjs/intl';
import { addFormattedNotification } from 'containers/App/state/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type UseNotifications = {
  showError: (descriptor: MessageDescriptor, messageValues?: { [parameter: string]: string }) => void;
  showSuccess: (descriptor: MessageDescriptor, messageValues?: { [parameter: string]: string }) => void;
};

export const useNotifications = (): UseNotifications => {
  const dispatch = useDispatch();
  const showError = useCallback(
    async (descriptor: MessageDescriptor, messageValues?: { [parameter: string]: string }) => {
      dispatch(
        addFormattedNotification({
          open: true,
          className: 'error',
          messageDescriptor: descriptor,
          messageValues,
        }),
      );
    },
    [],
  );
  const showSuccess = useCallback(
    async (descriptor: MessageDescriptor, messageValues?: { [parameter: string]: string }) => {
      dispatch(
        addFormattedNotification({
          open: true,
          className: 'success',
          messageDescriptor: descriptor,
          messageValues,
        }),
      );
    },
    [],
  );
  return {
    showSuccess,
    showError,
  };
};
