import { LinkButton } from 'components/Common';
import { getSupportedLocale } from 'configs';
import { watchSendEmailVerification } from 'containers/Auth/Auth/state/saga';
import { AuthLayout, SlidesPanel } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { changeLocale } from 'containers/Preferences/Locale/state/actions';
import { useTheme } from 'containers/Theme/useTheme';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { parseQueryString } from 'utils/queryString';
import { InvitePanel } from '../controls/InvitePanel';
import { WhiteLabelAuthLayout } from '../controls/WhiteLabelAuthLayout';
import { RegisteredImagePanel } from './controls';
import { RegisterWithEmailAddress } from './RegisterWithEmailAddress';
import { RegisterWithEthAddress } from './RegisterWithEthAddress';
import { DEFAULT_NAME } from 'containers/Theme/types';

export const Register: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { themeName } = useTheme();
  const [registering, setRegistering] = useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);

  const params = parseQueryString(window.location.search);

  const { email, ethAddress, locale } = params;

  useInjectSaga({
    key: 'email-verification',
    saga: watchSendEmailVerification,
  });

  useEffect(() => {
    if (locale) {
      dispatch(changeLocale(getSupportedLocale(locale)));
    }
  }, []);

  const header = registered
    ? intl.formatMessage(messages.thanksForJoining)
    : intl.formatMessage(messages.createAnAccount);
  const title = registered ? intl.formatMessage(messages.verifyEmail) : '';
  const rightPanel = registered ? <RegisteredImagePanel /> : email ? <InvitePanel /> : <SlidesPanel />;

  const loginButton = (
    <LinkButton to={`/login${window.location.search}`} size="s" type="outlined">
      {registered ? intl.formatMessage(messages.back) : intl.formatMessage(messages.login)}
    </LinkButton>
  );

  const Wrapper = themeName === DEFAULT_NAME ? AuthLayout : WhiteLabelAuthLayout;
  //This should change to a subdomain check probably.

  return (
    <Wrapper header={header} title={title} headerAddon={loginButton} rightPanel={rightPanel}>
      {ethAddress ? (
        <RegisterWithEthAddress params={params} />
      ) : (
        <RegisterWithEmailAddress
          params={params}
          registered={registered}
          registering={registering}
          setRegistered={setRegistered}
          setRegistering={setRegistering}
        />
      )}
    </Wrapper>
  );
};
