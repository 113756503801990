/*
 * Loading Indicator Messages
 *
 * This contains all the text for the Loading Indicator component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  inProgress: {
    id: 'app.components.ContentLoader.inProgress',
    defaultMessage: '...still working on it',
    description:
      'Message for loading time while an action takes longer than 3 seconds, to inform users that we are still working.',
  },
  createMessage: {
    id: 'app.components.ContentLoader.createMessage',
    defaultMessage: 'Creating a secure record...',
    description: 'Message for loading time while an action initiates an on-chain resources being retrieved',
  },
});
