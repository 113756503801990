import { AgreementParameter, createDocumentErrorResponse } from '@monax/types';
import { selectAgreement } from 'containers/Agreement/PostChain/Record/state/selectors';
import { showSnackbarErrorMessage } from 'containers/App/state/saga';
import { selectAPIClient } from 'containers/App/state/selectors';
import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { all, call, select } from 'typed-redux-saga';
import { RequestError } from 'types';
import messages from '../messages';
import { CanAssembleMime, CAN_ASSEMBLE_MIMES } from '../types';
import {
  LoadAssembledDocument,
  loadAssembledDocumentError,
  loadAssembledDocumentSuccess,
  LoadDocument,
  loadDocument as loadDocumentAction,
  loadDocumentError,
  LoadDocumentMeta,
  loadDocumentMetaError,
  loadDocumentMetaSuccess,
  loadDocumentSuccess,
  UploadAttachment,
  uploadAttachmentError,
  uploadAttachmentSuccess,
  UploadDocuments,
  uploadDocumentsError,
  uploadDocumentsSuccess,
} from './actions';

/**
 * Worker Sagas
 */
export function* loadAssembledDocument({ grant, parameters, address, signaturesFileReference }: LoadAssembledDocument) {
  try {
    const client = yield* select(selectAPIClient);
    //populates the display value and value properties for those party parameters which are not defined
    const populatedParameters: AgreementParameter[] = parameters.map((param: AgreementParameter) => {
      if (param.displayValue === '' && param.type === 8) {
        //uses the same default display value as in scalia
        return { ...param, displayValue: '___ [ Waiting on Value ] ___ ', value: 'value' };
      }
      return param;
    });
    const { data } = yield* call(client.assembleDocument, {
      address,
      signaturesFileReference,
      grants: [grant],
      parameters: populatedParameters,
    });
    const document = data.docs[0];
    yield put(loadAssembledDocumentSuccess(document));
  } catch (err) {
    yield put(loadAssembledDocumentError(err, grant));
  }
}

export function* loadDocumentMeta({ grant, extractParams = false }: LoadDocumentMeta) {
  try {
    const client = yield* select(selectAPIClient);
    const { data } = yield* call(client.getDocumentInfo, grant, extractParams, true);
    yield put(loadDocumentMetaSuccess(grant, data.meta, data.params));
  } catch (err) {
    yield put(loadDocumentMetaError(err, grant));
  }
}

export function* uploadDocuments({ documents, onUploadSuccess }: UploadDocuments) {
  try {
    const client = yield* select(selectAPIClient);
    const responses = yield* all(
      documents.map((doc) =>
        call(
          client.createDocument,
          CAN_ASSEMBLE_MIMES.includes(doc.type as CanAssembleMime),
          undefined,
          undefined,
          doc,
        ),
      ),
    );
    const uploads = responses.map((res) => res.data);
    onUploadSuccess(uploads);
    yield put(uploadDocumentsSuccess(uploads));
  } catch (err) {
    const errMessage = getUploadErrorMessage(err);
    yield showSnackbarErrorMessage(<FormattedMessage {...errMessage} />);
    yield put(uploadDocumentsError());
  }
}

export function* loadDocument({ grant, onSuccess }: LoadDocument) {
  try {
    const client = yield* select(selectAPIClient);

    const infoResponse = yield* call(client.getDocumentInfo, grant, false, true);
    const { meta } = infoResponse.data;

    const documentResponse = yield* call(client.getDocument, grant, {
      responseType: 'arraybuffer',
    });

    yield put(loadDocumentSuccess(grant, { meta }, Buffer.from(documentResponse.data)));
    onSuccess?.();
  } catch (err) {
    yield put(loadDocumentError(err, grant));
  }
}

export function* uploadAttachment({ attachment }: UploadAttachment) {
  try {
    const client = yield* select(selectAPIClient);
    const { address } = yield* select(selectAgreement);
    const createResponse = yield* call(client.createDocument, false, undefined, undefined, attachment);
    const attachResponse = yield* call(client.addAgreementDocument, address, { grant: createResponse.data.grant });
    yield put(uploadAttachmentSuccess(attachResponse.data.document));
    yield loadDocument(loadDocumentAction(attachResponse.data.document.grant));
  } catch (err) {
    yield put(uploadAttachmentError());
    yield showSnackbarErrorMessage(<FormattedMessage {...messages.failedToUploadDocuments} />);
  }
}

/**
 * Watcher Sagas
 */

export function* watchHandleDocuments() {
  yield takeEvery('app/Agreement/Document/LOAD_ASSEMBLED_DOCUMENT', loadAssembledDocument);
  yield takeEvery('app/Agreement/Document/UPLOAD_DOCUMENTS', uploadDocuments);
  yield takeEvery('app/Agreement/Document/LOAD_DOCUMENT_META', loadDocumentMeta);
  yield takeEvery('app/Agreement/Document/LOAD_DOCUMENT', loadDocument);
  yield takeLatest('app/Agreement/Document/UPLOAD_DOCUMENT', uploadAttachment);
}

/**
 * Helpers
 */

function getUploadErrorMessage(err: RequestError): MessageDescriptor {
  const response: createDocumentErrorResponse = err.response?.data;
  let errMessage: MessageDescriptor = messages.failedToUploadDocuments;
  if (response) {
    switch (response.errorCode) {
      case 'unsupported_elements':
        errMessage = messages.apiError_unsupportedElements;
        break;
      case 'nonsequential_headers':
        errMessage = messages.apiError_documenHeaders;
        break;
      case 'file_too_large':
        errMessage = messages.apiError_fileTooLarge;
        break;
      case 'request_unprocessable':
        errMessage = messages.apiError_unprocessable;
        break;
      default:
        break;
    }
  }
  return errMessage;
}
