import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core';
import { Button, ButtonSize, ButtonType } from 'components/Common';
import React from 'react';
import { MenuOption } from './types';

type Props = {
  id: string;
  options: MenuOption[];
  buttonText: string;
  buttonDisabled?: boolean;
  buttonClassName?: string;
  buttonType?: ButtonType;
  buttonIconEnd?: React.ReactNode;
  buttonSize?: ButtonSize;
  buttonDisableResponsiveness?: boolean;
};
export const ButtonMenu: React.FC<Props> = ({
  id,
  buttonText,
  options,
  buttonClassName,
  buttonDisabled,
  buttonType,
  buttonIconEnd,
  buttonSize,
  buttonDisableResponsiveness,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        key={id}
        anchorRef={anchorRef}
        onClick={handleToggle}
        className={`truncate ${buttonClassName}`}
        type={buttonType}
        disabled={buttonDisabled}
        iconEnd={buttonIconEnd}
        size={buttonSize}
        disableResponsiveness={buttonDisableResponsiveness}
      >
        {buttonText}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        className="z-50"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div className="px-1 py-2" style={{ minWidth: '220px' }}>
                  {options.map((option, index) => {
                    const isLast = index === options.length - 1;
                    return (
                      <div key={index} className="w-full">
                        <Button
                          type="dark-gray-link"
                          size={'s'}
                          className="w-full text-left"
                          onClick={(e) => {
                            if (option.onClick) {
                              option.onClick();
                              handleClose(e);
                            }
                          }}
                          icon={option.icon}
                        >
                          {option.label}
                        </Button>
                        {!isLast && <div className="pt-2" />}
                      </div>
                    );
                  })}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
