/*
 *
 * User actions
 *
 */

import { UserInfo, UserInfoByAddress } from '@monax/types';
import { RemoveMemberSuccess } from 'containers/Organization/Manage/state/actions';

/**
 * LOAD_USERS
 */
export type LoadUsers = ReturnType<typeof loadUsers>;
export function loadUsers() {
  return <const>{
    type: 'app/UserAccount/LOAD_USERS',
  };
}
export type LoadUsersSuccess = ReturnType<typeof loadUsersSuccess>;
export function loadUsersSuccess(users: UserInfo[] | UserInfoByAddress) {
  return <const>{
    type: 'app/UserAccount/LOAD_USERS_SUCCESS',
    users,
  };
}

export type UserAction = LoadUsers | LoadUsersSuccess | RemoveMemberSuccess;
