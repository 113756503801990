/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import * as Sentry from '@sentry/react';
import { CheckSession, SecureRoute } from 'containers/Auth';
import { Callback } from 'containers/Auth/Login/Callback';
import { Login } from 'containers/Auth/Login/Login';
import { Logout } from 'containers/Auth/Logout/Logout';
import { Register } from 'containers/Auth/Register/Register';
import { ResetPassword } from 'containers/Auth/ResetPassword/ResetPassword';
import { ResetPasswordRequest } from 'containers/Auth/ResetPasswordRequest/ResetPasswordRequest';
import { ClientError, NotFoundError, PaymentRequiredError, ServerError } from 'containers/Error';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import 'validation/yup';
import config from '../../configs';
import { Route } from './components/Route';
import { SecuredRoutes } from './components/SecuredRoutes';
import { useAppTitleTemplate } from './hooks/useAppTitleTemplate';
import { Snackbar } from './Snackbar';

export const App: React.FC = () => {
  const titleTemplate = useAppTitleTemplate();

  return (
    <>
      <Helmet titleTemplate={titleTemplate} defaultTitle="Suscribo">
        <meta name="keywords" content={config.siteData.keywords} />
        <meta property="og:url" content={`${window.location.protocol}//${window.location.host}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={config.siteData.title} />
        <meta name="twitter:site" content={`@${config.siteData.twitterUser}`} />
        <meta property="og:site_name" content={config.siteData.title} />

        <meta name="description" content={config.siteData.tagline} />
        <meta property="og:description" content={config.siteData.tagline} />
        <meta name="twitter:description" content={config.siteData.tagline} />
        <meta name="twitter:card" content="summary" />
        <meta itemProp="image" content={config.siteData.twitterImage} />
        <meta property="og:image" content={config.siteData.twitterImage} />
        <meta name="twitter:image" content={config.siteData.twitterImage} />
        <meta name="twitter:creator" content={`@${config.siteData.twitterUser}`} />
      </Helmet>
      <Switch>
        <Route pageCategory="Errors" pageName="402" exact path="/402" component={PaymentRequiredError} />
        <Route pageCategory="Errors" pageName="404" exact path="/404" component={NotFoundError} />
        <Route pageCategory="Errors" pageName="500" exact path="/500" component={ServerError} />
        <Route pageCategory="Errors" pageName="client" exact path="/error" component={ClientError} />
        <CheckSession>
          <Switch>
            <Route pageCategory="Login" pageName="login" exact path="/login" component={Login} />
            <Route exact path="/callback" component={Callback} />
            <Route pageCategory="Register" pageName="register" exact path="/register" component={Register} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/reset-password-request" component={ResetPasswordRequest} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <SecureRoute path="/" component={SecuredRoutes} />
          </Switch>
        </CheckSession>
      </Switch>
      <Snackbar />
    </>
  );
};

export default Sentry.withErrorBoundary(App, { fallback: <ClientError /> });
