import { SupportedLocale } from 'containers/Preferences/Locale/types';
import { createIntl } from 'react-intl';
import { translationMessages } from './i18n';

/**
 * Get intl object outside of react components and sagas
 */
export function getIntl(locale: SupportedLocale) {
  return createIntl({
    locale,
    messages: translationMessages[locale],
  });
}
