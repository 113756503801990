import { SegmentAnalytics } from '@segment/analytics.js-core';
import configs from 'configs';
import { mockAnalytics } from './mocks';
import { PageCategory, TrackProps, UserTraits } from './types';

type AnalyticsUser = {
  id: () => string;
  anonymousId: () => string;
  traits: () => Record<string, unknown>;
};

export const initAnalytics = () => {
  // return mock if this is jest running
  if (process.env.JEST_WORKER_ID !== undefined) {
    window.analytics = mockAnalytics;
    return;
  }
  if (!configs.integrations.analyticsID) {
    console.warn('No analytics ID found; using mock');
    window.analytics = mockAnalytics;
    return;
  }

  // BELOW IS MOSTLY COPIED FROM: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/#step-2-copy-the-segment-snippet

  // Create a queue, but don't obliterate an existing one!
  const analytics = window.analytics || [];

  // If the real analytics.js is already on the page or the snippet was already invoked return.
  if (analytics.initialize || analytics.invoked) {
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'user',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware',
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method) {
    return function () {
      const args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Add a version to keep track of what's in the wild.
  analytics._writeKey = configs.integrations.analyticsID;
  analytics.SNIPPET_VERSION = '4.1.0';

  window.analytics = analytics;

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  window
    .fetch('https://cdn.segment.com/analytics.js/v1/' + configs.integrations.analyticsID + '/analytics.min.js')
    .then(async (res) => {
      if (!res.ok) {
        console.error(`Failed to load from segment CDN`);
        window.analytics = mockAnalytics;
        return;
      }
      // Dangerous, but we trust are trusting segment...
      const script = new TextDecoder('utf-8').decode(new Uint8Array(await (await res.blob()).arrayBuffer()));
      window.eval(script);
    })
    .catch((err) => {
      console.error(`Failed to load from segment CDN: ${err.message}`);
      window.analytics = mockAnalytics;
    });
};

export const identify = async (userId: string, traits: UserTraits) => {
  const analytics = window.analytics;
  const user = analytics.user() as AnalyticsUser;
  traits = Object.assign(
    {
      userId,
      anonymousId: user.anonymousId?.(),
    },
    traits,
  );
  // check if user is anon. if so, merge traits with anon user traits which may contain utm info.
  if (user.id && !user.id()) {
    traits = Object.assign(user.traits(), traits);
  }
  analytics.identify(userId, traits, {
    context: {
      app: {
        version: configs.siteData.appVersion,
      },
    },
  });
};

export const anonymousIdentify = async (traits: unknown, options: SegmentAnalytics.SegmentOpts) => {
  const analytics = window.analytics;
  analytics.identify(undefined, traits, options);
};

export const track = async (props: TrackProps) => {
  const analytics = window.analytics;
  analytics.track(props.event, {
    properties: props.payload,
    context: {
      app: {
        version: configs.siteData.appVersion,
      },
    },
  });
};

let initialLanding = true;
export const page = async (category: PageCategory, name: string) => {
  const analytics = window.analytics;
  if (initialLanding) {
    track({ event: 'application.entered', payload: { pageCategory: category } });
    initialLanding = false;
  }
  analytics.page(category, name);
};
