import { ForeignChainAssetType } from '@monax/types';

export const getForeignChainAssetTypeCode = (type: ForeignChainAssetType): string | null => {
  switch (type) {
    case 'non_fungible_token':
      return 'ERC721';
    default:
      return type;
  }
};
