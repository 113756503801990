import messages from 'containers/Auth/messages';
import { IntlShape } from 'react-intl';
import yup from './yup';

export const loginSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

export const getEmailRegisterSchema = (intl: IntlShape) => {
  return yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .equalTo(yup.ref('password'), intl.formatMessage(messages.passwordsMustBeTheSame))
      .required(),
    tosAccepted: yup.boolean().true(intl.formatMessage(messages.youMustAcceptTheTermsOfService)),
  });
};

export const getEthRegisterSchema = (intl: IntlShape) => {
  return yup.object().shape({
    address: yup.string().required(),
    tosAccepted: yup.boolean().true(intl.formatMessage(messages.youMustAcceptTheTermsOfService)),
  });
};

export const resetPasswordRequestSchema = yup.object().shape({
  usernameOrEmail: yup.string().required(),
});

export const getResetPasswordSchema = (intl: IntlShape) => {
  return yup.object().shape({
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .equalTo(yup.ref('password'), intl.formatMessage(messages.passwordsMustBeTheSame))
      .required(),
  });
};
