import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';
import background from '../../../images/backgrounds/waves-and-dots.svg';

type Props = {
  header: string;
  title: string;
  rightPanel: React.ReactNode;
  headerAddon?: React.ReactNode;
};

export const WhiteLabelAuthLayout: React.FC<Props> = (props) => {
  const { logos } = useTheme();
  return (
    <div
      style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
      className="flex h-full w-full justify-center items-center"
    >
      <div
        style={{ boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)' }}
        className="flex items-center justify-center lg:w-1/3 md:w-full mx-2 bg-white py-8 rounded-xl"
      >
        <div className="w-full px-12 mx-4 my-4">
          <div className="flex flex-col">
            <div className="pb-10 flex justify-between">
              <div>
                <img src={logos.full.secondary} alt="logo" width="180px" />
              </div>
              {props.headerAddon && <div>{props.headerAddon}</div>}
            </div>
            <div className="uppercase pt-10 text-xs font-bold text-primary-main">{props.title}</div>
            <div className="pt-1 pb-6">
              <h2>{props.header}</h2>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};
