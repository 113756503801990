import configs from 'configs';
import { clearNotification } from 'containers/App/state/actions';
import graphic from 'images/error/500.svg';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action, ErrorLayout } from './controls';
import { messages } from './messages';

export const PaymentRequiredError: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearNotification());
  }, []);

  return (
    <ErrorLayout
      graphic={graphic}
      title={intl.formatMessage(messages.paymentRequiredErrorHeader)}
      description={intl.formatMessage(messages.paymentRequiredErrorDescription, {
        email: (
          <a className="text-primary-main" href={`mailto:${configs.emails.support}`}>
            {configs.emails.support}
          </a>
        ),
      })}
      actions={
        <>
          <Action href="/">{intl.formatMessage(messages.backToHome)}</Action>
          <Action onClick={() => history.goBack()}>{intl.formatMessage(messages.previousPage)}</Action>
        </>
      }
    />
  );
};
