import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';

export const ValidAdornment: React.FC = () => {
  return (
    <InputAdornment position="end">
      <CheckCircleFill className="text-primary-main" />
    </InputAdornment>
  );
};
