import { getNodeEnvironment } from 'configs';

export function unregister() {
  if (getNodeEnvironment() === 'production' && 'serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
}
