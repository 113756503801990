import { getInitialTemplateFilter } from 'containers/User/Current/ResourceFilters/utils';
import produce from 'immer';
import { mergeShallow } from 'utils/merge';
import { ListTemplateSort, ListTemplateState } from '../types';
import { HomePageTemplatesAction } from './actions';

export const defaultSort: ListTemplateSort = {
  column: 'createdAt',
  direction: 'desc',
};

export const initialState: ListTemplateState = {
  loading: false,
  templates: [],
  totalTemplates: 0,
  sort: defaultSort,
  selectedTemplates: [],
  search: {
    textSearch: null,
  },
  filter: getInitialTemplateFilter(),
};

const templatesReducer = (state = initialState, action: HomePageTemplatesAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Template_v4/List/Template/LOAD_TEMPLATES':
        draftState.loading = true;
        return draftState;

      case 'app/Template_v4/List/Template/LOAD_MORE_TEMPLATES':
        draftState.loading = true;
        return draftState;

      case 'app/Template_v4/List/Template/LOAD_TEMPLATES_SUCCESS':
        draftState.loading = false;
        draftState.totalTemplates = action.totalTemplates;
        draftState.templates = action.templates;
        return draftState;

      case 'app/Template_v4/List/Template/LOAD_MORE_TEMPLATES_SUCCESS':
        draftState.loading = false;
        draftState.totalTemplates = action.totalTemplates;
        draftState.templates = draftState.templates.concat(action.templates);
        return draftState;

      case 'app/Template_v4/List/Template/RECEIVE_LIST_AGREEMENT_SORT':
        draftState.sort = action.sort;
        return draftState;

      case 'app/Template_v4/List/Template/RECEIVE_LIST_AGREEMENT_SEARCH': {
        mergeShallow(draftState.search, action.search);
        return draftState;
      }

      case 'app/Template_v4/List/Template/TOGGLE_SELECTED_AGREEMENT':
        if (draftState.selectedTemplates.find((t) => t === action.id))
          draftState.selectedTemplates = draftState.selectedTemplates.filter((id) => id !== action.id);
        else draftState.selectedTemplates.push(action.id);
        return draftState;

      case 'app/Template_v4/List/Template/CLEAR_ALL_SELECTED_TEMPLATES':
        draftState.selectedTemplates = [];
        return draftState;

      case 'app/Template_v4/List/Template/SELECT_ALL_TEMPLATES':
        draftState.selectedTemplates = draftState.templates.map((t) => t.id);
        return draftState;

      case 'app/Template_v4/List/Template/RECEIVE_RESOURCE_FILTER_ID':
        draftState.loading = true;
        draftState.resourceFilterId = action.id;
        return draftState;

      case 'app/Template_v4/List/Template/RECEIVE_DEFAULT_RESOURCE_FILTER':
        draftState.loading = true;
        draftState.filter = action.filter;
        return draftState;

      default:
        return draftState;
    }
  });

export default templatesReducer;
