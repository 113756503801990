import { Address, Capability, CapabilityEnum, UserUpdate } from '@monax/types';
import { selectCurrentOrganizationAddress } from 'containers/Organization/Current/state/selectors';
import { selectOrganizations } from 'containers/Organization/List/state/selectors';
import { selectLocale } from 'containers/Preferences/Locale/state/selectors';
import { selectOrderedOrganizations } from 'containers/Preferences/Organization/state/selectors';
import { selectUsers } from 'containers/User/List/selectors';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { ApplicationState } from 'types';
import { CurrentUser } from '../types';
import { REMOVE_USER_FROM_ORGANIZATION, UPDATE_PROFILE, UPDATE_ROLE, UPDATE_SIGNATURE } from './constants';

const rootSelector = (state: ApplicationState) => state.users.current.profile;

const currentUserSelector = createSelector(rootSelector, (state) => state.user);

export const errorsSelector = createSelector(rootSelector, (state) => state.errors);

export const requestsSelector = createSelector(rootSelector, (state) => state.requests);

export const selectCurrentUser = createSelector(currentUserSelector, (currentUser) => currentUser);

export const selectCurrentUserAddress = createSelector(selectCurrentUser, (user) => user?.address);

export const selectCurrentUserUpdate = createSelector(selectCurrentUser, (user) => {
  if (!user) return null;

  const userUpdate: UserUpdate = {
    name: user.name,
    username: user.username,
    locale: user.locale,
    preferredName: user.preferredName,
    useMFA: user.useMFA,
    signatureGrant: user.signatureGrant,
  };

  return userUpdate;
});

export const selectProfileValues = createSelector(
  currentUserSelector,
  selectUsers,
  selectLocale,
  (currentUser, users, locale) => {
    if (!currentUser) return undefined;
    const profile = users[currentUser.address];
    return { ...profile, language: locale };
  },
);

export const selectCurrentUserCapabilities = createSelector(
  currentUserSelector,
  selectCurrentOrganizationAddress,
  (user: CurrentUser, organizationAddress: Address) => user?.permissions[organizationAddress]?.capabilities || [],
);

export const selectMemberOrganizations = createSelector(
  selectOrganizations,
  selectCurrentUser,
  selectOrderedOrganizations,
  (organizations, currentUser, orderedOrganizations) => {
    const userOrganizations = currentUser.organizations
      .map((address) => organizations[address])
      .filter((organization) => !!organization && !organization.toBeArchived);
    return sortBy(userOrganizations, (organization) => {
      return orderedOrganizations.indexOf(organization.address);
    });
  },
);

export const selectCurrentUserOrganizations = createSelector(
  currentUserSelector,
  selectOrganizations,
  (user, organizations) => user?.organizations.map((o) => organizations[o]) || [],
);

export const selectCurrentUserMaySign = makeSelectCurrentUserHasPermission(CapabilityEnum.SIGNATURE_CREATE);

export const selectCurrentUserIsAdmin = createSelector(
  currentUserSelector,
  selectCurrentOrganizationAddress,
  (currentUser: CurrentUser, organizationAddress: Address) =>
    currentUser?.permissions[organizationAddress]?.roles.includes('ACCOUNT_ADMIN') || false,
);

/**
 * Current user properties
 */

export const selectCurrentUserSignature = makeSelectCurrentUserProperty('signatureGrant');
export const selectCurrentUserName = makeSelectCurrentUserProperty('name');
export const selectInvitedAgreement = makeSelectCurrentUserProperty('invitedAgreement');

export const selectUpdateRoleError = makeSelectUserAccountError(UPDATE_ROLE);
export const selectEditProfileRequest = makeSelectUserAccountRequest(UPDATE_PROFILE);
export const selectUpdateRoleRequest = makeSelectUserAccountRequest(UPDATE_ROLE);
export const selectUpdateSignatureRequest = makeSelectUserAccountRequest(UPDATE_SIGNATURE);
export const selectRemoveUserFromOrganizationRequest = makeSelectUserAccountRequest(REMOVE_USER_FROM_ORGANIZATION);

/**
 * Selector factories
 */

function makeSelectCurrentUserProperty<T extends keyof CurrentUser>(prop: T) {
  return createSelector(currentUserSelector, (currentUser) => (currentUser ? currentUser[prop] : undefined));
}

export function makeSelectCurrentUserHasPermission(capability: Capability) {
  return createSelector(currentUserSelector, selectCurrentOrganizationAddress, (currentUser, organizationAddress) =>
    currentUser?.permissions[organizationAddress]?.capabilities.includes(capability),
  );
}

function makeSelectUserAccountError(requestType) {
  return createSelector(errorsSelector, (errors) => errors[requestType]);
}

function makeSelectUserAccountRequest(requestType) {
  return createSelector(requestsSelector, (requests) => Boolean(requests[requestType]));
}
