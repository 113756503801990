import React from 'react';
import { useIntl } from 'react-intl';
import { messages } from './messages';

export const LoadFailure: React.FC = () => {
  const intl = useIntl();
  return (
    <div>
      {intl.formatMessage(messages.loadFailure)}
    </div>
  );
};
