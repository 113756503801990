import { InfoTooltip } from 'components/Tooltip';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

type Props = {
  label?: ReactNode;
  infoText?: string;
  addon?: ReactNode;
  optional?: boolean;
};

export const Label: React.FC<Props> = ({ label, infoText, addon, optional }) => {
  const intl = useIntl();

  if (!label && !infoText && !addon && !optional) return <></>;

  return (
    <div className="md:flex md:justify-between md:items-end text-info-main text-sm mb-2 font-medium">
      <div className="flex pr-2 md:pr-0">
        {label && <div>{label}</div>}
        {infoText && <InfoIcon infoText={infoText} />}
      </div>
      <div className="flex items-center justify-start">
        {addon}
        {optional && (
          <span className="text-gray-400 font-medium break-normal" style={{ fontSize: '13px' }}>
            {intl.formatMessage(messages.optional)}
          </span>
        )}
      </div>
    </div>
  );
};

const InfoIcon: React.FC<{ infoText: string }> = ({ infoText }) => (
  <div style={{ marginTop: -2, paddingLeft: 5 }}>
    <InfoTooltip content={infoText} />
  </div>
);
