import { AppBar, AppBarProps, withStyles } from '@material-ui/core';
import React from 'react';

const AppBarStyled = withStyles(() => ({
  root: {
    backgroundColor: 'inherit',
  },
}))(AppBar);

export const TabHeader: React.FC<AppBarProps> = (props) => {
  return <AppBarStyled position="static" {...props} />;
};
