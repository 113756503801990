import { DocumentType } from '@monax/aeger/dist/document';
import { DocumentMeta } from '@monax/types';
import { Document, RequestError } from 'types';

export type AgreementDocumentState = {
  documents: {
    [grant: string]: Document;
  };
  documentMeta: {
    // We store original metadata here separately; if a document is docx and undergoes assembly, this metadata will be for the ORIGINAL docx
    // So look here when we want to know the original meta (eg checking whether we want to loadAssembledDocument vs loadDocument)
    // Conversely, when we want the metadata for the FINAL document (eg we when we want to display the document preview), look under the `documents` key
    [grant: string]: DocumentMeta;
  };
  documentParams: {
    [grant: string]: string[];
  };
  documentErrors: {
    [grant: string]: null | RequestError;
  };
  uploading: boolean;
  previewing: number;
  documentsExpanded: boolean;
  assemblingDocument: boolean;
};

export const CAN_ASSEMBLE_MIMES = [DocumentType.DOCX, DocumentType.MD, DocumentType.HTML, DocumentType.SVG] as const;
export type CanAssembleMime = typeof CAN_ASSEMBLE_MIMES[number];
export const CAN_ASSEMBLE_EXTS = ['.docx', '.md', '.html', '.svg'];

export const AGREEMENT_DOCUMENT_MIMES = [
  DocumentType.DOCX,
  DocumentType.MD,
  DocumentType.PDF,
  DocumentType.PLAIN,
  DocumentType.JSON,
  DocumentType.HTML,
  DocumentType.SVG,
  DocumentType.PNG,
  DocumentType.JPEG,
  DocumentType.JPG,
];
export const AGREEMENT_DOCUMENT_EXTS = [
  '.docx',
  '.md',
  '.pdf',
  '.txt',
  '.json',
  '.html',
  '.svg',
  '.jpg',
  '.jpeg',
  '.png',
];
