import { combineReducers } from 'redux';
import listReducer from './List/state/reducer';
import recordReducer from './Record/state/reducer';
import { AssetState } from './types';

const reducer = combineReducers<AssetState>({
  list: listReducer,
  record: recordReducer,
});

export default reducer;
