import { CSSObject } from '@emotion/serialize';
import { Palette } from 'containers/Theme/types';
import { ControlProps, Styles } from 'react-select';

const FONT_SIZE = '14px';
const HEIGHT = '37.59px'; // this height matches material ui text field

const getControlStyles = (
  styles: CSSObject,
  { isFocused, isMulti, isDisabled }: ControlProps<{}, boolean>,
  palette: Palette,
) => ({
  // drop maxWidth, postition, top and transform to fix the auto width issue
  ...styles,
  fontSize: FONT_SIZE,
  borderWidth: 1,
  borderColor: isDisabled ? palette.gray[200] : isFocused ? palette.primary.main : palette.gray[500],
  boxShadow: 'none',
  ':hover': {
    borderColor: isDisabled ? palette.gray[200] : palette.primary.main,
  },
  minHeight: HEIGHT,
  height: isMulti ? 'auto' : HEIGHT,
  maxHeight: isMulti ? 'auto' : HEIGHT,
  backgroundColor: isDisabled ? palette.gray[200] : 'white',
  cursor: isDisabled ? 'not-allowed' : 'default',
});

const getPlaceholderStyles = ({ maxWidth, position, top, transform, ...otherStyles }: CSSObject, palette: Palette) => ({
  // drop maxWidth, postition, top and transform to fix the auto width issue
  ...otherStyles,
  whiteSpace: 'nowrap' as const,
  textOverflow: 'clip',
  overflow: 'hidden',
  color: palette.gray[700],
  fontSize: FONT_SIZE,
});

export const baseStyles = (palette: Palette): Partial<Styles<{}, boolean>> => ({
  control: (styles, props) => {
    return getControlStyles(styles, props, palette);
  },
  menu: (styles) => {
    return {
      ...styles,
      fontSize: FONT_SIZE,
      margin: 0,
    };
  },
  menuPortal: (styles) => {
    return {
      ...styles,
      zIndex: 2000,
    };
  },
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? palette.primary.main
        : isFocused
        ? palette.primary.extraLight
        : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : palette.gray[900],
      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? palette.primary.main : palette.primary.extraLight),
      },
    };
  },
  valueContainer: (styles, { isMulti, hasValue }) => {
    return {
      ...styles,
      whitespace: 'nowrap',
      fontSize: FONT_SIZE,
      height: isMulti && hasValue ? 'auto' : '100%',
      flexWrap: isMulti && hasValue ? 'wrap' : 'nowrap',
    };
  },
  singleValue: ({ maxWidth, position, top, transform, ...otherStyles }, { isDisabled }) => {
    return {
      ...otherStyles,
      color: isDisabled ? palette.info.main : palette.info.main,
    };
  },
  multiValue: (styles, { isDisabled }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? palette.gray[300] : palette.primary.main,
    };
  },
  multiValueLabel: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? palette.info.main : 'white',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: palette.primary.contrastText,
    ':hover': {
      backgroundColor: palette.primary.light,
    },
  }),
  placeholder: (styles) => {
    return getPlaceholderStyles(styles, palette);
  },
});

export const errorStyles = (palette: Palette): Partial<Styles<{}, boolean>> => ({
  control: (styles, props) => {
    return {
      ...getControlStyles(styles, props, palette),
      borderColor: palette.error.light,
      ':hover': {
        borderColor: palette.error.main,
      },
    };
  },
  placeholder: (styles) => {
    return {
      ...getPlaceholderStyles(styles, palette),
      color: palette.error.dark,
      opacity: 0.4,
    };
  },
});
