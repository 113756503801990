import { Drawer } from '@material-ui/core';
import { AutoOverflow } from 'components/Layout/AutoOverflow';
import { Layout } from 'containers/App/components/Layout';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { HeaderTablet } from './components';
import { AssetTable } from './List/AssetTable';
import { loadAssets } from './List/state/actions';
import { watchAssets } from './List/state/saga';

export const AssetLayoutTablet: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const [showAssetList, setShowAssetList] = useState<boolean>(false);

  useInjectSaga({
    key: 'load-assets',
    saga: watchAssets,
  });

  useEffect(() => {
    dispatch(loadAssets());
  }, []);

  return (
    <Layout>
      <div className="flex h-full w-full flex-col">
        <div className="pb-4">
          <HeaderTablet setShowAssetList={setShowAssetList} />
        </div>
        <AutoOverflow>{children}</AutoOverflow>
      </div>
      <Drawer anchor="right" open={showAssetList} onClose={() => setShowAssetList(false)}>
        <AutoOverflow className="p-4">
          <AssetTable onAssetClicked={() => setShowAssetList(false)} hideTableHead={true} />
        </AutoOverflow>
      </Drawer>
    </Layout>
  );
};
