import React, { ReactNode } from 'react';
import { Size } from './types';
import { prefixSize } from './util';

type Props = {
  hiddenFrom: Size; // hide children from a certain size and up
  className?: string;
  children: ReactNode;
};

export const HiddenFrom: React.FC<Props> = ({ hiddenFrom, className = '', children }) => (
  <div className={`${prefixSize(hiddenFrom, 'hidden')} ${className}`}>{children}</div>
);
