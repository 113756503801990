import React, { ReactNode } from 'react';
import { Size } from './types';
import { prefixSize } from './util';

type Props = {
  visibleFrom: Size; // only show children from a certain size and up
  className?: string;
  children: ReactNode;
};

export const VisibleFrom: React.FC<Props> = ({ visibleFrom, className = '', children }) => (
  <div className={`hidden ${prefixSize(visibleFrom, 'block')} ${className}`}>{children}</div>
);
