import { MessageDescriptor } from '@formatjs/intl';
import { APIClient } from '@monax/types';
import { useNotifications } from 'containers/App/hooks/useNotifications';
import { selectAPIClient } from 'containers/App/state/selectors';
import { useState } from 'react';
import { useSelector } from 'react-redux';

type State<T extends keyof APIClient> = {
  loading: boolean;
  makeRequest: (...parameters: Parameters<APIClient[T]>) => ReturnType<APIClient[T]>;
};

export const useAPIRequest = <T extends keyof APIClient>(
  requestType: T,
  errorMessage?: MessageDescriptor | null,
  errorMessageValues?: { [parameter: string]: string } | null,
  successMessage?: MessageDescriptor | null,
  successMessageValues?: { [parameter: string]: string } | null,
): State<T> => {
  const client = useSelector(selectAPIClient);
  const [loading, setLoading] = useState<boolean>(false);
  const { showSuccess, showError } = useNotifications();

  const makeRequest = (...parameters: Parameters<APIClient[T]>): ReturnType<APIClient[T]> => {
    setLoading(true);
    // actual `request` type(`APIClient[T]`) doesn't like parameters being passed in via spread
    const request: any = client[requestType];

    return request(...parameters)
      .then((response) => {
        if (successMessage) {
          showSuccess(successMessage, successMessageValues);
        }
        return response;
      })
      .catch((err) => {
        if (!errorMessage) {
          throw err;
        }
        showError(errorMessage, errorMessageValues);
        return null;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    makeRequest,
  };
};
