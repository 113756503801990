import { showSnackbarErrorFormattedMessage } from 'containers/App/state/saga';
import { selectAPIClient } from 'containers/App/state/selectors';
import messages from 'containers/Auth/messages';
import { call, select, takeLatest } from 'typed-redux-saga';
import { SendResetPasswordRequestEmail } from './actions';

export function* sendResetPasswordRequestEmail({
  usernameOrEmail,
  onSuccess,
  onAlways,
}: SendResetPasswordRequestEmail) {
  try {
    const client = yield* select(selectAPIClient);
    yield* call(client.resetPasswordRequest, { usernameOrEmail });
    onSuccess();
  } catch {
    yield showSnackbarErrorFormattedMessage(messages.errorSendingEmail);
  }
  onAlways();
}

export function* watchSendResetPasswordRequestEmail() {
  yield takeLatest<SendResetPasswordRequestEmail>(
    'app/Auth/RESET_PASSWORD_REQUEST/SEND_RESET_PASSWORD_REQUEST_EMAIL',
    sendResetPasswordRequestEmail,
  );
}
