import { combineReducers } from 'redux';
import documentReducer from './Documents/state/reducer';
import formReducer from './Form/state/reducer';
import listReducer from './List/reducer';
import postChainReducer from './PostChain/reducer';
import preChainReducer from './PreChain/reducer';
import { AgreementState } from './types';

const reducer = combineReducers<AgreementState>({
  preChain: preChainReducer,
  postChain: postChainReducer,
  document: documentReducer,
  form: formReducer,
  list: listReducer,
});

export default reducer;
