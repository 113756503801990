import produce from 'immer';
import { ZapierState } from '../types';
import { ZapierAction } from './actions';

export const initialState: ZapierState = {
  accessToken: null,
  zaps: [],
  loadingZaps: false,
  error: null,
};

const reducer = (state: ZapierState = initialState, action: ZapierAction) =>
  produce(
    state,
    (draftState): ZapierState => {
      switch (action.type) {
        case 'app/Integrations/Zapier/RECEIVE_ACCESS_TOKEN':
          draftState.accessToken = action.token;
          return draftState;
        case 'app/Integrations/Zapier/CLEAR_ACCESS_TOKEN':
          draftState.accessToken = null;
          draftState.zaps = [];
          return draftState;
        case 'app/Integrations/Zapier/LOAD_ZAPS':
          draftState.error = null;
          draftState.loadingZaps = true;
          return draftState;
        case 'app/Integrations/Zapier/LOAD_ZAPS_SUCCESS':
          draftState.zaps = action.zaps;
          draftState.loadingZaps = false;
          return draftState;
        case 'app/Integrations/Zapier/LOAD_ZAPS_ERROR':
          draftState.loadingZaps = false;
          draftState.error = action.error;
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;
