import { MessageDescriptor } from '@formatjs/intl';
import createColor from 'color';
import randomMC from 'random-material-color';
import { messages } from './messages';
import { Logos, PaletteKey, Shade } from './types';

export const lightTargetLuminosity = 60;
export const extraLightTargetLuminosity = 90;
export const darkTargetLuminosity = 10;

export function getShades(hex: string, contrastText: string): Record<Shade, string> {
  return {
    main: hex,
    // TODO- may need to tweak these shades (uncomment code in render of ThemeWrapper to check colors)
    light: createColor(hex).lightness(lightTargetLuminosity).hex(),
    extraLight: createColor(hex).lightness(extraLightTargetLuminosity).hex(),
    dark: createColor(hex).lightness(darkTargetLuminosity).hex(),
    contrastText,
  };
}

export function toRgb(hex: string): [number, number, number] {
  const [r, g, b] = createColor(hex).rgb().array();
  return [r, g, b];
}

export const randomColor = (text, shades = ['300', '400']) => randomMC.getColor({ shades, text });

export function getPaletteKeyLabel(
  paletteKey: PaletteKey & ('primary' | 'secondary') /* only accepting the two for now */,
): MessageDescriptor {
  switch (paletteKey) {
    case 'primary':
      return messages.palettePrimaryLabel;
    case 'secondary':
      return messages.paletteSecondaryLabel;
  }
}

export function getPaletteShadeLabel(
  shade: Shade & ('main' | 'contrastText') /* only accepting the two for now */,
): MessageDescriptor {
  switch (shade) {
    case 'main':
      return messages.paletteShadeMainlabel;
    case 'contrastText':
      return messages.paletteShadeContrastTextlabel;
  }
}

export function getLogoSizeLabel(size: keyof Logos, isContrast: boolean): MessageDescriptor {
  switch (size) {
    case 'full':
      return isContrast ? messages.logoSizeFullContrast : messages.logoSizeFull;
    case 'favicon':
      return isContrast ? messages.logoSizeFaviconContrast : messages.logoSizeFavicon;
  }
}

export function getLogoSizeDescription(size: keyof Logos): MessageDescriptor {
  switch (size) {
    case 'full':
      return messages.logoSizeFullDescription;
    case 'favicon':
      return messages.logoSizeFaviconDescription;
  }
}
