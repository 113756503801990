import { useListOverflow } from 'lib/hooks';
import React from 'react';
import { Avatar, AvatarProps } from './Avatar';

type Props = {
  avatars: AvatarProps[];
  maxCount?: number;
};

export const AvatarList: React.FC<Props> = ({ avatars, maxCount = 2 }) => {
  if (avatars.length === 0) return <></>;

  const { result, overflow } = useListOverflow(avatars, maxCount, true);
  return (
    <div
      className={`flex items-center border border-gray-700 border-gray-100 bg-gray-100 rounded text-sm px-2`}
      style={{
        minHeight: '37px',
        padding: '0 14px',
      }}
    >
      {result.map((user, i) => (
        <span key={i.toString()} className="mr-2 last:mr-0">
          <Avatar {...user} />
        </span>
      ))}
      {overflow.length > 0 && (
        <Avatar
          name={overflow.map(({ name }) => name).join(', ')}
          bgColor="white"
          borderColor="gray"
          borderShade="light"
          textColor="gray"
          textShade="light"
        >
          +{overflow.length}
        </Avatar>
      )}
    </div>
  );
};
