import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';

type Props = {
  content: React.ReactNode;
  noContentPadding?: boolean;
  graphic: React.ReactNode;
};

export const SmallCardWithGraphic: React.FC<Props> = (props) => {
  const { palette } = useTheme();
  return (
    <div className="flex w-full mb-10 bg-white rounded-2xl" style={{ minHeight: '100px' }}>
      <div
        className="flex items-end justify-center"
        style={{
          width: '100px',
          background: `linear-gradient(134.85deg, ${palette.primary.extraLight} 1.75%, ${palette.primary.main} 100%)`,
          borderRadius: '16px 0px 0px 16px',
        }}
      >
        <div>{props.graphic}</div>
      </div>
      <div
        className="bg-white flex items-center p-4"
        style={{
          borderRadius: '0px 16px 16px 0px',
          width: 'calc(100% - 100px)',
        }}
      >
        <span>{props.content}</span>
      </div>
    </div>
  );
};
