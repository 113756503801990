import { withStyles } from '@material-ui/core';
import { Tooltip } from 'components/Tooltip/Tooltip';

export const WarningTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.warning.main,
  },
  tooltip: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    fontSize: 11,
    maxWidth: 220,
    whiteSpace: 'pre-wrap',
  },
}))(Tooltip);
