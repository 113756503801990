/*
 *
 * Users constants
 *
 */

// Redux action types- API requests
/**
 * For the sake of consistency:
 * Actions should be named as the camelCase version of the type
 * Watcher sagas should be named the same with "watch" + the action name
 * Worker sagas should be named the same as the action
 * Worker saga keys (at injection) should be the kabob-case version of the type
 *
 * Example:
 * - constants: `ADD_AGREEMENT_ATTACHMENT` and `ADD_AGREEMENT_ATTACHMENT_SUCCESS`
 * - actions: `addAgreementAttachment` and `addAgreementAttachmentSuccess`
 * - watcher saga: `function* watchAddAgreementAttachment(){}`
 * - worker saga: `function* addAgreementAttachment(){}`
 * - worker saga key: `add-agreement-attachment`
 *
 * Register new request types with the `REQUEST_TYPES` object below
 */
export type RequestType =
  | CHANGE_PASSWORD
  | UPDATE_EMAIL
  | UPDATE_PROFILE
  | UPDATE_ROLE
  | UPDATE_SIGNATURE
  | REMOVE_USER_FROM_ORGANIZATION;

export const LOAD_CURRENT_USER = 'app/UserAccount/LOAD_CURRENT_USER';
export type LOAD_CURRENT_USER = 'app/UserAccount/LOAD_CURRENT_USER';
export const LOAD_CURRENT_USER_SUCCESS = 'app/UserAccount/LOAD_CURRENT_USER_SUCCESS';
export type LOAD_CURRENT_USER_SUCCESS = 'app/UserAccount/LOAD_CURRENT_USER_SUCCESS';
export const CLEAR_CURRENT_USER = 'app/UserAccount/CLEAR_CURRENT_USER';
export type CLEAR_CURRENT_USER = 'app/UserAccount/CLEAR_CURRENT_USER';

export const CHANGE_PASSWORD = 'app/UserAccount/CHANGE_PASSWORD';
export type CHANGE_PASSWORD = 'app/UserAccount/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'app/UserAccount/CHANGE_PASSWORD_SUCCESS';
export type CHANGE_PASSWORD_SUCCESS = 'app/UserAccount/CHANGE_PASSWORD_SUCCESS';

export const UPDATE_PROFILE = 'app/UserAccount/UPDATE_PROFILE';
export type UPDATE_PROFILE = 'app/UserAccount/UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'app/UserAccount/UPDATE_PROFILE_SUCCESS';
export type UPDATE_PROFILE_SUCCESS = 'app/UserAccount/UPDATE_PROFILE_SUCCESS';

export const UPDATE_EMAIL = 'app/UserAccount/UPDATE_EMAIL';
export type UPDATE_EMAIL = 'app/UserAccount/UPDATE_EMAIL';
export const UPDATE_EMAIL_SUCCESS = 'app/UserAccount/UPDATE_EMAIL_SUCCESS';
export type UPDATE_EMAIL_SUCCESS = 'app/UserAccount/UPDATE_EMAIL_SUCCESS';

export const UPDATE_ROLE = 'app/UserAccount/UPDATE_ROLE';
export type UPDATE_ROLE = 'app/UserAccount/UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'app/UserAccount/UPDATE_ROLE_SUCCESS';
export type UPDATE_ROLE_SUCCESS = 'app/UserAccount/UPDATE_ROLE_SUCCESS';

export const UPDATE_SIGNATURE = 'app/UserAccount/UPDATE_SIGNATURE';
export type UPDATE_SIGNATURE = 'app/UserAccount/UPDATE_SIGNATURE';
export const UPDATE_SIGNATURE_SUCCESS = 'app/UserAccount/UPDATE_SIGNATURE_SUCCESS';
export type UPDATE_SIGNATURE_SUCCESS = 'app/UserAccount/UPDATE_SIGNATURE_SUCCESS';

export const REMOVE_USER_FROM_ORGANIZATION = 'app/UserAccount/REMOVE_USER_FROM_ORGANIZATION';
export type REMOVE_USER_FROM_ORGANIZATION = 'app/UserAccount/REMOVE_USER_FROM_ORGANIZATION';
export const REMOVE_USER_FROM_ORGANIZATION_SUCCESS = 'app/UserAccount/REMOVE_USER_FROM_ORGANIZATION_SUCCESS';
export type REMOVE_USER_FROM_ORGANIZATION_SUCCESS = 'app/UserAccount/REMOVE_USER_FROM_ORGANIZATION_SUCCESS';

export const REQUEST_TYPES: Record<RequestType, boolean> = {
  [CHANGE_PASSWORD]: true,
  [UPDATE_EMAIL]: true,
  [UPDATE_PROFILE]: true,
  [UPDATE_ROLE]: true,
  [UPDATE_SIGNATURE]: true,
  [REMOVE_USER_FROM_ORGANIZATION]: true,
};

// Redux action types- Error
export const RECEIVE_REQUEST_ERROR = 'app/UserAccount/RECEIVE_REQUEST_ERROR';
export type RECEIVE_REQUEST_ERROR = 'app/UserAccount/RECEIVE_REQUEST_ERROR';
