import React, { ReactNode } from 'react';

export type ButtonOption = {
  key: string | boolean;
  label: ReactNode;
  disabled?: boolean;
};

type Props = {
  color?: 'primary' | 'secondary';
  options: ButtonOption[];
  noneSelectedOption?: ButtonOption;
  selected?: string | boolean;
  onChange?: (key: string | boolean) => void;
  disabled?: boolean;
};

export const ButtonGroup: React.FC<Props> = ({
  color = 'primary',
  options,
  selected,
  onChange,
  disabled,
  noneSelectedOption,
}) => {
  const onClick = (key: string | boolean) => {
    if (onChange) onChange(key);
  };
  return (
    <div className="flex" role="group">
      {options.map((option) => (
        <Button
          color={color}
          key={option.key.toString()}
          selected={option.key === selected}
          onClick={() => onClick(option.key)}
          disabled={disabled || option.disabled}
          noneOption={false}
          noneSelected={selected === noneSelectedOption?.key}
        >
          {option.label}
        </Button>
      ))}
      {noneSelectedOption && (
        <Button
          color={color}
          key={noneSelectedOption.key.toString()}
          selected={noneSelectedOption.key === selected}
          onClick={() => onClick(noneSelectedOption.key)}
          disabled={disabled || noneSelectedOption.disabled}
          noneOption
        >
          {noneSelectedOption.label}
        </Button>
      )}
    </div>
  );
};

type ButtonProps = {
  key: string;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
  noneOption: boolean;
  noneSelected?: boolean;
  color: 'primary' | 'secondary';
};

const Button: React.FC<ButtonProps> = ({ color, selected, noneOption, noneSelected, onClick, disabled, children }) => {
  let classes =
    'whitespace-nowrap text-xs py-2 px-2 font-semibold outline-none border border-r-0 border-gray-200 mx-0 first:rounded-l last:rounded-r last:border-r sm:text-sm sm:py-2 sm:px-4';

  if (noneOption && selected) {
    classes = `${classes} bg-gray-200 text-secondary-main`;
  } else if (noneOption && !selected) {
    classes = `${classes} bg-white text-gray-700`;
  } else if (noneSelected) {
    classes = `${classes} bg-white text-gray-700`;
  } else if (disabled && selected) {
    classes = `${classes} bg-gray-300 text-secondary-dark`;
  } else if (selected) {
    classes = `${classes} ${
      color === 'primary'
        ? 'bg-primary-main text-primary-contrastText'
        : 'bg-secondary-main text-secondary-contrastText'
    }`;
  } else {
    classes = `${classes} bg-white`;
  }

  return (
    <button className={classes} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};
