import { Invitation } from '@monax/types';
import { createSelector, OutputSelector } from 'reselect';
import { ApplicationState } from 'types';
import { UserInvitationsState } from '../types';

const rootSelector = (state: ApplicationState) => state.users.current.userInvitations;

export const selectUserInvitations: OutputSelector<
  ApplicationState,
  Invitation[],
  (state: UserInvitationsState) => Invitation[]
> = createSelector(rootSelector, (state) => state.invitations);

export const selectActionInProgress: OutputSelector<
  ApplicationState,
  boolean,
  (state: UserInvitationsState) => boolean
> = createSelector(rootSelector, (state) => state.requesting);
