import React from 'react';
import { autoOverflowClassName } from './utils';

type Props = {
  className?: string;
  flex?: boolean;
};

export const AutoOverflow: React.FC<Props> = ({ children, className, flex }) => {
  return (
    <div className={`${autoOverflowClassName} ${className ? className : ''} ${flex ? 'flex w-full flex-col' : ''}`}>
      {children}
    </div>
  );
};
