import { Asset, ForeignChainAssetType, ForeignChainId } from '@monax/types';
import { addBreadcrumb, Severity } from '@sentry/react';
import { Dialog } from 'components/Dialog';
import { FormItem, SelectControl } from 'components/Form';
import { ContentLoader } from 'components/Loader';
import config from 'configs';
import { AddForeignAddressDialog } from 'containers/User/Current/ForeignAddresses/components';
import { useForeignAddressOptions } from 'containers/User/Current/ForeignAddresses/hooks';
import { getNfts } from 'lib/foreign-chain/foreign-chain-data';
import { sum } from 'lodash';
import React, { useCallback, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateAsset } from '../Form/hooks/useCreateAsset';
import { loadAssets } from '../List/state/actions';
import { selectAssetList } from '../List/state/selectors';
import { messages } from '../messages';
import { useTokenUri } from '../Record/hooks/useTokenUri';

type Props = {
  isOpen: boolean;
  closeDialog: () => void;
};

type ImportState = 'ready' | 'importing' | 'done';
type SuccessCount = number;

export const CreateAssetsFromForeignChainDialog: React.FC<Props> = ({ isOpen, closeDialog }) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const { create } = useCreateAsset();

  const { getMetadata } = useTokenUri();

  const assets = useSelector(selectAssetList);

  const supportedChainIds = config.foreignChain?.chainIds ?? [];

  const [foreignChainAddress, setForeignChainAddress] = useState<string>('');
  const [importedAssetCount, setImportedAssetCount] = useState<number>(0);

  const [state, setState] = useState<ImportState>('ready');

  const load = useCallback(async () => {
    setState('importing');

    const imports = supportedChainIds.map((foreignChainId) => importForForeignChainId(foreignChainId));

    const successCounts = await Promise.all(imports);

    setImportedAssetCount(sum(successCounts));

    setState('done');

    dispatch(loadAssets());
  }, [foreignChainAddress]);

  const importForForeignChainId = useCallback(
    async (foreignChainId: ForeignChainId): Promise<SuccessCount> => {
      const nfts = await getNfts(foreignChainAddress, foreignChainId);

      const successCounts = await Promise.all(
        nfts.map((nft) =>
          createAsset(foreignChainId, nft.contract_type, nft.token_address, nft.token_id, nft.token_uri),
        ),
      );
      return sum(successCounts);
    },
    [foreignChainAddress],
  );

  const close = () => {
    setState('ready');
    setImportedAssetCount(0);
    closeDialog();
  };

  const createAsset = useCallback(
    async (
      foreignChainId: ForeignChainId,
      contractType: string,
      contractAddress: string,
      tokenId: string,
      tokenUri: string,
    ): Promise<SuccessCount> => {
      if (assetExists(assets, foreignChainId, contractAddress, tokenId)) return 0;

      const metadata = await getMetadata(tokenUri);

      if (!metadata) return 0;

      const foreignChainAssetType = contractTypeToForeignChainAssetType(contractType);

      if (!foreignChainAssetType) return 0;

      const result = await create({
        name: metadata.name,
        description: metadata.description,
        departmentIds: [],
        config: {
          assetCurrentlyExists: true,
          assetTokenContractAddress: contractAddress,
          assetTokenId: tokenId,
          supportsMultipleAgreements: false,
          beaconOperatorIds: [],
          foreignAddress: foreignChainAddress,
          lockTemplate: false,
          templateId: undefined,
          foreignChainAssetType: 'non_fungible_token',
          foreignChainId: foreignChainId,
        },
      });

      return result.success ? 1 : 0;
    },
    [foreignChainAddress],
  );

  const onSubmit = () => {
    if (state === 'ready') {
      load();
      return;
    }

    if (state === 'done') {
      close();
      return;
    }
  };

  return (
    <Dialog
      className="info"
      fullWidth
      maxWidth="md"
      open={isOpen}
      cancel={state === 'done' ? undefined : close}
      content={
        <DialogContent
          state={state}
          foreignChainAddress={foreignChainAddress}
          setForeignChainAddress={setForeignChainAddress}
          importedAssetCount={importedAssetCount}
        />
      }
      title={intl.formatMessage(messages.importFromBlockchain)}
      disabled={state === 'importing'}
      submitButtonContent={getSubmitButtonText(intl, state)}
      submit={onSubmit}
      disabledSubmit={state === 'importing' || (state === 'ready' && !foreignChainAddress)}
    />
  );
};

type DialogContentProps = {
  state: ImportState;
  foreignChainAddress: string;
  setForeignChainAddress: (address: string) => void;
  importedAssetCount: number;
};

const DialogContent: React.FC<DialogContentProps> = ({
  state,
  foreignChainAddress,
  setForeignChainAddress,
  importedAssetCount,
}) => {
  const intl = useIntl();

  const [addWalletIsOpen, setAddWalletIsOpen] = useState<boolean>(false);

  const { loading, options: foreignAddressOptions } = useForeignAddressOptions();

  if (state === 'ready') {
    return (
      <>
        <FormItem
          label={intl.formatMessage(messages.assetImportSelectWallet)}
          labelAddon={
            <span className="text-gray-600 cursor-pointer" onClick={() => setAddWalletIsOpen(true)}>
              {intl.formatMessage(messages.assetImportAddWallet)}
            </span>
          }
        >
          <SelectControl
            placeholder={intl.formatMessage(messages.assetImportSelectWalletPlaceholder)}
            options={foreignAddressOptions}
            disabled={loading}
            isLoading={loading}
            value={foreignChainAddress}
            onChange={setForeignChainAddress}
          />
        </FormItem>
        <AddForeignAddressDialog
          isOpen={addWalletIsOpen}
          onCancel={() => setAddWalletIsOpen(false)}
          onForeignAddressAdded={() => setAddWalletIsOpen(false)}
        />
      </>
    );
  }

  if (state === 'importing') return <ContentLoader />;

  if (state === 'done')
    return <div>{intl.formatMessage(messages.assetImportComplete, { count: importedAssetCount })}</div>;

  console.error(`unhandled state ${state}`);
  return <div>Unhandled State</div>;
};

const getSubmitButtonText = (intl: IntlShape, state: ImportState): string => {
  switch (state) {
    case 'ready':
    case 'importing':
      return intl.formatMessage(messages.startImport);
    case 'done':
      return intl.formatMessage(messages.close);
    default: {
      console.error(`unhandled state ${state}`);
      return 'Unhandled State';
    }
  }
};

const contractTypeToForeignChainAssetType = (contractType: string): ForeignChainAssetType | undefined => {
  if (contractType === 'ERC721') return 'non_fungible_token';

  addBreadcrumb({
    level: Severity.Warning,
    category: 'foreign-chain-data',
    message: `unhandled foreign contract type ${contractType}`,
    data: {
      contractType,
    },
  });
  console.error(`unhandled foreign chain asset type ${contractType}`);
  return undefined;
};

const assetExists = (
  assets: Asset[],
  foreignChainId: ForeignChainId,
  contractAddress: string,
  tokenId: string,
): boolean => {
  return !!assets.some(
    ({ body: { config } }) =>
      config.foreignChainId === foreignChainId &&
      config.foreignChainAssetType === 'non_fungible_token' &&
      config.assetTokenContractAddress?.toLowerCase() === contractAddress.toLowerCase() &&
      config.assetTokenId?.toLowerCase() === tokenId.toLowerCase(),
  );
};
