import { LazyLoader } from 'components/LazyLoader';
import { Layout } from 'containers/App/components/Layout';
import { Route } from 'containers/App/components/Route';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Switch } from 'react-router-dom';

const Settings = LazyLoader(() => import('./Settings'));

const SettingsRoutes: React.FC = () => (
  <>
    <Helmet>
      <title>Settings</title>
    </Helmet>
    <Switch>
      <Route path="/settings/:tab" component={Settings} layout={Layout} />
      <Redirect to="/settings/profile" />
    </Switch>
  </>
);

export { SettingsRoutes };
