import produce from 'immer';
import { findIndex } from 'lodash';
import { PolicyState } from '../types';
import { EventAction } from './actions';

export const initialState: PolicyState = {
  eventPolicies: [],
  loadingEventPolicies: false,
  savingEventPolicy: false,
  savingEventPolicies: false,
  syncingZapierEventPolicies: false,
  error: null,
  eventPolicyServices: {},
};

const reducer = (state: PolicyState = initialState, action: EventAction) =>
  produce(
    state,
    (draftState): PolicyState => {
      switch (action.type) {
        case 'app/Event/Policy/LOAD_EVENT_POLICIES':
          draftState.loadingEventPolicies = true;
          draftState.error = null;
          return draftState;
        case 'app/Event/Policy/LOAD_EVENT_POLICIES_SUCCESS':
          draftState.eventPolicies = action.eventPolicies;
          action.eventPolicies.forEach((policy) => {
            draftState.eventPolicyServices[policy.id] = policy.service;
          });
          draftState.loadingEventPolicies = false;
          return draftState;
        case 'app/Event/Policy/SAVE_EVENT_POLICY':
        case 'app/Event/Policy/SAVE_EVENT_POLICY_TEMPLATE':
          draftState.savingEventPolicy = true;
          draftState.error = null;
          return draftState;
        case 'app/Event/Policy/SAVE_EVENT_POLICY_SUCCESS':
          draftState.savingEventPolicy = false;
          const index = findIndex(draftState.eventPolicies, (policies) => policies.id === action.eventPolicy.id);
          if (index >= 0) {
            draftState.eventPolicies[index] = action.eventPolicy;
          } else {
            draftState.eventPolicies.push(action.eventPolicy);
            draftState.eventPolicyServices[action.eventPolicy.id] = action.eventPolicy.service;
          }
          return draftState;
        case 'app/Event/Policy/SAVE_EVENT_POLICY_TEMPLATE_SUCCESS':
          draftState.savingEventPolicy = false;
          return draftState;
        case 'app/Event/Policy/DELETE_EVENT_POLICY_SUCCESS':
          draftState.eventPolicies = draftState.eventPolicies.filter((p) => p.id !== action.id);
          return draftState;
        case 'app/Event/Policy/SAVE_EVENT_POLICIES':
          draftState.savingEventPolicies = true;
          draftState.error = null;
          return draftState;
        case 'app/Event/Policy/SAVE_EVENT_POLICIES_SUCCESS':
          draftState.savingEventPolicies = false;
          return draftState;
        case 'app/Event/Policy/RECEIVE_EVENT_POLICY_SERVICES':
          action.data.forEach(({ id, service }) => {
            draftState.eventPolicyServices[id] = service;
          });
          return draftState;
        case 'app/Event/Policy/SYNC_ZAPIER_EVENT_POLICIES':
          draftState.syncingZapierEventPolicies = true;
          draftState.error = null;
          return draftState;
        case 'app/Event/Policy/SYNC_ZAPIER_EVENT_POLICIES_SUCCESS':
          draftState.syncingZapierEventPolicies = false;
          return draftState;
        case 'app/Event/Policy/RECEIVE_EVENT_POLICIES_ERROR':
          draftState.loadingEventPolicies = false;
          draftState.savingEventPolicy = false;
          draftState.syncingZapierEventPolicies = false;
          draftState.error = action.error;
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;
