import produce from 'immer';
import { OrganizationState } from '../types';
import { OrganizationAction } from './actions';

export const initialState: OrganizationState = {
  orderedOrganizations: [],
};

const reducer = (state: OrganizationState = initialState, action: OrganizationAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Preferences/ORGANIZATION/CHANGE_ORGANIZATION_ORDER':
        draftState.orderedOrganizations = action.organizations;
        return draftState;
    }
    return draftState;
  });

export default reducer;
