import { defineMessages } from 'react-intl';

const scope = 'app.containers.User.Current.Profile';

export const messages = defineMessages({
  profileSaved: {
    id: `${scope}.profileSaved`,
    description: 'snackbar message',
    defaultMessage: 'Profile saved!',
  },
  passwordUpdated: {
    id: `${scope}.passwordUpdated`,
    description: 'snackbar message',
    defaultMessage: 'Password Updated',
  },
  errorChangingPassword: {
    id: `${scope}.errorChangingPassword`,
    description: 'snackbar message',
    defaultMessage: 'Error Changing Password',
  },
  signatureUpdateSuccess: {
    id: `${scope}.signatureUpdateSuccess`,
    defaultMessage: 'Signature saved to profile',
  },
  failedToLoadUserProfile: {
    id: `${scope}.failedToLoadUserProfile`,
    defaultMessage: 'Failed to load user profile',
  },
  adminNoOptOutOfOrganization: {
    id: `${scope}.adminNoOptOutOfOrganization`,
    defaultMessage: 'Account Admins are not allowed to remove themselves from an organization',
  },
});
