import Avatar from '@material-ui/core/Avatar';
import { Identicon } from 'components/Identicon';
import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';

type Props = {
  organizationAddress: string;
  style?: React.CSSProperties;
};

export const OrganizationAvatar: React.FC<Props> = ({ organizationAddress, style = {} }) => {
  const { palette } = useTheme();

  const styles: React.CSSProperties = {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: palette.gray[300],
    ...style,
  };

  return (
    <Avatar key={organizationAddress} style={styles}>
      <Identicon seed={organizationAddress} size={28} />
    </Avatar>
  );
};
