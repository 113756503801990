/*
 * App Actions
 */

import { MessageDescriptor } from 'react-intl';
import {
  ADD_FORMATTED_NOTIFICATION,
  // Redux action types- UI
  ADD_NOTIFICATION,
  TOGGLE_UI_COMPONENT,
  UIComponent,
  // Other
  UI_COMPONENTS,
} from '../constants';
import { Snackbar } from '../types';

export type AppAction = AddNotification | AddFormattedNotification | ToggleUIComponent;

export type AddNotification = ReturnType<typeof addNotification>;
export function addNotification({
  action = null,
  open = true,
  className = 'success',
  message,
  autoHideDuration = 3000,
}: Snackbar) {
  return <const>{
    type: ADD_NOTIFICATION,
    snackbar: {
      action,
      open,
      className,
      message,
      autoHideDuration,
    },
  };
}

export function clearNotification() {
  return <const>{
    type: ADD_NOTIFICATION,
    snackbar: {
      open: false,
    },
  };
}

export type AddFormattedNotification = ReturnType<typeof addFormattedNotification>;
export function addFormattedNotification({
  action = null,
  open = true,
  className = 'success',
  messageDescriptor,
  messageValues = {},
  autoHideDuration = 3000,
}: Snackbar & { messageDescriptor: MessageDescriptor }) {
  return <const>{
    type: ADD_FORMATTED_NOTIFICATION,
    snackbar: {
      action,
      open,
      className,
      messageDescriptor,
      messageValues,
      autoHideDuration,
    },
  };
}

export type ToggleUIComponent = ReturnType<typeof toggleUIComponent>;
export function toggleUIComponent(component: UIComponent, value) {
  if (!UI_COMPONENTS[component]) throw new Error(`UI component ${component} not recognized`);
  return <const>{
    type: TOGGLE_UI_COMPONENT,
    component,
    value,
  };
}
