import configs from 'configs';
import WalletLink, { WalletLinkProvider } from 'walletlink';
import { CommonWallet } from './CommonWallet';
import { Wallet } from './types';
import { attachListeners } from './util';

// https://github.com/walletlink/walletlink
export class WalletLinkWallet extends CommonWallet implements Wallet {
  public type = 'wallet-link' as const;

  private provider: WalletLinkProvider;
  private walletLink: WalletLink;

  async connect() {
    try {
      // walletlink attempts to persist this session info and gets into some really state on reload
      // looks like this was the problem: https://github.com/walletlink/walletlink/pull/150
      // walletLink.disconnect() more effectively clears this state since it also disconnects the wallet but that forces a hard refresh of the browser which is very unpleasant...
      // TODO- upgrade walletlink version and remove this local storage cleanup after ^^ PR goes in
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:Addresses');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:session:id');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:session:secret');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:session:linked');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:version');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:walletUsername');
      const WalletLink = (await import(/* webpackChunkName: "walletlink" */ 'walletlink')).default;
      this.walletLink = new WalletLink({
        appName: configs.siteData.title,
        appLogoUrl: configs.siteData.twitterImage,
        darkMode: false,
      });
      this.provider = this.walletLink.makeWeb3Provider(
        // This seems to just get overriden, not sure we really need to care about what we're passing it here...
        '/',
      );
      const Web3 = (await import(/* webpackChunkName: "web3" */ 'web3')).default;
      await this.provider.enable();
      this.web3 = new Web3(this.provider);

      this.walletEvents.accountSet(await this.getAccount());
      this.walletEvents.chainSet(await this.getChain());

      attachListeners(this.provider, this.walletEvents);
    } catch (err) {
      this.walletEvents.errorSet({ type: 'connection', error: err });
    }
  }

  async disconnect() {
    if (this.provider?.isConnected()) {
      this.provider.disconnect();
    }
  }
}
