import screens from 'containers/Theme/screens';
import { Breakpoint } from 'containers/Theme/screens.types';
import { ViewportContext } from 'lib/contexts/ViewportContext';
import { findIndex } from 'lodash';
import { useCallback, useContext } from 'react';
import { parsePixelString } from 'utils/style';

type IsAtBreakpointFunction = (breakpoint: Breakpoint) => boolean;

const breakpoints: Breakpoint[] = ['0', 'sm', 'md', 'lg', 'xl'];

type BreakpointReturn = {
  isAtLeast: IsAtBreakpointFunction;
  breakpoint: Breakpoint;
};
const { widths } = screens;

export const useBreakpoint = (): BreakpointReturn => {
  const { width } = useContext(ViewportContext);
  const breakpoint = getBreakpoint(width);
  const isAtLeast: IsAtBreakpointFunction = useCallback(
    (b: Breakpoint): boolean => {
      const current = findIndex(breakpoints, (bp) => bp === breakpoint);
      const index = findIndex(breakpoints, (bp) => bp === b);
      return current >= index;
    },
    [breakpoint],
  );

  return { isAtLeast, breakpoint };
};

function getBreakpoint(width: number) {
  if (width >= parsePixelString(widths.xl)) {
    return 'xl';
  }
  if (width >= parsePixelString(widths.lg)) {
    return 'lg';
  }
  if (width >= parsePixelString(widths.md)) {
    return 'md';
  }
  if (width >= parsePixelString(widths.sm)) {
    return 'sm';
  }
  return '0';
}
