import { parse as parseAeger } from '@monax/aeger/dist/schema';
import { AuthErrorCode, RegisterQueryParameters } from '@monax/types';
import messages from 'containers/Auth/messages';
import { IntlShape } from 'react-intl';
import { parseQueryString } from 'utils/queryString';

// list of error codes: https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
export const getAuth0ErrorMessage = (
  intl: IntlShape,
  errorCode: AuthErrorCode,
  errorDescripton: string | object,
): string => {
  switch ((errorCode ?? '').replace(/ /g, '_')) {
    case 'access_denied':
    case 'invalid_user_password':
      return intl.formatMessage(messages.auth0ErrorCode_access_denied);
    case 'user_exists':
      return intl.formatMessage(messages.auth0ErrorCode_user_exists);
    case 'username_exists':
      return intl.formatMessage(messages.auth0ErrorCode_username_exists);
    case 'invalid_password':
    case 'PasswordStrengthError':
    case 'password_strength_error':
      return intl.formatMessage(messages.auth0ErrorCode_password_strength_error);
    case 'PasswordHistoryError':
      return intl.formatMessage(messages.auth0ErrorCode_password_history_error);
    case 'password_dictionary_error':
      return intl.formatMessage(messages.auth0ErrorCode_password_dictionary_error);
    case 'too_many_attempts':
      return intl.formatMessage(messages.auth0ErrorCode_too_many_attempts);
    case 'user_is_blocked':
      return intl.formatMessage(messages.auth0ErrorCode_user_is_blocked);
    case 'reset_password_request_used': // This rule code is 'custom' and is defined in our api
      return intl.formatMessage(messages.auth0ErrorCode_reset_password_request_used);
    case 'reset_password_request_expired': // This rule code is 'custom' and is defined in our api
      return intl.formatMessage(messages.reset_password_request_expired);
    case 'verify_email_before_login': // This rule code is 'custom' and is defined within the 'Force email verification' rule in Auth0
      return intl.formatMessage(messages.auth0ErrorCode_verify_email_before_login);
    case 'change_password_old_password_invalid': // This rule code is 'custom' and is defined in our api
      return intl.formatMessage(messages.change_password_old_password_invalid);
    default:
      // If we dont have a translation, then just default to the description
      return typeof errorDescripton === 'string'
        ? errorDescripton
        : intl.formatMessage(messages.auth0ErrorCode_unknown);
  }
};

export const getState = (): string => {
  const query = parseQueryString(window.location.search);
  const state = parseAeger(RegisterQueryParameters, {
    redirectURL: query.redirectURL || '',
    invitationCode: query.invitationCode || '',
    counterparty: query.counterparty || '',
    email: query.email || '',
    agreement: query.agreement || '',
    byAgreement: query.byAgreement || 'false',
    organization: query.organization || '',
    locale: query.locale || '',
  });
  return JSON.stringify(state);
};
