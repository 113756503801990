import { formatCuratorURL } from '@monax/aeger/dist/curator';
import { parse } from '@monax/aeger/dist/schema';
import { ForeignChainId, UserLocale } from '@monax/types';
import { SupportedLocale } from 'containers/Preferences/Locale/types';
import { merge } from 'lodash';
import storage from 'redux-persist/lib/storage';

/* global process */
export const DEFAULT_LOCALE = 'en-US';

export const appLocales = {
  en: ['US', 'GB'],
  es: ['ES'],
};

/* 
  If the locale is supported, return it. 
  If only the language is supported then return the first country. 
  Else return the DEFAULT_LOCALE 
*/
export const getSupportedLocale = (locale: string): SupportedLocale => {
  const split = locale.split('-');
  const language = split[0];
  const country = split[1];

  const countries = appLocales[language];

  if (!countries) return DEFAULT_LOCALE;

  if (countries.includes(country)) return locale as SupportedLocale;

  return parse(UserLocale, `${language}-${countries[0]}`);
};

function getURL(host: string = '', port: string): string {
  if (host !== 'localhost') {
    return host.match(/^https?:\/\//) ? host : `https://${host}`;
  }
  return `http://${host}:${port}`;
}

type Configs = {
  [env: string]: {
    siteData?: {
      title?: string;
      environment?: AppEnvironment;
      appVersion?: string;
      tagline?: string;
      keywords?: string;
      twitterSite?: string;
      twitterUser?: string;
      twitterDescription?: string;
      twitterImage?: string;
    };
    urls?: {
      api?: string;
      apiHost?: string;
    };
    integrations?: {
      sentryDNS?: string;
      analyticsID?: string;
      authHost?: string;
      authClientID?: string;
      authAudienceAPI?: string;
      authConnection?: string;
      etherscanKey?: string;
      zapierClientID?: string;
      curatorUrl?: string;
      moralisApiKey?: string;
    };
    emails?: {
      contact?: string;
      support?: string;
    };
    withBilling?: boolean;
    foreignChain?: {
      chainIds: ForeignChainId[];
    };
    uploads?: {
      maxSize: number;
    };
  };
};

const configsByEnv: Configs = {
  defaults: {
    siteData: {
      title: 'Suscribo',
      environment: process.env.APP_ENV as AppEnvironment,
      appVersion: process.env.APP_VERSION,
      tagline: 'Smart, simple, secure digital contracting',
      keywords: 'blockchain, smart contracts, bft, ecosystem, applications, platform, active agreements',
      twitterSite: '',
      twitterUser: '',
      twitterDescription: '',
      twitterImage: '/monax-square.svg',
    },
    urls: {
      api: getURL(process.env.API_HOST, process.env.API_PORT),
      apiHost: process.env.API_HOST,
    },
    emails: {
      contact: 'hello@suscribo.com',
      support: 'hello@suscribo.com',
    },
    integrations: {
      analyticsID: process.env.ANALYTICS_API_KEY_REACT,
      authHost: process.env.AUTH_HOST,
      authClientID: process.env.AUTH_REACT_CLIENT_ID,
      authConnection: process.env.AUTH_USER_DB_NAME,
      sentryDNS: process.env.SENTRY_DNS,
      etherscanKey: process.env.ETHERSCAN_KEY,
      zapierClientID: process.env.ZAPIER_CLIENT_ID,
      moralisApiKey: process.env.MORALIS_API_KEY,
      curatorUrl: formatCuratorURL(
        process.env.CURATOR_HOST,
        process.env.APP_ENV === 'local' ? process.env.CURATOR_PORT : undefined,
        process.env.APP_ENV === 'local' ? 'http' : 'https',
      ),
      authAudienceAPI:
        process.env.APP_ENV === 'production'
          ? `monax-api`
          : process.env.APP_ENV === 'local'
          ? `monax-api-develop`
          : `monax-api-${process.env.APP_ENV}`,
    },
    foreignChain: {
      chainIds: process.env.FOREIGN_CHAIN_IDS
        ? process.env.FOREIGN_CHAIN_IDS.split(',').map((id) => id.trim() as ForeignChainId)
        : [],
    },
    uploads: {
      maxSize: Number(process.env.MAX_UPLOAD_SIZE || 50000000),
    },
  },
};

type AppEnvironment = 'production' | 'develop' | 'demo' | 'staging' | 'local';

export const getEnvironment = (): AppEnvironment => {
  return process.env.APP_ENV as AppEnvironment;
};

export const getNodeEnvironment = (): string => {
  return process.env.NODE_ENV;
};

export const getPersistStorage = () => {
  return storage;
};

if (process.env.NODE_ENV === 'test') configsByEnv.test = configsByEnv.development;
const env = process.env.NODE_ENV || 'development';

const configs = merge({}, configsByEnv.defaults, configsByEnv[env]);

export const isForeignChainEnabled = (): boolean => {
  return configs.foreignChain?.chainIds ? configs.foreignChain?.chainIds.length > 0 : false;
};

export default configs;
