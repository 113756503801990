import { Dispatch, SetStateAction, useCallback, useState } from 'react';

// Needed because: https://github.com/facebook/react/issues/14087
export const useFunctionState = <S>(fn: S | (() => S)): [S, Dispatch<SetStateAction<S>>] => {
  const [val, setVal] = useState(() => fn);
  const setFunc = useCallback((fn) => {
    setVal(() => fn);
  }, []);
  return [val as S, setFunc];
};
