import produce from 'immer';
import { UserInvitationsState } from '../types';
import { UserInvitationAction } from './actions';

export const initialState: UserInvitationsState = {
  invitations: [],
  requesting: false,
};

const reducer = (state: UserInvitationsState = initialState, action: UserInvitationAction) =>
  produce(
    state,
    (draftState): UserInvitationsState => {
      switch (action.type) {
        case 'app/User/Current/UserInvitations/LOAD_USER_INVITATIONS':
          draftState.requesting = true;
          return draftState;
        case 'app/User/Current/UserInvitations/LOAD_USER_INVITATIONS_SUCCESS':
          draftState.invitations = action.invitations;
          draftState.requesting = false;
          return draftState;
        case 'app/User/Current/UserInvitations/ACCEPT_USER_INVITATION':
          draftState.requesting = true;
          return draftState;
        case 'app/User/Current/UserInvitations/ACCEPT_USER_INVITATION_SUCCESS':
          draftState.requesting = false;
          return draftState;
        case 'app/User/Current/UserInvitations/DECLINE_USER_INVITATION':
          draftState.requesting = true;
          return draftState;
        case 'app/User/Current/UserInvitations/DECLINE_USER_INVITATION_SUCCESS':
          draftState.requesting = false;
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;
