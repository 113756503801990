/*
 * Form Messages
 *
 * This contains all the text for the Form component.
 */
import { defineMessages } from 'react-intl';

const scope = 'app.components.Form';
export default defineMessages({
  uploadFile: {
    id: `${scope}.uploadFile`,
    defaultMessage: '{count, plural, one {Upload File} other {Upload Files}}',
  },
  maxFileSizeXExceeded: {
    id: `${scope}.maxFileSizeXExceeded`,
    defaultMessage: 'Max file size of {size}MB exceeded',
  },
  fileTypeNotSupported: {
    id: `${scope}.fileTypeNotSupported`,
    defaultMessage: 'File type not supported.',
  },
  maxFileCountExceeded: {
    id: `${scope}.maxFileCountExceeded`,
    defaultMessage: 'Max file count of {max} exceeded.',
  },
  dropLabel: {
    id: `${scope}.dropLabel`,
    defaultMessage: 'Drop your documents or click here to upload',
  },
  dropAcceptedTypes: {
    id: `${scope}.dropAcceptedTypes`,
    defaultMessage: 'Supporting {extensions}',
  },
  optional: {
    id: `${scope}.optional`,
    defaultMessage: 'Optional',
  },
});
