import { useCallback, useState } from 'react';

export type TokenMetadata = {
  name: string;
  description: string;
  image: string;
  external_url: string;
};

type UseTokenUri = {
  loading: boolean;
  getMetadata: (tokenUri: string) => Promise<TokenMetadata | undefined>;
};

export const useTokenUri = (): UseTokenUri => {
  const [loading, setLoading] = useState<boolean>();

  const getMetadata = useCallback(async (tokenUri: string) => {
    setLoading(true);

    try {
      const response = await fetch(tokenUri);
      const metadata = await response.json();
      return metadata;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    getMetadata,
  };
};
