import { Drawer } from '@material-ui/core';
import {
  getAdminPortalRoute,
  getAgreementListRoute,
  getAssetListRoute,
  getOverviewRoute,
  getTemplateListRoute,
} from '@monax/aeger/dist/webapp-routes';
import { CapabilityEnum, CustomerTypeEnum } from '@monax/types';
import { Search as SearchAgreements } from 'containers/Agreement/List/Agreement/components/Search';
import { receiveListAgreementSearch } from 'containers/Agreement/List/Agreement/state/actions';
import { selectSearchAgreements } from 'containers/Agreement/List/Agreement/state/selectors';
import { Search as SearchTemplates } from 'containers/Agreement/List/Template/components/Search';
import { receiveListTemplateSearch } from 'containers/Agreement/List/Template/state/actions';
import { selectSearchTemplates } from 'containers/Agreement/List/Template/state/selectors';
import { selectCurrentOrganizationCustomerType } from 'containers/Organization/Current/state/selectors';
import { useTheme } from 'containers/Theme/useTheme';
import { makeSelectCurrentUserHasPermission } from 'containers/User/Current/Profile/state/selectors';
import { useBreakpoint } from 'lib/hooks/useBreakpoint';
import React, { useState } from 'react';
import { List, Search, Trash, X } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container } from '../Container';
import { AccountSwitcherForm } from './AccountSwitcherForm';
import messages from './messages';
import { assetScreensEnabled, isCurrentPath } from './utils';

const overviewPath = getOverviewRoute();
const agreementListPath = getAgreementListRoute();
const templateListPath = getTemplateListRoute();
const adminPortalPath = getAdminPortalRoute();
const assetListPath = getAssetListRoute();

const selectCanReadAsset = makeSelectCurrentUserHasPermission(CapabilityEnum.ASSET_READ);
const selectCanAdminOrganizationRead = makeSelectCurrentUserHasPermission(CapabilityEnum.ADMIN_ORGANIZATION_READ);

export const NavbarMobile: React.FC = () => {
  const { push } = useHistory();
  const { logos } = useTheme();
  return (
    <Container className="bg-secondary-main">
      <div className="w-full py-4">
        <div className="flex">
          {logos?.favicon?.secondaryContrastText && (
            <img
              src={logos.favicon.secondaryContrastText}
              alt="logo"
              style={{ height: '30px' }}
              className="cursor-pointer"
              onClick={() => push(overviewPath)}
            />
          )}
          <div className="flex flex-1 pr-4 pl-5 truncate">
            <SearchBar />
          </div>
          <Menu />
        </div>
      </div>
    </Container>
  );
};

type MenuMode = 'account-switcher' | 'menu';

const Menu: React.FC = () => {
  const { isAtLeast } = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState<MenuMode>('menu');
  const { logos } = useTheme();
  const open = () => {
    setMode('menu');
    setIsOpen(true);
  };
  const close = () => setIsOpen(false);

  return (
    <div className="flex items-center">
      <List className="text-white text-3xl cursor-pointer" onClick={open} />
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={close}
        PaperProps={{ className: `${isAtLeast('md') ? 'w-5/12' : isAtLeast('sm') ? 'w-8/12' : 'w-full'}` }}
      >
        <div className="bg-secondary-main px-6">
          <div className="w-full py-4">
            <div className="flex justify-between">
              <img src={logos.favicon.secondaryContrastText} alt="logo" style={{ height: '30px' }} />
              <X className="text-white text-3xl cursor-pointer" onClick={close} />
            </div>
          </div>
        </div>
        {mode === 'menu' && <MenuItems setMode={setMode} />}
        {mode === 'account-switcher' && <AccountSwitcherForm onClose={close} />}
      </Drawer>
    </div>
  );
};

type MenuItemsProps = {
  setMode: (mode: MenuMode) => void;
};

export const MenuItems: React.FC<MenuItemsProps> = ({ setMode }) => {
  const intl = useIntl();
  const {
    location: { pathname },
    push,
  } = useHistory();

  const assetsEnabled = assetScreensEnabled();

  const canCanReadAsset = useSelector(selectCanReadAsset);
  const canAdminOrganizationRead = useSelector(selectCanAdminOrganizationRead);
  const customerType = useSelector(selectCurrentOrganizationCustomerType);

  return (
    <div className="p-3">
      <MenuItem
        selected={isCurrentPath(pathname, overviewPath)}
        label={intl.formatMessage(messages.overviewLabel)}
        onClick={() => push(overviewPath)}
      />
      <MenuItem
        selected={isCurrentPath(pathname, agreementListPath)}
        label={intl.formatMessage(messages.agreementsLabel)}
        onClick={() => push(agreementListPath)}
      />
      <MenuItem
        selected={isCurrentPath(pathname, templateListPath)}
        label={intl.formatMessage(messages.templatesLabel)}
        onClick={() => push(templateListPath)}
      />
      {assetsEnabled && canCanReadAsset && (
        <MenuItem
          selected={isCurrentPath(pathname, assetListPath)}
          label={intl.formatMessage(messages.assetsLabel)}
          onClick={() => push(assetListPath)}
        />
      )}
      <Splitter />
      <MenuItem
        selected={false}
        label={intl.formatMessage(messages.profileLabel)}
        onClick={() => setMode('account-switcher')}
      />
      {canAdminOrganizationRead &&
        (customerType === CustomerTypeEnum.PLATFORM_ADMIN_CLM || customerType === CustomerTypeEnum.MANAGED_CLM) && (
          <MenuItem
            selected={pathname === adminPortalPath}
            label={intl.formatMessage(messages.adminLabel)}
            onClick={() => push(adminPortalPath)}
          />
        )}
    </div>
  );
};

type MenuItemProps = {
  selected: boolean;
  label: string;
  onClick: () => void;
};
export const MenuItem: React.FC<MenuItemProps> = ({ selected, label, onClick }) => (
  <div
    onClick={onClick}
    className={`px-3 py-2 rounded-md cursor-pointer w-full text-secondary-dark mb-2 ${
      selected ? 'bg-accent3-extraLight' : ''
    }`}
  >
    {label}
  </div>
);

const Splitter: React.FC = () => (
  <div className="w-full px-2">
    <div className="w-full border-t border-gray-300" />
  </div>
);

const SearchBar: React.FC = () => {
  const {
    location: { pathname },
  } = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  if (pathname !== agreementListPath && pathname !== templateListPath) return <></>;

  return (
    <>
      <div
        className="w-full flex justify-center items-center rounded-md text-white font-light bg-white bg-opacity-25 px-2 min-w-0"
        onClick={open}
      >
        <div className="truncate">
          <SearchText pathname={pathname} />
        </div>
      </div>
      <MenuDrawer isOpen={isOpen} close={close} pathname={pathname} />
    </>
  );
};

type SearchTextProps = {
  pathname: string;
};

const SearchText: React.FC<SearchTextProps> = ({ pathname }) => {
  const { textSearch: agreementsTextSearch } = useSelector(selectSearchAgreements);
  const { textSearch: templatesTextSearch } = useSelector(selectSearchTemplates);
  const intl = useIntl();

  if (pathname === agreementListPath && !!agreementsTextSearch) return <>{agreementsTextSearch}</>;
  if (pathname === templateListPath && !!templatesTextSearch) return <>{templatesTextSearch}</>;

  return (
    <>
      <Search />
      <span className="ml-2">{intl.formatMessage(messages.searchTextPlaceholder)}</span>
    </>
  );
};

type MenuDrawerProps = {
  isOpen: boolean;
  close: () => void;
  pathname: string;
};
const MenuDrawer: React.FC<MenuDrawerProps> = ({ isOpen, close, pathname }) => {
  const dispatch = useDispatch();
  const clear = () => {
    if (pathname === agreementListPath) dispatch(receiveListAgreementSearch({ textSearch: '' }));
    if (pathname === templateListPath) dispatch(receiveListTemplateSearch({ textSearch: '' }));
    close();
  };

  return (
    <Drawer anchor="top" open={isOpen} onClose={close}>
      <div className="w-full flex p-3">
        <div className="flex-1">
          {pathname === agreementListPath && (
            <SearchAgreements
              autofocus
              onKeyPress={(e) => {
                if (e.code === 'Enter') close();
              }}
            />
          )}
          {pathname === templateListPath && (
            <SearchTemplates
              autofocus
              onKeyPress={(e) => {
                if (e.code === 'Enter') close();
              }}
            />
          )}
        </div>
        <div className="flex items-center pl-4 pr-1 text-lg cursor-pointer" onClick={clear}>
          <Trash />
        </div>
      </div>
    </Drawer>
  );
};
