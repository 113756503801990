import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroupMD from '@material-ui/core/RadioGroup';
import { FormItem } from 'components/Form';
import { useInputState } from 'components/Form/Hooks';
import { InputConfig } from 'components/Form/types';
import { checkbox } from 'components/IconButton/icons';
import appMessages from 'containers/App/messages';
import { useTheme } from 'containers/Theme/useTheme';
import { useField } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RadioOption } from './types';

type Props = {
  id?: string;
  name?: string;
  className?: string;
  label?: React.ReactNode;
  isRequired?: boolean;
  disabled?: boolean;
  hiddenLabel?: boolean;
  inputConfig?: InputConfig;
};

const booleanOptions: RadioOption[] = [
  {
    label: <FormattedMessage {...appMessages.yes} />,
    value: 'true',
  },
  {
    label: <FormattedMessage {...appMessages.no} />,
    value: 'false',
  },
];

export const BooleanRadioGroup: React.FC<Props> = ({ name, className, label, id, disabled, inputConfig }) => {
  const [input, meta, helpers] = useField(name);
  const { value, onBlur } = input;
  const inputState = useInputState(inputConfig, meta);
  const { palette } = useTheme();
  return (
    <FormItem label={label} inputState={inputState}>
      <RadioGroupMD
        className={className}
        onChange={(_e, v) => {
          helpers.setValue(v === 'true');
        }}
        onBlur={onBlur}
        value={value ? 'true' : value === false ? 'false' : null}
        name={name}
        color="primary"
        row
      >
        {booleanOptions.map((radio) => (
          <FormControlLabel
            key={radio.value.toString()}
            htmlFor={`${id || name}.${radio.value}`}
            value={radio.value}
            control={
              <Radio
                id={`${id || name}.${radio.value}`}
                name={name}
                size="small"
                disabled={disabled || radio.disabled}
                icon={checkbox.unSelected(palette.gray[700])}
                checkedIcon={checkbox.selected(palette.info.main)}
              />
            }
            label={radio.label}
          />
        ))}
      </RadioGroupMD>
    </FormItem>
  );
};
