import { AgreementDraftDate, AgreementDraftDateTypeEnum } from '@monax/types';
import { FormDate } from '../types';

export const circularDateReferenceExists = (
  date: FormDate | AgreementDraftDate,
  datesById: Record<string, FormDate | AgreementDraftDate>,
  seen: Record<string, boolean> = {},
): boolean => {
  if (!date || !date.data || date.type === AgreementDraftDateTypeEnum.FIXED) return false;

  // If we have have already seen this date, then we have a circular dep
  if (seen[date.id]) return true;

  seen[date.id] = true;

  return circularDateReferenceExists(datesById[date.data.baseId || ''], datesById, seen);
};
