import errorMessages from 'containers/App/errorMessages';
import { format } from 'date-fns';
import { isRight } from 'fp-ts/lib/Either';
import { set } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { BooleanSchema, MixedSchema, Schema, StringSchema, ValidationError } from 'yup';
import { EthereumAddress } from '../../../types/dist';

// Merge the declaration of our additional methods into the global yup type
// TODO: kill this with fire - don't rely on global instances and monkey-patching
declare module 'yup' {
  interface Schema<T> {
    forbidden(message?: TestOptionsMessage): Schema<T>;
  }

  interface BooleanSchema<T> {
    true(message?: TestOptionsMessage): BooleanSchema<T>;
  }

  interface StringSchema<T> {
    unique(tag?: string, message?: TestOptionsMessage): StringSchema<T>;

    address(message?: TestOptionsMessage): StringSchema<T>;

    ethAddress(message?: TestOptionsMessage): StringSchema<T>;

    hex(requirePrefix?: boolean, length?: number, message?: TestOptionsMessage): StringSchema<T>;

    json(message?: TestOptionsMessage): StringSchema<T>;

    dateFormat(message?: TestOptionsMessage): StringSchema<T>;

    equalTo(ref: Ref, message?: TestOptionsMessage): StringSchema<T>;
  }
}

const locale = {
  mixed: {
    required: 'fieldRequired',
    oneOf: 'mustBeOneOf',
    notOneOf: 'notOneOf',
    laterThanOpenOffset: 'laterThanOpenOffset',
    notType: 'mustBeType',
  },
  string: {
    max: 'lengthCannotExceed',
    address: 'addressRequired',
    ethAddress: 'ethAddress',
    hex: 'hexRequired',
    json: 'jsonRequired',
    email: 'invalidEmail',
    valuesMustBeEqual: 'valuesMustBeEqual',
  },
  number: {
    min: 'numberMin',
    max: 'numberMax',
  },
};

export type Errors = Record<string, any>;
export type ConfiguredYup = <T>(schema: Schema<T>) => (shape: any, returnErrors?: boolean) => T | Errors;

yup.setLocale(locale);

yup.addMethod<StringSchema>(yup.string, 'unique', function (tag = null, message = 'duplicatesNotPermitted') {
  return this.test('unique', message, function (value) {
    const { path, options } = this;
    // Assume uniqueness is based on field name
    const { uniques } = options.context as any;
    const setName = tag || path.split('.').pop();
    const uniqueSet = uniques[setName] || (uniques[setName] = new Set());
    const seen = uniqueSet.has(value);
    if (!seen) {
      uniqueSet.add(value);
    }
    return !value || !seen;
  });
});

yup.addMethod<StringSchema>(yup.string, 'address', function (message = 'addressRequired') {
  return this.test('address', message, function (value) {
    return !value || !!value.match(/^[0-9A-Fa-f]{40}$/);
  });
});

yup.addMethod<StringSchema>(yup.string, 'ethAddress', function (message = 'ethAddress') {
  return this.test('ethAddress', message, function (value) {
    return !value || isRight(EthereumAddress.decode(value));
  });
});

yup.addMethod<StringSchema>(
  yup.string,
  'hex',
  function (requirePrefix?: boolean, length?: number, message?: keyof typeof errorMessages) {
    message ||= length ? 'hexOfLengthRequired' : 'hexRequired';
    const regex = `^${requirePrefix ? '0x' : ''}[0-9A-Fa-f]${length ? `{${length}}` : '+'}$`;
    return this.test('hex', message, function (value) {
      if (!value || !!value.match(new RegExp(regex))) {
        return true;
      }
      return this.createError({ path: this.path, message, params: { length } });
    });
  },
);

yup.addMethod<StringSchema>(yup.string, 'json', function (message = 'jsonRequired') {
  return this.test('json', message, function (value) {
    if (!value) {
      return true;
    }
    try {
      JSON.parse(value);
      return true;
    } catch (err) {
      return false;
    }
  });
});

yup.addMethod<StringSchema>(yup.string, 'dateFormat', function (message = 'invalidDateFormat') {
  return this.test('dateFormat', message, function (value) {
    if (!value) {
      return true;
    }
    try {
      format(new Date(), value);
      return true;
    } catch (err) {
      return false;
    }
  });
});

yup.addMethod<BooleanSchema>(yup.bool, 'true', function (message = 'booleanMustBeTrue') {
  return this.test('true', message, function (value) {
    return !!value;
  });
});

yup.addMethod<MixedSchema>(yup.mixed, 'forbidden', function (message = 'valueForbidden') {
  return this.test('forbidden', message, function (value) {
    return !!value;
  });
});

yup.addMethod<MixedSchema>(yup.string, 'equalTo', function equalTo(ref: any, message = 'valuesMustBeEqual') {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: message,
    params: {
      reference: ref.path,
    },
    test: function (value: any) {
      return value === this.resolve(ref);
    },
  });
});

const errorMessage = (message, params) => {
  const messageConfig = errorMessages[message];
  if (messageConfig && messageConfig.defaultMessage) {
    // Params contains mostly suitable formatting values such as 'max'
    return <FormattedMessage {...messageConfig} values={params} />;
  }
  return message;
};

// Settings above are global but we're pretend their not and this ensures we apply them
// before any yup usage
export const validator = (schema) => (shape, returnErrors = true, stripUnknown = false): { [field: string]: any } => {
  try {
    const validated = schema.validateSync(shape, {
      abortEarly: false,
      stripUnknown,
      context: { uniques: {}, shape },
    });

    return returnErrors ? {} : validated;
  } catch (errs) {
    if (errs instanceof ValidationError) {
      const errors = errs.inner.reduce((es, e) => set(es, e.path, errorMessage(e.message, e.params)), {});
      if (returnErrors) {
        return errors;
      }
      throw new Error(JSON.stringify(errors));
    }
    throw errs;
  }
};

export default yup;
