import { SegmentAnalytics } from '@segment/analytics.js-core';

export const mockAnalytics: SegmentAnalytics.AnalyticsJS = ({
  identify: (id?: string, traits?: unknown, options?: unknown, fn?: Function) => {
    return {};
  },
  track: (event: string, properties?: unknown, options?: unknown, fn?: unknown) => {
    return {};
  },
  page: (category?: string, name?: string, properties?: any, options?: any, fn?: unknown) => {
    return {};
  },
  user: () => {
    return {
      id: () => undefined,
      anonymousId: () => '1234',
      traits: () => ({}),
    };
  },
  invoked: true,
} as unknown) as SegmentAnalytics.AnalyticsJS;
