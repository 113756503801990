import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularProgress } from './CircularProgress';
import messages from './messages';

type Props = {
  message?: string;
  overlay?: boolean;
  writing?: boolean;
  lightMode?: boolean;
  size?: React.ReactText;
};

export const ContentLoader: React.FC<Props> = (props) => {
  const { message, overlay, writing, lightMode, size = 48 } = props;
  const [render, setRender] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!message && !writing) {
        setRender(true);
      }
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const defaultClasses = overlay
    ? 'fixed top-0 left-0 h-full w-full bg-gray-600 flex flex-col justify-center items-center z-10'
    : 'z-10 flex flex-col items-center justify-center h-full';
  let renderedMessage: React.ReactNode;
  if (message) {
    renderedMessage = message;
  } else if (writing) {
    renderedMessage = <FormattedMessage {...messages.createMessage} />;
  } else {
    renderedMessage = null;
  }
  return (
    <div className={defaultClasses}>
      <CircularProgress size={size} lightMode={lightMode} />
      <div className={`text-info-main text-base w-full text-center mt-3 ${lightMode ? 'light-mode' : ''}`}>
        {render ? <FormattedMessage {...messages.inProgress} /> : renderedMessage}
      </div>
    </div>
  );
};
