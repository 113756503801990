/*
 *
 * Organizations reducer
 *
 */

import { OrganizationInfoByAddress } from '@monax/types';
import produce from 'immer';
import { merge } from 'utils/merge';
import { OrganizationAction } from './actions';

export const initialState: OrganizationInfoByAddress = {};

const organizationsReducer = (state = initialState, action: OrganizationAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Organization/List/LOAD_ORGANIZATIONS_SUCCESS':
        if (Array.isArray(action.organizations)) {
          action.organizations.forEach((org) => {
            if (draftState[org.address]) {
              merge(draftState[org.address], org);
            } else {
              draftState[org.address] = org;
            }
          });
        } else {
          merge(draftState, action.organizations);
        }
        return draftState;

      default:
        return draftState;
    }
  });

export default organizationsReducer;
