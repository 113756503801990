import { ForeignChainAssetType } from '@monax/types';
import { Badge, BadgeColor } from 'components/Common';
import React from 'react';
import { getForeignChainAssetTypeCode } from '../List/utils';

type Props = {
  type: ForeignChainAssetType;
};

export const ForiegnChainAssetTypeBadge: React.FC<Props> = ({ type }) => {
  let code = getForeignChainAssetTypeCode(type);
  let color: BadgeColor = 'primary';

  if (type === 'non_fungible_token') color = 'accent1';

  return (
    <Badge size="s" color={color}>
      {code}
    </Badge>
  );
};
