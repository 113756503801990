import {
  Agreement,
  GetAgreementBlockchainTransactionsResponse,
  SignatureMetaSchema,
  ThemeOverrides,
  ThrottledResourceLimits,
} from '@monax/types';

export enum AgreementRecordRequest {
  ADD_EVENTS = 'app/Agreement_v4/PostChain/Record/ADD_EVENTS',
  ADD_PARAMETERS = 'app/Agreement_v4/PostChain/Record/ADD_PARAMETERS',
  RECEIVE_PARAMETERS = 'app/Agreement_v4/PostChain/Record/RECEIVE_PARAMETERS',
  CANCEL_AGREEMENT = 'app/Agreement_v4/PostChain/Record/CANCEL_AGREEMENT',
  DELETE_AGREEMENT = 'app/Agreement_v4/PostChain/Record/DELETE_AGREEMENT',
  DOWNLOAD_AGREEMENT = 'app/Agreement_v4/PostChain/Record/DOWNLOAD_AGREEMENT',
  LOAD_AGREEMENT = 'app/Agreement_v4/PostChain/Record/LOAD_AGREEMENT',
  UPDATE_DOCUMENTS = 'app/Agreement_v4/PostChain/Record/UPDATE_DOCUMENTS',
  UPDATE_NAME = 'app/Agreement_v4/PostChain/Record/UPDATE_NAME',
  UPDATE_REPRESENTATIVE = 'app/Agreement_v4/PostChain/Record/UPDATE_REPRESENTATIVE',
  UPDATE_TEAMS = 'app/Agreement_v4/PostChain/Record/UPDATE_TEAMS',
}

export const RECORD_PANEL_TABS = ['documents', 'parties', 'terms', 'dates', 'events', 'settings'] as const;
export type RecordPanelTab = typeof RECORD_PANEL_TABS[number];
export type RecordSection = 'documents' | 'parties' | 'terms' | 'dates' | 'events' | 'settings';

export type ObligationDialog = {
  id: string;
  franchisee?: string;
};

export type AgreementRecordState = {
  agreement: Agreement | null;
  resourceLimits: ThrottledResourceLimits;
  themeOverrides: ThemeOverrides;
  signatures: SignatureMetaSchema[];
  blockchainTransactions: GetAgreementBlockchainTransactionsResponse;
  requests: AgreementRecordRequest[];
  section: RecordSection;
  onSave: null | (() => void);
  signatureDialog: string;
  signNudgeDialog: boolean;
  visibilityDialog: boolean;
  cancelDialog: boolean;
  deleteDialog: boolean;
  nameDialog: boolean;
  obligationDialog: ObligationDialog;
  blockchainTransactionDialog: string | void;
  blockchainLogDialog: string | void;
};
