import { TemplateFilter, TemplatesListItem } from '@monax/types';
import { selectTemplateResourceFilters } from 'containers/User/Current/ResourceFilters/state/selectors';
import { filterIsEmpty } from 'containers/User/Current/ResourceFilters/utils';
import { createSelector } from 'reselect';
import { ApplicationState } from 'types';
import { SelectedState } from '../../types';

const rootSelector = (state: ApplicationState) => state.agreement.list.template;

export const selectTemplateList = createSelector(rootSelector, (state) => state.templates);

export const selectTotalTemplates = createSelector(rootSelector, (state) => state.totalTemplates);

export const selectLoadingTemplates = createSelector(rootSelector, (state) => state.loading);

export const selectSortTemplates = createSelector(rootSelector, (state) => state.sort);

export const selectSearchTemplates = createSelector(rootSelector, (state) => state.search);

export const selectSelectedTemplateIds = createSelector(rootSelector, (state) => state.selectedTemplates);

export const selectTemplateResourceFilterId = createSelector(rootSelector, (state) => state.resourceFilterId);

export const selectDefaultTemplateResourceIsSelected = createSelector(selectTemplateResourceFilterId, (id) => !id);

export const selectDefaultTemplateResourceFilter = createSelector(rootSelector, (state) => state.filter);

export const selectDefaultTemplateResourceFilterIsEmpty = createSelector(
  selectDefaultTemplateResourceFilter,
  (filter) => filterIsEmpty(filter),
);

export const selectTemplateResourceFilter = createSelector(
  selectTemplateResourceFilters,
  selectTemplateResourceFilterId,
  (filters, id) => filters.find((f) => f.id === id),
);

export const selectCurrentTemplateResourceFilterFilter = createSelector(
  selectDefaultTemplateResourceIsSelected,
  selectDefaultTemplateResourceFilter,
  selectTemplateResourceFilter,
  (defaultIsSelected, defaultFilter, resourceFilter): TemplateFilter =>
    defaultIsSelected ? defaultFilter : (resourceFilter?.filter as TemplateFilter),
);

export const selectSelectedTemplates = createSelector(
  selectSelectedTemplateIds,
  selectTemplateList,
  (ids, templates): Record<string, TemplatesListItem> =>
    ids.reduce((p, c) => ({ ...p, [c]: templates.find((t) => t.id === c) }), {}),
);

export const selectSelectedTemplateList = createSelector(
  selectSelectedTemplateIds,
  selectTemplateList,
  (ids, templates): TemplatesListItem[] => ids.map((id) => templates.find((t) => t.id === id)),
);

export const selectSelectedTemplatesState = createSelector(
  selectTemplateList,
  selectSelectedTemplateIds,
  (templateList, selectedAddress): SelectedState => {
    if (selectedAddress.length === 0) return 'none-selected';
    if (selectedAddress.length === templateList.length) return 'all-selected';
    return 'indeterminate-selected';
  },
);

export const selectCanUnpublish = createSelector(
  selectSelectedTemplateIds,
  selectSelectedTemplates,
  (ids, templates) => !!ids.length && ids.map((id) => templates[id]).every((t) => t?.published === 'published'),
);

export const selectCanLock = createSelector(
  selectSelectedTemplateIds,
  selectSelectedTemplates,
  (ids, templates) =>
    !!ids.length && ids.map((id) => templates[id]).every((t) => !t?.currentEditor && t?.published === 'published'),
);

export const selectCanUnLock = createSelector(
  selectSelectedTemplateIds,
  selectSelectedTemplates,
  (ids, templates) =>
    !!ids.length && ids.map((id) => templates[id]).every((t) => !!t?.currentEditor && t?.published === 'published'),
);

export const selectCanDuplicate = createSelector(
  selectSelectedTemplateIds,
  selectSelectedTemplates,
  (ids, templates) => ids.length === 1 && templates[ids[0]]?.published === 'published',
);

export const selectCanDelete = createSelector(selectSelectedTemplateIds, (ids) => ids.length > 0);
