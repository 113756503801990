import { InputAdornment } from '@material-ui/core';
import React from 'react';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { ErrorTooltip } from '../Tooltip';

type Props = {
  message?: string;
  errorTooltipVisible?: boolean;
};

export const ErrorAdornment: React.FC<Props> = (props) => {
  const adornment = (
    <InputAdornment position="end">
      <ExclamationCircleFill className="text-error-main" />
    </InputAdornment>
  );

  if (!props.message || !props.errorTooltipVisible) {
    return adornment;
  }

  return <ErrorTooltip title={props.message}>{adornment}</ErrorTooltip>;
};
