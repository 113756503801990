import { Asset } from '@monax/types';
import { FormattedDate } from 'components/FormattedDate';
import { makeTable } from 'components/Table';
import emptyGraphic from 'images/common/empty-graphic-2.svg';
import React, { useMemo } from 'react';
import { ChevronRight } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { dateOptions } from 'utils/dateDisplayOptions';
import { getAssetRecordRoute } from '../../../../../aeger/dist/webapp-routes';
import { EmptyGraphic } from '../components/EmptyGraphic';
import { ForiegnChainAssetTypeBadge } from '../components/ForiegnChainAssetTypeBadge';
import { AssetTableHeader } from './AssetTableHeader';
import { messages } from './messages';
import { selectAssetList, selectAssetListFiltered, selectLoadingAssets } from './state/selectors';

const Table = makeTable<Asset>();

type RowProps = {
  row: { original: Asset };
};

type Props = {
  onAssetClicked?: () => void;
  disableHighlightSelectedAsset?: boolean;
  hideTableHead?: boolean;
};

export const AssetTable: React.FC<Props> = ({ onAssetClicked, disableHighlightSelectedAsset, hideTableHead }) => {
  const intl = useIntl();
  const history = useHistory();

  const { assetId } = useParams<{ assetId: string }>();

  const assets = useSelector(selectAssetList);
  const filteredAssets = useSelector(selectAssetListFiltered);
  const loading = useSelector(selectLoadingAssets);

  const navigate = (assetId: string) => {
    history.push(getAssetRecordRoute(assetId));
    onAssetClicked?.();
  };

  const columns: Column<Asset>[] = useMemo(
    () => [
      {
        id: 'id',
        accessor: (agr) => agr.id,
        Cell: ({ row: { original: asset } }: RowProps) => {
          return (
            <div className="flex flex-col py-1 cursor-pointer" onClick={() => navigate(asset.id)}>
              <div className="flex items-center pb-2">
                <div className="pr-4">
                  <ForiegnChainAssetTypeBadge type={asset.body.config.foreignChainAssetType} />
                </div>
                <div className="text-gray-800 text-xs">
                  <span className="mr-3">{intl.formatMessage(messages.created)}:</span>
                  <span>
                    <FormattedDate date={asset.created} jsDateFormat={dateOptions.short} />
                  </span>
                </div>
              </div>
              <div className="font-semibold text-lg text-info-main">{asset.body.name}</div>
            </div>
          );
        },
      },
      {
        id: 'select',
        width: '1%',
        Cell: ({ row: { original: asset } }: RowProps) => (
          <div className="flex items-center cursor-pointer" onClick={() => navigate(asset.id)}>
            <ChevronRight />
          </div>
        ),
      },
    ],
    [filteredAssets, assetId],
  );

  if (!loading && assets.length === 0)
    return (
      <EmptyGraphic
        image={emptyGraphic}
        header={intl.formatMessage(messages.emptyListHeader)}
        description={intl.formatMessage(messages.emptyListDescription)}
      />
    );

  return (
    <Table
      data={filteredAssets}
      loading={loading && filteredAssets.length === 0}
      columns={columns}
      hideTableHead={hideTableHead}
      tableHead={<AssetTableHeader />}
      paginate={false}
      isRowSelected={(asset) => asset.id === assetId && !disableHighlightSelectedAsset}
    />
  );
};
