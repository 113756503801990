import { defineMessages } from 'react-intl';

const scope = 'hooks.useWallet';

export const messages = defineMessages({
  selectWallet: {
    id: `${scope}.selectWallet`,
    defaultMessage: 'Select Wallet',
  },
  walletSelected: {
    id: `${scope}.walletSelected`,
    defaultMessage: 'Connected via {wallet}',
  },
  selectChain: {
    id: `${scope}.selectChain`,
    defaultMessage: 'Select Chain',
  },
  metamask: {
    id: `${scope}.metamask`,
    defaultMessage: 'Metamask',
  },
  walletConnect: {
    id: `${scope}.walletConnect`,
    defaultMessage: 'WalletConnect',
  },
  walletLink: {
    id: `${scope}.walletLink`,
    defaultMessage: 'Coinbase', // coinbase is the only wallet supported by WalletLink atm
  },
  pleaseSign: {
    id: `${scope}.pleaseSign`,
    defaultMessage: 'Please sign our message to confirm your identity.',
  },
  error_availability: {
    id: `${scope}.error_availability`,
    defaultMessage: 'Wallet not available- are you sure you have it installed?',
  },
  error_connection: {
    id: `${scope}.error_connection`,
    defaultMessage: 'Failed to connect wallet account: {error}',
  },
  error_sign: {
    id: `${scope}.error_sign`,
    defaultMessage: 'Failed to sign message: {error}',
  },
  error_signIn: {
    id: `${scope}.error_signIn`,
    defaultMessage: 'Failed to sign in with wallet: {error}',
  },
  error_tx: {
    id: `${scope}.error_tx`,
    defaultMessage: 'Failed to send transaction: {error}',
  },
  resetSessions: {
    id: `${scope}.resetSessions`,
    defaultMessage: 'Reset Sessions',
  },
  resetSessionsInfo: {
    id: `${scope}.resetSessionsInfo`,
    defaultMessage:
      'Use this to clear local session data in case things have gotten out of sync with your wallet. You may also have to clean up sessions via your wallet settings.',
  },
});
