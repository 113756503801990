import { defineMessages } from 'react-intl';

const scope = 'app.containers.Agreement_v4.PreChain';

export const messages = defineMessages({
  fullFormValidationError: {
    id: `${scope}.fullFormValidationError`,
    defaultMessage: 'Missing or invalid fields- please confirm all values are correct.',
    description: 'snackbar notification',
  },
  renewalObligationTitle: {
    id: `${scope}.renewalObligationTitle`,
    defaultMessage: 'Renew Agreement',
  },
  manageTermsTitle: {
    id: `${scope}.manageTermsTitle`,
    defaultMessage: 'Define agreement variables',
  },
  manageTermsTitle_short: {
    id: `${scope}.manageTermsTitle_short`,
    defaultMessage: 'Define variables',
  },
  manageTermsExplanation: {
    id: `${scope}.manageTermsExplanation`,
    defaultMessage: 'We found the following variables in your document(s) for you to edit.',
  },
  removeTermTitle: {
    id: `${scope}.removeTermTitle`,
    defaultMessage: 'Re-define term',
  },
  removeTermExplanation: {
    id: `${scope}.removeTermExplanation`,
    defaultMessage:
      'The following variable is linked to your agreement. Select whether you would like to re-define it as Term, Party or Date.\n<strong>**You may redefine it again later by clicking the {icon} button.**</strong>',
  },
  termOption_term: {
    id: `${scope}.termOption_term`,
    defaultMessage: 'Term',
  },
  termOption_party: {
    id: `${scope}.termOption_party`,
    defaultMessage: 'Party',
  },
  termOption_date: {
    id: `${scope}.termOption_date`,
    defaultMessage: 'Date',
  },
  termOption_disabled: {
    id: `${scope}.termOption_disabled`,
    defaultMessage: 'Disabled',
  },
  deleteTermTitle: {
    id: `${scope}.deleteTermTitle`,
    defaultMessage: 'Delete term linked to document(s)?',
  },
  deleteTermMessage_allowRecategorize: {
    id: `${scope}.deleteTermMessage_allowRecategorize`,
    defaultMessage:
      'Are you sure you want to delete this term? It is linked to your document(s) and, if needed, can be re-defined below. Once the term is deleted, it cannot be restored.',
  },
  deleteTermMessage_noRecategorize: {
    id: `${scope}.deleteTermMessage_noRecategorize`,
    defaultMessage:
      'Are you sure you want to delete this term? It is linked to your document(s) and once the term deleted, it cannot be restored.',
  },
  recategorizeButton: {
    id: `${scope}.recategorizeButton`,
    defaultMessage: 'Define Term',
  },
  offsetAmountPlaceholder: {
    id: `${scope}.offsetAmountPlaceholder`,
    defaultMessage: 'Amount',
  },
  offsetUnitPlaceholder: {
    id: `${scope}.offsetUnitPlaceholder`,
    defaultMessage: 'Unit',
  },
  offsetDirectionPlaceholder: {
    id: `${scope}.offsetDirectionPlaceholder`,
    defaultMessage: 'Direction',
  },
  offsetDirection_BEFORE: {
    id: `${scope}.offsetDirection_BEFORE`,
    defaultMessage: 'Before',
  },
  offsetDirection_AFTER: {
    id: `${scope}.offsetDirection_AFTER`,
    defaultMessage: 'After',
  },
  stepNav_Documents: {
    id: `${scope}.stepNav_Documents`,
    defaultMessage: 'Definition of Documents',
  },
  stepNav_Metadata: {
    id: `${scope}.stepNav_Metadata`,
    defaultMessage: 'Definition of Details',
  },
  stepNav_Review_Agreement: {
    id: `${scope}.stepNav_Review_Agreement`,
    defaultMessage: 'Review of Agreement',
  },
  stepNav_Review_Template: {
    id: `${scope}.stepNav_Review_Template`,
    defaultMessage: 'Review of Template',
  },
  stepNav_short_Documents: {
    id: `${scope}.stepNav_short_Documents`,
    defaultMessage: 'Documents',
  },
  stepNav_short_Metadata: {
    id: `${scope}.stepNav_short_Metadata`,
    defaultMessage: 'Details',
  },
  stepNav_short_Review_Agreement: {
    id: `${scope}.stepNav_short_Review_Agreement`,
    defaultMessage: 'Review',
  },
  stepNav_short_Review_Template: {
    id: `${scope}.stepNav_short_Review_Template`,
    defaultMessage: 'Review',
  },
  nonFungibleToken: {
    id: `${scope}.nonFungibleToken`,
    defaultMessage: 'Non-fungible Token',
  },
  completeOptionsHeading: {
    id: `${scope}.completeOptionsHeading`,
    defaultMessage: 'See what else you could do...',
  },
  addressType_tokenContractAddress: {
    id: `${scope}.addressType_tokenContractAddress`,
    defaultMessage: 'Token Contract Address',
  },
  addressType_userAddress: {
    id: `${scope}.addressType_userAddress`,
    defaultMessage: 'Token Creator Address',
  },
  resourceLimit_agreement_document: {
    id: `${scope}.resourceLimit_agreement_document`,
    defaultMessage:
      'Your current payment plan limits you to {number} {number, plural, one {document} other {documents}}',
  },
  resourceLimit_agreement_party: {
    id: `${scope}.resourceLimit_agreement_party`,
    defaultMessage: 'Your current payment plan limits you to {number} {number, plural, one {party} other {parties}}',
  },
  resourceLimit_agreement_term: {
    id: `${scope}.resourceLimit_agreement_term`,
    defaultMessage: 'Your current payment plan limits you to {number} {number, plural, one {term} other {terms}}',
  },
  resourceLimit_agreement_date: {
    id: `${scope}.resourceLimit_agreement_date`,
    defaultMessage:
      'Your current payment plan limits you to {number} {number, plural, one {highlighted date} other {highlighted dates}}',
  },
  resourceLimit_agreement_event: {
    id: `${scope}.resourceLimit_agreement_event`,
    defaultMessage: 'Your current payment plan limits you to {number} {number, plural, one {event} other {event}}',
  },
});
