import messages from 'containers/Auth/messages';
import { useTheme } from 'containers/Theme/useTheme';
import ladyWithKeyTerm from 'images/login/lady-with-key-term.svg';
import ladyWithObligation from 'images/login/lady-with-obligation.svg';
import manWithTeamsAndEvent from 'images/login/man-with-teams-and-event.svg';
import React from 'react';
import { useIntl } from 'react-intl';
import { Carousel } from 'react-responsive-carousel';

export const SlidesPanel: React.FC = () => {
  const intl = useIntl();
  const { palette } = useTheme();
  const imageWidth = '50%';
  return (
    <Carousel
      infiniteLoop
      autoPlay
      showArrows={false}
      showThumbs={false}
      showStatus={false}
      interval={7000}
      renderIndicator={(onClickHandler, isSelected, index) => (
        <li
          onClick={onClickHandler}
          onKeyDown={onClickHandler}
          value={index}
          key={index}
          role="button"
          style={{
            background: isSelected ? palette.primary.main : palette.primary.extraLight,
            width: 10,
            height: 10,
            display: 'inline-block',
            margin: '0 8px',
            borderRadius: 10,
          }}
        />
      )}
    >
      <div>
        <img src={ladyWithObligation} style={{ width: imageWidth }} />
        <Title tag={intl.formatMessage(messages.slide1Tag)} title={intl.formatMessage(messages.slide1Title)} />
      </div>

      <div>
        <img src={ladyWithKeyTerm} style={{ width: imageWidth }} />
        <Title tag={intl.formatMessage(messages.slide2Tag)} title={intl.formatMessage(messages.slide2Title)} />
      </div>

      <div>
        <img src={manWithTeamsAndEvent} style={{ width: imageWidth }} />
        <Title tag={intl.formatMessage(messages.slide3Tag)} title={intl.formatMessage(messages.slide3Title)} />
      </div>
    </Carousel>
  );
};

type TitleProps = {
  tag: string;
  title: string;
};
const Title: React.FC<TitleProps> = (props) => (
  <div className="w-full flex justify-center pt-6 pb-10">
    <div className="w-2/5 text-left">
      <div className="uppercase text-sm text-primary-main">{props.tag}</div>
      <div className="font-medium text-3xl lg:text-4xl text-info-main pt-4 pb-3">{props.title}</div>
    </div>
  </div>
);
