import { Tooltip } from 'components/Tooltip';
import getColors from 'containers/Theme/colors';
import { Palette } from 'containers/Theme/types';
import { useTheme } from 'containers/Theme/useTheme';
import React, { CSSProperties } from 'react';

const defaultColors = getColors('ignoreCustomThemes');

type BadgeSize = 's' | 'm';
export type BadgeColor =
  | 'primary'
  | 'gray-info'
  | 'gray-gray'
  | 'white'
  | 'success'
  | 'warning'
  | 'error'
  | 'accent1'
  | 'accent2'
  | 'accent3';

type Props = {
  color?: BadgeColor;
  size?: BadgeSize;
  uppercase?: boolean;
  title?: string;
  className?: string;
  allowColorOverride?: boolean;
};

export const Badge: React.FC<Props> = (props) => {
  const { palette } = useTheme();
  const colors = props.allowColorOverride ? palette : defaultColors;
  const { color = 'primary', size = 'm', uppercase, title, children } = props;

  const variantClasses = `${getSizeStyles(size)} ${uppercase ? 'uppercase' : ''}`;

  const badge = (
    <div
      className={`inline-flex items-center rounded-sm whitespace-nowrap truncate ${variantClasses} ${props.className}`}
      style={getColorStyles(colors, color)}
    >
      {children}
    </div>
  );

  if (title) {
    return <Tooltip title={title}>{badge}</Tooltip>;
  }
  return badge;
};

const getColorStyles = (colors: Palette, color: BadgeColor): CSSProperties => {
  switch (color) {
    case 'gray-info':
      return { color: colors.info.main, background: colors.gray[200] };
    case 'gray-gray':
      return { color: colors.gray['700'], background: colors.gray[200] };
    case 'white':
      return {
        color: colors.info.main,
        background: 'white',
        borderColor: colors.gray['300'],
        borderWidth: '1px',
      };
    case 'success':
      return { color: colors.success.dark, background: colors.success.extraLight };
    case 'warning':
      return { color: colors.warning.dark, background: colors.warning.extraLight };
    case 'error':
      return { color: colors.error.dark, background: colors.error.extraLight };
    case 'accent1':
      return { color: colors.accent1.dark, background: colors.accent1.extraLight };
    case 'accent2':
      return { color: colors.accent2.dark, background: colors.accent2.extraLight };
    case 'accent3':
      return { color: colors.accent3.dark, background: colors.accent3.extraLight };
    default:
      return { color: colors.primary.dark, background: colors.primary.extraLight };
  }
};

const getSizeStyles = (size: BadgeSize): string => {
  switch (size) {
    case 's':
      return 'py-1 px-4 text-xs font-normal';
    case 'm':
      return 'py-1 px-4 text-sm font-normal';
    default:
      return '';
  }
};
