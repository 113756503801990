/**
 * app.txs
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import { shouldPolyfill as shouldIntlLocalePolyfill } from '@formatjs/intl-locale/should-polyfill';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { TransactionContext } from '@sentry/types/dist/transaction';
import { ContentLoader } from 'components/Loader';
import configs from 'configs';
import { ConnectedRouter } from 'connected-react-router';
import App from 'containers/App/App';
// Import root app
import { BrowserNotSupported } from 'containers/App/components/BrowserNotSupported';
import { GlobalSagas } from 'containers/App/components/GlobalSagas';
// Import Language Provider
import { LanguageProvider } from 'containers/Preferences/Locale';
import { ThemeWrapper } from 'containers/Theme/ThemeWrapper';
import FontFaceObserver from 'fontfaceobserver';
import { createBrowserHistory } from 'history';
import { MobileProvider } from 'lib/contexts/MobileContext';
import { ViewportProvider } from 'lib/contexts/ViewportContext';
import { WalletProvider } from 'lib/hooks/useWallet';
import { register } from 'lib/service-worker';
// Import all the third party stuff
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import RenderInBrowser from 'react-render-in-browser';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'sanitize.css/sanitize.css';
// require fonts locally
import 'typeface-encode-sans-semi-expanded';
import 'typeface-mrs-saint-delafield';
import { initAnalytics } from 'utils/analytics';
// import GlobalStyle from 'global-styles';
import configureStore from './configureStore';
import './css/tailwind.css'; // eslint-disable-line import/no-unresolved
// Import i18n messages
import { translationMessages } from './i18n';

register();

function pathFixer(context: TransactionContext): TransactionContext {
  return {
    ...context,
    name:
      (context.op === 'navigation'
        ? 'NAV '
        : context.op === 'pageload'
        ? 'LOAD '
        : `${context.op.toLocaleUpperCase} `) +
      location.pathname
        .replace(/\/[A-F0-9]{40}/g, '/:address')
        .replace(/\/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/g, '/:id'),
  };
}

// Create redux store with history
const history = createBrowserHistory();

if (configs.integrations.sentryDNS) {
  Sentry.init({
    dsn: configs.integrations.sentryDNS,
    environment: configs.siteData.environment,
    release: `v${configs.siteData.appVersion}`,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
        tracingOrigins: ['localhost', `${configs.urls.apiHost}`],
        beforeNavigate: pathFixer,
      }),
      new Sentry.Integrations.UserAgent(),
      new Sentry.Integrations.Breadcrumbs({
        console: true,
        dom: true,
        fetch: false,
        history: false,
        sentry: false,
        xhr: true,
      }),
    ],
    ignoreErrors: [
      // https://stackoverflow.com/questions/63020810/what-is-best-way-in-javascript-to-stop-throwing-resizeobserver-loop-limit-excee
      'ResizeObserver loop limit exceeded',
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 6,
  });
  Sentry.setTags({
    component: 'webapp',
  });
}

// Observe loading of Encode Sans Semi Expanded (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const encodeSansObserver = new FontFaceObserver('Encode Sans Semi Expanded', {});

// When Encode Sans Semi Expanded is loaded, add a font-family using Encode Sans Semi Expanded to the body
encodeSansObserver.load().then(
  () => {
    document.body.classList.add('fontLoaded');
  },
  () => {
    document.body.classList.remove('fontLoaded');
  },
);

initAnalytics();

/* eslint-disable vars-on-top */
/* eslint-disable no-var */
var store = configureStore({}, history);
/* eslint-enable no-var */
/* eslint-enable vars-on-top */
const MOUNT_NODE = document.getElementById('app');

const render = (messages) => {
  ReactDOM.render(
    <>
      <RenderInBrowser ie except>
        <ViewportProvider>
          <MobileProvider>
            <Provider store={store}>
              <GlobalSagas>
                <ConnectedRouter history={history}>
                  <LanguageProvider messages={messages}>
                    <ThemeWrapper>
                      <Suspense fallback={<ContentLoader />}>
                        <WalletProvider>
                          <App />
                        </WalletProvider>
                      </Suspense>
                    </ThemeWrapper>
                  </LanguageProvider>
                </ConnectedRouter>
              </GlobalSagas>
            </Provider>
          </MobileProvider>
        </ViewportProvider>
      </RenderInBrowser>
      <RenderInBrowser ie only>
        <BrowserNotSupported />
      </RenderInBrowser>
    </>,
    MOUNT_NODE,
  );
};

if (module['hot']) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module['hot'].accept(['containers/App/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
new Promise((resolve) => {
  if (!window.Intl) return resolve(import('intl'));
  return resolve([]);
})
  .then(() => {
    if (!shouldIntlLocalePolyfill()) return Promise.resolve();
    return Promise.all([import('@formatjs/intl-locale/polyfill')]) as unknown;
  })
  .then(() => {
    if (window.Intl.PluralRules) return Promise.resolve();
    return Promise.all([
      import('@formatjs/intl-pluralrules/polyfill'),
      import('@formatjs/intl-pluralrules/locale-data/en'),
      import('@formatjs/intl-pluralrules/locale-data/es'),
    ]) as unknown;
  })
  .then(() => {
    // @ts-ignore
    if (window.Intl.RelativeTimeFormat) return Promise.resolve();
    return Promise.all([
      import('@formatjs/intl-relativetimeformat/polyfill'),
      import('@formatjs/intl-relativetimeformat/locale-data/en'),
      import('@formatjs/intl-relativetimeformat/locale-data/es'),
    ]) as unknown;
  })
  .then(() => {
    // @ts-ignore
    if (window.Intl.ListFormat) return Promise.resolve();
    return Promise.all([
      import('@formatjs/intl-listformat/polyfill'),
      import('@formatjs/intl-listformat/locale-data/en'),
      import('@formatjs/intl-listformat/locale-data/es'),
    ]) as unknown;
  })

  .then(() => render(translationMessages));
