import { defineMessages } from 'react-intl';

const scope = 'app.components.LazyLoader';

export const messages = defineMessages({
  loadFailure: {
    id: `${scope}.loadFailure`,
    defaultMessage: 'It looks like your version of our application is out of date. Please refresh your browser to load our latest version.',
  },
});
