import { CustomerTypeEnum } from '@monax/types';
import { ContentLoader } from 'components/Loader';
import { AdminPortalRoutes } from 'containers/AdminPortal/AdminPortalRoutes';
import AgreementRoutes from 'containers/Agreement/AgreementRoutes';
import TemplateRoutes from 'containers/Agreement/TemplateRoutes';
import AssetRoutes from 'containers/Asset/AssetRoutes';
import { IntegrationRoutes } from 'containers/Integration/IntegrationRoutes';
import {
  selectCurrentOrganization,
  selectCurrentOrganizationCustomerType,
} from 'containers/Organization/Current/state/selectors';
import { selectOrganizationSwitching } from 'containers/Organization/Switch/state/selectors';
import { OverviewRoutes } from 'containers/Overview/OverviewRoutes';
import { SettingsRoutes as SettingsRoutesV2 } from 'containers/Settings/SettingsRoutes';
import { TaskRoutes } from 'containers/Task/TaskRoutes';
import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { Route } from './Route';

export const SecuredRoutes: React.FC = () => {
  const currentOrganization = useSelector(selectCurrentOrganization);
  const switchingOrganization = useSelector(selectOrganizationSwitching);
  const customerType = useSelector(selectCurrentOrganizationCustomerType);
  const { loading } = useTheme();
  if (loading || switchingOrganization) {
    return <ContentLoader />;
  }
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          (currentOrganization?.createdAgreementCount || 0) > 0 ? (
            <Redirect to="/overview" />
          ) : (
            <Redirect to="/agreements" />
          )
        }
      />
      <Route path="/agreements" component={AgreementRoutes} />
      <Route path="/assets" component={AssetRoutes} />
      <Route path="/templates" component={TemplateRoutes} />
      <Route pageCategory="Overview" pageName="Overview" path="/overview" component={OverviewRoutes} />
      <Route path="/tasks" component={TaskRoutes} />
      <Route pageCategory="Settings" pageName="Settings" path="/settings" component={SettingsRoutesV2} />
      <Route path="/integrations" component={IntegrationRoutes} />
      {(customerType === CustomerTypeEnum.PLATFORM_ADMIN_CLM || customerType === CustomerTypeEnum.MANAGED_CLM) && (
        <Route path="/admin-portal" component={AdminPortalRoutes} />
      )}
      <Route pageCategory="Errors" pageName="404" render={() => <Redirect to="/404" />} />
    </Switch>
  );
};
