import { AgreementDraftBody, AgreementDraftDate, AgreementSystemDateEnum, ForeignAddressTypeEnum } from '@monax/types';
import { IntlShape } from 'react-intl';
import { v4 } from 'uuid';
import { messages } from './messages';
import {
  ForeignChainAccessEntity,
  FormDate,
  FormEventType,
  FormNotification,
  FormObligation,
  FormParameter,
  FormParty,
  FormRenewal,
  ObligationAction,
} from './types';

export const getInitialValues = (): AgreementDraftBody => {
  // return mockDraft;

  return {
    name: '',
    documents: [],
    dates: [],
    obligations: [],
    parameters: [],
    effectiveOnSigning: true,
    signatories: [],
  };
};

export const initialEffective: AgreementDraftDate & FormDate & { type: 'FIXED' } = {
  id: v4(),
  name: AgreementSystemDateEnum.AGREEMENT_EFFECTIVE_DATE,
  type: 'FIXED',
  data: {
    value: undefined,
  },
  cycle: null,
};
export const initialExpiration: AgreementDraftDate & FormDate & { type: 'FIXED' } = {
  id: v4(),
  name: AgreementSystemDateEnum.AGREEMENT_EXPIRATION_DATE,
  type: 'FIXED',
  data: {
    value: undefined,
  },
  cycle: null,
};
export const initialCancellation: AgreementDraftDate & FormDate & { type: 'FIXED' } = {
  id: v4(),
  name: AgreementSystemDateEnum.AGREEMENT_CANCELATION_DATE,
  type: 'FIXED',
  data: {
    value: undefined,
  },
  cycle: null,
};

export const initialDateCycle = (): FormDate['cycle'] => ({
  offset: 'never',
  until: [AgreementSystemDateEnum.AGREEMENT_CANCELATION_DATE, AgreementSystemDateEnum.AGREEMENT_EXPIRATION_DATE],
});

export const initialRenewal = (intl: IntlShape): FormRenewal => ({
  title: intl.formatMessage(messages.renewalObligationTitle),
  franchisees: [],
  openOffset: { amount: 30, unit: 'days', direction: 'BEFORE' },
  closeOffset: { amount: 0, unit: 'days', direction: 'BEFORE' },
  extensionOffset: { amount: 1, unit: 'years', direction: 'AFTER' },
});

export const initialAction = (): ObligationAction => ({
  key: v4(),
  type: undefined,
  eventPolicyId: '',
  recipients: [],
  message: '',
});

export const initialDate = (): FormDate => ({
  id: v4(),
  name: '',
  type: 'FIXED',
  data: {
    value: undefined,
  },
  cycle: initialDateCycle(),
});

export const initialNotification = (organizationAddress: string): FormNotification => ({
  type: FormEventType.NOTIFICATION,
  key: v4(),
  name: '',
  owner: organizationAddress,
  triggerDate: '',
  triggerOffset: {
    amount: 0,
    unit: 'days',
    direction: 'BEFORE',
  },
  actions: [initialAction()],
});

export const initialObligation = (): FormObligation => ({
  type: FormEventType.OBLIGATION,
  key: v4(),
  name: '',
  startDate: '',
  closeDate: '',
  attesters: [],
  actions: [initialAction()],
});

export const initialParty = (isIndividual: boolean, signatureRequired: boolean): FormParty => ({
  key: v4(),
  label: '',
  value: '',
  representative: {
    address: '',
    email: '',
  },
  signatureRequired,
  isIndividual,
});

export const initialTerm = (assignee = ''): FormParameter => ({
  key: v4(),
  type: undefined,
  name: '',
  title: '',
  assignee,
  optional: false,
  value: undefined,
  visibility: undefined,
  useTitle: false,
  useConstraints: false,
});

export const initialForeignChainApprovalFilter = (): ForeignChainAccessEntity => ({
  key: v4(),
  foreignChainId: undefined,
  foreignAddressType: ForeignAddressTypeEnum.TOKEN_CONTRACT_ADDRESS,
  foreignAddress: '',
});

export const repeatOptions = {
  never: true,
  PT1M: true,
  PT1H: true,
  P1D: true,
  P1W: true,
  P1M: true,
  P3M: true,
  P1Y: true,
};

export const DefaultPartyNames = {
  partyName1: 'Party 1',
  partyName2: 'Party 2',
};

// TODO- remove test values here
// const mockDraft: AgreementDraftBody = {
//   name: 'Test Template',
//   documents: [
//     {
//       grant:
//         'Cg4SDAoKdGVzdGluZy1pZBJz+EtaKhwnMoUKTp5n7PeXmBpMa33fvLeMy/nYd3pZSU4I1Ct3PxLyXau4JSdDfVM1kxREnWbqwF5+Jkpr3Q5MIkPzqSCs5xNj7FHp3K2FtQxh9160rY4u5jV+v6k5fn66yM5CBaPA/6PpHmROVkGJwwisgBgD',
//       parameters: ['Text Short'],
//     },
//   ],
//   signatories: [
//     {
//       label: 'Buyer',
//       value: '',
//       representative: {
//         address: '',
//       },
//       isIndividual: false,
//       signatureRequired: true,
//     },
//   ],
//   parameters: [
//     {
//       type: 11,
//       name: 'Text Short',
//       value: 'Text value!',
//       assignee: 'Buyer',
//     },
//   ],
//   effectiveOnSigning: true,
//   dates: [initialEffective],
//   obligations: [
//     {
//       type: 'NOTIFICATION',
//       config: {
//         title: 'Notify me!',
//         owner: '',
//         version: 'v1.1.0',
//         trigger: {
//           datetimeName: AgreementSystemDateEnum.AGREEMENT_EFFECTIVE_DATE,
//           datetimeOffset: 'P1Y',
//         },
//         events: [
//           {
//             when: 'onTrigger',
//             policies: [
//               {
//                 id: 'abc',
//                 category: 'messenger',
//                 message:
//                   'Hello! asd ;aoidj;a jid;asjida o;dijuqo;idhja; icjl;jsklasjd;al jd;adu0[q9ruao;idjASIL:jd ;ALdjal;d j;asld j;asldjal;djopw9duasoduduadupa89dua pddadasodasdao  8yu iodhah k adakldhakldh alodaopu op 8po 9',
//                 recipients: {
//                   templates: [],
//                   users: [],
//                   representatives: ['Buyer'],
//                   emails: ['hello@suscribo.com'],
//                 },
//               },
//             ],
//           },
//           {
//             when: 'onTrigger',
//             policies: [
//               {
//                 id: 'abc',
//                 category: 'messenger',
//                 message:
//                   'Hello! asd ;aoidj;a jid;asjida o;dijuqo;idhja; icjl;jsklasjd;al jd;adu0[q9ruao;idjASIL:jd ;ALdjal;d j;asld j;asldjal;djopw9duasoduduadupa89dua pddadasodasdao  8yu iodhah k adakldhakldh alodaopu op 8po 9',
//                 recipients: {
//                   templates: [],
//                   users: [],
//                   representatives: ['Buyer'],
//                   emails: ['hello@suscribo.com'],
//                 },
//               },
//             ],
//           },
//         ],
//       },
//     },
//     {
//       type: 'OBLIGATION',
//       config: {
//         title: 'Notice of Approval',
//         version: 'v1.1.0',
//         attesters: ['Buyer'],
//         triggers: {
//           begin: [
//             {
//               datetimeName: AgreementSystemDateEnum.AGREEMENT_EFFECTIVE_DATE,
//               datetimeOffset: 'P1M',
//             },
//           ],
//           complete: [
//             {
//               datetimeName: AgreementSystemDateEnum.AGREEMENT_EFFECTIVE_DATE,
//               datetimeOffset: 'P12M',
//             },
//           ],
//         },
//         events: [
//           {
//             when: 'onRatify',
//             policies: [
//               {
//                 id: 'abc',
//                 category: 'messenger',
//                 message:
//                   'Hello! asd ;aoidj;a jid;asjida o;dijuqo;idhja; icjl;jsklasjd;al jd;adu0[q9ruao;idjASIL:jd ;ALdjal;d j;asld j;asldjal;djopw9duasoduduadupa89dua pddadasodasdao  8yu iodhah k adakldhakldh alodaopu op 8po 9',
//                 recipients: {
//                   templates: [],
//                   users: [],
//                   representatives: ['Buyer'],
//                   emails: [],
//                 },
//               },
//             ],
//           },
//         ],
//       },
//     },
//   ],
// };
