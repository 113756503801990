/*
 *
 * Organization actions
 *
 */
import { Address, MemberOrganization } from '@monax/types';
import { RemoveMemberSuccess, UpdateOrganizationSettingsSuccess } from '../../Manage/state/actions';

export type LoadOrganization = ReturnType<typeof loadOrganization>;
export type LoadOrganizationSuccess = ReturnType<typeof loadOrganizationSuccess>;

export type OrganizationAction =
  | LoadOrganization
  | LoadOrganizationSuccess
  | UpdateOrganizationSettingsSuccess
  | RemoveMemberSuccess;

/**
 * LOAD_ORGANIZATION
 */
export function loadOrganization(address: Address) {
  return <const>{
    type: 'app/Organization/Current/LOAD_ORGANIZATION',
    address,
  };
}
export function loadOrganizationSuccess(organization: MemberOrganization) {
  return <const>{
    type: 'app/Organization/Current/LOAD_ORGANIZATION_SUCCESS',
    organization,
  };
}
