import { TextField } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';
import { get } from 'lodash';
import React, { InputHTMLAttributes } from 'react';
import { X } from 'react-bootstrap-icons';
import { removeAtIndex } from 'utils/immutable';
import { Badge as BadgeComponent } from '../Common';
import { FormItem } from './FormItem';
import { useInputState } from './Hooks';
import { ErrorTooltip } from './Tooltip';
import { InputConfig } from './types';

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'color'> & {
  name: string;
  label?: React.ReactNode;
  labelAddon?: React.ReactNode;
  infoText?: string;
  autoFocus?: boolean;
  endAdornment?: React.ReactNode;
  inputConfig?: InputConfig;
};

export const MultiValueInput: React.FC<Props> = (props) => {
  const [addInput, , addHelpers] = useField(`${props.name}_add`);

  const [input, meta, helpers] = useField<unknown[]>(props.name);

  const values = input.value || [];

  const { name, label, labelAddon, infoText, autoFocus, endAdornment, inputConfig, ...rest } = props;
  const inputState = useInputState(inputConfig, meta);

  const useErrorStyle = !!meta.error;
  return (
    <FormItem label={label} labelAddon={labelAddon} inputState={inputState} infoText={infoText}>
      <TextField
        {...addInput}
        {...rest}
        value={addInput.value || ''}
        id={addInput.name}
        error={useErrorStyle}
        size="small"
        variant="outlined"
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          const value = (e.target as HTMLInputElement).value;
          if (value && e.key === 'Enter' && !values.includes(value)) {
            helpers.setValue(values.concat([value]));
            addHelpers.setValue('');
          }
        }}
      />
      {values.length > 0 && (
        <div
          className={`w-full flex flex-wrap border-b border-l border-r rounded-b-md px-2 py-1 ${
            useErrorStyle ? 'border-error-main' : 'border-gray-400'
          }`}
          style={{ minHeight: '38px' }}
        >
          {values.map((value, i) => (
            <Badge key={`${value}_${i}`} name={name} index={i} />
          ))}
        </div>
      )}
    </FormItem>
  );
};

type BadgeProps = {
  name: string;
  index: number;
};

export const Badge: React.FC<BadgeProps> = ({ name, index }) => {
  const [input, meta] = useField(`${name}.${index}`);
  const { setFieldValue, values } = useFormikContext();
  const content = (
    <span className="flex items-center">
      <span>{input.value}</span>
      <button
        className="ml-1 font-semibold"
        type="button"
        onClick={() => setFieldValue(name, removeAtIndex(get(values, name), index))}
        style={{ color: 'inherit' }}
      >
        <X />
      </button>
    </span>
  );
  return (
    <BadgeComponent color={meta.error ? 'error' : 'primary'} className="mr-1">
      {meta.error ? <ErrorTooltip title={meta.error}>{content}</ErrorTooltip> : content}
    </BadgeComponent>
  );
};
