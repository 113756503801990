import { Address, Role, RoleEnum } from '@monax/types';
import produce from 'immer';
import { AdminPortalAction } from './state/actions';
import { AdminPortalState } from './types';

export const initialState: AdminPortalState = {
  user: null,
  users: null,
  organization: null,
  organizations: null,
};

const adminPortalReducer = (state = initialState, action: AdminPortalAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/AdminPortal/RECEIVE_USER':
        draftState.user = action.user;
        return draftState;

      case 'app/AdminPortal/RECEIVE_USER_ROLE':
        let userRoles: Role[] = draftState.user.profile.permissions[action.organizationAddress].roles.filter(
          (role: Role) => role === RoleEnum.SIGNING_AUTHORITY,
        );
        userRoles.push(action.role);
        draftState.user.profile.permissions[action.organizationAddress].roles = userRoles;
        return draftState;

      case 'app/AdminPortal/RECEIVE_REMOVED_MEMBERSHIP':
        delete draftState.user.organizations[action.organizationAddress];
        draftState.user.profile.organizations = draftState.user.profile.organizations.filter(
          (organization: Address) => organization !== action.organizationAddress,
        );
        return draftState;

      case 'app/AdminPortal/RECEIVE_USER_BLOCKED':
        draftState.user.profile.blocked = action.blocked;
        return draftState;

      case 'app/AdminPortal/RECEIVE_USERS':
        draftState.users = action.users;
        return draftState;

      case 'app/AdminPortal/RECEIVE_ORGANIZATIONS':
        draftState.organizations = action.organizations;
        return draftState;

      case 'app/AdminPortal/RECEIVE_ORGANIZATION':
        draftState.organization = action.organization;
        return draftState;

      case 'app/AdminPortal/RECEIVE_BILLING_NOTES':
        draftState.organization.organization.billingNotes = action.billingNotes;
        return draftState;

      case 'app/AdminPortal/RECEIVE_CUSTOMER_TYPE':
        draftState.organization.organization.customerType = action.customerType;
        draftState.organization.organization.isIndividual = action.isIndividual;
        return draftState;

      case 'app/AdminPortal/RECEIVE_ORGANIZATION_TRIAL_PERIOD':
        draftState.organization.organization.trialBegins = action.trialBegin;
        draftState.organization.organization.trialEnds = action.trialEnd;
        return draftState;

      case 'app/AdminPortal/RECEIVE_MEMBER_ROLE':
        let memberRoles: Role[] = draftState.organization.organization.rolesByUser[action.userAddress].filter(
          (role: Role) => role === RoleEnum.SIGNING_AUTHORITY,
        );
        memberRoles.push(action.role);
        draftState.organization.organization.rolesByUser[action.userAddress] = memberRoles;
        return draftState;

      case 'app/AdminPortal/RECEIVE_SIGNING_AUTHORITY':
        let roles: Role[] = draftState.organization.organization.rolesByUser[action.userAddress].filter(
          (role: Role) => role !== RoleEnum.SIGNING_AUTHORITY,
        );
        roles.push(RoleEnum.SIGNING_AUTHORITY);
        draftState.organization.organization.rolesByUser[action.userAddress] = roles;
        return draftState;

      case 'app/AdminPortal/RECEIVE_REMOVED_SIGNING_AUTHORITY':
        let oldRoles: Role[] = draftState.organization.organization.rolesByUser[action.userAddress].filter(
          (role: Role) => role !== RoleEnum.SIGNING_AUTHORITY,
        );
        draftState.organization.organization.rolesByUser[action.userAddress] = oldRoles;
        return draftState;

      case 'app/AdminPortal/RECEIVE_REMOVED_USER':
        delete draftState.organization.users[action.userAddress];
        draftState.organization.organization.users = draftState.organization.organization.users.filter(
          (user: Address) => user !== action.userAddress,
        );
        draftState.organization.organization.rolesByUser[action.userAddress]?.map((role: Role) => {
          draftState.organization.organization.usersByRole[role] = draftState.organization.organization.usersByRole[
            role
          ].filter((user: Address) => user !== action.userAddress);
        });
        delete draftState.organization.organization.rolesByUser[action.userAddress];
        return draftState;

      case 'app/AdminPortal/RECEIVE_ORGANIZATION_THROTTLING_LIMITS':
        draftState.organization.organization.throttledResourceLimits = action.throttledResourceLimits;
        return draftState;

      case 'app/AdminPortal/RECEIVE_ORGANIZATION_THROTTLING_DATE':
        draftState.organization.organization.throttlingDisabledUntil = action.throttlingDisabledUntil;
        return draftState;

      case 'app/AdminPortal/RECEIVE_SIGNING_BY_ALL':
        draftState.organization.organization.requireSigningByAll = action.requireSigningByAll;
        return draftState;
      default:
        return draftState;
    }
  });

export default adminPortalReducer;
