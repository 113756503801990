import { CapabilityEnum } from '@monax/types';
import { LazyLoader } from 'components/LazyLoader';
import { Layout } from 'containers/App/components/Layout';
import { Route } from 'containers/App/components/Route';
import { permissionsRequired } from 'containers/App/hocs';
import { throttleResources } from 'containers/Billing/hocs';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import { compose } from 'redux';

// 4.0 screens
const CreateDefault = compose(
  permissionsRequired([CapabilityEnum.TEMPLATE_CREATE]),
  throttleResources(['template']),
)(LazyLoader(() => import('./PreChain/Template/Forms/CreateDefault')));
const CreateForBlockchain = compose(
  permissionsRequired([CapabilityEnum.TEMPLATE_CREATE]),
  throttleResources(['template']),
)(LazyLoader(() => import('./PreChain/Template/Forms/CreateForBlockchain')));
const Edit = compose(permissionsRequired([CapabilityEnum.TEMPLATE_UPDATE]))(
  LazyLoader(() => import('./PreChain/Template/Forms/Edit')),
);
const TemplatesList = LazyLoader(() => import('./List/Template/TemplateList'));

const TemplateRoutes: React.FC = () => (
  <>
    <Helmet>
      <title>Templates</title>
    </Helmet>
    <Switch>
      <Route
        pageCategory="Templates"
        pageName="Create-For-Blockchain"
        path="/templates/create-for-blockchain"
        component={CreateForBlockchain}
      />
      <Route pageCategory="Templates" pageName="Create" path="/templates/create" component={CreateDefault} />
      <Route pageCategory="Templates" pageName="Edit" path="/templates/:templateId/edit" component={Edit} />
      <Route
        layout={Layout}
        pageCategory="Agreements"
        pageName="List"
        exact
        path="/templates"
        component={TemplatesList}
      />
    </Switch>
  </>
);

export default TemplateRoutes;
