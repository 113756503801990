import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { track as trackEvent, TrackProps } from 'utils/analytics';
import { Icon } from './Icon';
import { getClasses } from './style';
import { ButtonSize, ButtonType, TextTransform } from './types';

export type LinkButtonProps = {
  type?: ButtonType;
  size?: ButtonSize;
  textTransform?: TextTransform;
  icon?: React.ReactNode;
  iconEnd?: React.ReactNode;
  className?: string;
  track?: TrackProps;
  disableResponsiveness?: boolean;
};

export const LinkButton: React.FC<LinkButtonProps & LinkProps> = (props) => {
  const {
    type = 'primary',
    size = 'm',
    textTransform = 'normal-case',
    icon,
    iconEnd,
    className = '',
    track,
    disableResponsiveness,
    onClick,
    ...linkProps
  } = props;

  const classes =
    getClasses(type, size, className, disableResponsiveness, textTransform, !!icon) + ' inline-flex items-center';

  return (
    <Link
      className={classes}
      onClick={(e) => {
        onClick?.(e);
        if (track) trackEvent(track);
      }}
      {...linkProps}
    >
      <>
        {icon && <Icon icon={icon} size={size} position={'start'} />}
        <span>{props.children}</span>
        {iconEnd && <Icon icon={iconEnd} size={size} position={'end'} />}
      </>
    </Link>
  );
};
