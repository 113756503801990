import Popover from '@material-ui/core/Popover';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { ChevronDown } from 'react-bootstrap-icons';

type FilterProps = {
  popupId: string;
  onClick: (value: any) => void;
  inputValue: any;
  filterButtonTitle: string;
  filterButtonMessage: string;
  options: React.FC<{ onClick: (value: any) => void; inputValue; closeMenu: () => void }>;
};
export const ColumnFilter: React.FC<FilterProps> = ({
  popupId,
  options: Options,
  onClick,
  filterButtonTitle,
  filterButtonMessage,
  inputValue,
}) => {
  return (
    <PopupState variant="popover" popupId={popupId}>
      {(popupState) => (
        <React.Fragment>
          <div className="flex justify-center">
            <button
              {...bindTrigger(popupState)}
              className="select-filter-wrapper text-gray-700"
              type="button"
              title={filterButtonTitle}
            >
              {filterButtonMessage} <ChevronDown className="text-gray-700 text-lg" />
            </button>
          </div>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="options">
              <Options onClick={onClick} inputValue={inputValue} closeMenu={popupState.close} />
            </div>
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  );
};
