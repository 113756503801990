/*
 * ParameterType Messages
 *
 * This contains all the text for the ParameterType component.
 */

import { defineMessages } from 'react-intl';

export default defineMessages({
  parameterType0: {
    id: 'app.containers.ParameterType.parameterType0',
    defaultMessage: 'Yes/No',
  },
  parameterType0Description: {
    id: 'app.containers.ParameterType.parameterType0Description',
    defaultMessage: 'Generate a term with yes or no as an option.',
  },
  parameterType1: {
    id: 'app.containers.ParameterType.parameterType1',
    defaultMessage: 'Text (short)',
  },
  parameterType2: {
    id: 'app.containers.ParameterType.parameterType2',
    defaultMessage: 'Number',
  },
  parameterType3: {
    id: 'app.containers.ParameterType.parameterType3',
    defaultMessage: 'Date',
  },
  parameterType4: {
    id: 'app.containers.ParameterType.parameterType4',
    defaultMessage: 'Datetime',
  },
  parameterType5: {
    id: 'app.containers.ParameterType.parameterType5',
    defaultMessage: 'Number',
  },
  parameterType5Description: {
    id: 'app.containers.ParameterType.parameterType5Description',
    defaultMessage: 'Create a term with a min. or max. number.',
  },
  parameterType6: {
    id: 'app.containers.ParameterType.parameterType6',
    defaultMessage: 'User/Organization',
  },
  parameterType7: {
    id: 'app.containers.ParameterType.parameterType7',
    defaultMessage: 'Contract Address',
  },
  parameterType8: {
    id: 'app.containers.ParameterType.parameterType8',
    defaultMessage: 'Signing Party',
  },
  parameterType11: {
    id: 'app.containers.ParameterType.parameterType11',
    defaultMessage: 'Text',
  },
  parameterType11Description: {
    id: 'app.containers.ParameterType.parameterType11Description',
    defaultMessage: 'Set up a term with text as an input.',
  },
  parameterType12: {
    id: 'app.containers.ParameterType.parameterType12',
    defaultMessage: 'Number (positive)',
  },
  parameterType: {
    id: 'app.containers.ParameterType.parameterType',
    defaultMessage: 'Type',
  },
});
