import produce from 'immer';
import { AgreementSetupState } from '../types';
import { AgreementSetupAction } from './actions';

export const initialState: AgreementSetupState = {
  requesting: false,
};

const reducer = (state: AgreementSetupState = initialState, action: AgreementSetupAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Agreement_v4/PostChain/Setup/REQUESTING':
        draftState.requesting = action.requesting;
        return draftState;

      default:
        return draftState;
    }
  });

export default reducer;
