import { parse } from 'query-string';

export const parseQueryString = (queryString: string) => {
  const parsed = parse(queryString);
  const formatted: { [key: string]: string } = {};
  Object.keys(parsed).forEach((key) => {
    if (!key) return;
    const value = parsed[key];
    let stringValue = '';
    if (typeof value === 'string') {
      stringValue = value;
    } else if (Array.isArray(value)) {
      stringValue = value[0];
    }
    formatted[key] = stringValue;
  });
  return formatted;
};

export function formatQueryString(queryString: object): string {
  return Object.keys(queryString)
    .filter((k) => queryString[k])
    .map((k) => `${k}=${encodeURIComponent(queryString[k])}`)
    .join('&');
}

export function formatUrl(baseUrl: string, queryString: object): string {
  return `${baseUrl}?${formatQueryString(queryString)}`;
}
