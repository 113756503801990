import { useMemo } from 'react';

export function useListOverflow<T>(
  list: T[],
  maxCount: number,
  countOverflowAsItem: boolean = false,
): { result: T[]; overflow: T[] } {
  return useMemo(() => {
    // if there is an overflow, count the overflow as an item (do this by subtracing 1 from the maxCount)
    if (countOverflowAsItem) maxCount = list.length <= maxCount ? maxCount : maxCount - 1;

    const result = [];
    const overflow = [];
    list.forEach((item, i) => {
      if (i < maxCount) result.push(item);
      else overflow.push(item);
    });
    return { result, overflow };
  }, [list, maxCount]);
}
