import { AuthErrorCode } from '@monax/types';

export type ResetPassword = ReturnType<typeof resetPassword>;

export type ResetPasswordAction = ResetPassword;

export const resetPassword = (
  token: string,
  password: string,
  onSuccess: () => void,
  onError: (errorCode: AuthErrorCode, errorMessage: string) => void,
  onAlways: () => void,
) => {
  return <const>{
    type: 'app/Auth/RESET_PASSWORD/RESET_PASSWORD',
    token,
    password,
    onSuccess,
    onError,
    onAlways,
  };
};
