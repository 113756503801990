import { UserTask } from '@monax/types';

export type LoadTasks = ReturnType<typeof loadTasks>;
export type LoadTasksSuccess = ReturnType<typeof loadTasksSuccess>;

export type HomePageHeaderAction = LoadTasks | LoadTasksSuccess;

/**
 * LOAD_TASKS
 */
export function loadTasks() {
  return <const>{
    type: 'app/Task/List/LOAD_TASKS',
  };
}

export function loadTasksSuccess(tasks: UserTask[]) {
  return <const>{
    type: 'app/Task/List/LOAD_TASKS_SUCCESS',
    tasks,
  };
}
