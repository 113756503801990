import { ContentLoader } from 'components/Loader';
import { clearZapierAccessToken } from 'containers/Integration/Zapier/state/actions';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ApplicationState } from 'types';
import { formatUrl, parseQueryString } from 'utils/queryString';
import { clearAuthTokens } from '../Auth/state/actions';
import { selectWebAuth } from '../Auth/state/selectors';
import { LoginQueryParameters } from '../Login/types';
import { LogoutQueryParameters } from './types';

const LogoutComponent: React.FC<PropsFromState> = ({ webAuth }) => {
  const dispatch = useDispatch();
  const queryString: LogoutQueryParameters = parseQueryString(window.location.search);

  useEffect(() => {
    dispatch(clearAuthTokens());
    dispatch(clearZapierAccessToken());

    let redirect = `${window.location.origin}/login`;

    if (queryString.subdomain) {
      redirect = formatUrl(redirect, { subdomain: queryString.subdomain });
    }

    if (queryString.loginEmail) {
      const registerQueryString: LoginQueryParameters = {
        email: queryString.loginEmail,
        authProvider: queryString.loginAuthProvider,
      };
      redirect = formatUrl(redirect, registerQueryString);
    }

    webAuth.logout({
      returnTo: redirect,
    });
  }, []);
  return <ContentLoader />;
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
  webAuth: selectWebAuth(state),
});

export const Logout = connect(mapStateToProps)(LogoutComponent);
