/*
 * Dialog Messages
 *
 * This contains all the text for the Dialog component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'app.components.Dialog.cancel',
    defaultMessage: 'Cancel',
  },
});
