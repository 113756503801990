import { useEffect, useState } from 'react';

// Use this hook if you want an effect only on updates on not on the initial mount

export function useUpdateEffect(effect, dependencies = []) {
  const [isInitialMount, setIsInitialMount] = useState(true);
  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
    } else {
      effect();
    }
  }, dependencies);
}
