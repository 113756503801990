import { CapabilityEnum } from '@monax/types';
import { LazyLoader } from 'components/LazyLoader';
import { Route } from 'containers/App/components/Route';
import { permissionsRequired } from 'containers/App/hocs';
import { useBreakpoint } from 'lib/hooks/useBreakpoint';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import { compose } from 'redux';
import { AssetLayoutDesktop } from './AssetLayoutDesktop';
import { AssetLayoutMobile } from './AssetLayoutMobile';
import { AssetLayoutTablet } from './AssetLayoutTablet';

const AssetList = compose(permissionsRequired([CapabilityEnum.ASSET_READ]))(LazyLoader(() => import('./AssetList')));
const AssetRecordMobile = compose(permissionsRequired([CapabilityEnum.ASSET_READ]))(
  LazyLoader(() => import('./Record/AssetRecordMobile')),
);
const AssetRecordDesktop = compose(permissionsRequired([CapabilityEnum.ASSET_READ]))(
  LazyLoader(() => import('./Record/AssetRecordDesktop')),
);
const CreateAsset = compose(permissionsRequired([CapabilityEnum.ASSET_CREATE]))(
  LazyLoader(() => import('./Form/CreateAsset')),
);
const EditAsset = compose(permissionsRequired([CapabilityEnum.ASSET_UPDATE]))(
  LazyLoader(() => import('./Form/EditAsset')),
);

const AssetRoutes: React.FC = () => {
  const { isAtLeast } = useBreakpoint();

  let Layout = AssetLayoutMobile;
  if (isAtLeast('sm')) {
    Layout = AssetLayoutTablet;
  }
  if (isAtLeast('lg')) {
    Layout = AssetLayoutDesktop;
  }

  let AssetRecord = AssetRecordMobile;
  if (isAtLeast('sm')) {
    AssetRecord = AssetRecordDesktop;
  }

  return (
    <>
      <Helmet>
        <title>Assets</title>
      </Helmet>
      <Switch>
        <Route
          pageCategory="Assets"
          pageName="List"
          exact={true}
          path={'/assets'}
          component={AssetList}
          layout={Layout}
        />
        <Route
          pageCategory="Assets"
          exact={true}
          pageName="Create"
          path={'/assets/create'}
          component={CreateAsset}
          layout={Layout}
        />
        <Route
          pageCategory="Assets"
          pageName="Record"
          path={'/assets/:assetId/record'}
          component={AssetRecord}
          layout={Layout}
        />
        <Route
          pageCategory="Assets"
          pageName="Edit"
          path={'/assets/:assetId/edit'}
          component={EditAsset}
          layout={Layout}
        />
      </Switch>
    </>
  );
};

export default AssetRoutes;
