import { BaseTextFieldProps, TextField } from '@material-ui/core';
import { useField } from 'formik';
import React, { ChangeEventHandler, CSSProperties, TextareaHTMLAttributes } from 'react';
import { InputEndAdornment } from './common';
import { FormItem } from './FormItem';
import { useInputState } from './Hooks';
import { InputConfig } from './types';

type Props = Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, 'rows' | 'placeholder'> & {
  name: string; // make name required
  label?: string;
  labelAddon?: React.ReactNode;
  inputConfig?: InputConfig;
  disabled?: boolean;
  rowsMax?: number;
  rows?: number;
};

export const TextArea: React.FC<Props> = (props) => {
  const [input, meta] = useField(props);

  const { label, labelAddon, rowsMax, inputConfig, ...rest } = props;

  const inputState = useInputState(inputConfig, meta);

  return (
    <FormItem label={label} labelAddon={labelAddon} inputState={inputState}>
      <TextField
        {...input}
        {...rest}
        rowsMax={rowsMax || 3}
        id={input.name}
        multiline={true}
        error={inputState.showError}
        size="small"
        variant="outlined"
        InputProps={{
          endAdornment: <InputEndAdornment inputState={inputState} />,
        }}
      />
    </FormItem>
  );
};

type TextAreaControlProps = Omit<Props, 'labelAddon' | 'inputConfig' | 'name'> & {
  name?: string;
  value: unknown;
  onChange: ChangeEventHandler<HTMLInputElement>;
  style?: CSSProperties;
} & BaseTextFieldProps;

export const TextAreaControl: React.FC<TextAreaControlProps> = ({ ...input }) => {
  return (
    <TextField
      {...input}
      multiline={true}
      id={input.name}
      rowsMax={input.rowsMax || 3}
      rows={input.rows}
      size="small"
      variant="outlined"
    />
  );
};
