import { clearNotification } from 'containers/App/state/actions';
import graphic from 'images/error/404.svg';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Action, ErrorLayout } from './controls';
import { messages } from './messages';

export const NotFoundError: React.FC = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearNotification());
  }, []);

  return (
    <ErrorLayout
      graphic={graphic}
      title={intl.formatMessage(messages.notFoundHeader)}
      description={intl.formatMessage(messages.notFoundDescription)}
      actions={<Action href="/">{intl.formatMessage(messages.backToHome)}</Action>}
    />
  );
};
