import { useEffect, useRef } from 'react';

export const useOnceEffect = (shouldCall: boolean, callback: () => void, deps: unknown[]) => {
  const calledOnce = useRef<boolean>(false);
  useEffect(() => {
    if (!calledOnce.current && shouldCall) {
      callback();
      calledOnce.current = true;
    }
  }, deps);
};
