import produce from 'immer';
import { BeaconOperatorState } from '../types';
import { BeaconOperatorsAction } from './actions';

export const initialState: BeaconOperatorState = {
  operators: [],
  requesting: false,
};

const reducer = (state: BeaconOperatorState = initialState, action: BeaconOperatorsAction) =>
  produce(
    state,
    (draftState): BeaconOperatorState => {
      switch (action.type) {
        case 'app/Organization/Beacon/Operators/LOAD_BEACON_OPERATORS':
          draftState.requesting = true;
          return draftState;
        case 'app/Organization/Beacon/Operators/LOAD_BEACON_OPERATORS_SUCCESS':
          draftState.operators = action.operators;
          draftState.requesting = false;
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;
