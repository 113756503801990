import { ResponsiveGrid } from 'components/Common';
import { Layout } from 'components/Common/Responsive/types';
import React, { ReactNode } from 'react';

type Props = {
  columns?: 1 | 2 | 3;
  className?: string;
  children: ReactNode;
};

export const FormLayout: React.FC<Props> = ({ columns = 1, className = '', children }) => {
  let layout: Layout = '1';
  if (columns === 2) layout = '1-1';
  if (columns === 3) layout = '1-1-1';
  return (
    <ResponsiveGrid layout="1" mdLayout={layout} gapX="lg" className={className}>
      {children}
    </ResponsiveGrid>
  );
};
