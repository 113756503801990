import { getPersistStorage } from 'configs';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import authReducer from './Auth/state/reducer';
import { AuthState } from './Auth/types';
import { RootAuthState } from './types';

const authConfig: PersistConfig<AuthState> = {
  key: 'auth-auth',
  storage: getPersistStorage(),
  whitelist: ['token', 'idTokenPayload', 'authHistory'],
};

const reducer = combineReducers<RootAuthState>({
  auth: persistReducer(authConfig, authReducer),
});

export default reducer;
