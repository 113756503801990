/*
 * App Messages
 *
 * This contains all the text for the App component.
 */
import { defineMessages } from 'react-intl';

const scope = 'app.containers.App';

export default defineMessages({
  sessionExtended: {
    id: `${scope}.sessionExtended`,
    defaultMessage: 'Your session has been extended',
    description: 'snackbar notification',
  },
  errorExtendingSession: {
    id: `${scope}.errorExtendingSession`,
    defaultMessage: 'There was an error extending your session',
    description: 'snackbar notification',
  },
  extendSession: {
    id: `${scope}.extendSession`,
    defaultMessage: 'Extend Session',
    description: 'Submit button for extend session dialog',
  },
  sessionIsExpiring: {
    id: `${scope}.sessionIsExpiring`,
    defaultMessage: 'Your Session is Expiring',
    description: 'Title for extend session dialog',
  },
  pleaseExtendSessionOrSaveWork: {
    id: `${scope}.pleaseExtendSessionOrSaveWork`,
    defaultMessage:
      'Your session is expiring in 10 minutes. Please extend your session to continue working on our platform. Otherwise please make sure to save any unfinished work you have open.',
    description: 'Title for extend session dialog',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Log Out',
    description: 'Log out button',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
    description: 'Submit button',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
    description: 'Confirm button',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
    description: 'Cancel button',
  },
  agree: {
    id: `${scope}.agree`,
    defaultMessage: 'Agree',
    description: 'Agree button',
  },
  errorLoadingResource: {
    id: `${scope}.errorLoadingResource`,
    defaultMessage: "Sorry, we couldn't load your {resource}. Please try again later.",
    description: 'Loading error message.',
  },
  clickHereToLearnMore: {
    id: `${scope}.clickHereToLearnMore`,
    defaultMessage: 'Click here to learn more.',
    description: 'Link text to knowledge base',
  },
  agreement: {
    id: `${scope}.agreement`,
    defaultMessage: '{count, plural, one {agreement} other {agreements}}',
  },
  template: {
    id: `${scope}.template`,
    defaultMessage: '{count, plural, one {template} other {templates}}',
  },
  draft: {
    id: `${scope}.draft`,
    defaultMessage: '{count, plural, one {draft} other {drafts}}',
  },
  archetype: {
    id: `${scope}.archetype`,
    defaultMessage: '{count, plural, one {archetype} other {archetypes}}',
  },
  task: {
    id: `${scope}.task`,
    defaultMessage: '{count, plural, one {task} other {tasks}}',
  },
  organization: {
    id: `${scope}.organization`,
    defaultMessage: '{count, plural, one {organization} other {organizations}}',
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: '{count, plural, one {user} other {users}}',
  },
  ownedBy: {
    id: `${scope}.ownedBy`,
    defaultMessage: 'Owned by',
  },
  createdBy: {
    id: `${scope}.createdBy`,
    defaultMessage: 'Created by',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
    description: 'Close button',
  },
  neverMind: {
    id: `${scope}.neverMind`,
    defaultMessage: 'Never Mind',
    description: 'Cancel out of action',
  },
  or: {
    id: `${scope}.or`,
    defaultMessage: 'or',
    description: 'ex, x OR y',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  clickHere: {
    id: `${scope}.clickHere`,
    defaultMessage: 'Click here',
    description: 'Link text, (ex Click here to learn more about xyz',
  },
  pleaseFillOutRequiredFields: {
    id: `${scope}.pleaseFillOutRequiredFields`,
    defaultMessage: 'Please fill out all required fields',
    description: 'snackbar notification on form submission',
  },
  noneSpecified: {
    id: `${scope}.noneSpecified`,
    defaultMessage: 'None specified',
    description: 'Placeholder for empty value',
  },
  quit: {
    id: `${scope}.quit`,
    defaultMessage: 'Quit',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  actionCannotBeUndone: {
    id: `${scope}.actionCannotBeUndone`,
    defaultMessage: 'This action cannot be undone.',
  },
  you: {
    id: `${scope}.you`,
    defaultMessage: 'you',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  saveChanges: {
    id: `${scope}.saveChanges`,
    defaultMessage: 'Save Changes',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  complete: {
    id: `${scope}.complete`,
    defaultMessage: 'Complete',
  },
  finish: {
    id: `${scope}.finish`,
    defaultMessage: 'Finish',
  },
  termsOfService: {
    id: `${scope}.termsOfService`,
    defaultMessage: 'Terms of Service',
  },
  homepage: {
    id: `${scope}.homepage`,
    defaultMessage: 'Homepage',
  },
  emailAddress: {
    id: `${scope}.emailAddress`,
    defaultMessage: 'Email Address',
  },
  team: {
    id: `${scope}.team`,
    defaultMessage: 'Team',
  },
  years: {
    id: `${scope}.years`,
    defaultMessage: '{count, plural, one {year} other {years}}',
  },

  quarters: {
    id: `${scope}.quarters`,
    defaultMessage: '{count, plural, one {quarter} other {quarters}}',
  },

  months: {
    id: `${scope}.months`,
    defaultMessage: '{count, plural, one {month} other {months}}',
  },
  weeks: {
    id: `${scope}.weeks`,
    defaultMessage: '{count, plural, one {week} other {weeks}}',
  },
  days: {
    id: `${scope}.days`,
    defaultMessage: '{count, plural, one {day} other {days}}',
  },
  hours: {
    id: `${scope}.hours`,
    defaultMessage: '{count, plural, one {hour} other {hours}}',
  },
  minutes: {
    id: `${scope}.minutes`,
    defaultMessage: '{count, plural, one {minute} other {minutes}}',
  },
  seconds: {
    id: `${scope}.seconds`,
    defaultMessage: '{count, plural, one {second} other {seconds}}',
  },
  numberOf_years: {
    id: `${scope}.numberOf_years`,
    defaultMessage: '{count} {count, plural, one {year} other {years}}',
  },
  numberOf_months: {
    id: `${scope}.numberOf_months`,
    defaultMessage: '{count} {count, plural, one {month} other {months}}',
  },
  numberOf_weeks: {
    id: `${scope}.numberOf_weeks`,
    defaultMessage: '{count} {count, plural, one {week} other {weeks}}',
  },
  numberOf_days: {
    id: `${scope}.numberOf_days`,
    defaultMessage: '{count} {count, plural, one {day} other {days}}',
  },
  numberOf_hours: {
    id: `${scope}.numberOf_hours`,
    defaultMessage: '{count} {count, plural, one {hour} other {hours}}',
  },
  numberOf_minutes: {
    id: `${scope}.numberOf_minutes`,
    defaultMessage: '{count} {count, plural, one {minute} other {minutes}}',
  },
  numberOf_seconds: {
    id: `${scope}.numberOf_seconds`,
    defaultMessage: '{count} {count, plural, one {second} other {seconds}}',
  },
  before: {
    id: `${scope}.before`,
    defaultMessage: 'before',
  },
  after: {
    id: `${scope}.after`,
    defaultMessage: 'after',
  },
  repeatingOffset: {
    id: `${scope}.repeatingOffset`,
    defaultMessage: 'Every {offset}',
  },
  emptyDateValue: {
    id: `${scope}.emptyDateValue`,
    defaultMessage: 'No date set',
  },
  true: {
    id: `${scope}.true`,
    defaultMessage: 'True',
  },
  false: {
    id: `${scope}.false`,
    defaultMessage: 'False',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  review: {
    id: `${scope}.review`,
    defaultMessage: 'Review',
  },
  setup: {
    id: `${scope}.setup`,
    defaultMessage: 'Setup',
  },
  noThanks: {
    id: `${scope}.noThanks`,
    defaultMessage: 'No, Thanks',
  },
  unexpectedError: {
    id: `${scope}.unexpectedError`,
    defaultMessage: 'An unexpected error occurred',
  },
  goodMorningUser: {
    id: `${scope}.goodMorningUser`,
    defaultMessage: 'Good morning, {userName}',
  },
  goodAfternoonUser: {
    id: `${scope}.goodAfternoonUser`,
    defaultMessage: 'Good afternoon, {userName}',
  },
  goodEveningUser: {
    id: `${scope}.goodEveningUser`,
    defaultMessage: 'Good evening, {userName}',
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'OK',
  },
  choose: {
    id: `${scope}.choose`,
    defaultMessage: 'Choose',
  },
  numOfNum: {
    id: `${scope}.numOfNum`,
    defaultMessage: '{num1} of {num2}',
  },
  duplicate: {
    id: `${scope}.duplicate`,
    defaultMessage: 'Duplicate',
  },
  exit: {
    id: `${scope}.exit`,
    defaultMessage: 'Exit',
  },
  returnHome: {
    id: `${scope}.returnHome`,
    defaultMessage: 'Want to return home?',
  },
  accountNotFound: {
    id: `${scope}.accountNotFound`,
    defaultMessage: 'Account not found',
  },
  hide: {
    id: `${scope}.hide`,
    defaultMessage: 'Hide',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Reset',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Create',
  },
});
