import React, { useEffect } from 'react';

export const ViewportContext = React.createContext<{ width: number; height: number }>({ width: 0, height: 0 });

export const ViewportProvider: React.FC = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('orientationchange', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('orientationchange', handleWindowResize);
    };
  }, []);
  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>;
};
