import { parseAddress, safeParseAddress } from '@monax/aeger/dist/address';
import { getAgreementListRoute, getAgreementRoute } from '@monax/aeger/dist/webapp-routes';
import { Address, RegisterQueryParameters } from '@monax/types';
import { push } from 'connected-react-router';
import { showSnackbarErrorFormattedMessage, showSnackbarSuccessFormattedMessage } from 'containers/App/state/saga';
import { selectAPIClient } from 'containers/App/state/selectors';
import messages from 'containers/Auth/messages';
import { selectCreatedAgreementCount } from 'containers/Organization/Current/state/selectors';
import { loadCurrentUser } from 'containers/User/Current/Profile/state/saga';
import { selectCurrentUserAddress, selectInvitedAgreement } from 'containers/User/Current/Profile/state/selectors';
import { StatusCodes } from 'http-status-codes';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { track, TrackProps } from 'utils/analytics';
import { HandleLoginSuccess, SendEmailVerification } from './actions';
import { selectIDTokenFromAuth, selectInvitationCodeFromIDToken, selectIsUsingSocialAuth } from './selectors';

export function* sendEmailVerification({ email }: SendEmailVerification) {
  try {
    const client = yield* select(selectAPIClient);
    yield* call(client.sendEmailVerification, { email });
    yield showSnackbarSuccessFormattedMessage(messages.emailSentSucessfully);
  } catch {
    yield showSnackbarErrorFormattedMessage(messages.errorSendingEmail);
  }
}

export function calculateLandingPage(
  state: RegisterQueryParameters,
  isUsingSocialAuth: boolean,
  isFirstLogin: boolean,
  invitedAgreement: Address | undefined,
  createdAgreementCount: number,
): string {
  // TODO: revisit first touch onboarding as part of agreement setup
  let landingPage: string;
  switch (true) {
    case state.redirectURL && state.redirectURL !== '':
      landingPage = state.redirectURL;
      break;
    // case isFirstLogin && !isUsingSocialAuth && !invitedAgreement:
    //   landingPage = onboarding;
    //   break;
    case createdAgreementCount > 3:
      landingPage = '/overview';
      break;
    case createdAgreementCount > 0:
      landingPage = getAgreementListRoute();
      break;
    case invitedAgreement && invitedAgreement !== '':
      landingPage = getAgreementRoute(invitedAgreement); // if there's nothing to setup this will redirect to record
      break;
    case state.agreement && state.agreement !== '':
      landingPage = getAgreementRoute(parseAddress(state.agreement)); // if there's nothing to setup this will redirect to record
      break;
    default:
      landingPage = '/';
  }
  return landingPage;
}

export function* handleLoginSuccess({ state }: HandleLoginSuccess) {
  try {
    const client = yield* select(selectAPIClient);
    const { invitationCode } = state;
    const invitationCodeFromIDToken = yield* select(selectInvitationCodeFromIDToken);

    const code = invitationCode || invitationCodeFromIDToken;
    if (code) {
      console.log('user appears to have an invitation; attempting to accept');
      try {
        yield* call(client.acceptUserInvitation, code);
      } catch (error) {
        if (String(error.message).includes(StatusCodes.NOT_FOUND.toString()))
          console.log('invitation code not found (may be a retry); attempting to load profile');
        else throw new Error('');
      }
    }

    yield* loadCurrentUser();

    const currentUser = yield* select(selectCurrentUserAddress);
    if (!currentUser) return;

    const idToken = yield* select(selectIDTokenFromAuth);
    const isUsingSocialAuth = yield* select(selectIsUsingSocialAuth);

    const trackProps: TrackProps = { event: 'application.login', payload: {} };
    if (state.counterparty) {
      trackProps.payload.invitedBy = 'counterparty';
    } else if (state.invitationCode) {
      trackProps.payload.invitedBy = 'team-member';
    }

    track(trackProps);

    const invitedAgreement = yield* select(selectInvitedAgreement);
    const createdAgreementCount = yield* select(selectCreatedAgreementCount);

    const isFirstLogin = idToken['https://login.suscribo.com/first_login'];

    yield* put(
      push(
        calculateLandingPage(
          state,
          isUsingSocialAuth,
          isFirstLogin,
          safeParseAddress(invitedAgreement),
          createdAgreementCount,
        ),
      ),
    );
  } catch (err) {
    console.error(err);
    yield showSnackbarErrorFormattedMessage(messages.invitationError);
  }
}

export function* watchSendEmailVerification() {
  yield takeLatest<SendEmailVerification>('app/Auth/SEND_VERIFICATION_EMAIL', sendEmailVerification);
}

export function* watchHandleLoginSuccess() {
  yield takeLatest<HandleLoginSuccess>('app/Auth/HANDLE_LOGIN_SUCCESS', handleLoginSuccess);
}
