import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { put } from 'typed-redux-saga';
import messages from '../messages';
import { addFormattedNotification, addNotification } from './actions';

/**
 * Helper Sagas
 */

export function* showSnackbarErrorMessage(message: React.ReactNode = '') {
  yield put(
    addNotification({
      className: 'error',
      message,
    }),
  );
}

export function* showSnackbarErrorFormattedMessage(
  messageDescriptor: MessageDescriptor,
  messageValues?: { [parameter: string]: string },
) {
  yield put(
    addFormattedNotification({
      className: 'error',
      messageDescriptor,
      messageValues,
    }),
  );
}

export function* showSnackbarSuccessMessage(message) {
  yield put(
    addNotification({
      message,
    }),
  );
}

export function* showSnackbarSuccessFormattedMessage(
  messageDescriptor: MessageDescriptor,
  messageValues?: { [parameter: string]: string },
) {
  yield put(
    addFormattedNotification({
      messageDescriptor,
      messageValues,
    }),
  );
}

export function* showSnackbarWarningMessage(message) {
  yield put(
    addNotification({
      className: 'warning',
      message,
    }),
  );
}

export function* showSnackbarWarningFormattedMessage(
  messageDescriptor: MessageDescriptor,
  messageValues?: { [parameter: string]: string },
) {
  yield put(
    addFormattedNotification({
      className: 'warning',
      messageDescriptor,
      messageValues,
    }),
  );
}

/**
 * Worker Sagas
 */
export function* displaySubmitError() {
  yield showSnackbarErrorMessage(<FormattedMessage {...messages.pleaseFillOutRequiredFields} />);
}
