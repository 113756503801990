import {
  OrganizationAddress,
  removeUserFromOrganizationByUserErrorResponse,
  RoleEnum,
  UserProfile,
} from '@monax/types';
import { setUser } from '@sentry/react';
import { getSupportedLocale } from 'configs';
import { push } from 'connected-react-router';
import { addFormattedNotification } from 'containers/App/state/actions';
import {
  showSnackbarErrorFormattedMessage,
  showSnackbarErrorMessage,
  showSnackbarSuccessFormattedMessage,
} from 'containers/App/state/saga';
import { selectAPIClient } from 'containers/App/state/selectors';
import { clearAuthTokens } from 'containers/Auth/Auth/state/actions';
import { selectIDTokenFromAuth, selectWebAuth } from 'containers/Auth/Auth/state/selectors';
import { loadOrganization } from 'containers/Organization/Current/state/saga';
import { selectCurrentOrganizationAddress } from 'containers/Organization/Current/state/selectors';
import { loadOrganizations } from 'containers/Organization/List/state/actions';
import { loadOrganizationResourceLimits } from 'containers/Organization/ResourceBalance/state/actions';
import { changeLocale } from 'containers/Preferences/Locale/state/actions';
import { selectLocale } from 'containers/Preferences/Locale/state/selectors';
import { loadUsers, loadUsersSuccess } from 'containers/User/List/actions';
import { call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { UserTraits } from 'utils/analytics';
import { identify } from 'utils/analytics/helpers';
import { messages } from '../messages';
import { CurrentUser } from '../types';
import {
  ChangePassword,
  clearCurrentUser,
  loadCurrentUserSuccess,
  receiveError,
  RemoveUserFromOrganization,
  removeUserFromOrganizationSuccess,
  UpdateEmail,
  updateEmailSuccess,
  UpdateProfile,
  updateProfileSuccess,
  UpdateRole,
  updateRoleSuccess,
  UpdateSignature,
  updateSignatureSuccess,
} from './actions';
import {
  CHANGE_PASSWORD,
  LOAD_CURRENT_USER,
  REMOVE_USER_FROM_ORGANIZATION,
  UPDATE_EMAIL,
  UPDATE_PROFILE,
  UPDATE_ROLE,
  UPDATE_SIGNATURE,
} from './constants';
import { selectCurrentUserUpdate } from './selectors';

/**
 * Worker Sagas
 */

export function* loadCurrentUser() {
  try {
    const client = yield* select(selectAPIClient);
    const idToken = yield* select(selectIDTokenFromAuth);
    const locale = yield* select(selectLocale);
    let currentOrganizationAddress = yield* select(selectCurrentOrganizationAddress);

    const {
      data: { profile, organizations },
    } = yield* call(client.getUserProfile);

    const user: CurrentUser = profile;

    const traits: UserTraits = {};
    if (profile.organizations.length) {
      currentOrganizationAddress = getCurrentOrganization(currentOrganizationAddress, profile);

      yield* loadOrganization({
        type: 'app/Organization/Current/LOAD_ORGANIZATION',
        address: currentOrganizationAddress,
      });
      yield* put(loadOrganizationResourceLimits(currentOrganizationAddress));
      yield* put(loadUsersSuccess([profile]));
      yield* put(loadOrganizations());
      traits.singleUserOrganization = false;
      if (organizations[currentOrganizationAddress]?.isIndividual) {
        traits.singleUserOrganization = true;
      }
      traits.firstLogin = idToken['https://login.suscribo.com/first_login'];
      traits.isAdmin = false;
      if (user[currentOrganizationAddress]?.roles.includes(RoleEnum.ACCOUNT_ADMIN)) {
        traits.isAdmin = true;
      }
    }

    yield* call(identify, user.userid, traits);

    setUser({
      username: user.username,
      userid: user.userid,
      address: user.address,
      organizations: user.organizations,
      permissions: user.permissions,
    });

    if (user.locale && user.locale != locale) {
      yield put(changeLocale(getSupportedLocale(user.locale)));
    } else if (!user.locale) {
      // Make sure locale gets saved server-side
      user.locale = locale;
      yield* call(client.updateUserProfile, user);
    }
    yield put(loadCurrentUserSuccess(user));
  } catch (err) {
    console.error(err);
    yield put(clearCurrentUser());
    yield put(clearAuthTokens());
    yield showSnackbarErrorFormattedMessage(messages.failedToLoadUserProfile);
  }
}

const getCurrentOrganization = (
  currentOrganizationAddress: OrganizationAddress,
  user: UserProfile,
): OrganizationAddress => {
  // Use the 'stored' current organization, but make sure it exists in the users organizations
  const address = user.organizations.find((address) => currentOrganizationAddress === address);

  if (address) return address;

  // If there is no match then default to the first user organization
  return user.organizations[0];
};

export function* changePassword({ oldPassword, newPassword, onSuccess, onError, onAlways }: ChangePassword) {
  try {
    const client = yield* select(selectAPIClient);
    const { data: result } = yield* call(client.changePassword, { oldPassword, newPassword });

    if (result.success) {
      yield* showSnackbarSuccessFormattedMessage(messages.passwordUpdated);
      onSuccess();
    } else {
      onError(result.errorCode, result.errorMessage);
    }
  } catch {
    yield* showSnackbarErrorFormattedMessage(messages.errorChangingPassword);
  }
  onAlways();
}

export function* updateEmail({ email, onSuccess }: UpdateEmail) {
  const client = yield* select(selectAPIClient);

  try {
    yield* call(client.updateUserEmail, { email });
    onSuccess?.();
    yield* put(updateEmailSuccess());
    const returnTo = `${window.location.origin}/login?redirectURL=${window.encodeURIComponent(
      window.location.pathname,
    )}`;
    const webAuth = yield* select(selectWebAuth);
    webAuth.logout({ returnTo });
  } catch (err) {
    yield* put(receiveError(UPDATE_EMAIL, err));
    yield* showSnackbarErrorMessage();
  }
}

export function* updateProfile({ user, redirectUrl, showSuccessNotification = true, onSuccess }: UpdateProfile) {
  const client = yield* select(selectAPIClient);

  try {
    yield* call(client.updateUserProfile, user);

    yield* loadCurrentUser();
    yield* put(loadUsers());

    onSuccess?.();

    yield* put(updateProfileSuccess());

    if (showSuccessNotification) yield* showSnackbarSuccessFormattedMessage(messages.profileSaved);
    if (redirectUrl) yield* put(push(redirectUrl));
  } catch (err) {
    yield* put(receiveError(UPDATE_PROFILE, err));
    yield* showSnackbarErrorMessage();
  }
}

export function* removeUserFromOrganization({ organizationAddress, onSuccess }: RemoveUserFromOrganization) {
  const client = yield* select(selectAPIClient);

  try {
    yield* call(client.removeUserFromOrganizationByUser, organizationAddress);

    yield* loadCurrentUser();
    yield* put(loadOrganizations());

    onSuccess?.();

    yield* put(removeUserFromOrganizationSuccess());
  } catch (err) {
    yield* put(receiveError(REMOVE_USER_FROM_ORGANIZATION, err));
    const response: removeUserFromOrganizationByUserErrorResponse = err.response?.data;
    switch (response?.errorCode) {
      case 'admin_no_opt_out_of_organization':
        yield* put(
          addFormattedNotification({
            messageDescriptor: messages.adminNoOptOutOfOrganization,
            className: 'error',
            autoHideDuration: 6000,
          }),
        );
        break;
      default:
        yield* showSnackbarErrorMessage();
    }
  }
}

export function* updateRole({ userAddress, oldRole, newRole, onSuccess }: UpdateRole) {
  try {
    const client = yield* select(selectAPIClient);
    const organizationAddress = yield* select(selectCurrentOrganizationAddress);
    if (newRole) {
      yield* call(client.assignRoleToUser, organizationAddress, userAddress, newRole);
    }
    if (oldRole) {
      yield* call(client.removeRoleFromUser, organizationAddress, userAddress, oldRole);
    }
    yield* put(updateRoleSuccess());
    if (onSuccess) onSuccess();
  } catch (err) {
    yield* put(receiveError(UPDATE_ROLE, err));
  }
}

export function* updateSignature({ signature, onSuccess }: UpdateSignature) {
  const client = yield* select(selectAPIClient);
  let grant: string = null;
  if (signature) {
    const file = new File([signature], 'signature.png', { type: 'image/png' });
    const upload = yield* call(client.createDocument, false, undefined, undefined, file);
    grant = upload.data.grant;
  }
  const profile = yield* select(selectCurrentUserUpdate);
  yield* call(client.updateUserProfile, {
    ...profile,
    signatureGrant: grant,
  });
  yield* showSnackbarSuccessFormattedMessage(messages.signatureUpdateSuccess);
  yield* put(updateSignatureSuccess(grant));
  onSuccess?.();
}

/**
 * Watcher Sagas
 */

export function* watchLoadCurrentUser() {
  yield takeEvery(LOAD_CURRENT_USER, loadCurrentUser);
}

export function* watchEditAuthPassword() {
  yield* takeLatest(CHANGE_PASSWORD, changePassword);
}

export function* watchEditProfile() {
  yield* takeLatest(UPDATE_PROFILE, updateProfile);
  yield* takeLatest(UPDATE_EMAIL, updateEmail);
}

export function* watchUpdateSignature() {
  yield* takeLatest(UPDATE_SIGNATURE, updateSignature);
}

export function* watchRemoveUserFromOrganization() {
  yield* takeLatest(REMOVE_USER_FROM_ORGANIZATION, removeUserFromOrganization);
}
