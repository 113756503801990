/*
 * Snackbar Messages
 *
 * This contains all the text for the Snackbar component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  genericErrorMessage: {
    id: 'app.containers.Snackbar.genericErrorMessage',
    description: 'snackbar message',
    defaultMessage: 'Oops. Something went wrong.',
  },
});
