import React, { ReactElement } from 'react';
import { InputState } from '../Hooks';
import { ErrorTooltip } from '../Tooltip';

type Props = {
  inputState: InputState;
  children: ReactElement<any, any>; // Set children type so that Tooltip is hapy
};

export const InputErrorTooltip: React.FC<Props> = ({ inputState, children }) => {
  const { errorTooltipVisible, errorMessage } = inputState ?? {};

  if (!errorTooltipVisible) return children;

  return (
    <ErrorTooltip title={errorMessage} arrow={true} open={errorTooltipVisible} placement="right">
      {children}
    </ErrorTooltip>
  );
};
