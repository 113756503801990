import { defineMessages } from 'react-intl';

const scope = 'app.components.form.Select';

export const messages = defineMessages({
  defaultPlaceholder: {
    id: `${scope}.defaultPlaceholder`,
    defaultMessage: 'No Results Found',
  },
  noOptions: {
    id: `${scope}.noOptions`,
    defaultMessage: 'No Options',
  },
  creatableNoOptions: {
    id: `${scope}.creatableNoOptions`,
    defaultMessage: 'Type in a new option',
  },
  addItem: {
    id: `${scope}.addItem`,
    defaultMessage: 'Add "{item}"',
  },
});
