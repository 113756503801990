import produce from 'immer';
import { uniqBy } from 'lodash';
import { ManageOrganizationState } from '../types';
import { AccountOnboardingAction } from './actions';

export const initialState: ManageOrganizationState = {
  requesting: false,
  error: null,
  invitations: [],
};

const reducer = (state: ManageOrganizationState = initialState, action: AccountOnboardingAction) =>
  produce(
    state,
    (draftState): ManageOrganizationState => {
      switch (action.type) {
        case 'app/Organization/Manage/ADD_INVITATION':
        case 'app/Organization/Manage/REMOVE_INVITATION':
        case 'app/Organization/Manage/LOAD_INVITATIONS':
        case 'app/Organization/Manage/REMOVE_MEMBER':
        case 'app/Organization/Manage/ADD_DEPARTMENT':
        case 'app/Organization/Manage/REMOVE_DEPARTMENT':
        case 'app/Organization/Manage/UPDATE_DEPARTMENT_SETTINGS':
        case 'app/Organization/Manage/ADD_MEMBER_TO_DEPARTMENT':
        case 'app/Organization/Manage/REMOVE_MEMBER_FROM_DEPARTMENT':
        case 'app/Organization/Manage/UPDATE_ORGANIZATION_SETTINGS':
          draftState.requesting = true;
          draftState.error = null;
          break;
        case 'app/Organization/Manage/ADD_INVITATION_SUCCESS':
        case 'app/Organization/Manage/REMOVE_INVITATION_SUCCESS':
        case 'app/Organization/Manage/LOAD_INVITATIONS_SUCCESS':
        case 'app/Organization/Manage/REMOVE_MEMBER_SUCCESS':
        case 'app/Organization/Manage/ADD_DEPARTMENT_SUCCESS':
        case 'app/Organization/Manage/REMOVE_DEPARTMENT_SUCCESS':
        case 'app/Organization/Manage/UPDATE_DEPARTMENT_SETTINGS_SUCCESS':
        case 'app/Organization/Manage/ADD_MEMBER_TO_DEPARTMENT_SUCCESS':
        case 'app/Organization/Manage/REMOVE_MEMBER_FROM_DEPARTMENT_SUCCESS':
        case 'app/Organization/Manage/UPDATE_ORGANIZATION_SETTINGS_SUCCESS':
          draftState.requesting = false;
          break;
        case 'app/Organization/Manage/ADD_INVITATION_ERROR':
        case 'app/Organization/Manage/REMOVE_INVITATION_ERROR':
        case 'app/Organization/Manage/LOAD_INVITATIONS_ERROR':
        case 'app/Organization/Manage/REMOVE_MEMBER_ERROR':
        case 'app/Organization/Manage/ADD_DEPARTMENT_ERROR':
        case 'app/Organization/Manage/REMOVE_DEPARTMENT_ERROR':
        case 'app/Organization/Manage/UPDATE_DEPARTMENT_SETTINGS_ERROR':
        case 'app/Organization/Manage/ADD_MEMBER_TO_DEPARTMENT_ERROR':
        case 'app/Organization/Manage/REMOVE_MEMBER_FROM_DEPARTMENT_ERROR':
        case 'app/Organization/Manage/UPDATE_ORGANIZATION_SETTINGS_ERROR':
          draftState.requesting = false;
          draftState.error = action.error;
          break;
        default:
          break;
      }

      switch (action.type) {
        case 'app/Organization/Manage/ADD_INVITATION_SUCCESS':
          draftState.invitations = uniqBy(draftState.invitations.concat([action.invitation]), 'invitationCode');
          break;
        case 'app/Organization/Manage/LOAD_INVITATIONS_SUCCESS':
          draftState.invitations = action.invitations;
          break;
        case 'app/Organization/Manage/REMOVE_INVITATION_SUCCESS':
          draftState.invitations = draftState.invitations.filter(
            ({ invitationCode }) => invitationCode !== action.invitationCode,
          );
          break;
        default:
          break;
      }
      return draftState;
    },
  );

export default reducer;
