import manWithPasswordVault from 'images/login/man-with-password-vault.svg';
import React from 'react';

export const FormImagePanel: React.FC = () => {
  return (
    <div className="w-full flex justify-center">
      <img src={manWithPasswordVault} style={{ width: '45%' }} />
    </div>
  );
};
