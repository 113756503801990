import { AuthProvider, AuthProviderEnum } from '@monax/types';
import { WebAuth } from 'auth0-js';
import { parseISO } from 'date-fns';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { ApplicationState } from 'types';

const rootSelector = (root: ApplicationState) => root.auth.auth;

export const selectAuthToken = createSelector(rootSelector, (authState) => authState.token);

export const selectIDTokenFromAuth = createSelector(rootSelector, (authState) => authState.idTokenPayload);

export const selectIDTokenIdentityProviders = createSelector(
  selectIDTokenFromAuth,
  (idToken) => (idToken?.['https://login.suscribo.com/identity_providers'] ?? []) as AuthProvider[],
);

export const selectInvitationCodeFromIDToken = createSelector(
  selectIDTokenFromAuth,
  (idToken) => idToken?.['https://login.suscribo.com/invitation_code'] ?? undefined,
);

export const selectIsUsingSocialAuth = createSelector(selectIDTokenIdentityProviders, (providers) =>
  providers.some((p) => p != AuthProviderEnum.AUTH0 && p != AuthProviderEnum.MONAX),
);

export const selectHasAuth0Provider = createSelector(selectIDTokenIdentityProviders, (providers) =>
  providers.some((p) => p === AuthProviderEnum.AUTH0),
);

export const selectAuthError = createSelector(rootSelector, (authState) => authState.error);

export const selectHasToken = createSelector(rootSelector, (authState) => !!authState.token);

const selectAuthConfig = createSelector(rootSelector, (authState) => authState.config);

export const selectAuthHistory = createSelector(rootSelector, (authState) => authState.authHistory);

export const selectAuthHistoryOrdered = createSelector(selectAuthHistory, (authHistory) => {
  const history = Object.keys(authHistory).map((email) => ({
    date: authHistory[email].date,
    idTokenPayload: authHistory[email].idTokenPayload,
  }));

  return sortBy(history, (h) => (h.date ? parseISO(h.date) : new Date(0))).reverse();
});

export const selectWebAuth = createSelector(selectAuthConfig, (config) => {
  const webAuth = new WebAuth({
    clientID: config.clientID,
    domain: config.domain,
    audience: config.audience,
    scope: config.scope,
    redirectUri: config.redirectUri,
    responseType: config.responseType,
  });
  return webAuth;
});
