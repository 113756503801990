import * as routes from '@monax/aeger/dist/webapp-routes';

export const AGREEMENT_ROUTES = {
  createFromTemplate: routes.getAgreementDraftFromTemplateRoute(':templateId'),
  createFromScratch: routes.getAgreementDraftFromScratchRoute(),
  edit: routes.getAgreementDraftEditRoute(':draftId'),
  approve: routes.getAgreementApproveRoute(':draftId'),
  setup: routes.getAgreementRoute(':agreementAddress'),
  review: routes.getAgreementReviewRoute(':draftId'),
  obligation: routes.getAgreementObligationRoute(':agreementAddress', ':obligationId'),
  record: routes.getAgreementRecordRoute(':agreementAddress'),
  list: routes.getAgreementListRoute(),
} as const;
