import { defineMessages } from 'react-intl';
export const scope = 'app.Task';

export const messages = defineMessages({
  taskSignActivityName: {
    id: `${scope}.taskSignActivityName`,
    defaultMessage: `Sign agreement`,
    description: `Task menu item name for signing activity`,
  },
  taskConnectionName: {
    id: `${scope}.taskConnectionName`,
    defaultMessage: `New Connection`,
    description: `Task menu item name for connection`,
  },
  taskDraftApprovalName: {
    id: `${scope}.taskDraftApprovalName`,
    defaultMessage: `Approve agreement`,
    description: `Task menu item name for draft approval`,
  },
  taskPartyConfirmationName: {
    id: `${scope}.taskPartyConfirmationName`,
    defaultMessage: `Confirm party`,
  },
  taskAgreementRenewalName: {
    id: `${scope}.taskAgreementRenewalName`,
    defaultMessage: `Renew agreement`,
    description: `Task menu item name for agreement renewal`,
  },
  taskBlockchainSignatureName: {
    id: `${scope}.taskBlockchainSignatureName`,
    defaultMessage: `Sign Transaction`,
  },
  taskActivityDetail: {
    id: `${scope}.taskActivityDetail`,
    defaultMessage: `{taskName} for {agreementName}`,
  },
  taskSignActivityDetail: {
    id: `${scope}.taskSignActivityDetail`,
    defaultMessage: `{agreementName}`,
    description: `Task menu item detail for signing activity`,
  },
  taskConnectionDetail: {
    id: `${scope}.taskConnectionDetail`,
    defaultMessage: `Connect with {nameOrEmail}`,
    description: `Task menu item detail for connection`,
  },
  taskDraftApprovalDetail: {
    id: `${scope}.taskDraftApprovalDetail`,
    defaultMessage: `{agreementName}`,
    description: `Task menu item detail for connection`,
  },
  taskActivityName: {
    id: `${scope}.taskActivityName`,
    defaultMessage: `Fulfill Obligation`,
    description: `Task menu item name for signing activity`,
  },
  taskAgreementRenewalDetail: {
    id: `${scope}.taskAgreementRenewalDetail`,
    defaultMessage: `{agreementName}`,
    description: `Task menu item detail for connection`,
  },
  taskPartyConfirmationDetail: {
    id: `${scope}.taskPartyConfirmationDetail`,
    defaultMessage: `{agreementName}`,
  },
  taskBlockchainSignatureDetail: {
    id: `${scope}.taskBlockchainSignatureDetail`,
    defaultMessage: `{agreementName}`,
  },
});
