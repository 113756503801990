import produce from 'immer';
import { AgreementCreationCountsState } from '../types';
import { AgreementCreationCountsAction } from './actions';

export const initialState: AgreementCreationCountsState = {
  userAgreementCount: undefined,
  userAgreementDifference: undefined,
  organizationAgreementCount: undefined,
  organizationAgreementDifference: undefined,
  requesting: false,
};

const reducer = (state: AgreementCreationCountsState = initialState, action: AgreementCreationCountsAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Overview/AgreementCreationCounts/LOAD_COUNTS':
        draftState.requesting = true;
        return draftState;
      case 'app/Overview/AgreementCreationCounts/LOAD_COUNTS_SUCCESS':
        draftState.requesting = false;
        draftState.userAgreementCount = action.userAgreementCount;
        draftState.userAgreementDifference = action.userAgreementDifference;
        draftState.organizationAgreementCount = action.organizationAgreementCount;
        draftState.organizationAgreementDifference = action.organizationAgreementDifference;
        return draftState;
      default:
        return draftState;
    }
  });

export default reducer;
