import produce from 'immer';
import { SwitchState } from '../types';
import { SwitchAction } from './actions';

export const initialState: SwitchState = {
  switching: false,
};

const switchReducer = (state = initialState, action: SwitchAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Organization/Switch/SWITCHING_ORGANIZATION': {
        draftState.switching = action.switching;
        return draftState;
      }
      default:
        return draftState;
    }
  });

export default switchReducer;
