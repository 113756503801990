import { Button } from 'components/Common';
import { sendEmailVerification } from 'containers/Auth/Auth/state/actions';
import messages from 'containers/Auth/messages';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { EmailRegisterForm } from '../types';

export const RegisteredPanel: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { values } = useFormikContext<EmailRegisterForm>();

  const onSendEmailVerification = () => {
    dispatch(sendEmailVerification(values.email));
  };

  return (
    <div>
      <div className="pb-2 text-gray-400">{intl.formatMessage(messages.finalizeAccount)}</div>
      <div className="pb-5 font-bold">{values.email}</div>
      <div className="pb-10 text-sm text-gray-300">{intl.formatMessage(messages.noEmailReceivedResend)}</div>
      <div>
        <Button size="l" type="primary" buttonType="button" className="w-full" onClick={onSendEmailVerification}>
          {intl.formatMessage(messages.resendEmail)}
        </Button>
      </div>
    </div>
  );
};
