import produce, { Draft } from 'immer';

export const insertAtIndex = <T>(array: T[], index: number, item: T): T[] => {
  return produce(array, (draft) => {
    draft.splice(index, 0, item as Draft<T>);
  });
};

export const replaceAtIndex = <T>(array: T[], index: number, item: T): T[] => {
  return produce(array, (draft) => {
    draft[index] = item as Draft<T>;
  });
};

export const removeAtIndex = <T>(array: T[], index: number): T[] => {
  return produce(array, (draft) => {
    draft.splice(index, 1);
  });
};

export const push = <T>(array: T[], item: T): T[] => {
  return produce(array, (draft) => {
    draft.push(item as Draft<T>);
  });
};
