import { ThrottledResourceBalance, ThrottledResourceLimits } from '@monax/types';
import { createSelector } from 'reselect';
import { ApplicationState } from 'types';

const rootSelector = (root: ApplicationState) => root.organizations.resourceBalance;

export const selectOrganizationResourceBalance = createSelector(
  rootSelector,
  (root): ThrottledResourceBalance => root.balance,
);

export const selectOrganizationResourceLimits = createSelector(
  rootSelector,
  (root): ThrottledResourceLimits => root.limits,
);
