import { flatten } from 'lodash';
import { createSelector } from 'reselect';
import { ApplicationState } from 'types';
import { sortByParams } from 'utils/sort';
import { userDisplayName } from './utils';

const usersSelector = (state: ApplicationState) => state.users.list.users;

export const selectUsers = createSelector(usersSelector, (users) => users);

export const selectUsersList = createSelector(selectUsers, (users) => Object.values(users));

export const selectUserByOrganizationList = createSelector(selectUsersList, (users) =>
  flatten(
    users.map(({ organizations, ...user }) =>
      organizations.map((org) => ({
        ...user,
        organizationAddress: org,
      })),
    ),
  ),
);

export const makeSelectUserOptions = () =>
  createSelector(usersSelector, (users) => {
    const options = [];
    Object.values(users).forEach(({ address, name, email }) => {
      options.push({
        value: address,
        label: userDisplayName(name, email),
      });
    });
    return options.sort(sortByParams(1, 'label'));
  });

export const makeSelectUserDetails = () =>
  createSelector(
    (_, ownProps) => ownProps.userAddress,
    usersSelector,
    (userAddress, users) => users[userAddress],
  );
