import axios, { AxiosRequestConfig } from 'axios';
import { ContentLoader } from 'components/Loader';
import configs from 'configs';
import { Route } from 'containers/App/components/Route';
import { useNotifications } from 'containers/App/hooks/useNotifications';
import { selectHasToken } from 'containers/Auth/Auth/state/selectors';
import { NoOrganizationError } from 'containers/Error';
import { loadCurrentOrganizationResourceLimits } from 'containers/Organization/ResourceBalance/state/actions';
import { loadCurrentUser } from 'containers/User/Current/Profile/state/actions';
import { selectCurrentUser } from 'containers/User/Current/Profile/state/selectors';
import { loadResourceFilters } from 'containers/User/Current/ResourceFilters/state/actions';
import { loadUsers } from 'containers/User/List/actions';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteProps, useHistory } from 'react-router-dom';
import { RequestError } from 'types';
import { anonymousIdentify } from 'utils/analytics';
import { RequestOptions } from 'utils/apiClient';
import { parseQueryString, formatUrl } from 'utils/queryString';
import messages from './messages';

function storeUTMParameters() {
  const { utm_source, utm_campaign, utm_medium, utm_term, utm_content } = parseQueryString(window.location.search);
  if (utm_source || utm_campaign || utm_medium || utm_term || utm_content) {
    anonymousIdentify(
      {
        utm_source,
        utm_campaign,
        utm_medium,
        utm_term,
        utm_content,
      },
      {
        context: {
          app: {
            version: configs.siteData.appVersion,
          },
        },
      },
    );
  }
}

type Props = {
  component: React.ComponentType<unknown>;
};

const SecureRouteComponent: React.FC<Props & RouteProps & StateProps> = ({
  component: Component,
  currentUser,
  isAuthenticated,
  ...routeProps
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showError } = useNotifications();

  const handleAuthRedirect = async () => {
    if (!isAuthenticated) {
      const { subdomain } = parseQueryString(window.location.search);
      const redirectURL =
        window.location.pathname === '/' ? '/' : window.location.pathname.concat(window.location.search);
      history.push(formatUrl('/login', { redirectURL, subdomain }));
    }
  };

  useEffect(() => {
    // global axios error handler
    const errorInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error: RequestError) => {
        const statusCode: number | undefined = error.response?.status;
        const options = error.config as RequestOptions & AxiosRequestConfig;

        // redirect 401s to logout
        if (statusCode === 401) {
          showError(messages.unauthorizedAccess);
          history.push('/logout');
        }

        // redirect 402s to our 402 page
        // ideally we shouldnt be getting here if our client side validation is working correctly
        if (statusCode === 402) {
          history.push('/402');
        }

        // redirect 404s to our 404 page
        if (statusCode === 404 && !options.disable404Redirect) {
          history.replace('/404');
        }

        // redirect 500s to our 500 page
        if (statusCode === 500) {
          history.push('/500');
        }

        return Promise.reject(error);
      },
    );

    const throttlingInterceptor = axios.interceptors.response.use((response) => {
      // Reload the resource balance if an endpoint has been call that could have affected the resource balance
      if (response.config?.['affectsResourceBalance']) dispatch(loadCurrentOrganizationResourceLimits());
      return response;
    });

    return () => {
      axios.interceptors.response.eject(errorInterceptor);
      axios.interceptors.response.eject(throttlingInterceptor);
    };
  }, []);

  useEffect(() => {
    if (window.location.search.includes('utm')) {
      window.setTimeout(() => {
        storeUTMParameters();
        handleAuthRedirect();
      }, 300);
    } else {
      handleAuthRedirect();
    }

    if (isAuthenticated && !currentUser) {
      dispatch(loadCurrentUser());
    }

    if (isAuthenticated) {
      dispatch(loadUsers());
      dispatch(loadResourceFilters());
    }
  }, [isAuthenticated, currentUser]);

  if (!isAuthenticated || !currentUser) return <ContentLoader />;

  if (!currentUser.organizations.length) {
    return <Route pageCategory="Errors" pageName="No Organization" component={NoOrganizationError} />;
  }
  return <Route {...routeProps} component={Component} />;
};

type StateProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  isAuthenticated: selectHasToken(state),
  currentUser: selectCurrentUser(state),
});

export const SecureRoute = connect(mapStateToProps)(SecureRouteComponent);
