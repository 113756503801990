import { SubmitButton } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { ResetPasswordRequestForm } from '../types';

type Props = {
  loading: boolean;
};

export const RequestSentPanel: React.FC<Props> = ({ loading }) => {
  const intl = useIntl();
  const { values, handleSubmit } = useFormikContext<ResetPasswordRequestForm>();

  return (
    <div>
      <div className="pb-2 text-gray-400">{intl.formatMessage(messages.ifValidEmailResetEmailSent)}</div>
      <div className="pb-5 font-bold">{values.usernameOrEmail}</div>
      <div className="pb-10 text-sm text-gray-300">{intl.formatMessage(messages.resendIfEmailNotRecieved)}</div>
      <div>
        <SubmitButton type="button" onClick={() => handleSubmit()} disabled={loading}>
          {intl.formatMessage(messages.resendEmail)}
        </SubmitButton>
      </div>
    </div>
  );
};
