import { DocumentType } from '@monax/aeger/dist/document';
import messages from 'components/Form/messages';
import configs from 'configs';
import { addNotification } from 'containers/App/state/actions';
import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

export type FileInputEvent = ChangeEvent & {
  target: {
    files: FileList;
  };
};

/**
 *
 * @param acceptTypes If empty, will accept all file types
 * @param handleFiles Handle selected files
 */
export function useOnFileChange(
  acceptTypes: DocumentType[],
  handleFiles: (files: File[]) => void,
  maxFiles = 10,
  maxSize = configs.uploads.maxSize,
  ...handlers: ((file: File) => Promise<boolean>)[]
) {
  const intl = useIntl();
  const dispatch = useDispatch();
  return async (e: FileInputEvent) => {
    if (!e.target.files[0]) return;
    const files = [];
    if (e.target.files.length > maxFiles) {
      dispatch(
        addNotification({
          className: 'warning',
          message: intl.formatMessage(messages.maxFileCountExceeded, {
            max: maxFiles,
          }),
        }),
      );
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files.item(i);
      if (acceptTypes.length && !acceptTypes.includes(file.type as DocumentType)) {
        dispatch(
          addNotification({
            className: 'warning',
            message: intl.formatMessage(messages.fileTypeNotSupported),
          }),
        );
        return;
      }
      if (file.size > maxSize) {
        dispatch(
          addNotification({
            className: 'warning',
            message: intl.formatMessage(messages.maxFileSizeXExceeded, {
              size: maxSize / 1000000,
            }),
          }),
        );
        return;
      }
      const handlerResults = await Promise.all(handlers.map((handler) => handler(file)));
      if (handlerResults.some((handler) => !handler)) {
        return;
      }
      files.push(file);
    }
    handleFiles(files);
  };
}
