import {
  Address,
  AgreementDraftBody,
  AgreementDraftDocument,
  AgreementDraftParameter,
  AgreementDraftParticipant,
  AgreementForeignChain,
  AgreementForeignChainAccess,
  DurationUnit,
  ForeignChainAssetType,
  OffsetDirection,
  TemplateBody,
} from '@monax/types';
import { repeatOptions } from './constants';

/**
 * State
 */
export type AgreementFormState = {
  form: null | AgreementDraftBody | TemplateBody;
  reviewSection: ReviewSection;
  termManagementDialog: boolean;
  termRemovalDialog: TermRemoval;
  reviewed: PanelReviewState;
  reviewedDocuments: { [grant: string]: boolean };
  editing: boolean;
};

export type TermRemoval = { section: TermCategory; term: string; onRemove: () => void };

export type PanelReviewState = Record<ReviewPanelSection, boolean>;

export type Resource = 'template' | 'agreement';

export type ResourceState = 'drafting' | 'approving' | 'reviewing' | 'on-chain';

/**
 * Form steps
 */
export type Step =
  | 'name'
  | 'documents'
  | 'parties'
  | 'terms'
  | 'dates'
  | 'events'
  | 'foreignChain'
  | 'review'
  | 'saved'
  | 'sent'
  | 'created'
  | 'published';

/**
 * Form sections
 */
export type Section = 'name' | 'documents' | 'parties' | 'terms' | 'dates' | 'events' | 'settings' | 'foreignChain';

/**
 * Review step types
 */
export const REVIEW_SECTIONS = [
  'documents',
  'parties',
  'terms',
  'dates',
  'events',
  'foreignChain',
  'settings',
] as const;
export type ReviewSection = typeof REVIEW_SECTIONS[number];
export type ReviewPanelSection = 'parties' | 'terms' | 'dates' | 'events' | 'foreignChain' | 'settings';

/**
 * Form values for each section (couldn't find a way around having 'section' prop in each as a type helper)
 */

/**
 * Name
 */
export type NameSectionValues = {
  section: 'name';
  name: string;
};

/**
 * Documents
 */
export type DocumentsSectionValues = {
  section: 'documents';
  documents: AgreementDraftDocument[];
};

/**
 * Parties
 */
export type FormParty = Omit<AgreementDraftParticipant, 'representative'> & {
  key: string;
  representative: { address: string; email: string };
};
export type PartiesSectionValues = {
  section: 'parties';
  parties: {
    currentUser: FormParty;
    other: FormParty[];
  };
  approver: Address;
};

/**
 * Terms
 */
export type FormParameter = AgreementDraftParameter & { key: string; useTitle: boolean; useConstraints: boolean };
export type TermsSectionValues = { section: 'terms'; parameters: FormParameter[]; add: FormParameter };

/**
 * Dates
 */
export type RepeatOption = keyof typeof repeatOptions;
export type OffsetPeriod = {
  amount: number;
  unit: DurationUnit;
};
export type FormOffset = {
  direction: OffsetDirection;
  amount: number;
  unit: DurationUnit;
};
export type FormDate = {
  id: string;
  name: string;
  title?: string;
  cycle?: {
    offset: RepeatOption;
    until: string[];
  };
} & (
  | {
      type: 'FIXED';
      data: {
        assignee?: string; // no longer setting in UI
        value: Date | null;
      };
    }
  | {
      type: 'RELATIVE';
      data: {
        baseId: string;
        offset: FormOffset;
      };
    }
);
export type FormRenewal = {
  title: string;
  franchisees: string[];
  openOffset: FormOffset;
  closeOffset: FormOffset;
  extensionOffset: FormOffset;
};
export type DatesSectionValues = {
  section: 'dates';
  effectiveOnSigning: boolean;
  dates: FormDate[];
  renewal: FormRenewal | null;
  hasSigning: boolean;
};

/**
 * Events
 */
export enum ObligationActionType {
  MESSAGE = 'send-a-message',
  WEBHOOK = 'trigger-webhook',
  BLOCKCHAIN = 'trigger-blockchain',
}
export type ObligationActionBase<T extends ObligationActionType> = {
  key: string;
  type: T;
  eventPolicyId: string;
};
export type ObligationActionMessageData = {
  recipients: string[];
  message?: string;
};
export type ObligationActionEventPolicyData = {};
export type ObligationActionMessage = ObligationActionBase<ObligationActionType.MESSAGE> & ObligationActionMessageData;
export type ObligationActionEventPolicy = ObligationActionBase<
  ObligationActionType.WEBHOOK | ObligationActionType.BLOCKCHAIN
> &
  ObligationActionEventPolicyData;

export type ObligationAction = ObligationActionMessage | ObligationActionEventPolicy;
export enum FormEventType {
  NOTIFICATION = 'NOTIFICATION',
  OBLIGATION = 'OBLIGATION',
}
export type FormNotification = {
  type: FormEventType.NOTIFICATION;
  key: string;
  name: string;
  owner: string; // Not editing in the UI, but easier to store it here rather than have ensure setting it elsewhere
  triggerDate: string;
  triggerOffset: FormOffset;
  actions: ObligationAction[];
};
export type FormObligation = {
  type: FormEventType.OBLIGATION;
  key: string;
  name: string;
  startDate: string;
  closeDate: string;
  attesters: string[];
  actions: ObligationAction[];
};
export type EventsSectionValues = {
  section: 'events';
  events: (FormNotification | FormObligation)[];
};

/**
 * Name
 */
export type SettingsSectionValues = {
  section: 'settings';
  name: string;
  message: string;
  locale: string;
  ownerScope: string;
  timezone: string;
  dateFormat: string;
};

/**
 * Values by section
 */
export type SectionValues<T extends Section> = T extends 'name'
  ? NameSectionValues
  : T extends 'documents'
  ? DocumentsSectionValues
  : T extends 'parties'
  ? PartiesSectionValues
  : T extends 'terms'
  ? TermsSectionValues
  : T extends 'foreignChain'
  ? ForeignChainValues
  : T extends 'dates'
  ? DatesSectionValues
  : T extends 'events'
  ? EventsSectionValues
  : SettingsSectionValues;

/**
 * Terms management
 */
export type TermCategory = 'term' | 'party' | 'date' | 'disabled';
export type TermConfiguration = {
  allTerms: string[];
  termCategories: { [term: string]: TermCategory };
  documentTerms: Set<string>;
  values: SectionValues<Section>;
};

/**
 * Foreign Chain Config
 */

export type ForeignChainAccessEntity = { key: string } & AgreementForeignChainAccess;

export type ForeignChainAccess = {
  add: ForeignChainAccessEntity | undefined;
  items: ForeignChainAccessEntity[];
};

/**
 * Foreign Chain
 */
export type ForeignChainValues = {
  section: 'foreignChain';
  parameterNames: string[];
  foreignChainAssetType?: ForeignChainAssetType;
  templateOwnerForeignAddress?: string;
  documents: AgreementDraftDocument[];
  publicDocumentGrant?: string;
  access: ForeignChainAccess;
  operatorWhitelist: AgreementForeignChain['operatorWhitelist'];
};
