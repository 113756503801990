import { customerTypeHasFeatureAccess } from '@monax/aeger/dist/billing';
import { CustomerType, MemberOrganization, OrganizationTeam, RestrictedFeature } from '@monax/types';
import { selectUsers } from 'containers/User/List/selectors';
import { userDisplayName } from 'containers/User/List/utils';
import { createDeepEqualSelector } from 'lib/reselect';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { ApplicationState } from 'types';
import { UserWithRoles } from '../types';

const rootSelector = (root: ApplicationState) => root.organizations.current;

export const selectCurrentOrganization = createSelector(rootSelector, (organization) => organization);

/**
 * Organization properties
 */
export const selectCurrentOrganizationAddress = makeSelectCurrentOrganizationProperty('address');
export const selectCurrentOrganizationName = makeSelectCurrentOrganizationProperty('name');
export const selectCurrentOrganizationTimezone = makeSelectCurrentOrganizationProperty('defaultTimezone');
export const selectCurrentOrganizationDateFormat = makeSelectCurrentOrganizationProperty('defaultDateFormat');
export const selectCurrentOrganizationIsIndividual = makeSelectCurrentOrganizationProperty('isIndividual');
export const selectCreatedAgreementCount = makeSelectCurrentOrganizationProperty('createdAgreementCount');
export const selectCurrentOrganizationThemeOverrides = makeSelectCurrentOrganizationProperty('themeOverrides');

/**
 * Billing data
 */

export const selectCurrentOrganizationCustomerType = createSelector(
  selectCurrentOrganization,
  (organization: MemberOrganization): CustomerType => organization?.customerType,
);

export const selectCurrentOrganizationHasConfigureEventsFeatureAccess = makeSelectCurrentOrganizationFeatureAccess(
  'configure_events',
);
export const selectCurrentOrganizationHasAddDatesFeatureAccess = makeSelectCurrentOrganizationFeatureAccess(
  'add_dates',
);
export const selectCurrentOrganizationHasAddEventsFeatureAccess = makeSelectCurrentOrganizationFeatureAccess(
  'add_events',
);
export const selectCurrentOrganizationHasAddSigningURLFeatureAccess = makeSelectCurrentOrganizationFeatureAccess(
  'add_signing_url',
);
export const selectCurrentOrganizationHasViewDashboardFeatureAccess = makeSelectCurrentOrganizationFeatureAccess(
  'view_dashboard',
);

/**
 * Organization members
 */
export const selectCurrentOrganizationMembers = createSelector(
  selectCurrentOrganization,
  selectUsers,
  (organization, users): UserWithRoles[] => {
    if (!organization) {
      return [];
    }
    return sortBy(
      organization.users
        .filter((user) => users[user])
        .map((user) => ({
          ...users[user],
          roles: organization.rolesByUser[user],
        })),
      'name',
    );
  },
);

export const selectCurrentOrganizationMemberOptions = createSelector(selectCurrentOrganizationMembers, (members) =>
  members.map((user) => ({
    label: userDisplayName(user.name, user.email),
    value: user.address,
  })),
);

export const selectCurrentOrganizationSigners = createSelector(
  selectCurrentOrganization,
  selectUsers,
  (organization, users) =>
    organization?.usersByRole.SIGNING_AUTHORITY.filter((user) => users[user]).map((user) => users[user]) || [],
);

export const selectCurrentOrganizationAdmins = createSelector(
  selectCurrentOrganization,
  selectUsers,
  (organization) => organization?.usersByRole.ACCOUNT_ADMIN,
);

/**
 * Departments
 */
export const selectCurrentOrganizationDepartments = createSelector(
  selectCurrentOrganization,
  (organization) => organization.departments,
);

export const selectCurrentOrganizationDepartmentsById = createSelector(
  selectCurrentOrganizationDepartments,
  (departments): Record<string, OrganizationTeam> => departments.reduce((acc, dep) => ({ ...acc, [dep.id]: dep }), {}),
);

export const selectCurrentOrganizationDepartmentOptions = createSelector(
  selectCurrentOrganizationDepartments,
  (departments) => departments.map((dep) => ({ value: dep.id, label: dep.name })),
);

/**
 * Selectors Factories
 */
export function makeSelectCurrentOrganizationProperty<T extends keyof MemberOrganization>(prop: T) {
  return createDeepEqualSelector(selectCurrentOrganization, (organization) =>
    organization ? organization[prop] : undefined,
  );
}

export function makeSelectCurrentOrganizationFeatureAccess<T extends RestrictedFeature>(feature: T) {
  return createSelector(selectCurrentOrganizationCustomerType, (customerType) =>
    customerTypeHasFeatureAccess(customerType, feature),
  );
}
