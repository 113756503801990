import { selectAPIClient } from 'containers/App/state/selectors';
import { useWallet, WalletMenu } from 'lib/hooks/useWallet';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { watchHandleLoginSuccess } from '../../Auth/state/saga';

type Props = {
  disabled: boolean;
  requireAddress?: {
    value: string;
    handleMismatch: () => void;
  };
};

export const WalletLogin: React.FC<Props> = ({ disabled, requireAddress }) => {
  const dispatch = useDispatch();

  useInjectSaga({
    key: 'handle-auth-state',
    saga: watchHandleLoginSuccess,
  });

  const apiClient = useSelector(selectAPIClient);
  const { wallet, account } = useWallet();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (wallet && account) {
      if (requireAddress && requireAddress.value !== account) {
        requireAddress.handleMismatch();
        return;
      }
      setLoading(true);
      wallet.signIn(apiClient, dispatch).finally(() => setLoading(false));
    }
  }, [wallet, account]);

  return <WalletMenu disabled={disabled || loading} fullWidth />;
};
