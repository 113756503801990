import { combineReducers } from 'redux';
import foreignAddressesReducer from './ForeignAddresses/state/reducer';
import profileReducer from './Profile/state/reducer';
import resourceFiltersReducer from './ResourceFilters/state/reducer';
import { UserCurrentState } from './types';
import userInvitationsReducer from './UserInvitations/state/reducer';

const reducer = combineReducers<UserCurrentState>({
  foreignAddresses: foreignAddressesReducer,
  profile: profileReducer,
  userInvitations: userInvitationsReducer,
  resourceFilters: resourceFiltersReducer,
});

export default reducer;
