import { DialogTitle as MuiDialogTitle } from '@material-ui/core';
import { PaletteKey } from 'containers/Theme/types';
import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';
import { X } from 'react-bootstrap-icons';

type Props = {
  title: React.ReactNode;
  className?: PaletteKey;
  cancel?: () => void;
  disabled?: boolean;
};

export const DialogTitle: React.FC<Props> = ({ className = 'primary', title, cancel, disabled }) => {
  const { palette } = useTheme();
  return (
    <>
      {cancel ? (
        <button
          type="button"
          onClick={cancel}
          style={{ position: 'absolute', top: '26px', right: '33px' }}
          disabled={disabled}
        >
          <X fontSize={32} color={palette.gray[400]} />
        </button>
      ) : null}
      <MuiDialogTitle
        style={{
          color: palette[className].main,
        }}
      >
        {title}
      </MuiDialogTitle>
    </>
  );
};
