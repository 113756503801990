import { AuthProvider } from '@monax/types';
import { Button } from 'components/Common';
import messages from 'containers/Auth/messages';
import googleIcon from 'images/social/google.svg';
import linkedinIcon from 'images/social/linkedin.svg';
import microsoftIcon from 'images/social/microsoft.svg';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ApplicationState } from 'types';
import { track, TrackProps } from 'utils/analytics';
import { parseQueryString } from 'utils/queryString';
import { selectWebAuth } from '../Auth/state/selectors';
import { getState } from '../utils';
import { WalletLogin } from './WalletLogin';

type SocialLogin = AuthProvider | 'wallet';

type Props = {
  loading?: boolean;
  showOnly?: SocialLogin[];
};

const SocialLoginButtonsComponent: React.FC<Props & PropsFromState> = ({ webAuth, loading, showOnly }) => {
  const intl = useIntl();
  const queryString = parseQueryString(window.location.search);
  const login = (connection: AuthProvider) => {
    const trackProps: TrackProps = {
      event: 'application.login-with-sso',
      payload: {
        authProvider: connection,
      },
    };
    if (queryString.invitationCode) {
      trackProps.payload.invitedBy = 'team-member';
    } else if (queryString.counterparty) {
      trackProps.payload.invitedBy = 'counterparty';
    }
    track(trackProps);
    webAuth.authorize({
      connection: connection,
      state: getState(),
    });
  };

  const visible = (socialLogin: SocialLogin) => {
    if (!showOnly) return true;
    return showOnly.some((l) => l === socialLogin);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap pt-2">
        {visible('google-oauth2') && (
          <div className="flex-1 p-1">
            <SocialButton icon={googleIcon} onClick={() => login('google-oauth2')} disabled={loading}>
              {intl.formatMessage(messages.google)}
            </SocialButton>
          </div>
        )}
        {visible('windowslive') && (
          <div className="flex-1 p-1">
            <SocialButton icon={microsoftIcon} onClick={() => login('windowslive')} disabled={loading}>
              {intl.formatMessage(messages.microsoft)}
            </SocialButton>
          </div>
        )}
        {visible('linkedin') && (
          <div className="flex-1 p-1">
            <SocialButton icon={linkedinIcon} onClick={() => login('linkedin')} disabled={loading}>
              {intl.formatMessage(messages.linkedIn)}
            </SocialButton>
          </div>
        )}
        {visible('wallet') && (
          <div className="flex-1 p-1">
            <WalletLogin disabled={loading} />
          </div>
        )}
      </div>
    </div>
  );
};

type SocialButtonProps = {
  icon: string;
  onClick: () => void;
  disabled: boolean;
};

export const SocialButton: React.FC<SocialButtonProps> = (props) => {
  return (
    <Button
      size="s"
      type="gray-light-outlined"
      buttonType="button"
      className="w-full justify-center"
      onClick={props.onClick}
      disabled={props.disabled}
      icon={<img className="fill-current w-4 h-4" src={props.icon} alt="Social Login" width="14px" />}
    >
      <span>{props.children}</span>
    </Button>
  );
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
  webAuth: selectWebAuth(state),
});

export const SocialLoginButtons = connect(mapStateToProps)(SocialLoginButtonsComponent);
