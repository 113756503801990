import { defineMessages } from 'react-intl';

export const scope = 'app.containers.agreement_v4.list';

export default defineMessages({
  createButton: {
    id: `${scope}.createButton`,
    defaultMessage: 'Create',
  },
  agreements: {
    id: `${scope}.agreements`,
    defaultMessage: 'Agreements',
  },
  templates: {
    id: `${scope}.templates`,
    defaultMessage: 'Templates',
  },
  createAgreement: {
    id: `${scope}.createAgreement`,
    defaultMessage: 'New Agreement',
  },
  createAgreementFromTemplate: {
    id: `${scope}.createAgreementFromTemplate`,
    defaultMessage: 'Agreement from Template',
  },
  createTemplate: {
    id: `${scope}.createTemplate`,
    defaultMessage: 'Create Template',
  },
  createBlockchainTemplate: {
    id: `${scope}.createBlockchainTemplate`,
    defaultMessage: 'Blockchain Template',
  },
  importTemplate: {
    id: `${scope}.importTemplate`,
    defaultMessage: 'Import JSON Template',
  },
  selectTemplateFromList: {
    id: `${scope}.selectTemplateFromList`,
    defaultMessage: 'Select a template to create a new agreement from.',
  },
  draftType_agreement: {
    id: `${scope}.draftType_agreement`,
    defaultMessage: 'Agreement Draft',
  },
  draftType_template: {
    id: `${scope}.draftType_template`,
    defaultMessage: 'Template Draft',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  sortBy: {
    id: `${scope}.sortBy`,
    defaultMessage: 'Sort by:',
  },
  sortName_asc: {
    id: `${scope}.sortName_asc`,
    defaultMessage: 'Name (A-Z)',
  },
  sortName_desc: {
    id: `${scope}.sortName_desc`,
    defaultMessage: 'Name (Z-A)',
  },
  sortCreated_asc: {
    id: `${scope}.sortCreated_asc`,
    defaultMessage: 'Date Created (Ascending)',
  },
  sortCreated_desc: {
    id: `${scope}.sortCreated_desc`,
    defaultMessage: 'Date Created (Descending)',
  },
  forTeam: {
    id: `${scope}.forTeam`,
    defaultMessage: 'for {team}',
  },
  allTeams: {
    id: `${scope}.allTeams`,
    defaultMessage: 'All teams',
  },
  updateFilter: {
    id: `${scope}.updateFilter`,
    defaultMessage: 'Update Filter',
  },
  saveCustomizedFilter: {
    id: `${scope}.saveCustomizedFilter`,
    defaultMessage: 'Save Customized Filter',
  },
  customizeFilters: {
    id: `${scope}.customizeFilters`,
    defaultMessage: 'Customize Filters',
  },
  deleteFilter: {
    id: `${scope}.deleteFilter`,
    defaultMessage: 'Delete Filter',
  },
  applyFilter: {
    id: `${scope}.applyFilter`,
    defaultMessage: 'Apply Filter',
  },
  saveAs: {
    id: `${scope}.saveAs`,
    defaultMessage: 'Save as',
  },
  resetAllFilters: {
    id: `${scope}.resetAllFilters`,
    defaultMessage: 'Reset all',
  },
  filterUpdated: {
    id: `${scope}.filterUpdated`,
    defaultMessage: 'Filter Updated',
  },
  filterCreated: {
    id: `${scope}.filterCreated`,
    defaultMessage: 'Filter Created',
  },
  filterDeleted: {
    id: `${scope}.filterDeleted`,
    defaultMessage: 'Filter Deleted',
  },
  filterName: {
    id: `${scope}.filterName`,
    defaultMessage: 'Filter Name',
  },
  filterNamePlaceholder: {
    id: `${scope}.filterNamePlaceholder`,
    defaultMessage: 'Provide a filter name',
  },
});
