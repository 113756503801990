import manWithSuccessEmail from 'images/login/man-with-success-email.svg';
import React from 'react';

export const RegisteredImagePanel: React.FC = () => {
  return (
    <div className="w-full flex justify-center">
      <img src={manWithSuccessEmail} style={{ width: '45%' }} />
    </div>
  );
};
