import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Search } from '../List/Search';
import { selectAssetList } from '../List/state/selectors';
import { messages } from '../messages';
import { CreateOptions } from './CreateOptions';

export const HeaderDesktop: React.FC = () => {
  const intl = useIntl();
  const assets = useSelector(selectAssetList);

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <span className="text-xl md:text-2xl text-secondary-main font-bold">{intl.formatMessage(messages.wallet)}</span>
      </div>
      <div className="flex items-center">
        {assets.length > 0 && (
          <div style={{ width: 400 }}>
            <Search />
          </div>
        )}
        <div className="pl-8">
          <CreateOptions />
        </div>
      </div>
    </div>
  );
};
