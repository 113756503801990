import produce from 'immer';
import { merge } from 'lodash';
import { TemplateState } from '../types';
import { PreChainTemplateAction } from './actions';

export const initialState: TemplateState = {
  template: null,
  request: null,
  lockedDialog: false,
};

const reducer = (state: TemplateState = initialState, action: PreChainTemplateAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Agreement_v4/PreChain/Template/LOAD_TEMPLATE':
      case 'app/Agreement_v4/PreChain/Template/SAVE_TEMPLATE':
        draftState.request = action.type;
        return draftState;
      case 'app/Agreement_v4/PreChain/Template/LOAD_TEMPLATE_SUCCESS':
        draftState.template = action.template;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Template/SAVE_TEMPLATE_SUCCESS':
        draftState.template = action.template;
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Template/RECEIVE_TEMPLATE_ERROR':
        draftState.request = null;
        return draftState;
      case 'app/Agreement_v4/PreChain/Template/TOGGLE_LOCKED_DIALOG':
        draftState.lockedDialog = action.shouldOpen;
        return draftState;
      case 'app/Agreement_v4/PreChain/Template/RECEIVE_TEMPLATE':
        draftState.template = merge(draftState.template, action.template);
        return draftState;
      default:
        return draftState;
    }
  });

export default reducer;
