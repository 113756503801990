import { Input, PasswordInput } from 'components/Form';
import { Divider, SocialLoginButtons, SubmitButton } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { hasErrors } from 'utils/formik';
import { LoginForm } from '../types';

type Props = {
  loading: boolean;
  email?: string;
  fixedEmail: boolean;
};

export const FormPanel: React.FC<Props> = ({ loading, email, fixedEmail }) => {
  const intl = useIntl();
  const { errors, handleSubmit } = useFormikContext<LoginForm>();

  return (
    <>
      <div>
        <SocialLoginButtons loading={loading} />
      </div>
      <div className="py-5">
        <Divider>{intl.formatMessage(messages.orLoginWith)}</Divider>
      </div>
      <form onSubmit={handleSubmit}>
        <Input
          name="username"
          autoFocus={!email}
          placeholder={intl.formatMessage(messages.enterYourEmailUsername)}
          inputConfig={{ showValidAdornment: true, errorMessageDisplayMode: 'none' }}
          disabled={fixedEmail}
        />
        <PasswordInput
          name="password"
          autoFocus={!!email}
          placeholder={intl.formatMessage(messages.enterYourPassword)}
          inputConfig={{ errorMessageDisplayMode: 'none' }}
        />
        <div className="pb-6 flex justify-end">
          <Link className="text-xs text-primary-main" to="/reset-password-request">
            {intl.formatMessage(messages.forgotPassword)}
          </Link>
        </div>
        <div className="pt-4">
          <SubmitButton disabled={hasErrors(errors) || loading}>{intl.formatMessage(messages.login)}</SubmitButton>
        </div>
      </form>
    </>
  );
};
