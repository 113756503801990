import { createSelector } from 'reselect';
import { ApplicationState } from 'types';

const rootSelector = (state: ApplicationState) => state.agreement.document;

export const selectDocuments = createSelector(rootSelector, (documentState) => documentState.documents);

export const selectMeta = createSelector(rootSelector, (documentState) => documentState.documentMeta);

export const selectUploadingState = createSelector(rootSelector, (documentState) => documentState.uploading);

export const selectDocumentErrors = createSelector(rootSelector, (documentState) => documentState.documentErrors);

export const selectDocumentParameters = createSelector(rootSelector, (documentState) => documentState.documentParams);

export const selectDocumentsExpanded = createSelector(rootSelector, (root) => root.documentsExpanded);

export const selectAssemblingDocument = createSelector(rootSelector, (root) => root.assemblingDocument);

// Factory selectors -------------------------------------------------
export const makeSelectDocumentByGrant = () =>
  createSelector(
    (state: ApplicationState, { grant }: { grant: string }) => state.agreement.document.documents[grant],
    (document) => document,
  );

export const makeSelectMetaByGrant = () =>
  createSelector(
    (state: ApplicationState, { grant }: { grant: string }) => state.agreement.document.documentMeta[grant],
    (meta) => meta,
  );

export const makeSelectErrorByGrant = () =>
  createSelector(
    (state: ApplicationState, { grant }: { grant: string }) => state.agreement.document.documentErrors[grant],
    (error) => error,
  );
// ---------------------------------------------------------------------
