import { Checkbox, Input, PasswordInput } from 'components/Form';
import { SUPPORTED_SSO_AUTH_PROVIDERS } from 'containers/Auth/constants';
import { Divider, SocialLoginButtons, SubmitButton } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { EmailRegisterForm } from '../types';

type Props = {
  loading: boolean;
  fixedEmail: boolean;
};

export const EmailFormPanel: React.FC<Props> = ({ loading, fixedEmail }) => {
  const intl = useIntl();
  const { handleSubmit } = useFormikContext<EmailRegisterForm>();

  return (
    <>
      <div>
        <SocialLoginButtons loading={loading} showOnly={SUPPORTED_SSO_AUTH_PROVIDERS} />
      </div>
      <div className="py-5">
        <Divider>{intl.formatMessage(messages.orSignupHere)}</Divider>
      </div>
      <form onSubmit={handleSubmit}>
        <Input
          name="email"
          placeholder={intl.formatMessage(messages.email)}
          inputConfig={{ errorMessageDisplayMode: 'tooltip', showValidAdornment: true }}
          disabled={fixedEmail}
        />
        <PasswordInput
          name="password"
          placeholder={intl.formatMessage(messages.password)}
          inputConfig={{ errorMessageDisplayMode: 'tooltip' }}
        />
        <PasswordInput
          name="confirmPassword"
          placeholder={intl.formatMessage(messages.confirmPassword)}
          inputConfig={{ errorMessageDisplayMode: 'tooltip' }}
        />
        <Checkbox
          name="tosAccepted"
          label={intl.formatMessage(messages.acceptTOS, {
            termsOfService: (
              <a target="_blank" href="https://suscribo.com/terms/">
                {intl.formatMessage(messages.termsOfService)}
              </a>
            ),
            privacyPolicy: (
              <a target="_blank" href="https://suscribo.com/privacy/">
                {intl.formatMessage(messages.privacyPolicy)}
              </a>
            ),
          })}
          inputConfig={{ errorMessageDisplayMode: 'tooltip' }}
        />
        <div className="pt-4">
          <SubmitButton disabled={loading}>{intl.formatMessage(messages.signup)}</SubmitButton>
        </div>
      </form>
    </>
  );
};
