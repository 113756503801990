import { Menu as MuiMenu } from '@material-ui/core';
import MuiAvatarGroup from '@material-ui/lab/AvatarGroup';
import { OrganizationAvatar, UserAvatar } from 'components/Avatar';
import { selectAuthHistoryOrdered } from 'containers/Auth/Auth/state/selectors';
import { selectCurrentOrganization } from 'containers/Organization/Current/state/selectors';
import { selectCurrentUser } from 'containers/User/Current/Profile/state/selectors';
import { loadUserInvitations } from 'containers/User/Current/UserInvitations/state/actions';
import React, { MouseEventHandler, useEffect } from 'react';
import { X } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { AccountSwitcherForm } from './AccountSwitcherForm';
import messages from './messages';

export const AccountSwitcher: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const currentOrganization = useSelector(selectCurrentOrganization);
  const authHistory = useSelector(selectAuthHistoryOrdered);

  const currentIdTokenPayload = authHistory.find((h) => h.idTokenPayload?.email === currentUser?.email)?.idTokenPayload;

  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    dispatch(loadUserInvitations());
  }, []);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!currentUser) return <></>;

  return (
    <>
      <div className="pr-8">
        <TriggerButton
          organizationAddress={currentOrganization.address}
          userEmail={currentUser.email}
          userIconSrc={currentIdTokenPayload?.picture}
          onClick={handleClick}
        />
      </div>
      <MuiMenu
        id={'account-switcher'}
        anchorEl={anchorEl}
        // keepMounted // Cant keepMounted or tabs bug out a bit
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transitionDuration={0} // Needed or tabs bug out a bit
        MenuListProps={{
          className: 'sm:w-96',
        }}
        elevation={2}
      >
        <div className="bg-secondary-main text-sm text-secondary-contrastText p-4 font-medium flex">
          <div className="flex items-center flex-grow">{intl.formatMessage(messages.accountSwitcherYourProfile)}</div>
          <div className="pl-4 flex-none">
            <button onClick={handleClose}>
              <X className="text-secondary-contrastText" size={24} />
            </button>
          </div>
        </div>
        <AccountSwitcherForm onClose={handleClose} />
      </MuiMenu>
    </>
  );
};

type TriggerButtonProps = {
  userEmail: string;
  userIconSrc?: string;
  organizationAddress: string;
  onClick: MouseEventHandler<HTMLDivElement>;
};
export const TriggerButton: React.FC<TriggerButtonProps> = ({
  userEmail,
  userIconSrc,
  organizationAddress,
  onClick,
}) => {
  const behindStyle: React.CSSProperties = {
    zIndex: 1,
    marginLeft: '-16px',
  };

  return (
    <div className="cursor-pointer" onClick={onClick}>
      <MuiAvatarGroup spacing="small">
        <OrganizationAvatar organizationAddress={organizationAddress} />
        <UserAvatar userEmail={userEmail} userIconSrc={userIconSrc} style={behindStyle} />
      </MuiAvatarGroup>
    </div>
  );
};
