import { selectAPIClient } from 'containers/App/state/selectors';
import { loadOrganizations } from 'containers/Organization/List/state/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { all, call, select } from 'typed-redux-saga';
import { loadCurrentUser } from '../../Profile/state/actions';
import {
  AcceptInvitationAction,
  acceptInvitationSuccess,
  declineInvitationSuccess,
  loadUserInvitations as loadUserInvitationsAction,
  loadUserInvitationsSuccess,
} from './actions';

/**
 * Worker Sagas
 */
export function* loadUserInvitations() {
  const client = yield* select(selectAPIClient);
  const response = yield* call(client.getUserInvitationsByUser);
  yield put(loadUserInvitationsSuccess(response.data.invitations));
}

export function* acceptInvitation({ invitationCode, onSuccess }: AcceptInvitationAction) {
  const client = yield* select(selectAPIClient);
  yield* call(client.acceptUserInvitation, invitationCode);
  yield put(loadUserInvitationsAction());
  yield put(loadOrganizations());
  yield put(loadCurrentUser());
  yield put(acceptInvitationSuccess());
  onSuccess();
}

export function* declineInvitation({ invitationCode, onSuccess }: AcceptInvitationAction) {
  const client = yield* select(selectAPIClient);
  yield* call(client.rejectUserInvitation, invitationCode);
  yield put(loadUserInvitationsAction());
  yield put(declineInvitationSuccess());
  onSuccess();
}

/**
 * Watcher Sagas
 */
export function* watchUserInvitations() {
  yield all([
    yield takeLatest('app/User/Current/UserInvitations/LOAD_USER_INVITATIONS', loadUserInvitations),
    yield takeLatest('app/User/Current/UserInvitations/ACCEPT_USER_INVITATION', acceptInvitation),
    yield takeLatest('app/User/Current/UserInvitations/DECLINE_USER_INVITATION', declineInvitation),
  ]);
}
