import { defineMessages } from 'react-intl';

const scope = 'app.Nav';

export default defineMessages({
  accountSwitcherYourProfile: {
    id: `${scope}.accountSwitcherYourProfile`,
    defaultMessage: `Your Profile`,
  },
  accountSwitcherSettings: {
    id: `${scope}.accountSwitcherSettings`,
    defaultMessage: `Settings`,
  },
  accountSwitcherLogout: {
    id: `${scope}.accountSwitcherLogout`,
    defaultMessage: `Logout`,
  },
  accountSwitcherLogIntoAnother: {
    id: `${scope}.accountSwitcherLogIntoAnother`,
    defaultMessage: `Log into another account`,
  },
  accountSwitcherLogOutOfAll: {
    id: `${scope}.accountSwitcherLogOutOfAll`,
    defaultMessage: `Log out of all`,
  },
  showMore: {
    id: `${scope}.showMore`,
    defaultMessage: `Show more`,
  },
  notificationsMenuHeading: {
    id: `${scope}.notificationsMenuHeading`,
    defaultMessage: `Your Tasks`,
    description: `Heading of notifications menu`,
  },
  emptyNotificationsListContent: {
    id: `${scope}.emptyNotificationsListContent`,
    defaultMessage: `You have no new notifications.`,
    description: `Content of notifications menu if empty`,
  },
  organizationInvited: {
    id: `${scope}.organizationInvited`,
    defaultMessage: `{organizationName} (invited)`,
  },
  searchTextPlaceholder: {
    id: `${scope}.searchTextPlaceholder`,
    defaultMessage: `Search`,
  },
  agreementsLabel: {
    id: `${scope}.agreementsLabel`,
    defaultMessage: `Agreements`,
  },
  templatesLabel: {
    id: `${scope}.templatesLabel`,
    defaultMessage: `Templates`,
  },
  assetsLabel: {
    id: `${scope}.assetsLabel`,
    defaultMessage: `Wallet`,
  },
  profileLabel: {
    id: `${scope}.profileLabel`,
    defaultMessage: `Profile`,
  },
  adminLabel: {
    id: `${scope}.adminLabel`,
    defaultMessage: `Admin`,
  },
  overviewLabel: {
    id: `${scope}.overviewLabel`,
    defaultMessage: `Overview`,
  },
});
