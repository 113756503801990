import { merge as lmerge } from 'lodash';

/**
 * Wraps lodash merge
 *
 * Requires all arguments to be same type
 */
export const merge = <T extends object>(base: T, ...toMerge: T[]): T => {
  return lmerge(base, ...toMerge);
};

/**
 * Requires all arguments to be same type
 */
export const mergeShallow = <T extends object>(base: T, ...toMerge: T[]): T => {
  return Object.assign(base, ...toMerge);
};
