import { FormItem, PasswordInput } from 'components/Form';
import { SubmitButton } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { useFormikContext } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { ResetPasswordForm } from '../types';

type Props = {
  loading: boolean;
  email: string;
};

export const FormPanel: React.FC<Props> = ({ loading, email }) => {
  const intl = useIntl();
  const { handleSubmit } = useFormikContext<ResetPasswordForm>();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormItem>
          <div className="text-gray-400">
            {intl.formatMessage(messages.enterAndConfirmNewPassword, { email: email })}
          </div>
        </FormItem>
        <PasswordInput
          name="password"
          placeholder={intl.formatMessage(messages.password)}
          inputConfig={{ errorMessageDisplayMode: 'tooltip' }}
        />
        <PasswordInput
          name="confirmPassword"
          placeholder={intl.formatMessage(messages.confirmPassword)}
          inputConfig={{ errorMessageDisplayMode: 'tooltip' }}
        />
        <div className="pt-4">
          <SubmitButton disabled={loading}>
            {intl.formatMessage(messages.confirm)}
          </SubmitButton>
        </div>
      </form>
    </>
  );
};
