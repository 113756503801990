import { getEnvironment, isForeignChainEnabled } from 'configs';

export const isCurrentPath = (currentPath: string, path: string): boolean => {
  // This will only compare the first segment
  // so "/assets/" will match "/assets/{id}/"
  return currentPath.split('/')[1]?.toLowerCase() === path.split('/')[1]?.toLowerCase();
};

export const assetScreensEnabled = () => {
  return isForeignChainEnabled() && getEnvironment() != 'production';
};
