/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { connectRouter } from 'connected-react-router';
import activityReducer from 'containers/Activity/reducer';
import agreementReducer from 'containers/Agreement/reducer';
import globalReducer from 'containers/App/state/reducer';
import assetReducer from 'containers/Asset/reducer';
import authReducer from 'containers/Auth/reducer';
import eventReducer from 'containers/Event/reducer';
import integrationReducer from 'containers/Integration/reducer';
import organizationReducer from 'containers/Organization/reducer';
import overviewReducer from 'containers/Overview/reducer';
import preferencesReducer from 'containers/Preferences/reducer';
import tasksReducer from 'containers/Task/reducer';
import userReducer from 'containers/User/reducer';
import { combineReducers } from 'redux';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(history, injectedReducers) {
  const rootReducer = combineReducers({
    // Overview
    overview: overviewReducer,

    // Participants
    auth: authReducer,
    users: userReducer,
    organizations: organizationReducer,
    preferences: preferencesReducer,

    // Contracting
    agreement: agreementReducer,
    activity: activityReducer,
    event: eventReducer,
    task: tasksReducer,
    asset: assetReducer,

    // App
    global: globalReducer,
    router: connectRouter(history),
    integration: integrationReducer,
    ...injectedReducers,
  });
  return rootReducer;
}
