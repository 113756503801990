import { showSnackbarErrorFormattedMessage } from 'containers/App/state/saga';
import { selectAPIClient } from 'containers/App/state/selectors';
import { loadOrganizationSuccess } from 'containers/Organization/Current/state/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import messages from '../../messages';
import { receiveSwitchingOrganization, SwitchOrganization } from './actions';

/**
 * Worker Sagas
 */
export function* switchOrganization({ address, redirectURL }: SwitchOrganization) {
  try {
    yield put(receiveSwitchingOrganization(true));

    const client = yield* select(selectAPIClient);
    const {
      data: { organization },
    } = yield* call(client.getOrganization, address);

    yield put(loadOrganizationSuccess(organization));

    // Give state a chance to sync before reloading
    setTimeout(() => {
      if (redirectURL) {
        window.location.assign(redirectURL);
      } else {
        window.location.reload();
      }
    }, 200);
  } catch (err) {
    yield showSnackbarErrorFormattedMessage(messages.loadOrganizationError);
  }
}

/**
 * Watcher Sagas
 */
export function* watchSwitchOrganization() {
  yield takeLatest<SwitchOrganization>('app/Organization/Switch/SWITCH_ORGANIZATION', switchOrganization);
}
