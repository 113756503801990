import MuiAvatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { PaletteKey, Shade } from 'containers/Theme/types';
import React, { Children } from 'react';
import { useAvatarProps } from './hooks/useAvatarProps';
import { getAvatarStyles } from './styles';

const useStyles = makeStyles((theme) => ({
  root: (props: AvatarProps) => ({
    ...getAvatarStyles(props.size, theme),
  }),
}));

export type AvatarSize = 's' | 'm';

export type AvatarProps = {
  name: string;
  textColor?: PaletteKey | 'white';
  textShade?: Shade;
  bgColor?: PaletteKey | 'white';
  bgShade?: Shade;
  borderColor?: PaletteKey | 'white';
  borderShade?: Shade;
  size?: AvatarSize;
};

export const Avatar: React.FC<AvatarProps> = (props) => {
  const classes = useStyles(props);
  const [initials, bgColor, textColor, border] = useAvatarProps(
    props.name,
    props.bgColor,
    props.bgShade,
    props.textColor,
    props.textShade,
    props.borderColor,
    props.borderShade,
  );
  return (
    <Tooltip title={props.name}>
      <MuiAvatar
        style={{
          color: textColor,
          backgroundColor: bgColor,
          border,
        }}
        className={classes.root}
      >
        {props.children ? Children.toArray(props.children) : initials}
      </MuiAvatar>
    </Tooltip>
  );
};
