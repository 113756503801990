/*
 * NotFoundPage Messages
 *
 * This contains all the text for the NotFoundPage component.
 */
import { defineMessages } from 'react-intl';

export const scope = 'app.containers.error';

export const messages = defineMessages({
  notFoundHeader: {
    id: `${scope}.notFoundHeader`,
    defaultMessage: `Oops, something's missing`,
  },
  notFoundDescription: {
    id: `${scope}.notFoundDescription`,
    defaultMessage: `We can't seem to locate this resource. This can happen if your permissions change or if the creator deleted your resource.`,
  },
  serverErrorHeader: {
    id: `${scope}.serverErrorHeader`,
    defaultMessage: `Sorry, something went wrong`,
  },
  serverErrorDescription: {
    id: `${scope}.serverErrorDescription`,
    defaultMessage: `We're not quite sure what happened here. You can go back or contact us at {email} for more assistance.`,
  },
  backToHome: {
    id: `${scope}.backToHome`,
    defaultMessage: 'Back to Home',
  },
  previousPage: {
    id: `${scope}.previousPage`,
    defaultMessage: 'Previous Page',
  },
  paymentRequiredErrorHeader: {
    id: `${scope}.paymentRequiredErrorHeader`,
    defaultMessage: `Payment Plan Error`,
  },
  paymentRequiredErrorDescription: {
    id: `${scope}.paymentRequiredErrorDescription`,
    defaultMessage: `Your payment plan does not allow you access the resource. You can go back or contact us at {email} for more assistance.`,
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error',
  },
  errorMessage: {
    id: `${scope}.errorMessage`,
    defaultMessage: 'Error message: ',
  },
  failedRequest: {
    id: `${scope}.failedRequest`,
    defaultMessage: 'Your request has failed.',
  },
  youAreNotAuthorizedToViewThisPage: {
    id: `${scope}.youAreNotAuthorizedToViewThisPage`,
    defaultMessage: 'You are not authorized to view this page.',
  },
  somethingWentWrong: {
    id: `${scope}.somethingWentWrong`,
    defaultMessage: 'Sorry, something went wrong.',
  },
  badRequest: {
    id: `${scope}.badRequest`,
    defaultMessage: "We can't process your request. Please make sure all parameters have been entered correctly.",
  },
  yourSessionHasExpired: {
    id: `${scope}.yourSessionHasExpired`,
    defaultMessage: 'Your session has expired. Please log back in.',
  },
  helpPageHeading: {
    id: `${scope}.helpPageHeading`,
    defaultMessage: 'Oops!',
  },
  helpPageExplanation: {
    id: `${scope}.helpPageExplanation`,
    defaultMessage: "If we've brought you here, that means something has gone wrong.",
  },
  helpPageInstructions: {
    id: `${scope}.helpPageInstructions`,
    defaultMessage:
      "We're sorry you're experiencing issues on our platform. Please help by contacting us to resolve any issues.",
  },
  clientSideError: {
    id: `${scope}.clientSideError`,
    defaultMessage: "Looks like you've run into a client-side error!",
  },
  noOrganizationErrorHeader: {
    id: `${scope}.noOrganizationErrorHeader`,
    defaultMessage: "Nearly there, but something's wrong",
  },
  noOrganizationErrorDescription: {
    id: `${scope}.noOrganizationErrorDescription`,
    defaultMessage:
      'Sorry, but it appears your email, {email} is not associated with an organization. Please re-register using the email that matches your invitation, or contact us.',
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Contact Us',
  },
  register: {
    id: `${scope}.register`,
    defaultMessage: 'Register Again',
  },
});
