import { combineReducers } from 'redux';
import policyReducer from './Policy/state/reducer';
import subscriptionReducer from './Subscription/state/reducer';
import { EventState } from './types';

const reducer = combineReducers<EventState>({
  policy: policyReducer,
  subscription: subscriptionReducer,
});

export default reducer;
