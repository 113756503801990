import { Checkbox as CheckboxMD, FormControlLabel, FormGroup } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { InputEndAdornment } from './common';
import { FormItem } from './FormItem';
import { useInputState } from './Hooks';
import { InputConfig } from './types';

type Props = {
  name: string; // make name required
  label?: string | React.ReactNode;
  disabled?: boolean;
  formItemLabel?: string;
  inputConfig?: InputConfig;
};

export const Checkbox: React.FC<Props> = (props) => {
  const [input, meta] = useField(props.name);

  const { label, disabled, formItemLabel, inputConfig, ...rest } = props;

  const inputState = useInputState(inputConfig, meta);

  return (
    <FormItem label={formItemLabel} inputState={inputState}>
      <FormGroup row>
        <FormControlLabel
          control={
            <CheckboxMD {...input} {...rest} checked={input.value} id={input.name} size="small" disabled={disabled} />
          }
          label={label}
        />
        <FormControlLabel control={<InputEndAdornment inputState={inputState} />} label={<></>} />
      </FormGroup>
    </FormItem>
  );
};

type CheckboxControlProps = Omit<Props, 'formItemLabel' | 'inputConfig' | 'name'> & {
  name?: string;
  checked: boolean;
  size?: 'small' | 'medium';
  indeterminate?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const CheckboxControl: React.FC<CheckboxControlProps> = (props) => {
  const { label, ...rest } = props;

  return <FormControlLabel disabled={props.disabled} control={<CheckboxMD {...rest} />} label={label} />;
};
