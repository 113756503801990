import { selectAPIClient } from 'containers/App/state/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { LoadTasks, loadTasksSuccess } from './actions';

/**
 * Worker Sagas
 */
export function* loadTasks({}: LoadTasks) {
  const client = yield* select(selectAPIClient);
  const {
    data: { tasks },
  } = yield* call(client.getUserTasks);
  yield put(loadTasksSuccess(tasks));
}

/**
 * Watcher Sagas
 */
export function* watchLoadTasks() {
  yield takeLatest('app/Task/List/LOAD_TASKS', loadTasks);
}
