import { ButtonSize, ButtonType, TextTransform } from './types';

export function getClasses(
  type: ButtonType,
  size: ButtonSize,
  className: string,
  disableResponsiveness: boolean,
  textTransform: TextTransform,
  icon: boolean,
) {
  const baseClasses = 'btn shadow-none';
  // Apply classes
  let sizeClasses = `${getSizeClasses(size)}`;
  if (disableResponsiveness) sizeClasses = sizeClasses.split('sm:').join('');

  let classes = `${baseClasses} ${getTypeClasses(type)} ${sizeClasses}`;
  classes = `${classes} ${textTransform} ${className}`;

  if (icon) classes = `${classes} inline-flex items-center`;
  return classes;
}

export const getTypeClasses = (type: ButtonType): string => {
  switch (type) {
    case 'primary':
      return 'bg-primary-main text-primary-contrastText active:bg-primary-dark hover:shadow-xs hover:bg-primary-dark';
    case 'outlined':
      return 'border border-primary-main bg-white text-primary-main hover:shadow-xs';
    case 'gray-outlined':
      return 'border border-gray-600 bg-white text-gray-dark hover:shadow-xs';
    case 'gray-light-outlined':
      return 'border border-gray-300 bg-white text-gray-dark hover:shadow-xs';
    case 'warning-outlined':
      return 'border border-warning-main bg-white text-warning-main hover:shadow-xs focus:ring-warning-main';
    case 'danger-outlined':
      return 'border border-error-main bg-white text-error-main hover:shadow-xs focus:ring-error-main';
    case 'danger':
      return 'text-error-contrastText bg-error-main hover:shadow-xs focus:ring-error-main';
    case 'accent3':
      return 'text-accent3-contrastText bg-accent3-main hover:shadow-xs focus:ring-accent3-main';
    case 'accent3-light':
      return 'text-secondary-dark bg-accent3-extraLight hover:shadow-xs focus:ring-accent3-main';
    case 'primary-link':
      return 'text-primary-main bg-transparent hover:shadow-none';
    case 'secondary-link':
      return 'text-secondary-main bg-transparent hover:shadow-none';
    case 'gray-link':
      return 'text-gray-700 bg-transparent hover:shadow-none';
    case 'dark-gray-link':
      return 'text-gray-dark bg-transparent hover:shadow-none';
    default:
      return '';
  }
};

export const getSizeClasses = (size: ButtonSize): string => {
  switch (size) {
    case 'xxs':
      return 'h-6	text-xs rounded-sm px-3.5';
    case 'xs':
      return 'h-8	text-xs rounded-sm px-3.5';
    case 's':
      return 'h-8	text-xs sm:h-9 sm:rounded sm:px-4 sm:text-sm';
    case 'm':
      return 'h-8	text-xs sm:h-10 sm:rounded sm:px-5 sm:text-base';
    case 'l':
      return 'h-9	text-sm sm:h-11 sm:rounded sm:px-5 sm:text-lg';
    case 'xl':
      return 'h-10 text-base sm:h-14 sm:rounded-md sm:px-6 sm:text-xl';
    default:
      return '';
  }
};

export const getIconSize = (size: ButtonSize): string => {
  switch (size) {
    case 'xs':
      return '14px';
    case 's':
      return '16px';
    case 'm':
      return '17px';
    case 'l':
      return '18px';
    case 'xl':
      return '20px';
    default:
      return '15px';
  }
};
