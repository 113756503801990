/*
 * UserAccount Messages
 *
 * This contains all the text for the UserAccount component.
 */
import { defineMessages } from 'react-intl';

const scope = `app.containers.UserAccount`;

export default defineMessages({
  name: {
    id: `${scope}.fullName`,
    defaultMessage: 'Full Name',
  },
  preferredName: {
    id: `${scope}.preferredName`,
    defaultMessage: 'Preferred Name',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Username',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  userNotFound: {
    id: `${scope}.userNotFound`,
    defaultMessage: 'user not found',
  },
  turnOnMFA: {
    id: `${scope}.turnOnMFA`,
    defaultMessage: 'Turn On Two-factor Authentication',
  },
  turnOffMFA: {
    id: `${scope}.turnOffMFA`,
    defaultMessage: 'Turn Off Two-factor Authentication',
  },
  role: {
    id: `${scope}.role`,
    defaultMessage: 'Role',
  },
  roleACCOUNT_ADMIN: {
    id: `${scope}.roleACCOUNT_ADMIN`,
    defaultMessage: 'Admin',
  },
  roleSTANDARD_USER: {
    id: `${scope}.roleSTANDARD_USER`,
    defaultMessage: 'Standard',
  },
  roleRESTRICTED_USER: {
    id: `${scope}.roleRESTRICTED_USER`,
    defaultMessage: 'Restricted',
  },
  roleSIGNING_AUTHORITY: {
    id: `${scope}.roleSIGNING_AUTHORITY`,
    defaultMessage: 'Signing Authority',
  },
  roleACCOUNT_ADMINDescription: {
    id: `${scope}.roleACCOUNT_ADMINDescription`,
    defaultMessage: 'Can invite users, manage teams, assign signing authority, & change permissions',
  },
  roleSTANDARD_USERDescription: {
    id: `${scope}.roleSTANDARD_USERDescription`,
    defaultMessage: 'Can create templates and agreements',
  },
  roleRESTRICTED_USERDescription: {
    id: `${scope}.roleRESTRICTED_USERDescription`,
    defaultMessage:
      'Can read agreements and templates assigned to their team and can create agreements from those templates',
  },
});
