import { DocumentType } from '@monax/aeger/dist/document';
import { bufferToDataURL, bufferToString } from 'containers/Agreement/Documents/util';
import produce from 'immer';
import { AgreementDocumentState } from '../types';
import { AgreementDocumentAction } from './actions';

export const initialState: AgreementDocumentState = {
  documents: {},
  documentMeta: {},
  documentErrors: {},
  documentParams: {},
  uploading: false,
  previewing: 0,
  documentsExpanded: false,
  assemblingDocument: false,
};

const reducer = (state: AgreementDocumentState = initialState, action: AgreementDocumentAction) =>
  produce(
    state,
    (draftState): AgreementDocumentState => {
      switch (action.type) {
        case 'app/Agreement/Document/LOAD_ASSEMBLED_DOCUMENT': {
          if (!draftState.documents[action.grant]) draftState.assemblingDocument = true;
          return draftState;
        }
        case 'app/Agreement/Document/LOAD_ASSEMBLED_DOCUMENT_SUCCESS': {
          const { grant, meta, data } = action.assembled;
          if (data) {
            const { dataURI, rawDisplay } = getDocumentData(data.data, meta.mime);
            draftState.documents[grant] = {
              grant,
              meta,
              dataURI,
              rawDisplay,
            };
          }
          draftState.assemblingDocument = false;
          return draftState;
        }
        case 'app/Agreement/Document/LOAD_ASSEMBLED_DOCUMENT_ERROR':
          draftState.assemblingDocument = false;
          draftState.documentErrors[action.grant] = action.error;
          return draftState;
        case 'app/Agreement/Document/LOAD_DOCUMENT_META_SUCCESS': {
          const { grant, meta, params } = action;
          draftState.documentMeta[grant] = meta;
          if (params) {
            draftState.documentParams[grant] = params;
          }
          return draftState;
        }
        case 'app/Agreement/Document/LOAD_DOCUMENT_META_ERROR':
          draftState.documentErrors[action.grant] = action.error;
          return draftState;

        case 'app/Agreement/Document/UPLOAD_DOCUMENTS':
          draftState.uploading = true;
          return draftState;
        case 'app/Agreement/Document/UPLOAD_DOCUMENTS_SUCCESS': {
          action.documents.forEach(({ grant, meta, params }) => {
            draftState.documentMeta[grant] = meta;
            draftState.documentParams[grant] = params;
          });
          draftState.uploading = false;
          return draftState;
        }
        case 'app/Agreement/Document/UPLOAD_DOCUMENTS_ERROR':
          draftState.uploading = false;
          return draftState;

        case 'app/Agreement/Document/LOAD_DOCUMENT_SUCCESS':
          {
            const { grant, file, meta } = action;
            const { dataURI, rawDisplay } = getDocumentData(file, meta.mime);
            draftState.documents[grant] = {
              grant,
              meta,
              dataURI,
              rawDisplay,
            };
          }
          return draftState;
        case 'app/Agreement/Document/LOAD_DOCUMENT_ERROR':
          draftState.documentErrors[action.grant] = action.error;
          return draftState;
        case 'app/Agreement/Document/UPLOAD_DOCUMENT':
          draftState.uploading = true;
          return draftState;
        case 'app/Agreement/Document/UPLOAD_DOCUMENT_SUCCESS':
          draftState.uploading = false;
          return draftState;
        case 'app/Agreement/Document/UPLOAD_DOCUMENT_ERROR':
          draftState.uploading = false;
          return draftState;
        case 'app/Agreement/Document/DOCUMENTS_EXPANDED':
          draftState.documentsExpanded = action.documentsExpanded;
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;

function getDocumentData(file: Buffer | number[], mime: string) {
  const dataURI = bufferToDataURL(file, mime);
  let rawDisplay;
  if (
    mime === DocumentType.PLAIN ||
    mime === DocumentType.MD ||
    mime === DocumentType.JSON ||
    mime === DocumentType.HTML ||
    mime === DocumentType.SVG
  ) {
    rawDisplay = bufferToString(file);
  }
  return { dataURI, rawDisplay };
}
