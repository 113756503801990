import { defineMessages } from 'react-intl';

export const scope = 'app.containers.assets';

export const messages = defineMessages({
  wallet: {
    id: `${scope}.wallet`,
    defaultMessage: 'Wallet',
  },
  createNewAsset: {
    id: `${scope}.createNewAsset`,
    defaultMessage: 'Create New Asset',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Create',
  },
  menu: {
    id: `${scope}.menu`,
    defaultMessage: 'Menu',
  },
  emptyRecordHeader: {
    id: `${scope}.emptyRecordHeader`,
    defaultMessage: 'Your Wallet’s Information will appear here',
  },
  emptyRecordDescription: {
    id: `${scope}.emptyRecordDescription`,
    defaultMessage: "You don't have any wallet yet. To get things into motion you should create your first.",
  },
  importFromBlockchain: {
    id: `${scope}.importFromBlockchain`,
    defaultMessage: 'Import Assets from Blockchain',
  },
  startImport: {
    id: `${scope}.startImport`,
    defaultMessage: 'Start Import',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  assetImportComplete: {
    id: `${scope}.assetImportComplete`,
    defaultMessage: 'Asset import complete, {count} {count, plural, one {Asset} other {Assets}} imported.',
  },
  assetImportSelectWallet: {
    id: `${scope}.assetImportSelectWallet`,
    defaultMessage: 'Select Wallet',
  },
  assetImportSelectWalletPlaceholder: {
    id: `${scope}.assetImportSelectWalletPlaceholder`,
    defaultMessage: 'What Blockchain wallet would you like us to use to search for your assets?',
  },
  assetImportAddWallet: {
    id: `${scope}.assetImportAddWallet`,
    defaultMessage: 'Add new wallet',
  },
});
