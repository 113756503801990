import React from 'react';
import { ErrorAdornment, ValidAdornment } from '../Adornments';
import { InputState } from '../Hooks';

type Props = {
  inputState: InputState;
};

export const InputEndAdornment: React.FC<Props> = ({ inputState }) => {
  const { errorAdornmentVisible, errorAdornmentMessage, validAdornmentVisible, errorTooltipVisible } = inputState;

  if (errorAdornmentVisible) {
    return <ErrorAdornment message={errorAdornmentMessage} errorTooltipVisible={errorTooltipVisible} />;
  }
  if (validAdornmentVisible) return <ValidAdornment />;

  return <></>;
};
