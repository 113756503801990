import { showSnackbarErrorFormattedMessage } from 'containers/App/state/saga';
import { selectAPIClient } from 'containers/App/state/selectors';
import { put, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import messages from '../../messages';
import { LoadOrganizations, loadOrganizationsSuccess } from './actions';

/**
 * Worker Sagas
 */

export function* loadOrganizations() {
  try {
    const client = yield* select(selectAPIClient);
    const {
      data: { organizations },
    } = yield* call(client.listOrganizations);
    yield put(loadOrganizationsSuccess(organizations));
  } catch (err) {
    yield showSnackbarErrorFormattedMessage(messages.loadOrganizationError);
  }
}

/**
 * Watcher Sagas
 */

export function* watchLoadOrganizations() {
  yield takeLatest<LoadOrganizations>('app/Organization/List/LOAD_ORGANIZATIONS', loadOrganizations);
}
