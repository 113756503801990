import { IDToken } from '@monax/types';
import { ContentLoader } from 'components/Loader';
import { fromUnixTime } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ApplicationState } from 'types';
import { receiveAuthToken } from './state/actions';
import {
  selectAuthError,
  selectHasAuth0Provider,
  selectHasToken,
  selectIDTokenFromAuth,
  selectIsUsingSocialAuth,
  selectWebAuth,
} from './state/selectors';

const CheckSessionComponent: React.FC<PropsFromState> = ({
  webAuth,
  hasToken,
  children,
  error,
  idToken,
  social,
  auth0,
}) => {
  const dispatch = useDispatch();
  const [checkingSession, setCheckingSession] = useState(true);

  // Check if the user is signed in already by attempting to get the token
  useEffect(() => {
    const checkSession = () =>
      webAuth.checkSession({}, (error, result) => {
        if (result?.accessToken && result?.idTokenPayload) {
          // User is signed in, set the token
          console.log('tokens obtained from auth0');
          dispatch(receiveAuthToken(result.accessToken, result.idTokenPayload));
          // Scheduling token renewal
          if (result.idTokenPayload.exp) {
            console.log(
              `scheduling token renewal for 1 hour before ${fromUnixTime(result.idTokenPayload.exp).toISOString()}`,
            );
            setTimeout(checkSession, getRenewTime(result.idTokenPayload));
          }
        } else {
          // User is NOT signed in, clear any existing token
          console.log(`failed to obtain token: ${error?.description}`);
          dispatch(receiveAuthToken(null, null));
        }
        setCheckingSession(false);
      });

    console.log('checking for existing user session');
    if (!hasToken) {
      console.log('no token found in store, checking session');
      checkSession();
    } else if (!social && !auth0) {
      console.log('tokens found in store, but not using auth0/social login');
      setCheckingSession(false);
    } else {
      console.log('tokens found in store from auth0/social provider');
      if (idToken.exp) {
        console.log(`scheduling token renewal for 1 hour before ${fromUnixTime(idToken.exp).toISOString()}`);
        setTimeout(checkSession, getRenewTime(idToken));
      }
      setCheckingSession(false);
    }
  }, []);

  if (error) console.error('session error: user session cleared', error.name);

  return !checkingSession ? <>{children}</> : <ContentLoader />;
};

function getRenewTime(idToken: IDToken): number {
  const expiresIn = fromUnixTime(idToken.exp).getTime() - new Date().getTime();
  return expiresIn - 3600000; // expiration date - buffer period of one hour
}

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
  webAuth: selectWebAuth(state),
  error: selectAuthError(state),
  hasToken: selectHasToken(state),
  idToken: selectIDTokenFromAuth(state),
  auth0: selectHasAuth0Provider(state),
  social: selectIsUsingSocialAuth(state),
});
export const CheckSession = connect(mapStateToProps)(CheckSessionComponent);
