import { RegisterQueryParameters } from '@monax/types';
import { SmallCardWithGraphic } from 'components/Wizard_v4/SmallCardWithGraphic';
import graphic3 from 'images/agreement/contract-with-plus-icon.svg';
import graphic1 from 'images/agreement/woman-with-plane-icon.svg';
import graphic2 from 'images/agreement/woman-with-plus-icon.svg';
import React, { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { parseQueryString } from 'utils/queryString';
import messages from '../messages';

export const InvitePanel: React.FC = () => {
  const intl = useIntl();
  const query: RegisterQueryParameters = parseQueryString(window.location.search);
  return (
    <div className="w-full p-32">
      <div className="mb-12">
        <h3 className="text-info-main font-semibold text-3xl">
          {intl.formatMessage(messages.inviteWelcomeHeader2, {
            name: query.name || query.email,
          })}
        </h3>
      </div>
      <div>
        <SmallCardWithGraphic content={getCard1Message(intl, query)} graphic={<img src={graphic1} />} />
        <SmallCardWithGraphic content={getCard2Message(intl, query)} graphic={<img src={graphic2} />} />
        <SmallCardWithGraphic
          content={intl.formatMessage(messages.inviteCard3_default)}
          graphic={<img src={graphic3} className="mb-2" />}
        />
      </div>
    </div>
  );
};

const HighlightedText: React.FC = (props) => <span className="text-primary-main font-semibold">{props.children}</span>;

function getCard1Message(intl: IntlShape, props: RegisterQueryParameters): ReactNode {
  if (props.agreement && props.counterparty) {
    return intl.formatMessage(messages.inviteCard1_byCounterparty, {
      agreement: <HighlightedText>{props.agreement}</HighlightedText>,
      counterparty: <HighlightedText>{props.counterparty}</HighlightedText>,
    });
  }
  if (props.organization) {
    return intl.formatMessage(messages.inviteCard1_byOrganization, {
      organization: <HighlightedText>{props.organization}</HighlightedText>,
    });
  }
  return intl.formatMessage(messages.inviteCard1_default);
}

function getCard2Message(intl: IntlShape, props: RegisterQueryParameters): ReactNode {
  if (props.byAgreement === 'true' || props.agreement) {
    return intl.formatMessage(messages.inviteCard2_byAgreement, {
      email: <HighlightedText>{props.email}</HighlightedText>,
    });
  }
  return intl.formatMessage(messages.inviteCard2_default, {
    email: <HighlightedText>{props.email}</HighlightedText>,
  });
}
