import { defineMessages } from 'react-intl';

export const scope = 'app.containers.assets.list';

export const messages = defineMessages({
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  yourWallet: {
    id: `${scope}.yourWallet`,
    defaultMessage: 'Your Wallet',
  },
  created: {
    id: `${scope}.created`,
    defaultMessage: 'Created',
  },
  emptyListHeader: {
    id: `${scope}.emptyListHeader`,
    defaultMessage: 'Your Wallet’s will show here',
  },
  emptyListDescription: {
    id: `${scope}.emptyListDescription`,
    defaultMessage: 'After creating some wallet, you can switch between them here.',
  },
});
