import { UserTask, UserTaskTypeEnum } from '@monax/types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../messages';

export const useTaskMessage = () => {
  const intl = useIntl();

  const getMessage = useCallback((task: UserTask) => {
    if (task.type === UserTaskTypeEnum.signingActivity) {
      return intl.formatMessage(messages.taskSignActivityDetail, { agreementName: task.detail });
    }
    if (task.type === UserTaskTypeEnum.activity) {
      return intl.formatMessage(messages.taskActivityDetail, { taskName: task.name, agreementName: task.detail });
    }
    if (task.type === UserTaskTypeEnum.connection) {
      return intl.formatMessage(messages.taskConnectionDetail, { nameOrEmail: task.detail });
    }
    if (task.type === UserTaskTypeEnum.renewal) {
      return intl.formatMessage(messages.taskAgreementRenewalDetail, { agreementName: task.detail });
    }
    if (task.type === UserTaskTypeEnum.partyConfirmation) {
      return intl.formatMessage(messages.taskPartyConfirmationDetail, { agreementName: task.detail });
    }
    if (task.type === UserTaskTypeEnum.blockchainSignature) {
      return intl.formatMessage(messages.taskBlockchainSignatureDetail, { agreementName: task.detail });
    }
    return intl.formatMessage(messages.taskDraftApprovalDetail, { agreementName: task.detail });
  }, []);

  return {
    getMessage,
  };
};
