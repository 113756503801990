/*
 *
 * Organizations reducer
 *
 */

import { MemberOrganization } from '@monax/types';
import produce from 'immer';
import { OrganizationAction } from './actions';

export const initialState: MemberOrganization | null = null;

const organizationsReducer = (state = initialState, action: OrganizationAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Organization/Current/LOAD_ORGANIZATION_SUCCESS': {
        return action.organization;
      }

      // From outside containers
      // Manage
      case 'app/Organization/Manage/REMOVE_MEMBER_SUCCESS':
        draftState.users = draftState.users.filter((address) => address !== action.address);
        draftState.usersByRole.RESTRICTED_USER = draftState.usersByRole.RESTRICTED_USER.filter(
          (address) => address !== action.address,
        );
        delete draftState.rolesByUser[action.address];
        return draftState;
      case 'app/Organization/Manage/UPDATE_ORGANIZATION_SETTINGS_SUCCESS':
        draftState.name = action.settings.name;
        draftState.signingURL = action.settings.signingURL;
        draftState.defaultTimezone = action.settings.defaultTimezone;
        draftState.defaultDateFormat = action.settings.defaultDateFormat;
        draftState.domain = action.settings.domain;
        draftState.billingEmail = action.settings.billingEmail;
        draftState.themeOverrides = action.settings.themeOverrides;
        draftState.requireSigningByAll = action.settings.requireSigningByAll;
        return draftState;

      default:
        return draftState;
    }
  });

export default organizationsReducer;
