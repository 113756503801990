import { LinkButton } from 'components/Common';
import { AuthLayout } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { DEFAULT_NAME } from 'containers/Theme/types';
import { useTheme } from 'containers/Theme/useTheme';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { RESTART_ON_REMOUNT } from 'utils/constants';
import { useInjectSaga } from 'utils/injectSaga';
import { resetPasswordRequestSchema } from 'validation/login';
import { validator } from 'validation/yup';
import { WhiteLabelAuthLayout } from '../controls/WhiteLabelAuthLayout';
import { FormImagePanel, FormPanel, RequestSentImagePanel, RequestSentPanel } from './controls';
import { sendResetPasswordRequestEmail } from './state/actions';
import { watchSendResetPasswordRequestEmail } from './state/saga';
import { ResetPasswordRequestForm } from './types';

const validateForm = validator(resetPasswordRequestSchema);

export const ResetPasswordRequest: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { themeName } = useTheme();
  const [loading, setLoading] = useState<boolean>(false);
  const [requestSent, setRequestSent] = useState<boolean>(false);

  useInjectSaga({
    key: 'email-reset-password-request',
    saga: watchSendResetPasswordRequestEmail,
    mode: RESTART_ON_REMOUNT,
  });

  const resetPasswordRequest = (values: ResetPasswordRequestForm) => {
    setLoading(true);

    dispatch(
      sendResetPasswordRequestEmail(
        values.usernameOrEmail,
        // on success
        () => {
          setRequestSent(true);
        },
        // on always
        () => {
          setLoading(false);
        },
      ),
    );
  };

  const header = requestSent
    ? intl.formatMessage(messages.pleaseCheckYourInbox)
    : intl.formatMessage(messages.resetYourPassword);
  const title = requestSent
    ? intl.formatMessage(messages.resetYourPassword)
    : intl.formatMessage(messages.forgotSomething);
  const rightPanel = requestSent ? <RequestSentImagePanel /> : <FormImagePanel />;

  const backButton = (
    <LinkButton to="/login" size="s" type="outlined">
      {intl.formatMessage(messages.back)}
    </LinkButton>
  );

  const Wrapper = themeName === DEFAULT_NAME ? AuthLayout : WhiteLabelAuthLayout;
  //This should change to a subdomain check probably.

  return (
    <Wrapper header={header} title={title} headerAddon={backButton} rightPanel={rightPanel}>
      <Formik<ResetPasswordRequestForm>
        initialValues={{
          usernameOrEmail: '',
        }}
        onSubmit={resetPasswordRequest}
        validate={validateForm}
        validateOnMount={true}
      >
        <>
          {!requestSent && <FormPanel loading={loading} />}
          {requestSent && <RequestSentPanel loading={loading} />}
        </>
      </Formik>
    </Wrapper>
  );
};
