import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import MuiAvatarGroup from '@material-ui/lab/AvatarGroup';
import { Tooltip } from 'components/Tooltip/Tooltip';
import React from 'react';
import { AvatarProps } from './Avatar';
import { getInitials, useAvatarProps } from './hooks/useAvatarProps';
import { getAvatarStyles } from './styles';

const useStyles = makeStyles((theme) => ({
  root: (props: Props) => ({
    '& div': getAvatarStyles(props.size, theme),
  }),
}));

type Props = {
  names: string[];
  maxCount?: number;
} & Omit<AvatarProps, 'name'>;

export const AvatarGroup: React.FC<Props> = ({ size = 'm', ...rest }) => {
  const props = {
    size,
    ...rest,
  };

  const classes = useStyles(props);

  const [, bgColor, textColor, border] = useAvatarProps(
    '',
    props.bgColor || 'primary',
    props.bgShade || 'main',
    props.textColor || 'white',
    props.textShade || 'main',
    props.borderColor || 'white',
    props.borderShade || 'main',
  );

  if (props.names.length === 0) return <></>;

  return (
    <MuiAvatarGroup max={props.maxCount} className={classes.root}>
      {props.names.map((name, i) => {
        const initials = getInitials(name);

        return (
          <Tooltip key={name} title={name}>
            <Avatar
              key={i}
              style={{
                color: textColor,
                backgroundColor: bgColor,
                border,
              }}
            >
              {initials}
            </Avatar>
          </Tooltip>
        );
      })}
    </MuiAvatarGroup>
  );
};
