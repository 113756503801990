import { selectCurrentOrganization } from 'containers/Organization/Current/state/selectors';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from 'utils/string';
import config from '../../../configs';

export const useAppTitleTemplate = (): string => {
  const currentOrganization = useSelector(selectCurrentOrganization);
  let environment = '';
  if (config.siteData.environment && config.siteData.environment !== 'production')
    environment = `${capitalizeFirstLetter(config.siteData.environment)} |`;
  return `${currentOrganization?.name || config.siteData.title} | ${environment} %s`;
};
