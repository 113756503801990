import { selectAPIClient } from 'containers/App/state/selectors';
import { put, takeLatest } from 'redux-saga/effects';
import { all, call, select } from 'typed-redux-saga';
import {
  AddForeignAddressAction,
  addForeignAddressSuccess,
  loadForeignAddressesSuccess,
  RemoveForeignAddressAction,
  removeForeignAddressSuccess,
  UpdateForeignAddressAction,
  updateForeignAddressSuccess,
} from './actions';

/**
 * Worker Sagas
 */
export function* loadUserForeignAddresses() {
  const client = yield* select(selectAPIClient);
  const response = yield* call(client.getUserForeignAddresses);
  yield put(loadForeignAddressesSuccess(response.data));
}

export function* addUserForeignAddresses({ address, onSuccess }: AddForeignAddressAction) {
  const client = yield* select(selectAPIClient);
  yield* call(client.addUserForeignAddress, {
    address: address.address,
    organizationAddress: address.organizationAddress,
    chainId: address.chainId,
    signature: address.signature,
    name: address.name,
  });
  yield put(addForeignAddressSuccess());
  onSuccess();
}

export function* removeUserForeignAddresses({ address, chainId, onSuccess }: RemoveForeignAddressAction) {
  const client = yield* select(selectAPIClient);
  yield* call(client.removeUserForeignAddress, chainId, address);
  yield put(removeForeignAddressSuccess());
  onSuccess();
}

export function* updateUserForeignAddresses({ address, chainId, update, onSuccess }: UpdateForeignAddressAction) {
  const client = yield* select(selectAPIClient);
  yield* call(client.updateUserForeignAddress, chainId, address, update);
  yield put(updateForeignAddressSuccess());
  onSuccess();
}

/**
 * Watcher Sagas
 */
export function* watchUserForeignAddresses() {
  yield all([
    yield takeLatest('app/User/Current/ForeignAddresses/LOAD_FOREIGN_ADDRESSES', loadUserForeignAddresses),
    yield takeLatest('app/User/Current/ForeignAddresses/ADD_FOREIGN_ADDRESS', addUserForeignAddresses),
    yield takeLatest('app/User/Current/ForeignAddresses/REMOVE_FOREIGN_ADDRESS', removeUserForeignAddresses),
    yield takeLatest('app/User/Current/ForeignAddresses/UPDATE_FOREIGN_ADDRESS', updateUserForeignAddresses),
  ]);
}
