import { AgreementObligation, AgreementParameter } from '@monax/types';

export enum Step {
  fulfill = 0,
  complete = 1,
}

export type ObligationState = {
  readonly visible: boolean;
  readonly obligationId: string | null | undefined;
  readonly obligation: AgreementObligation | null;
  readonly requesting: 'load' | 'submit';
  readonly currentStep: Step;
};

export type CompleteObligationRedirectRouteMatchProps = {
  agreementAddress: string;
  obligationId: string;
};

export type CompletionFormParameter = AgreementParameter;

export type CompletionFormData = {
  parameters: CompletionFormParameter[];
  notes: string | null;
  files: File[];
};
