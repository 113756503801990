import { CommonWallet } from './CommonWallet';
import { Wallet } from './types';
import { attachListeners } from './util';

export class MetamaskWallet extends CommonWallet implements Wallet {
  static isAvailable(): boolean {
    return !!window.ethereum;
  }

  public type = 'metamask' as const;

  private listenersAttached: boolean;

  async connect() {
    if (!window.ethereum) {
      // Not showing metamask as an option if no window.ethereum but just in case
      this.walletEvents.errorSet({
        type: 'availability',
        error: new Error('Do you have the metamask extension installed?'),
      });
      return;
    }

    try {
      const Web3 = (await import(/* webpackChunkName: "web3" */ 'web3')).default;
      this.web3 = new Web3(window.ethereum);
      this.walletEvents.accountSet(await this.getAccount());
      this.walletEvents.chainSet(await this.getChain());
      if (!this.listenersAttached) {
        attachListeners(window.ethereum, this.walletEvents);
        this.listenersAttached = true;
      }
    } catch (err) {
      this.walletEvents.errorSet({ type: 'connection', error: err });
    }
  }

  async disconnect() {
    // Doesn't look like window.ethereum has any disconnect function
  }
}
