import { SupportedLocale } from '../types';

export type ChangeLocale = ReturnType<typeof changeLocale>;

export type LocaleAction = ChangeLocale;

export function changeLocale(locale: SupportedLocale) {
  return <const>{
    type: 'app/Preferences/Locale/CHANGE_LOCALE',
    locale: locale,
  };
}
