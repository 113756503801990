/*
 * App Messages
 *
 * This contains all the text for the App component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  fieldRequired: {
    id: 'app.containers.App.errors.fieldRequired',
    defaultMessage: 'This field is required',
    description: 'Input error message',
  },
  invalidDateFormat: {
    id: 'app.containers.App.errors.invalidDateFormat',
    defaultMessage: 'Invalid date format',
  },
  numbersOnly: {
    id: 'app.containers.App.errors.numbersOnly',
    defaultMessage: 'Must be a number',
    description: 'Input error message',
  },
  positiveValuesOnly: {
    id: 'app.containers.App.errors.positiveValuesOnly',
    defaultMessage: 'Positive values only',
  },
  wholeNumbersOnly: {
    id: 'app.containers.App.errors.wholeNumbersOnly',
    defaultMessage: 'Whole numbers only',
  },
  twoDecimalPlacesExceeded: {
    id: 'app.containers.App.errors.twoDecimalPlacesExceeded',
    defaultMessage: 'Maximum 2 decimal places allowed',
  },
  addressRequired: {
    id: 'app.containers.App.errors.addressRequired',
    defaultMessage: 'This field requires a 40-digit hexadecimal value (0-9, a/A-f/F)',
  },
  hexOfLengthRequired: {
    id: 'app.containers.App.errors.hexOfLengthRequired',
    defaultMessage: 'This field requires a {length}-digit hexadecimal value (0-9, a/A-f/F)',
  },
  hexRequired: {
    id: 'app.containers.App.errors.hexRequired',
    defaultMessage: 'This field requires a hexadecimal value (0-9, a/A-f/F)',
  },
  jsonRequired: {
    id: 'app.containers.App.errors.jsonRequired',
    defaultMessage: 'This field requires a JSON value',
  },
  ethAddress: {
    id: 'app.containers.App.errors.ethAddress',
    defaultMessage: 'This field requires a contract address',
  },
  tagAlreadyApplied: {
    id: 'app.containers.App.errors.tagAlreadyApplied',
    defaultMessage: 'Tag already applied',
  },
  lengthCannotExceed: {
    id: 'app.containers.App.errors.lengthCannotExceed',
    defaultMessage: 'Length cannot exceed {max} characters',
  },
  mustHaveSignatories: {
    id: 'app.containers.App.errors.mustHaveSignatories',
    defaultMessage: 'At least one signatory is required',
  },
  duplicatesNotPermitted: {
    id: 'app.containers.App.errors.duplicatesNotPermitted',
    defaultMessage: 'No duplicates allowed',
  },
  invalidEmail: {
    id: 'app.containers.App.errors.invalidEmail',
    defaultMessage: 'Invalid email address',
    description: 'Error message for email inputs',
  },
  alphanumericAndSpecialCharactersOnly: {
    id: 'app.containers.App.errors.alphanumericAndSpecialCharactersOnly',
    defaultMessage: 'Only alphanumeric and the following characters allowed: _, +, -, ., !, #, $, ^, `, ~ and @',
    description: 'Error message for alphanumeric + restricted special char inputs',
  },
  booleanMustBeTrue: {
    id: 'app.containers.App.errors.booleanMustBeTrue',
    defaultMessage: 'This value must be true',
    description: 'Error message for boolean fields that require true value (ex, checkboxes that need to be checked)',
  },
  mustBeOneOf: {
    id: 'app.containers.App.errors.mustBeOneOf',
    defaultMessage: 'Must be one of: {values}',
    description: 'Error message for value not in given options',
  },
  notOneOf: {
    id: 'app.containers.App.errors.notOneOf',
    defaultMessage: 'May not be: {values}',
    description: 'Error message for value in given options',
  },
  emailOrAddress: {
    id: 'app.containers.App.errors.emailOrAddress',
    defaultMessage: 'This field requires a 40-digit hexadecimal value (0-9, a/A-f/F) or an email address',
    description: 'Error message for address/email fields',
  },
  valueForbidden: {
    id: 'app.containers.App.errors.valueForbidden',
    defaultMessage: 'This field must not have any value',
  },
  laterThanOpenOffset: {
    id: 'app.containers.App.errors.laterThanOpenOffset',
    defaultMessage: 'Must be later than window start date',
  },
  signingRequired: {
    id: 'app.containers.App.errors.signingRequired',
    defaultMessage: 'Requires signing parties',
  },
  numberMin: {
    id: 'app.containers.App.errors.numberMin',
    defaultMessage: 'Must be greater than or equal to {min}',
  },
  numberMax: {
    id: 'app.containers.App.errors.numberMax',
    defaultMessage: 'Must be less than or equal to {max}',
  },
  circularReferencesNotPermitted: {
    id: 'app.containers.App.errors.circularReferencesNotPermitted',
    defaultMessage: 'Relationship cannot be circular',
  },
  invalidValue: {
    id: 'app.containers.App.errors.invalidValue',
    defaultMessage: 'Invalid input',
  },
  valuesMustBeEqual: {
    id: 'app.containers.App.errors.valuesMustBeEqual',
    defaultMessage: 'Values must match',
  },
  mustBeType: {
    id: 'app.containers.App.errors.mustBeType',
    defaultMessage: 'Must be type: {type}',
  },
});
