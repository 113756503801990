import { TemplateFilter, TemplatesListItem } from '@monax/types';
import { ListTemplateSearch, ListTemplateSort } from '../types';
import { initialState } from './reducer';

export type LoadTemplates = ReturnType<typeof loadTemplates>;
export type LoadTemplatesSuccess = ReturnType<typeof loadTemplatesSuccess>;
export type LoadMoreTemplates = ReturnType<typeof loadMoreTemplates>;
export type LoadMoreTemplatesSuccess = ReturnType<typeof loadMoreTemplatesSuccess>;
export type ReceiveListTemplateSort = ReturnType<typeof receiveListTemplateSort>;
export type ReceiveListTemplateSearch = ReturnType<typeof receiveListTemplateSearch>;
export type ToggleSelectedTemplate = ReturnType<typeof toggleSelectedTemplate>;
export type ClearAllSelectedTemplates = ReturnType<typeof clearAllSelectedTemplates>;
export type SelectAllTemplates = ReturnType<typeof selectAllTemplates>;
export type ReceiveTemplateResourceFilterId = ReturnType<typeof receiveTemplateResourceFilterId>;
export type ReceiveDefaultTemplateResourceFilter = ReturnType<typeof receiveDefaultTemplateResourceFilter>;

export type HomePageTemplatesAction =
  | LoadTemplates
  | LoadTemplatesSuccess
  | LoadMoreTemplates
  | LoadMoreTemplatesSuccess
  | ReceiveListTemplateSort
  | ReceiveListTemplateSearch
  | ToggleSelectedTemplate
  | ClearAllSelectedTemplates
  | SelectAllTemplates
  | ReceiveTemplateResourceFilterId
  | ReceiveDefaultTemplateResourceFilter;

/**
 * LOAD_TEMPLATES
 */
export function loadTemplates() {
  return <const>{
    type: 'app/Template_v4/List/Template/LOAD_TEMPLATES',
  };
}
export function loadTemplatesSuccess(templates: TemplatesListItem[], totalTemplates: number) {
  return <const>{
    type: 'app/Template_v4/List/Template/LOAD_TEMPLATES_SUCCESS',
    templates,
    totalTemplates,
  };
}

/**
 * LOAD_MORE_TEMPLATES
 */
export function loadMoreTemplates() {
  return <const>{
    type: 'app/Template_v4/List/Template/LOAD_MORE_TEMPLATES',
  };
}
export function loadMoreTemplatesSuccess(templates: TemplatesListItem[], totalTemplates: number) {
  return <const>{
    type: 'app/Template_v4/List/Template/LOAD_MORE_TEMPLATES_SUCCESS',
    templates,
    totalTemplates,
  };
}

/**
 * RECEIVE_LIST_AGREEMENT_SORT
 */
export function receiveListTemplateSort(sort: ListTemplateSort) {
  return <const>{
    type: 'app/Template_v4/List/Template/RECEIVE_LIST_AGREEMENT_SORT',
    sort,
  };
}

/**
 * RECEIVE_LIST_AGREEMENT_SORT
 * @param search Optional. If not provided, all search values will be reset
 */
export function receiveListTemplateSearch(search: Partial<ListTemplateSearch> = initialState.search) {
  return <const>{
    type: 'app/Template_v4/List/Template/RECEIVE_LIST_AGREEMENT_SEARCH',
    search,
  };
}

/**
 * TOGGLE_SELECTED_AGREEMENT
 */
export function toggleSelectedTemplate(id: string) {
  return <const>{
    type: 'app/Template_v4/List/Template/TOGGLE_SELECTED_AGREEMENT',
    id,
  };
}

/**
 * CLEAR_ALL_SELECTED_TEMPLATES
 */
export function clearAllSelectedTemplates() {
  return <const>{
    type: 'app/Template_v4/List/Template/CLEAR_ALL_SELECTED_TEMPLATES',
  };
}

/**
 * SELECT_ALL_TEMPLATES
 */
export function selectAllTemplates() {
  return <const>{
    type: 'app/Template_v4/List/Template/SELECT_ALL_TEMPLATES',
  };
}

/**
 * RECEIVE_RESOURCE_FILTER_ID
 */
export function receiveTemplateResourceFilterId(id?: string) {
  return <const>{
    type: 'app/Template_v4/List/Template/RECEIVE_RESOURCE_FILTER_ID',
    id,
  };
}

/**
 * RECEIVE_DEFAULT_RESOURCE_FILTER
 */
export function receiveDefaultTemplateResourceFilter(filter: TemplateFilter) {
  return <const>{
    type: 'app/Template_v4/List/Template/RECEIVE_DEFAULT_RESOURCE_FILTER',
    filter,
  };
}
