/*
 *
 * Users reducer
 *
 */
import { combineReducers } from 'redux';
import currentReducer from './Current/reducer';
import listReducer from './List/reducer';
import { UserState } from './types';

const reducer = combineReducers<UserState>({
  list: listReducer,
  current: currentReducer,
});

export default reducer;
