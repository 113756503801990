import { UserTask, UserTaskTypeEnum } from '@monax/types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../messages';

export const useTaskTitle = () => {
  const intl = useIntl();

  const getTitle = useCallback((task: UserTask) => {
    if (task.type === UserTaskTypeEnum.signingActivity) {
      return intl.formatMessage(messages.taskSignActivityName);
    }
    if (task.type === UserTaskTypeEnum.activity) {
      return intl.formatMessage(messages.taskActivityName);
    }
    if (task.type === UserTaskTypeEnum.connection) {
      return intl.formatMessage(messages.taskConnectionName);
    }
    if (task.type === UserTaskTypeEnum.renewal) {
      return intl.formatMessage(messages.taskAgreementRenewalName);
    }
    if (task.type === UserTaskTypeEnum.partyConfirmation) {
      return intl.formatMessage(messages.taskPartyConfirmationName);
    }
    if (task.type === UserTaskTypeEnum.blockchainSignature) {
      return intl.formatMessage(messages.taskBlockchainSignatureName);
    }
    return intl.formatMessage(messages.taskDraftApprovalName);
  }, []);

  return {
    getTitle,
  };
};
