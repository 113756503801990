import { APIClient, ForeignChainId } from '@monax/types';
import { addFormattedNotification } from 'containers/App/state/actions';
import { JsonArray } from 'io-ts-types';
import { Dispatch } from 'redux';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { messages } from './messages';
import { WalletEvents } from './types';
import { chainNameFromId, getNonce, signIn } from './util';

export class CommonWallet {
  constructor(public walletEvents: WalletEvents) {
    this.walletEvents = walletEvents;
  }

  public web3: Web3;

  public async getAccount(): Promise<string> {
    const [account = null] = await this.web3.eth.requestAccounts();
    return account;
  }

  public async getChain(): Promise<ForeignChainId> {
    const chain = chainNameFromId(await this.web3.eth.getChainId());
    return chain;
  }

  public async sign(apiClient: APIClient, dispatch: Dispatch) {
    try {
      const { fromUtf8 } = await import(/* webpackChunkName: "web3-utils" */ 'web3-utils');
      const account = await this.getAccount();
      const nonce = await getNonce(apiClient, account);
      dispatch(addFormattedNotification({ messageDescriptor: messages.pleaseSign, open: true, className: 'info' }));
      const signature = await this.web3.eth.personal.sign(fromUtf8(nonce), account, '');
      return { account, signature };
    } catch (err) {
      this.walletEvents.errorSet({ type: 'sign', error: err });
      return null;
    }
  }

  public async signIn(apiClient: APIClient, dispatch: Dispatch) {
    try {
      const signRes = await this.sign(apiClient, dispatch);
      if (!signRes) {
        return;
      }
      await signIn(apiClient, dispatch, signRes.account, signRes.signature);
    } catch (err) {
      this.walletEvents.errorSet({ type: 'signIn', error: err });
    }
  }

  public async send(contractAddress: string, method: AbiItem, abi: AbiItem[], parameters: JsonArray) {
    try {
      const contract = new this.web3.eth.Contract(abi, contractAddress);
      const tx = contract.methods[method.name](...parameters);
      const receipt = await tx.send({ from: await this.getAccount() });
      if (receipt) {
        return receipt;
      }
      return null;
    } catch (err) {
      this.walletEvents.errorSet({ type: 'tx', error: err });
      return null;
    }
  }
}
