import { getAssetCreateRoute } from '@monax/aeger/dist/webapp-routes';
import { AutoOverflow } from 'components/Layout/AutoOverflow';
import { Card } from 'components/Layout/Card';
import { Layout } from 'containers/App/components/Layout';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';
import { HeaderDesktop } from './components';
import { AssetTable } from './List/AssetTable';
import { loadAssets } from './List/state/actions';
import { watchAssets } from './List/state/saga';

export const AssetLayoutDesktop: React.FC = ({ children }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  useInjectSaga({
    key: 'load-assets',
    saga: watchAssets,
  });

  useEffect(() => {
    dispatch(loadAssets());
  }, []);

  const hideAssetList = history.location.pathname === getAssetCreateRoute();

  return (
    <Layout>
      <div className="flex h-full w-full flex-col">
        <div className="pb-6">
          <HeaderDesktop />
        </div>
        <AutoOverflow className={`${hideAssetList ? '' : 'grid grid-cols-4 gap-8'}`}>
          {!hideAssetList && (
            <Card autoOverflow={true}>
              <AssetTable />
            </Card>
          )}
          <AutoOverflow className="col-span-3">{children}</AutoOverflow>
        </AutoOverflow>
      </div>
    </Layout>
  );
};
