import SpeechBubble from 'components/SpeechBubble';
import { useOnClickOutside } from 'lib/hooks';
import React, { ReactNode, useRef } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';

type Props = {
  isOpen: boolean;
  openOptions: () => void;
  closeOptions: () => void;
  content: ReactNode;
};
export const RowOptions: React.FC<Props> = ({ isOpen, openOptions, closeOptions, content }) => {
  const baseRef = useRef(null);
  useOnClickOutside(baseRef, closeOptions);
  return (
    <div style={{ width: '30px' }} ref={baseRef}>
      <button type="button" onClick={isOpen ? closeOptions : openOptions}>
        {isOpen ? <ChevronUp className="text-gray-400 text-xl" /> : <ChevronDown className="text-gray-400 text-xl" />}
      </button>
      {isOpen && <SpeechBubble content={content} baseRef={baseRef} />}
    </div>
  );
};
