import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { LazyLoader } from 'components/LazyLoader';

const OAuth2Callback = LazyLoader(() => import('./Auth/OAuth2Callback'));
const ZapierLogin = LazyLoader(() => import('./Zapier/ZapierLogin'));

export const IntegrationRoutes: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Integration</title>
      </Helmet>
      <Switch>
        <Route exact path="/integrations/oauth2/callback" component={OAuth2Callback} />
        <Route exact path="/integrations/zapier/login" component={ZapierLogin} />
      </Switch>
    </React.Fragment>
  );
};
