import { AgreementFilter, TemplateFilter } from '@monax/types';
import { AgreementFilterForm } from 'containers/Agreement/List/Agreement/types';
import { every, isEmpty, values } from 'lodash';

export const getInitialTemplateFilter = (): TemplateFilter => {
  return {
    name: '',
    published: undefined,
    ownerScopes: [],
  };
};

export const getInitialAgreementFilter = (): AgreementFilterForm => {
  return {
    name: '',
    departmentIds: [],
    ownedByUs: false,
    statusDraft: false,
    statusDraftApproving: false,
    statusDraftReviewing: false,
    statusFormulated: false,
    statusExecuted: false,
    statusFulfilled: false,
    statusCancelled: false,
    dateType: null,
    dateName: null,
    dateFixedStart: '',
    dateFixedEnd: '',
    dateRelativeDurationAmount: null,
    dateRelativeDurationUnit: null,
  };
};

export const filterIsEmpty = (filter: TemplateFilter | AgreementFilter | undefined): boolean => {
  if (!filter) return true;
  return every(values(filter), isEmpty);
};
