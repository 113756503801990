import { RegisterQueryParameters } from '@monax/types';
import { Checkbox, Input } from 'components/Form';
import messages from 'containers/Auth/messages';
import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { getEthRegisterSchema } from 'validation/login';
import { validator } from 'validation/yup';
import { WalletLogin } from '../controls/WalletLogin';
import { EthRegisterForm } from './types';

type Props = {
  params: RegisterQueryParameters;
};

export const RegisterWithEthAddress: React.FC<Props> = ({ params }) => {
  const intl = useIntl();

  const ethRegisterSchema = getEthRegisterSchema(intl);
  const validateEethForm = validator(ethRegisterSchema);

  const { ethAddress: paramEthAddress } = params;

  return (
    <Formik<EthRegisterForm>
      initialValues={{
        address: paramEthAddress,
        tosAccepted: false,
      }}
      onSubmit={() => {}}
      validate={validateEethForm}
    >
      {({ setFieldError, values }) => {
        return (
          <form>
            <Input name="address" placeholder={intl.formatMessage(messages.ethereumAddress)} disabled={true} />
            <Checkbox
              name="tosAccepted"
              label={intl.formatMessage(messages.acceptTOS, {
                termsOfService: (
                  <a target="_blank" href="https://suscribo.com/terms/">
                    {intl.formatMessage(messages.termsOfService)}
                  </a>
                ),
                privacyPolicy: (
                  <a target="_blank" href="https://suscribo.com/privacy/">
                    {intl.formatMessage(messages.privacyPolicy)}
                  </a>
                ),
              })}
              inputConfig={{ errorMessageDisplayMode: 'tooltip' }}
            />
            <div className="pt-4">
              <WalletLogin
                disabled={!values.tosAccepted}
                requireAddress={{
                  value: paramEthAddress,
                  handleMismatch: () =>
                    setFieldError('address', intl.formatMessage(messages.metaMaskConnectedWithIncorrectAddress)),
                }}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
