/*
 *
 * Users reducer
 *
 */
import produce from 'immer';
import { CurrentUserProfileState } from '../types';
import { UserAction } from './actions';
import {
  // UI Components
  // API Requests
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_CURRENT_USER,
  LOAD_CURRENT_USER_SUCCESS,
  // Error
  RECEIVE_REQUEST_ERROR,
  REMOVE_USER_FROM_ORGANIZATION,
  REMOVE_USER_FROM_ORGANIZATION_SUCCESS,
  // UI
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_SIGNATURE,
  UPDATE_SIGNATURE_SUCCESS,
} from './constants';

export const initialState: CurrentUserProfileState = {
  user: null,
  requests: {},
  errors: {},
};

const profileReducer = (state = initialState, action: UserAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case CHANGE_PASSWORD_SUCCESS:
      case UPDATE_PROFILE_SUCCESS:
      case UPDATE_ROLE_SUCCESS:
      case UPDATE_SIGNATURE_SUCCESS:
      case REMOVE_USER_FROM_ORGANIZATION_SUCCESS:
        draftState.requests[action.type.split('_SUCCESS')[0]] = false;
        break;
      default:
        break;
    }

    switch (action.type) {
      case LOAD_CURRENT_USER_SUCCESS:
        draftState.user = action.user;
        return draftState;

      case CLEAR_CURRENT_USER:
        draftState.user = null;
        return draftState;

      case UPDATE_SIGNATURE_SUCCESS: {
        draftState.user.signatureGrant = action.signatureGrant;
        return draftState;
      }

      // Auth
      case 'app/Auth/RECEIVE_AUTH_ERROR':
        draftState.user = null;
        return draftState;
      case 'app/Auth/RECEIVE_AUTH_TOKEN':
        if (!action.token) draftState.user = null;
        return draftState;

      case CHANGE_PASSWORD:
      case UPDATE_PROFILE:
      case UPDATE_ROLE:
      case UPDATE_SIGNATURE:
      case REMOVE_USER_FROM_ORGANIZATION:
        draftState.requests[action.type] = true;
        draftState.errors[action.type] = null;
        return draftState;

      // Set requesting state to false and store error if request failed
      case RECEIVE_REQUEST_ERROR:
        draftState.requests[action.requestType] = false;
        draftState.errors[action.requestType] = action.error;
        return draftState;

      default:
        return draftState;
    }
  });

export default profileReducer;
