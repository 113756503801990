import { AgreementDraftBody, AgreementForeignChain, TemplateBody } from '@monax/types';
import { assign } from 'lodash';

export const mergeBody = <T extends TemplateBody | AgreementDraftBody>(
  form: T,
  update: RecursivePartial<T>,
  additional?: RecursivePartial<T>,
) => {
  // Merge child foreignChain properties
  let foreignChain: AgreementForeignChain = form.foreignChain;
  if (update.foreignChain) foreignChain = assign({}, form.foreignChain, update.foreignChain);

  // Assign rest
  return assign({}, form, update, { foreignChain: foreignChain }, additional ?? {});
};
