/**
 *
 * Dialog
 *
 * Be sure to add aria-labelledby="id...", referencing the modal title, to the Dialog.
 * Additionally, you may give a description of your modal dialog with the aria-describedby="id..." property on the Dialog.
 * Redux forms don't work in this component
 */

import { Dialog as MuiDialog, DialogActions, DialogContent } from '@material-ui/core';
import { Button, ButtonType } from 'components/Common/Button';
import { PaletteKey } from 'containers/Theme/types';
import React, { ButtonHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { DialogTitle } from './DialogTitle';
import messages from './messages';

type Props = {
  title: React.ReactNode;
  content: React.ReactNode;
  open: boolean;
  className?: PaletteKey;
  cancel?: () => void;
  submit?: () => void;
  submitButtonContent?: React.ReactNode;
  cancelButtonContent?: React.ReactNode;
  requireAction?: boolean;
  hideActions?: boolean;
  disabled?: boolean;
  disabledSubmit?: boolean;
  fullWidth?: boolean;
  maxWidth?: 'lg' | 'md';
  submitButtonType?: ButtonType;
  submitButtonButtonType?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  transparentBackdrop?: boolean;
  ChildrenWrapper?: React.FC;
};

export const Dialog: React.FC<Props> = (props) => {
  const {
    className = 'primary',
    title,
    content,
    open,
    cancel,
    submit,
    submitButtonContent = '',
    cancelButtonContent,
    requireAction,
    hideActions,
    disabled,
    disabledSubmit,
    maxWidth,
    fullWidth,
    submitButtonType,
    submitButtonButtonType = 'button',
    transparentBackdrop,
    ChildrenWrapper,
  } = props;
  const actions = [];
  const intl = useIntl();
  if (cancel) {
    actions.push(
      <Button key="cancel" type="gray-link" buttonType="button" onClick={cancel} disabled={disabled}>
        {cancelButtonContent || intl.formatMessage(messages.cancel)}
      </Button>,
    );
  }
  if (submit || submitButtonButtonType === 'submit') {
    actions.push(
      <Button
        key="submit"
        type={submitButtonType || (className === 'error' ? 'danger' : 'primary')}
        onClick={submit}
        disabled={disabled || disabledSubmit}
        buttonType={submitButtonButtonType}
      >
        {submitButtonContent}
      </Button>,
    );
  }
  const children = (
    <>
      <DialogTitle className={className} title={title} cancel={cancel} disabled={disabled}></DialogTitle>
      <DialogContent dividers>{content}</DialogContent>
      {hideActions || !actions.length ? null : <DialogActions>{hideActions ? [] : actions}</DialogActions>}
    </>
  );

  return (
    <MuiDialog
      disableBackdropClick={disabled || requireAction}
      disableEscapeKeyDown={disabled || requireAction}
      onClose={cancel}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      BackdropProps={{
        style: { background: `rgba(255, 255, 255, ${transparentBackdrop ? '0' : '0.8'})` },
      }}
      open={open}
    >
      {ChildrenWrapper ? <ChildrenWrapper>{children}</ChildrenWrapper> : children}
    </MuiDialog>
  );
};
