/*
 *
 * User actions
 *
 */

import { Address, AuthErrorCode, Email, Role, UserUpdate } from '@monax/types';
import { LoadAgreementSuccess } from 'containers/Agreement/PostChain/Record/state/actions';
import { ReceiveAuthError, ReceiveAuthToken } from 'containers/Auth/Auth/state/actions';
import { LoadOrganizationSuccess } from 'containers/Organization/Current/state/actions';
import { RequestError } from 'types';
import { CurrentUser } from '../types';
import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_CURRENT_USER,
  LOAD_CURRENT_USER,
  LOAD_CURRENT_USER_SUCCESS,

  // Redux action types- Error
  RECEIVE_REQUEST_ERROR,
  REMOVE_USER_FROM_ORGANIZATION,
  REMOVE_USER_FROM_ORGANIZATION_SUCCESS,
  RequestType,
  // Other
  REQUEST_TYPES,

  // Redux action types- UI
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_ROLE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_SIGNATURE,
  UPDATE_SIGNATURE_SUCCESS,
} from './constants';

export type UserAction =
  | ChangePassword
  | ChangePasswordSuccess
  | UpdateEmail
  | UpdateEmailSuccess
  | UpdateProfile
  | UpdateProfileSuccess
  | UpdateRole
  | UpdateRoleSuccess
  | UpdateSignature
  | UpdateSignatureSuccess
  | ReceiveError
  | LoadAgreementSuccess
  | LoadCurrentUserSuccess
  | LoadOrganizationSuccess
  | ClearCurrentUser
  | ReceiveAuthToken
  | ReceiveAuthError
  | RemoveUserFromOrganization
  | RemoveUserFromOrganizationSuccess;

/**
 * LOAD_CURRENT_USER
 */
export type LoadCurrentUser = ReturnType<typeof loadCurrentUser>;
export function loadCurrentUser() {
  return <const>{
    type: LOAD_CURRENT_USER,
  };
}

export type LoadCurrentUserSuccess = ReturnType<typeof loadCurrentUserSuccess>;
export function loadCurrentUserSuccess(user: CurrentUser) {
  return <const>{
    type: LOAD_CURRENT_USER_SUCCESS,
    user: user,
  };
}

export type ClearCurrentUser = ReturnType<typeof clearCurrentUser>;
export function clearCurrentUser() {
  return <const>{
    type: CLEAR_CURRENT_USER,
  };
}

/**
 * CHANGE_PASSWORD
 */
export type ChangePassword = ReturnType<typeof changePassword>;
export function changePassword(
  oldPassword: string,
  newPassword: string,
  onSuccess: () => void,
  onError: (errorCode: AuthErrorCode, errorMessage: string) => void,
  onAlways: () => void,
) {
  return <const>{
    type: CHANGE_PASSWORD,
    oldPassword,
    newPassword,
    onSuccess,
    onError,
    onAlways,
  };
}
export type ChangePasswordSuccess = ReturnType<typeof ChangePasswordSuccess>;
export function ChangePasswordSuccess() {
  return <const>{
    type: CHANGE_PASSWORD_SUCCESS,
  };
}

/**
 * UPDATE_EMAIL
 */
export type UpdateEmail = ReturnType<typeof updateEmail>;
export function updateEmail(email: Email, onSuccess?: () => void) {
  return <const>{
    type: UPDATE_EMAIL,
    email,
    onSuccess,
  };
}
export type UpdateEmailSuccess = ReturnType<typeof updateEmailSuccess>;
export function updateEmailSuccess() {
  return <const>{
    type: UPDATE_EMAIL_SUCCESS,
  };
}

/**
 * UPDATE_PROFILE
 */
export type UpdateProfile = ReturnType<typeof updateProfile>;
export function updateProfile(
  user: UserUpdate,
  redirectUrl?: string,
  showSuccessNotification?: boolean,
  onSuccess?: () => void,
) {
  return <const>{
    type: UPDATE_PROFILE,
    user,
    redirectUrl,
    showSuccessNotification,
    onSuccess,
  };
}
export type UpdateProfileSuccess = ReturnType<typeof updateProfileSuccess>;
export function updateProfileSuccess() {
  return <const>{
    type: UPDATE_PROFILE_SUCCESS,
  };
}

/**
 * UPDATE_ROLE
 */
export type UpdateRole = ReturnType<typeof updateRole>;
export function updateRole(userAddress: Address, oldRole: null | Role, newRole: null | Role, onSuccess?: () => void) {
  return <const>{
    type: UPDATE_ROLE,
    userAddress,
    oldRole,
    newRole,
    onSuccess,
  };
}
export type UpdateRoleSuccess = ReturnType<typeof updateRoleSuccess>;
export function updateRoleSuccess() {
  return <const>{
    type: UPDATE_ROLE_SUCCESS,
  };
}

/**
 * UPDATE_SIGNATURE
 */
export type UpdateSignature = ReturnType<typeof updateSignature>;
export function updateSignature(signature: Blob, onSuccess?: () => void) {
  return <const>{
    type: UPDATE_SIGNATURE,
    signature,
    onSuccess,
  };
}
export type UpdateSignatureSuccess = ReturnType<typeof updateSignatureSuccess>;
export function updateSignatureSuccess(signatureGrant: string) {
  return <const>{
    type: UPDATE_SIGNATURE_SUCCESS,
    signatureGrant,
  };
}

/**
 * ERROR
 */

export type ReceiveError = ReturnType<typeof receiveError>;
export function receiveError(requestType: RequestType, error: RequestError) {
  if (!REQUEST_TYPES[requestType]) throw new Error(`Request type ${requestType} not recognized`);
  return <const>{
    type: RECEIVE_REQUEST_ERROR,
    requestType,
    error,
  };
}

/**
 * REMOVE_USER_FROM_ORGANIZATION
 */
export type RemoveUserFromOrganization = ReturnType<typeof removeUserFromOrganization>;
export function removeUserFromOrganization(organizationAddress: Address, onSuccess?: () => void) {
  return <const>{
    type: REMOVE_USER_FROM_ORGANIZATION,
    organizationAddress,
    onSuccess,
  };
}
export type RemoveUserFromOrganizationSuccess = ReturnType<typeof removeUserFromOrganizationSuccess>;
export function removeUserFromOrganizationSuccess() {
  return <const>{
    type: REMOVE_USER_FROM_ORGANIZATION_SUCCESS,
  };
}
