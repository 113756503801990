import React, { MouseEventHandler, ReactNode } from 'react';

type Props = {
  closeOptions: () => void;
  onClick: MouseEventHandler<HTMLButtonElement>;
  content: ReactNode;
  disabled?: boolean;
  closeOnClick?: boolean;
};

export const rowOptionClasses =
  'block text-left w-full px-4 py-1 hover:bg-primary-extraLight first:rounded-t-md last:rounded-b-md';

export const RowOption: React.FC<Props> = ({ closeOptions, onClick, content, disabled, closeOnClick = true }) => {
  return (
    <button
      type="button"
      onClick={(e) => {
        onClick(e);
        if (closeOnClick) closeOptions();
      }}
      disabled={disabled}
      className={rowOptionClasses}
    >
      {content}
    </button>
  );
};
