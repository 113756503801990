import CircularProgressMUI from '@material-ui/core/CircularProgress';
import React from 'react';

type Props = {
  size?: React.ReactText;
  lightMode?: boolean;
};

export const CircularProgress: React.FC<Props> = ({ size, lightMode }) => (
  <CircularProgressMUI size={size} color={lightMode ? 'secondary' : 'primary'} />
);
