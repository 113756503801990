import { useViewport } from 'lib/hooks/useViewport';
import MobileDetect from 'mobile-detect';
import React, { useEffect, useState } from 'react';

const mobileDetect = new MobileDetect(window.navigator.userAgent);

export const MobileContext = React.createContext<{ isMobile: boolean; orientation: OrientationType }>({
  isMobile: false,
  orientation: 'landscape-primary',
});

export const MobileProvider: React.FC = ({ children }) => {
  const { width, height } = useViewport();

  const [isMobile, setIsMobile] = useState<boolean>(getIsMobile());
  const [orientation, setOrientation] = useState<OrientationType>(getOrientation());

  useEffect(() => {
    setIsMobile(getIsMobile());
    setOrientation(getOrientation());
  }, [width, height]);

  return <MobileContext.Provider value={{ isMobile, orientation }}>{children}</MobileContext.Provider>;
};

/* eslint-disable-next-line */
const getIsMobile = (): boolean => {
  // Use below for testing in Chrome with device set to 'Galaxy S5'
  // return window.innerWidth < 641;

  // we need to check !mobileDetect.tablet() here because mobileDetect.mobile() returns true on tablets
  return !!mobileDetect.mobile() && !mobileDetect.tablet();
};

/*
OrientationType 
===============
landscape-primary   ->  landscape
landscape-secondary ->  landscape but upside down
portrait-primary    ->  portrait
portrait-secondary  ->  portrait but upside down
*/
// https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
const getOrientation = (): OrientationType => {
  // Use below for testing in Chrome with device set to 'Galaxy S5'
  // return window.innerWidth < 361 ? 'portrait-primary' : 'landscape-primary';

  let orientation: OrientationType =
    /* eslint-disable-next-line */
    (window.screen.orientation || {}).type || window.screen['mozOrientation'] || window.screen['msOrientation'];

  // Fallback 1 - try deprecated 'window.orientation'
  if (!orientation && window.orientation != undefined)
    orientation = window.orientation === 0 ? 'portrait-primary' : 'landscape-primary';

  // Fallback 2 - compare height and width
  if (!orientation) orientation = window.innerHeight > window.innerWidth ? 'portrait-primary' : 'landscape-primary';

  return orientation;
};
