import { defineMessages } from 'react-intl';

export const scope = 'app.containers.assets.create';

export const messages = defineMessages({
  details_header_create: {
    id: `${scope}.details_header_create`,
    defaultMessage: 'Create a new Asset',
  },
  details_header_edit: {
    id: `${scope}.details_header_edit`,
    defaultMessage: 'Edit Asset',
  },
  details_description: {
    id: `${scope}.details_description`,
    defaultMessage: 'Add Wallet meta information here',
  },
  foreignChain_header: {
    id: `${scope}.foreignChain_header`,
    defaultMessage: 'Provide Blockchain and Token information',
  },
  foreignChain_description: {
    id: `${scope}.foreignChain_description`,
    defaultMessage: 'Insert relevant Blockchain information below',
  },
  token_header: {
    id: `${scope}.token_header`,
    defaultMessage: 'Connect your Asset',
  },
  token_description: {
    id: `${scope}.token_description`,
    defaultMessage: 'Add relevant asset information below',
  },
  template_header: {
    id: `${scope}.template_header`,
    defaultMessage: 'Connect a Template',
  },
  template_description: {
    id: `${scope}.template_description`,
    defaultMessage: 'Connect your Template and customize terms',
  },
  stepProgress: {
    id: `${scope}.stepProgress`,
    defaultMessage: 'Step {current} of {total}',
  },
  addNewWallet: {
    id: `${scope}.addNewWallet`,
    defaultMessage: 'Add new wallet',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  finish: {
    id: `${scope}.finish`,
    defaultMessage: 'Finish',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  form_name_label: {
    id: `${scope}.form_name_label`,
    defaultMessage: 'Asset Name',
  },
  form_name_placeholder: {
    id: `${scope}.form_name_placeholder`,
    defaultMessage: 'Provide a title for your asset',
  },
  form_description_label: {
    id: `${scope}.form_description_label`,
    defaultMessage: 'Asset Description',
  },
  form_description_placeholder: {
    id: `${scope}.form_description_placeholder`,
    defaultMessage: "What's the asset for?",
  },
  form_departmentIds_label: {
    id: `${scope}.form_departmentIds_label`,
    defaultMessage: 'Visibility',
  },
  form_departmentIds_placeholder: {
    id: `${scope}.form_departmentIds_placeholder`,
    defaultMessage: 'What teams should see this asset?',
  },
  form_foreignAddress_label: {
    id: `${scope}.form_foreignAddress_label`,
    defaultMessage: 'Select Blockchain Wallet',
  },
  form_foreignAddress_placeholder: {
    id: `${scope}.form_foreignAddress_placeholder`,
    defaultMessage: 'What Blockchain wallet would you like to use to manage this asset?',
  },
  form_foreignChainId_label: {
    id: `${scope}.form_foreignChainId_label`,
    defaultMessage: 'What blockchain should this connect to?',
  },
  form_foreignChainId_placeholder: {
    id: `${scope}.form_foreignChainId_placeholder`,
    defaultMessage: 'Select Blockchain',
  },
  form_foreignChainAssetType_label: {
    id: `${scope}.form_foreignChainAssetType_label`,
    defaultMessage: 'What type of asset is this?',
  },
  form_foreignChainAssetType_placeholder: {
    id: `${scope}.form_foreignChainAssetType_placeholder`,
    defaultMessage: 'Select Asset Type',
  },
  form_supportsMultipleAgreements_label: {
    id: `${scope}.form_supportsMultipleAgreements_label`,
    defaultMessage: 'How many agreements do you want associate with this asset?',
  },
  form_supportsMultipleAgreements_yes: {
    id: `${scope}.form_supportsMultipleAgreements_yes`,
    defaultMessage: 'Multiple',
  },
  form_supportsMultipleAgreements_no: {
    id: `${scope}.form_supportsMultipleAgreements_no`,
    defaultMessage: 'One',
  },
  form_assetCurrentlyExists_label: {
    id: `${scope}.form_assetCurrentlyExists_label`,
    defaultMessage: 'Does your asset currently exist?',
  },
  form_assetCurrentlyExists_yes: {
    id: `${scope}.form_assetCurrentlyExists_yes`,
    defaultMessage: 'Yes',
  },
  form_assetCurrentlyExists_no: {
    id: `${scope}.form_assetCurrentlyExists_no`,
    defaultMessage: 'No',
  },
  form_assetTokenContractAddress_label: {
    id: `${scope}.form_assetTokenContractAddress_label`,
    defaultMessage: 'What is your token contract addess?',
  },
  form_assetTokenContractAddress_placeholder: {
    id: `${scope}.form_assetTokenContractAddress_placeholder`,
    defaultMessage: 'Enter your token contract address',
  },
  form_assetTokenId_label: {
    id: `${scope}.form_assetTokenId_label`,
    defaultMessage: 'What is your token ID?',
  },
  form_assetTokenId_placeholder: {
    id: `${scope}.form_assetTokenId_placeholder`,
    defaultMessage: 'Enter your token ID',
  },
  form_beaconOperatorIds_label: {
    id: `${scope}.form_beaconOperatorIds_label`,
    defaultMessage: 'Where should it be listed?',
  },
  form_beaconOperatorIds_placeholder: {
    id: `${scope}.form_beaconOperatorIds_placeholder`,
    defaultMessage: 'Select platform',
  },
  form_templateId_label: {
    id: `${scope}.form_templateId_label`,
    defaultMessage: 'Select a template to connect',
  },
  form_templateId_placeholder: {
    id: `${scope}.form_templateId_placeholder`,
    defaultMessage: 'Select template',
  },
  form_lockTemplate_label: {
    id: `${scope}.form_lockTemplate_label`,
    defaultMessage: 'May this template be edited?',
  },
  form_lockTemplate_yes: {
    id: `${scope}.form_lockTemplate_yes`,
    defaultMessage: 'Yes',
  },
  form_lockTemplate_no: {
    id: `${scope}.form_lockTemplate_no`,
    defaultMessage: 'No',
  },
  assetCreated: {
    id: `${scope}.assetCreated`,
    defaultMessage: 'Asset Created',
  },
  assetUpdated: {
    id: `${scope}.assetUpdated`,
    defaultMessage: 'Asset Updated',
  },
  failedToCreateAsset: {
    id: `${scope}.failedToCreateAsset`,
    defaultMessage: 'Failed to create asset',
  },
  failedToUpdateAsset: {
    id: `${scope}.failedToUpdateAsset`,
    defaultMessage: 'Failed to create asset',
  },
});
