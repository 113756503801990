/**
 *
 * Table
 *
 */

import {
  createStyles,
  Table as MaUTable,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { ContentLoader } from 'components/Loader';
import { useTheme } from 'containers/Theme/useTheme';
import { useOnResizeCallback } from 'lib/hooks';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { ChevronExpand } from 'react-bootstrap-icons';
import { Column, PluginHook, SortingRule, useExpanded, usePagination, useSortBy, useTable } from 'react-table';
import VisibilitySensor from 'react-visibility-sensor';
import { RequestError } from 'types';
import { useTableCellStyles, useTableHeaderCellStyles } from './Wrappers';

const MIN_PAGE_SIZE = 5;

type Props<T extends object> = {
  data: T[];
  error?: RequestError;
  errorMessage?: ReactNode;
  columns: Column<T>[];
  header?: ReactNode;
  footer?: ReactNode;
  paginate?: boolean;
  manualSortBy?: boolean;
  onScrollEnd?: () => void; // fires when the last records becomes visible
  onSortChange?: (columns: SortingRule<T>[]) => void;
  tableHead?: ReactNode;
  hideTableHead?: boolean;
  loading?: boolean;
  emptyMessage?: string;
  isRowSelected?: (data: T) => boolean;
  disableSortBy?: boolean;
};

export const makeTable: <T extends object>() => React.FC<Props<T>> = () => ({
  data,
  error,
  errorMessage,
  columns,
  header = null,
  footer = null,
  paginate = false,
  manualSortBy = false,
  onScrollEnd,
  disableSortBy,
  onSortChange,
  tableHead,
  loading,
  emptyMessage,
  isRowSelected,
  hideTableHead,
}) => {
  const defaultState = {};
  const paginateState = { pageIndex: 0, pageSize: MIN_PAGE_SIZE };

  const classes = {
    cell: useTableCellStyles(),
    headerCell: useTableHeaderCellStyles(),
  };

  let hooks: PluginHook<typeof data[number]>[] = [useSortBy, useExpanded];

  if (paginate) {
    hooks.push(usePagination);
  }

  // Infinite rerender loop caused by redefining new empty array for null data fallback
  const defaultData: typeof data = useMemo(() => [], []);

  let initialState = defaultState;

  if (paginate) {
    initialState = {
      ...initialState,
      ...paginateState,
    };
  }

  const {
    headerGroups,
    rows,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: data || defaultData,
      initialState: initialState,
      disableSortBy: disableSortBy,
      manualSortBy: manualSortBy,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    ...hooks,
  );

  let adjustPageSize: () => void;
  let containerRef: React.MutableRefObject<HTMLDivElement>;

  if (paginate) {
    useEffect(() => {
      //If the base list of items changes, navigate back to page 1
      if (paginate) {
        gotoPage(0);
      }
    }, [data]);

    // Adjusts the page size depending on the container height
    containerRef = useRef<HTMLDivElement>(null);
    adjustPageSize = useCallback(() => {
      let height = containerRef.current.offsetHeight;
      height -= 50; // height of pager
      if (footer) height -= 40;
      if (header) height -= 100;
      setPageSize(Math.max(Math.floor(height / 70), MIN_PAGE_SIZE));
    }, []);

    useEffect(() => {
      adjustPageSize();
    }, []);

    useOnResizeCallback(adjustPageSize);
  }

  useEffect(() => {
    if (onSortChange) {
      onSortChange(sortBy);
    }
  }, [sortBy]);

  const { palette } = useTheme();

  if (error) return <>{errorMessage}</>;

  const records = page || rows;

  const onVisibleChange = (isVisible: boolean, index: number) => {
    if (isVisible && index === records.length - 1 && onScrollEnd) onScrollEnd();
  };

  if (emptyMessage && !loading && data?.length === 0)
    return <div className="w-full text-secondary-main py-6 text-center text-sm">{emptyMessage}</div>;

  if (loading && data?.length === 0) return <ContentLoader />;

  return (
    <div className="h-full overflow-y-auto relative" ref={containerRef}>
      {header}
      <MaUTable stickyHeader>
        {!tableHead && !hideTableHead && (
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    classes={classes.cell}
                    variant="head"
                    sortDirection={column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined}
                    {...column.getHeaderProps(column.canSort ? column.getSortByToggleProps() : undefined)}
                    style={{
                      width: column.width,
                      borderTop: column.isSorted && !column.isSortedDesc ? `2px solid ${palette.gray[600]}` : undefined,
                      borderBottom:
                        column.isSorted && column.isSortedDesc ? `2px solid ${palette.gray[600]}` : undefined,
                    }}
                    width={column.width}
                  >
                    {column.render('Header')}
                    {/* TODO- different icons for sortAsc vs sortDesc */}
                    {column.canSort ? (
                      <button type="button" className="ml-2">
                        <ChevronExpand className="text-gray-700" />
                      </button>
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
        )}
        {tableHead && !hideTableHead && (
          <TableHead>
            <TableRow>
              <TableCell classes={classes.headerCell} colSpan={columns.length} variant="head">
                {tableHead}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {records.map((row, index) => {
            prepareRow(row);
            return (
              <TableRow key={row.id} selected={isRowSelected && isRowSelected(row.original)}>
                <VisibilitySensor onChange={(visible) => onVisibleChange(visible, index)}>
                  <>
                    {row.cells.map((cell, cellIndex) => (
                      <TableCell width={columns[cellIndex].width} classes={classes.cell} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </TableCell>
                    ))}
                  </>
                </VisibilitySensor>
              </TableRow>
            );
          })}
        </TableBody>
      </MaUTable>
      {data && footer}
      {paginate && data && data.length > pageSize && (
        <div className="flex justify-center w-full">
          <Pager
            rowsPerPageOptions={[]}
            count={data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            onChangePage={(_e, pageIndex) => gotoPage(pageIndex)}
          />
        </div>
      )}
    </div>
  );
};

const Pager = withStyles(() =>
  createStyles({
    root: {
      border: 0,
    },
    caption: {
      fontSize: 12,
    },
  }),
)(TablePagination);
