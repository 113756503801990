import {
  getRequesterCanceledBy,
  getRequesterMayActAsParty,
  isSystemDate,
  requesterMayCancel,
  requesterMayDelete,
  TERM_PARAMETER_TYPES,
} from '@monax/aeger/dist/agreement/agreement';
import { getHighestCustomerType } from '@monax/aeger/dist/billing';
import { parseDuration } from '@monax/aeger/dist/duration';
import {
  Agreement,
  AgreementObligation,
  AgreementParameter,
  AgreementParty,
  AgreementRecipient,
  AgreementRequester,
  AgreementSystemDateEnum,
  CapabilityEnum,
  DateCycles,
  LegalState,
  LegalStateEnum,
  OrganizationInfoByAddress,
  ParameterTypeEnum,
  ThrottledResourceBalance,
  UserInfoByAddress,
} from '@monax/types';
import { selectAgreementViewedDocuments } from 'containers/Activity/Agreement/selectors';
import { PartiesSummaryProps, Party, RenewalConfig } from 'containers/Agreement/components';
import { selectDocuments } from 'containers/Agreement/Documents/state/selectors';
import { FormParty } from 'containers/Agreement/Form/types';
import { buildRepresentativeAddress } from 'containers/Agreement/Form/util';
import { getRemaining } from 'containers/Agreement/util/throttling';
import {
  selectCurrentOrganizationAddress,
  selectCurrentOrganizationCustomerType,
  selectCurrentOrganizationDepartmentsById,
} from 'containers/Organization/Current/state/selectors';
import { selectOrganizations } from 'containers/Organization/List/state/selectors';
import { parameterHasNoValue } from 'containers/ParameterType/utils';
import {
  selectCurrentUserAddress,
  selectCurrentUserCapabilities,
} from 'containers/User/Current/Profile/state/selectors';
import { selectUsers } from 'containers/User/List/selectors';
import { keyBy, last, reverse, sortBy } from 'lodash';
import { createSelector, OutputSelector } from 'reselect';
import { ApplicationState } from 'types';
import { AgreementRecordRequest, AgreementRecordState, RecordPanelTab } from '../types';

const rootSelector = (state: ApplicationState) => state.agreement.postChain.record;

export const selectRequests = createSelector(rootSelector, (root) => root.requests);

export const selectSection = createSelector(rootSelector, (root) => root.section);

export const selectOnSave = createSelector(rootSelector, (root) => root.onSave);

export const selectEditing = createSelector(rootSelector, (root) => !!root.onSave);

export const selectAgreement = createSelector(rootSelector, (root) => root.agreement);

export const selectAgreementResourceLimits = createSelector(rootSelector, (root) => root.resourceLimits);

export const selectAgreementThemeOverrides = createSelector(rootSelector, (root) => root.themeOverrides);

export const selectSignatures = createSelector(rootSelector, (root) => root.signatures);

export const selectBlockchainTransactions = createSelector(rootSelector, (root) => root.blockchainTransactions);

/**
 * Dialogs
 */
export const selectSignNudgeDialog = createSelector(rootSelector, (root) => root.signNudgeDialog);
export const selectVisibilityDialog = createSelector(rootSelector, (root) => root.visibilityDialog);
export const selectCancelDialog = createSelector(rootSelector, (root) => root.cancelDialog);
export const selectDeleteDialog = createSelector(rootSelector, (root) => root.deleteDialog);
export const selectNameDialog = createSelector(rootSelector, (root) => root.nameDialog);
export const selectObligationDialog = createSelector(rootSelector, (root) => root.obligationDialog);
export const selectBlockchainTransactionDialog = createSelector(
  rootSelector,
  (root) => root.blockchainTransactionDialog,
);
export const selectBlockchainLogDialog = createSelector(rootSelector, (root) => root.blockchainLogDialog);

/**
 * Request selectors
 */
export const selectRequestingAddEvents = makeSelectAgreementRequest(AgreementRecordRequest.ADD_EVENTS);
export const selectRequestingAddParameters = makeSelectAgreementRequest(AgreementRecordRequest.ADD_PARAMETERS);
export const selectRequestingCancelAgreement = makeSelectAgreementRequest(AgreementRecordRequest.CANCEL_AGREEMENT);
export const selectRequestingDeleteAgreement = makeSelectAgreementRequest(AgreementRecordRequest.DELETE_AGREEMENT);
export const selectRequestingDownloadAgreement = makeSelectAgreementRequest(AgreementRecordRequest.DOWNLOAD_AGREEMENT);
export const selectRequestingLoadAgreement = makeSelectAgreementRequest(AgreementRecordRequest.LOAD_AGREEMENT);
export const selectRequestingUpdateDocuments = makeSelectAgreementRequest(AgreementRecordRequest.UPDATE_DOCUMENTS);
export const selectRequestingUpdateName = makeSelectAgreementRequest(AgreementRecordRequest.UPDATE_NAME);
export const selectRequestingUpdateRepresentative = makeSelectAgreementRequest(
  AgreementRecordRequest.UPDATE_REPRESENTATIVE,
);
export const selectRequestingUpdateTeams = makeSelectAgreementRequest(AgreementRecordRequest.UPDATE_TEAMS);

/**
 * Signatures selectors
 */
export const selectSignatureParty = createSelector(rootSelector, (root) => root.signatureDialog);
export const selectSignature = createSelector(selectSignatures, selectSignatureParty, (signatures, party) => {
  const filteredSignatures = signatures?.filter((signature) => signature.signatoryAddress === party);
  return filteredSignatures.length === 0 ? undefined : filteredSignatures;
});

/**
 * Agreement property selectors
 */
export const selectAgreementAddress = makeSelectAgreementProperty('address', null);
export const selectAgreementSignatureFileGrant = makeSelectAgreementProperty('signaturesFileReference', '');
export const selectAgreementName = makeSelectAgreementProperty('name', '');
export const selectAgreementTimezone = makeSelectAgreementProperty('timezone', null);
export const selectAgreementDateFormat = makeSelectAgreementProperty('dateFormat', null);
export const selectAgreementTeams = makeSelectAgreementProperty('ownerScope', []);
export const selectAgreementLegalState = makeSelectAgreementProperty('legalState', undefined);
export const selectAgreementDocuments = makeSelectAgreementProperty('documents', { documents: [] });
export const selectAgreementParties = makeSelectAgreementProperty('parties', []);
export const selectAgreementParameters = makeSelectAgreementProperty('parameters', []);
export const selectAgreementObligations = makeSelectAgreementProperty('obligations', []);
export const selectAgreementNotifications = makeSelectAgreementProperty('notifications', []);
export const selectAgreementDateCycles = makeSelectAgreementProperty('dateCycles', {});
export const selectAgreementDateRelations = makeSelectAgreementProperty('dateRelations', {});
export const selectAgreementRepresentatives = makeSelectAgreementProperty('representatives', {});
export const selectAgreementOwner = makeSelectAgreementProperty('owner', null);
export const selectAgreementApprovers = makeSelectAgreementProperty('approvers', []);
export const selectAgreementRecipients = makeSelectAgreementProperty('recipients', []);
export const selectAgreementRequester = makeSelectAgreementProperty('requester', {
  address: undefined,
  organizationAddress: undefined,
  mayActAsParty: undefined,
  isOwner: false,
  isRepresentative: false,
  isViewer: false,
});

/**
 * Billing
 */
export const selectAgreementCustomerType = createSelector(
  selectAgreement,
  selectCurrentOrganizationCustomerType,
  (agreement, orgCustomerType) => getHighestCustomerType([agreement?.customerType, orgCustomerType]),
);

/**
 * Requester selectors
 */
export const selectIsOwner = makeSelectRequesterProperty('isOwner');
export const selectIsRepresentative = makeSelectRequesterProperty('isRepresentative');
export const selectMayActAsParty = makeSelectRequesterProperty('mayActAsParty');
export const selectUserIsParticipant = createSelector(
  selectIsOwner,
  selectIsRepresentative,
  selectMayActAsParty,
  (isOwner, isRepresentative, party) => isOwner || isRepresentative || !!party,
);

/**
 * Terms (ie non date/party parameters)
 */
export const selectTerms = createSelector(selectAgreementParameters, (parameters) =>
  parameters.filter((parameter): parameter is AgreementParameter & {
    type:
      | typeof ParameterTypeEnum.BOOLEAN
      | typeof ParameterTypeEnum.STRING
      | typeof ParameterTypeEnum.LARGE_TEXT
      | typeof ParameterTypeEnum.MONETARY_AMOUNT;
  } => TERM_PARAMETER_TYPES.some((t) => parameter.type === t)),
);

/**
 * Dates
 */

export const selectDates = createSelector(selectAgreementParameters, (parameters) =>
  parameters.filter(
    (parameter): parameter is AgreementParameter & { type: typeof ParameterTypeEnum.DATE } =>
      parameter.type === ParameterTypeEnum.DATE,
  ),
);
export const selectDateNames = createSelector(selectDates, (dates) => dates.map((date) => date.name));
export const selectNonSystemDates = createSelector(selectDates, (dates) =>
  dates.filter((date) => !isSystemDate(date.name)),
);
export const selectEffectiveDate = createSelector(selectDates, (dates) =>
  dates.find((date) => date.name === AgreementSystemDateEnum.AGREEMENT_EFFECTIVE_DATE),
);
export const selectExpirationDate = createSelector(selectDates, (dates) =>
  dates.find((date) => date.name === AgreementSystemDateEnum.AGREEMENT_EXPIRATION_DATE),
);
export const selectDatesByName = createSelector(selectDates, (dates) => keyBy(dates, 'name'));
export const selectExpirationCycle = createSelector(
  selectAgreementDateCycles,
  (cycles) => cycles[AgreementSystemDateEnum.AGREEMENT_EXPIRATION_DATE],
);
export const selectRenewalObligations = createSelector(
  selectExpirationCycle,
  selectAgreementObligations,
  selectAgreementLegalState,
  (cycle, obligations, legalState) => {
    const renewalObligationId = cycle?.metadata.config.prerequisiteObligation;
    if (!renewalObligationId) {
      return [];
    }
    return filterAndSortObligations(
      legalState,
      obligations,
      (obligation) => obligation.config.id === renewalObligationId,
    );
  },
);

export const selectRenewalConfig: OutputSelector<
  ApplicationState,
  RenewalConfig,
  (
    currentUserOrganization: string,
    obligations: AgreementObligation[],
    requester: AgreementRequester,
    cycles: DateCycles,
  ) => RenewalConfig
> = createSelector(
  selectCurrentOrganizationAddress,
  selectRenewalObligations,
  selectAgreementRequester,
  selectAgreementDateCycles,
  (currentUserOrganization, renewals, requester, cycles): RenewalConfig => {
    const cycle = cycles[AgreementSystemDateEnum.AGREEMENT_EXPIRATION_DATE];
    if (!cycle?.metadata.config.prerequisiteObligation) return null;
    if (!renewals.length) return null;
    const [obligation] = renewals;
    const openDuration = parseDuration(obligation.config.triggers.begin[0].datetimeOffset);
    const closeDuration = parseDuration(obligation.config.triggers.complete[0].datetimeOffset);
    const extendDuration = parseDuration(cycle.metadata.config.offset);

    const renewalConfig: RenewalConfig = {
      state: 'postchain',
      extensionOffset: extendDuration,
      openOffset: openDuration,
      openDate: obligation.config.triggers.begin[0].datetimeName,
      closeOffset: closeDuration,
      closeDate: obligation.config.triggers.complete[0].datetimeName,
      allowedToComplete: !!obligation.franchisees.find(
        ({ organizationAddress, attestedAt }) =>
          organizationAddress === currentUserOrganization &&
          (requester.isRepresentative || requester.mayActAsParty) &&
          !attestedAt,
      ),
      franchisees: obligation.config.attesters,
    };

    return renewalConfig;
  },
);

/**
 * Parties
 */
type PartyParameter = AgreementParameter & { type: typeof ParameterTypeEnum.SIGNING_PARTY };
export const selectPartyParameters = createSelector(selectAgreementParameters, (parameters) =>
  parameters.filter((parameter): parameter is PartyParameter => parameter.type === ParameterTypeEnum.SIGNING_PARTY),
);
export const selectPartyLabels = createSelector(selectPartyParameters, (parties) => parties.map((party) => party.name));
export const selectPartyNamesByLabel = createSelector(
  selectPartyParameters,
  selectOrganizations,
  (parameters, organizations) =>
    parameters.reduce<{ [label: string]: string }>(
      (acc, parameter) => ({
        ...acc,
        [parameter.name]: organizations[parameter.value]?.name || parameter.name,
      }),
      {},
    ),
);
export const selectCurrentUserParty = createSelector(
  selectCurrentOrganizationAddress,
  selectPartyParameters,
  selectAgreementParties,
  selectAgreementRepresentatives,
  (organizationAddress, partyParams, parties, reps): FormParty => {
    const partyParam = partyParams.find((param) => param.value === organizationAddress);
    const party = parties.find((party) => party.address === organizationAddress);
    const rep = reps[organizationAddress];
    return {
      key: organizationAddress,
      label: partyParam.name,
      value: organizationAddress,
      signatureRequired: party.signatureRequired,
      representative: {
        address: buildRepresentativeAddress(rep, organizationAddress),
        email: '',
      },
    };
  },
);
export const selectPartyAddressesByLabel = createSelector(selectPartyParameters, (parameters) =>
  parameters.reduce<{ [label: string]: string }>(
    (acc, parameter) => ({
      ...acc,
      [parameter.name]: parameter.value,
    }),
    {},
  ),
);

type PartyWithAddress = Party & { address: string; representative: Party['representative'] & { address?: string } };

export const selectAllParties = createSelector(
  selectPartyParameters,
  selectAgreementParties,
  selectAgreementLegalState,
  selectIsOwner,
  selectAgreementRepresentatives,
  selectOrganizations,
  selectUsers,
  (parameters, parties, legalState, isOwner, representatives, organizations, users): PartyWithAddress[] => {
    return parties.map((party) =>
      mapSignatory(
        party,
        parameters.find((p) => p.value === party.address),
        legalState,
        isOwner,
        representatives,
        organizations,
        users,
      ),
    );
  },
);

export const selectParties = createSelector(
  selectPartyParameters,
  selectAgreementParties,
  selectAgreementLegalState,
  selectIsOwner,
  selectAgreementRepresentatives,
  selectAgreementApprovers,
  selectCurrentOrganizationAddress,
  selectOrganizations,
  selectUsers,
  (
    parameters,
    parties,
    legalState,
    isOwner,
    representatives,
    approvers,
    currentUserOrganization,
    organizations,
    users,
  ): Omit<PartiesSummaryProps, 'currentUserParty' | 'counterparties'> & {
    currentUserParty: PartyWithAddress;
    counterparties: PartyWithAddress[];
  } => {
    return {
      currentUserParty: mapSignatory(
        parties.find((p) => p.address === currentUserOrganization),
        parameters.find((p) => p.value === currentUserOrganization),
        legalState,
        isOwner,
        representatives,
        organizations,
        users,
      ),
      counterparties: parties
        .filter((s) => s.address !== currentUserOrganization)
        .map((s) =>
          mapSignatory(
            s,
            parameters.find((p) => p.value === s.address),
            legalState,
            isOwner,
            representatives,
            organizations,
            users,
          ),
        ),
      approver: approvers?.length ? users[approvers[0]]?.name : null,
    };
  },
);

/**
 * Events
 */
export const selectNonRenewalObligations = createSelector(
  selectExpirationCycle,
  selectAgreementObligations,
  selectAgreementLegalState,
  (cycle, obligations, legalState) => {
    const renewalObligationId = cycle?.metadata.config.prerequisiteObligation;
    return filterAndSortObligations(
      legalState,
      obligations,
      (obligation) => obligation.config.id !== renewalObligationId,
    );
  },
);
export const selectEventCount = createSelector(
  selectNonRenewalObligations,
  selectAgreementNotifications,
  (obligations, notifications) => obligations.length + notifications.length,
);

export const selectBlockchainTransactionTasks = createSelector(
  selectBlockchainTransactions,
  (data) => data.transactions,
);
export const selectBlockchainTransactionLogs = createSelector(selectBlockchainTransactions, (data) => data.log);

/**
 * Roles
 */
export const selectIsCounterpartyParticipant = createSelector(
  selectAgreementRequester,
  selectAgreementOwner,
  (requester, owner) =>
    requester.organizationAddress !== owner && (!!requester.mayActAsParty || requester.isRepresentative),
);
export const selectUserOrganizationRecipient = createSelector(
  selectAgreementRecipients,
  selectCurrentOrganizationAddress,
  selectCurrentOrganizationDepartmentsById,
  (recipients, organizationAddress, departments) => {
    const recipient = recipients.find(
      (recipient): recipient is AgreementRecipient & { type: 'organization' } =>
        recipient.type === 'organization' && recipient.address === organizationAddress,
    );
    if (!recipient) {
      return null;
    }
    return {
      ...recipient,
      teamName: departments[recipient.teamId]?.name,
    };
  },
);

/**
 * Terms
 */
export const selectAgreementAssignedTerms = createSelector(
  selectAgreement,
  selectCurrentOrganizationAddress,
  (agreement, currentUserOrganization) => {
    if (!agreement) return [];
    const parameters = agreement.parameters.reduce<Record<string, AgreementParameter>>(
      (acc, param) => ({ ...acc, [param.name]: param }),
      {},
    );
    return agreement.parameters.filter(({ assignee }) => parameters[assignee]?.value === currentUserOrganization);
  },
);

export const selectAgreementHasAssignedTerms = createSelector(
  selectAgreementAssignedTerms,
  (parameters) => parameters.length > 0,
);

export const selectAgreementHasEmptyAssignedTerms = createSelector(
  selectAgreementAssignedTerms,
  (parameters) => parameters.filter(parameterHasNoValue).length > 0,
);

export const selectAgreementHasEmptyRequiredAssignedTerms = createSelector(
  selectAgreementAssignedTerms,
  (parameters) => parameters.filter((p) => !p.optional).filter(parameterHasNoValue).length > 0,
);

export const selectAgreementMustBeSignedByCurrentParty = createSelector(
  selectAgreement,
  selectCurrentOrganizationAddress,
  (agreement, currentUserOrganization) =>
    !!(
      agreement &&
      agreement.legalState === LegalStateEnum.FORMULATED &&
      agreement.parties.find(
        ({ address, signatureTimestamp, signatureRequired }) =>
          signatureRequired && address === currentUserOrganization && !signatureTimestamp,
      )
    ),
);

/**
 * Documents
 */
export const selectAgreementDocumentGrants = createSelector(selectAgreementDocuments, (agreementDocuments) =>
  agreementDocuments.documents.map((doc) => last(doc.versions).grant),
);
export const selectMainAgreementDocumentGrant = createSelector(selectAgreementDocumentGrants, (grants) => grants[0]);
export const selectLatestAgreementDocuments = createSelector(selectAgreementDocuments, (agreementDocuments) =>
  agreementDocuments.documents.map((doc) => last(doc.versions)),
);
export const selectViewedDocuments = createSelector(
  selectAgreementAddress,
  selectAgreementViewedDocuments,
  (address, agreementViewedDocuments) => {
    return agreementViewedDocuments[address] ?? {};
  },
);

/**
 * States
 */
export const selectAllDocumentsViewed = createSelector(
  selectViewedDocuments,
  selectAgreementDocumentGrants,
  (viewedDocuments, grants) => {
    return grants.every((grant) => viewedDocuments[grant]);
  },
);
export const selectMainDocumentViewed = createSelector(
  selectViewedDocuments,
  selectMainAgreementDocumentGrant,
  (viewedDocuments, grant) => {
    return viewedDocuments[grant];
  },
);
export const selectAgreementRequiresSignByUser = createSelector(
  selectAgreementLegalState,
  selectAgreementParties,
  selectCurrentOrganizationAddress,
  selectCurrentUserCapabilities,
  selectSignatures,
  selectCurrentUserAddress,
  selectOrganizations,
  (legalState, parties, organizationAddress, capabilities, signatures, currentUserAddress, organizations) => {
    return (
      legalState === LegalStateEnum.FORMULATED &&
      capabilities.includes(CapabilityEnum.SIGNATURE_CREATE) &&
      (!!parties.find(
        (party) => party.address === organizationAddress && party.signatureRequired && !party.signatureTimestamp,
      ) ||
        (!!parties.find(
          (party) => party.address === organizationAddress && organizations[party.address]?.requireSigningByAll,
        ) &&
          !signatures.find((signature) => signature.userAddress === currentUserAddress)))
    );
  },
);
export const selectRenewalIsCompletable = createSelector(
  selectAgreementLegalState,
  selectRenewalObligations,
  selectAgreementRequester,
  isAnyObligationCompletable,
);
export const selectNonRenewalObligationIsCompletable = createSelector(
  selectAgreementLegalState,
  selectNonRenewalObligations,
  selectAgreementRequester,
  isAnyObligationCompletable,
);
export const selectBlockchainTransactionIsCompletable = createSelector(
  selectBlockchainTransactions,
  (data) => !!data.transactions.length,
);

/**
 * Document Review
 */
export const selectTrackDocumentsReview = createSelector(
  selectIsOwner,
  selectAgreementRequiresSignByUser,
  (isOwner, signatureRequired) => {
    return !isOwner && signatureRequired;
  },
);
export const selectDocumentsRequireReview = createSelector(
  selectTrackDocumentsReview,
  selectAllDocumentsViewed,
  (shouldTrack, allReviewed) => shouldTrack && !allReviewed,
);

/**
 * Permissions
 */
export const selectUserMayAddDocuments = selectUserIsParticipant;
export const selectUserMayReorderDocuments = createSelector(selectIsOwner, (isOwner) => isOwner);
export const selectPanelTabActionable = createSelector(
  selectAgreementRequiresSignByUser,
  selectNonRenewalObligationIsCompletable,
  selectRenewalIsCompletable,
  selectDocumentsRequireReview,
  selectBlockchainTransactionIsCompletable,
  (
    signRequired,
    obligationRequired,
    renewalRequired,
    docReviewRequired,
    txRequired,
  ): Record<RecordPanelTab, boolean> => ({
    documents: docReviewRequired,
    parties: signRequired,
    terms: false,
    dates: renewalRequired,
    events: obligationRequired || txRequired,
    settings: false,
  }),
);
export const selectUserMayCancel = createSelector(
  selectAgreementLegalState,
  selectAgreementRequester,
  selectAgreementParties,
  (legalState, requester, parties) =>
    requesterMayCancel(
      legalState,
      getRequesterMayActAsParty(parties, requester),
      getRequesterCanceledBy(parties, requester),
    ),
);
export const selectUserMayDelete = createSelector(
  selectAgreementLegalState,
  selectIsOwner,
  selectAgreementParties,
  (legalState, isOwner, parties) => requesterMayDelete(legalState, isOwner, parties.length),
);
export const selectUserMayAddEvents = createSelector(
  selectUserIsParticipant,
  selectAgreementLegalState,
  (isParticipant, legalState) => isParticipant && legalState <= LegalStateEnum.EXECUTED,
);
export const selectUserMayAddTerms = selectUserIsParticipant;

/**
 * Settings
 */
export const selectAgreementTeamName = createSelector(
  selectAgreementTeams,
  selectCurrentOrganizationDepartmentsById,
  (agreementTeams, teams) => teams[agreementTeams[0]]?.name,
);

/**
 * Balance
 */
export const selectAgreementResourceBalance = createSelector(
  selectAgreementResourceLimits,
  selectTerms,
  selectAgreementObligations,
  selectAgreementNotifications,
  selectDocuments,
  (resourceLimits, terms, obligations, notifications, documents): ThrottledResourceBalance => {
    return {
      // For now at post chain level we only throttle on docs, terms and events so only calculate those for now.
      agreement_document: getRemaining(resourceLimits.agreement_document?.limit, Object.keys(documents).length),
      agreement_event: getRemaining(resourceLimits.agreement_event?.limit, obligations.length + notifications.length),
      agreement_term: getRemaining(resourceLimits.agreement_term?.limit, terms.length),
    };
  },
);

/**
 * UI
 */
export const selectDialogObligation = createSelector(
  selectObligationDialog,
  selectAgreementObligations,
  (dialog, obligations) => {
    if (!dialog) return null;
    return obligations.find((obligation) => obligation.id === dialog.id);
  },
);
export const selectDialogFranchisee = createSelector(
  selectObligationDialog,
  selectAgreementObligations,
  (dialog, obligations) => {
    if (!dialog) return null;
    return obligations
      .find((obligation) => obligation.id === dialog.id)
      ?.franchisees.find((franchisee) => franchisee.organizationAddress === dialog.franchisee);
  },
);

/**
 * Selector factories
 */
function makeSelectAgreementProperty<T extends keyof Agreement>(
  property: T,
  defaultValue: Agreement[T],
): OutputSelector<ApplicationState, Agreement[T], (ageement: Agreement) => Agreement[T]> {
  return createSelector(selectAgreement, (agreement) => (agreement ? agreement[property] : defaultValue));
}
function makeSelectRequesterProperty<T extends keyof AgreementRequester>(
  property: T,
): OutputSelector<ApplicationState, AgreementRequester[T], (ageement: AgreementRequester) => AgreementRequester[T]> {
  return createSelector(selectAgreementRequester, (requester) => requester?.[property]);
}
function makeSelectAgreementRequest<T extends AgreementRecordRequest>(
  request: T,
): OutputSelector<ApplicationState, boolean, (requests: AgreementRecordState['requests']) => boolean> {
  return createSelector(selectRequests, (requests) => requests.includes(request));
}

/**
 * Helpers
 */
function mapSignatory(
  s: AgreementParty,
  p: PartyParameter,
  legalState: LegalState,
  isOwner: boolean,
  representatives: { [organization: string]: string },
  organizations: OrganizationInfoByAddress,
  users: UserInfoByAddress,
): PartyWithAddress {
  if (!s) {
    return null;
  }
  const user = users[representatives[s.address]];
  return {
    label: p?.name,
    name: organizations[s.address]?.name || p?.name,
    representative: {
      address: user?.address,
      name: user?.name || representatives[s.address],
      email: user?.email || '?',
    },
    address: s.address,
    isIndividual: organizations[s.address]?.isIndividual,
    requireSigningByAll: organizations[s.address]?.requireSigningByAll,
    signatureState: s.cancelationTimestamp
      ? 'canceled'
      : s.signatureTimestamp
      ? 'signed'
      : !s.signatureRequired
      ? 'signNotRequired'
      : legalState < LegalStateEnum.EXECUTED && s.signatureRequired
      ? 'signRequired'
      : undefined,
    isOwner,
  };
}
function isAnyObligationCompletable(
  legalState: LegalState,
  obligations: AgreementObligation[],
  requester: AgreementRequester,
): boolean {
  return (
    (legalState === LegalStateEnum.FORMULATED || legalState === LegalStateEnum.EXECUTED) &&
    (requester.isRepresentative || !!requester.mayActAsParty) &&
    !!obligations.find((o) => isObligationCompletable(o, requester.organizationAddress))
  );
}

export function isObligationCompletable(obligation: AgreementObligation, organizationAddress: string) {
  return (
    obligation.beganAt &&
    !obligation.completedAt &&
    obligation.franchisees.find(
      (franchisee) => franchisee.organizationAddress === organizationAddress && !franchisee.attestedAt,
    )
  );
}

function filterAndSortObligations(
  legalState: LegalState,
  completables: AgreementObligation[],
  filter: (completable: AgreementObligation) => boolean,
): AgreementObligation[] {
  return reverse(
    sortBy(
      completables.filter(
        (obligation) =>
          filter(obligation) &&
          // Don't include pending renewal if agreement is already completed
          (obligation.beganAt || legalState < LegalStateEnum.FULFILLED),
      ),
      'createdAt',
    ),
  );
}
