import produce from 'immer';
import { ActivityLogSort, ActivityLogsState } from '../types';
import { ActivityLogsAction } from './actions';

export const defaultSort: ActivityLogSort = {
  column: 'timestamp',
  direction: 'desc',
};

export const initialState: ActivityLogsState = {
  loading: false,
  activityLogs: [],
  totalActivityLogs: 0,
  sort: defaultSort,
  includeAllUserOrganizations: false,
};

const templatesReducer = (state = initialState, action: ActivityLogsAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Overview/ActivityLog/LOAD_ACTIVITY_LOGS':
        draftState.loading = true;
        return draftState;

      case 'app/Overview/ActivityLog/LOAD_MORE_ACTIVITY_LOGS':
        draftState.loading = true;
        return draftState;

      case 'app/Overview/ActivityLog/LOAD_ACTIVITY_LOGS_SUCCESS':
        draftState.loading = false;
        draftState.totalActivityLogs = action.totalActivityLogs;
        draftState.activityLogs = action.activityLogs;
        return draftState;

      case 'app/Overview/ActivityLog/LOAD_MORE_ACTIVITY_LOGS_SUCCESS':
        draftState.loading = false;
        draftState.totalActivityLogs = action.totalActivityLogs;
        draftState.activityLogs = draftState.activityLogs.concat(action.activityLogs);
        return draftState;

      case 'app/Overview/ActivityLog/RECEIVE_ACTIVITY_LOG_SORT':
        draftState.sort = action.sort;
        return draftState;

      case 'app/Overview/ActivityLog/RECEIVE_INCLUDE_ALL_USER_ORGANIZATIONS':
        draftState.includeAllUserOrganizations = action.includeAllUserOrganizations;
        return draftState;

      default:
        return draftState;
    }
  });

export default templatesReducer;
