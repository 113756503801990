import React from 'react';
import shape1 from './images/bg-shape-1.svg';
import shape2 from './images/bg-shape-2.svg';
import shape3 from './images/bg-shape-3.svg';
import shape4 from './images/bg-shape-4.svg';
import shape5 from './images/bg-shape-5.svg';

export const ShapesBackground: React.FC = (props) => {
  return (
    <div className="h-full w-full relative flex items-center">
      <img src={shape1} style={{ position: 'absolute', zIndex: -1, top: -300, left: 0 }} />
      <img src={shape4} style={{ position: 'absolute', zIndex: -1, bottom: 0, left: 0, top: 0 }} />
      <img src={shape2} style={{ position: 'absolute', zIndex: -1, bottom: 0, left: 0 }} />
      <img src={shape3} style={{ position: 'absolute', zIndex: -1, top: 0, right: 0 }} />
      <img src={shape5} style={{ position: 'absolute', zIndex: -1, bottom: 0, right: 0 }} />
      {props.children}
    </div>
  );
};
