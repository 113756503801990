import { AgreementParameter } from '@monax/types';
import { IntlShape } from 'react-intl';
import messages from './messages';

export type ParameterTypeOption = {
  value: number;
  label: string;
};

const PARAMETER_TYPES = [0, 1, 11, 2, 12, 3, 4, 5, 6, 8, 7];

export const getParameterTypeOptions = (intl: IntlShape, includeSignatory: boolean = false): ParameterTypeOption[] => {
  return PARAMETER_TYPES.filter(
    (parameterType) =>
      (includeSignatory ? parameterType <= 8 : parameterType < 8) || (parameterType >= 11 && parameterType <= 12),
  ).map((parameterType) => ({
    value: parameterType,
    label: intl.formatMessage(messages[`parameterType${parameterType}`]),
  }));
};

export const getParameterTypeOptionsFiltered = (intl: IntlShape, filter: number[]): ParameterTypeOption[] => {
  return PARAMETER_TYPES.filter((parameterType) => filter.some((t) => t === parameterType)).map((parameterType) => ({
    value: parameterType,
    label: intl.formatMessage(messages[`parameterType${parameterType}`]),
  }));
};

export const parameterHasValue = ({ value }: AgreementParameter): boolean => {
  return value !== null && value !== undefined && value !== '';
};

export const parameterHasNoValue = (parameter: AgreementParameter): boolean => {
  return !parameterHasValue(parameter);
};
