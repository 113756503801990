import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'containers/Theme/useTheme';

const bubbleWidth = 160;
const arrowHeight = 8;
const arrowWidth = 16.8;

function SpeechBubble({ content, baseRef }) {
  const bubbleRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    setContentHeight(bubbleRef.current.getBoundingClientRect().height);
  }, []);
  const { palette } = useTheme();

  const { top, height, left, right } = baseRef.current
    ? baseRef.current.getBoundingClientRect()
    : { top: 0, height: 0, left: 0, right: 0, width: 0 };

  let bubbleLeft = (left + right) / 2 - bubbleWidth / 2;
  const arrowLeft = (left + right) / 2 - arrowWidth / 2;
  const overflowsRight = bubbleLeft + bubbleWidth > window.innerWidth;
  if (overflowsRight) bubbleLeft = window.innerWidth - bubbleWidth - 4;
  let bubbleTop = top + height + arrowHeight;
  let arrowTop = top + height - 4;
  const overflowsBottom = bubbleTop + contentHeight > window.innerHeight;
  if (overflowsBottom) {
    bubbleTop = top - contentHeight - arrowHeight;
    arrowTop = top - arrowHeight - 8;
  }

  return (
    <div
      className="fixed box-border text-info-main rounded-lg bg-white border border-gray-300"
      ref={bubbleRef}
      style={{
        width: `${bubbleWidth}px`,
        maxWidth: `${bubbleWidth}px`,
        minWidth: `${bubbleWidth}px`,
        top: bubbleTop,
        left: bubbleLeft,
        visibility: contentHeight ? 'visible' : 'hidden',
      }}
    >
      <div
        className="fixed"
        style={{
          left: arrowLeft,
          top: arrowTop,
          transform: overflowsBottom ? 'rotate(270deg)' : 'rotate(90deg)',
        }}
      >
        <svg viewBox="0 0 8 16.8" width="8" height="16.8">
          <g>
            <path
              d=" M 2.245 9.181 C 1.918 8.9 1.918 7.9 2.245 7.619 L 6 4.4 L 6 12.4 L 2.245 9.181 Z "
              fill="white"
              strokeWidth="2"
              stroke={palette.gray[300]}
            />
            <path d=" M 2.245 9.181 C 1.918 8.9 1.918 7.9 2.245 7.619 L 6 4.4 L 6 12.4 L 2.245 9.181 Z " fill="white" />
            <path d=" M 6 4.4 L 8 2.8 L 8 14 L 6 12.4 L 6 4.4 Z " fill="white" />
          </g>
        </svg>
      </div>
      {content}
    </div>
  );
}

SpeechBubble.propTypes = {
  content: PropTypes.any.isRequired,
  baseRef: PropTypes.object.isRequired,
};

export default SpeechBubble;
