import { MessageDescriptor } from '@formatjs/intl';
import { ForeignChainAssetType } from '../../../../../../types/dist';
import { messages } from '../messages';

export function foreignChainAssetTypeMessage(assetType: ForeignChainAssetType): MessageDescriptor {
  switch (assetType) {
    case 'non_fungible_token':
      return messages.nonFungibleToken;
    default:
      throw new Error(`Unrecognized asset type: ${assetType}`);
  }
}
