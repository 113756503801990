import { Zap } from '../types';

export type ReceiveZapierAccessToken = ReturnType<typeof receiveZapierAccessToken>;
export type LoadZaps = ReturnType<typeof loadZaps>;
export type LoadZapsSuccess = ReturnType<typeof loadZapsSuccess>;
export type LoadZapsError = ReturnType<typeof loadZapsError>;
export type ClearZapierAccessToken = ReturnType<typeof clearZapierAccessToken>;

export type ZapierAction =
  | ReceiveZapierAccessToken
  | LoadZaps
  | LoadZapsSuccess
  | LoadZapsError
  | ClearZapierAccessToken;

export const receiveZapierAccessToken = (token: string) => {
  return <const>{
    type: 'app/Integrations/Zapier/RECEIVE_ACCESS_TOKEN',
    token,
  };
};

export const clearZapierAccessToken = () => {
  return <const>{
    type: 'app/Integrations/Zapier/CLEAR_ACCESS_TOKEN',
  };
};

export const loadZaps = () => {
  return <const>{
    type: 'app/Integrations/Zapier/LOAD_ZAPS',
  };
};

export const loadZapsSuccess = (zaps: Zap[]) => {
  return <const>{
    type: 'app/Integrations/Zapier/LOAD_ZAPS_SUCCESS',
    zaps,
  };
};

export const loadZapsError = (error: unknown) => {
  return <const>{
    type: 'app/Integrations/Zapier/LOAD_ZAPS_ERROR',
    error,
  };
};
