import { showSnackbarErrorFormattedMessage } from 'containers/App/state/saga';
import { selectAPIClient } from 'containers/App/state/selectors';
import { loadOrganizationResourceLimits } from 'containers/Organization/ResourceBalance/state/actions';
import { loadUsersSuccess } from 'containers/User/List/actions';
import { put, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import messages from '../../messages';
import { LoadOrganization, loadOrganizationSuccess } from './actions';

/**
 * Worker Sagas
 */
export function* loadOrganization({ address }: LoadOrganization) {
  try {
    const client = yield* select(selectAPIClient);
    const {
      data: { organization, users },
    } = yield* call(client.getOrganization, address);
    yield put(loadOrganizationResourceLimits(address));
    yield put(loadOrganizationSuccess(organization));
    yield put(loadUsersSuccess(users));
  } catch (err) {
    yield showSnackbarErrorFormattedMessage(messages.loadOrganizationError);
  }
}

/**
 * Watcher Sagas
 */
export function* watchLoadOrganization() {
  yield takeLatest<LoadOrganization>('app/Organization/Current/LOAD_ORGANIZATION', loadOrganization);
}
