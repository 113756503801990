import { ListItemIcon, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@material-ui/core';
import React, { MouseEventHandler } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import { MenuOption } from './types';

type Props = {
  id: string;
  options: MenuOption[];
  bordered?: boolean;
};
export const Menu: React.FC<Props> = ({ id, options, children, bordered }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`w-full`}>
      <button
        onClick={handleClick}
        className={`${
          bordered
            ? 'bg-white w-full flex items-center justify-between py-1 px-6 border border-gray-500 rounded-lg'
            : ''
        } text-sm`}
      >
        <span className="truncate tracking-normal">{children}</span>
        <ChevronDown className="ml-2 text-secondary-main" />
      </button>
      <MuiMenu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={2}
      >
        {options.map((option, i) => (
          <MuiMenuItem
            key={i}
            onClick={() => {
              option.onClick?.();
              handleClose();
            }}
          >
            {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
            {option.label}
          </MuiMenuItem>
        ))}
      </MuiMenu>
    </div>
  );
};
