/**
 * Create the store with dynamic reducers
 */

import * as Sentry from '@sentry/react';
import configs, { getNodeEnvironment } from 'configs';
import { routerMiddleware } from 'connected-react-router';
import { AuthAction } from 'containers/Auth/Auth/state/actions';
import { merge } from 'lodash';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { ApplicationState } from 'types';
import createReducer from './reducers';

export default function configureStore(initialState: Partial<ApplicationState> = {}, history) {
  let composeEnhancers = compose;
  const reduxSagaMonitorOptions = {};

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (getNodeEnvironment() !== 'production' && typeof window === 'object') {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];
  const enhancers = [applyMiddleware(...middlewares)];

  if (configs.integrations.sentryDNS)
    enhancers.push(
      Sentry.createReduxEnhancer({
        actionTransformer: (action: AuthAction) => {
          const transformedAction = merge({}, action);
          if (transformedAction.type === 'app/Auth/RECEIVE_AUTH_TOKEN') {
            merge(transformedAction, { token: 'REDACTED' });
            merge(transformedAction, { idTokenPayload: 'READACTED' });
          }
          return transformedAction;
        },
        stateTransformer: (state: ApplicationState) => {
          const transformedState = merge({}, state);
          transformedState.auth.auth.token = 'REDACTED';
          delete transformedState.auth.auth.idTokenPayload;
          return transformedState;
        },
      }),
    );

  const store: Store & {
    runSaga: any;
    injectedReducers: any;
    injectedSagas: any;
  } = createStore(createReducer(history, {}), initialState, composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  persistStore(store);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(history, store.injectedReducers));
    });
  }

  return store;
}
