import { APIClient } from '@monax/types';
import WalletConnect from '@walletconnect/client';
import { addFormattedNotification } from 'containers/App/state/actions';
import { JsonArray } from 'io-ts-types';
import { Dispatch } from 'redux';
import { AbiItem } from 'web3-utils';
import { messages } from './messages';
import { Wallet, WalletEvents } from './types';
import { chainNameFromId, getNonce, signIn } from './util';

// Currently implementing via: https://docs.walletconnect.org/quick-start/dapps/client
// TODO- Ideally should implement via: https://docs.walletconnect.org/quick-start/dapps/web3-provider
export class WalletConnectWallet implements Wallet {
  public type = 'wallet-connect' as const;

  private connector: WalletConnect;

  constructor(private walletEvents: WalletEvents) {
    this.walletEvents = walletEvents;
  }

  private getAccount() {
    return this.connector.accounts[0];
  }

  private getChain() {
    return chainNameFromId(this.connector.chainId);
  }

  async connect() {
    const QRCodeModal = (
      await import(/* webpackChunkName: "@walletconnect/qrcode-modal" */ '@walletconnect/qrcode-modal')
    ).default;
    const WalletConnect = (await import(/* webpackChunkName: "@walletconnect/client" */ '@walletconnect/client'))
      .default;
    try {
      this.connector = new WalletConnect({
        bridge: 'https://bridge.walletconnect.org',
        qrcodeModal: QRCodeModal,
      });

      if (!this.connector.connected) {
        await this.connector.createSession();
      }

      this.walletEvents.accountSet(this.getAccount());
      this.walletEvents.chainSet(this.getChain());

      this.connector.on('connect', (error, payload) => {
        if (error) {
          this.walletEvents.errorSet({ type: 'connection', error });
          return;
        }
        const {
          accounts: [account = null],
          chainId,
        } = payload.params[0];
        this.walletEvents.accountSet(account);
        this.walletEvents.chainSet(chainNameFromId(chainId));
      });

      this.connector.on('session_update', (error, payload) => {
        if (error) {
          this.walletEvents.errorSet({ type: 'connection', error });
          return;
        }
        const {
          accounts: [account = null],
          chainId,
        } = payload.params[0];
        this.walletEvents.accountSet(account);
        this.walletEvents.chainSet(chainNameFromId(chainId));
      });

      this.connector.on('disconnect', (error) => {
        this.walletEvents.errorSet({ type: 'connection', error: error || new Error('Wallet has been disconnected') });
      });
    } catch (err) {
      this.walletEvents.errorSet({ type: 'connection', error: err });
    }
  }

  public async sign(apiClient: APIClient, dispatch: Dispatch) {
    try {
      const { fromUtf8 } = await import(/* webpackChunkName: "web3-utils" */ 'web3-utils');
      const account = this.getAccount();
      const nonce = await getNonce(apiClient, account);
      dispatch(addFormattedNotification({ messageDescriptor: messages.pleaseSign, open: true, className: 'info' }));
      const signature = await this.connector.signPersonalMessage([fromUtf8(nonce), account]);
      return { account, signature };
    } catch (err) {
      this.walletEvents.errorSet({ type: 'sign', error: err });
      return null;
    }
  }

  public async signIn(apiClient: APIClient, dispatch: Dispatch) {
    try {
      const signRes = await this.sign(apiClient, dispatch);
      if (!signRes) {
        return;
      }
      await signIn(apiClient, dispatch, signRes.account, signRes.signature);
    } catch (err) {
      this.walletEvents.errorSet({ type: 'signIn', error: err });
    }
  }

  public async send(contractAddress: string, method: AbiItem, abi: AbiItem[], parameters: JsonArray) {
    try {
      const Web3 = (await import(/* webpackChunkName: "web3" */ 'web3')).default;
      const data = new Web3().eth.abi.encodeFunctionCall(
        {
          name: method.name,
          type: 'function',
          inputs: method.inputs,
        },
        parameters.map((p) => `${p}`),
      );
      const from = this.getAccount();
      const tx = {
        from,
        to: contractAddress,
        data,
      };
      const transactionHash = await this.connector.sendTransaction(tx);
      if (transactionHash) {
        return { transactionHash, from };
      }
      return null;
    } catch (err) {
      this.walletEvents.errorSet({ type: 'tx', error: err });
      return null;
    }
  }

  async disconnect() {}
}
