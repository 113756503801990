import React from 'react';

type Props = {
  index: number;
  selectedIndex: number;
  noPadding?: boolean;
  className?: string;
};

export const TabPanel: React.FC<Props> = ({ index, selectedIndex, children, noPadding, className }) => {
  return (
    <div
      id={`wrapped-tabpanel-${index}`}
      hidden={index !== selectedIndex}
      className={`${noPadding ? '' : 'pt-4'} ${className}`}
    >
      {index === selectedIndex && <div className={noPadding ? '' : 'p-2'}>{children}</div>}
    </div>
  );
};
