import { useTheme } from 'containers/Theme/useTheme';
import { useMemo } from 'react';
import { OptionsType, Styles } from 'react-select';
import { Option, OptionValue } from 'types/form';
import { baseStyles, errorStyles } from './styles';

export const getSelectedValue = (
  isMulti: boolean,
  options: OptionsType<Option>,
  value: unknown,
): Option | Option[] | null => {
  if (isMulti) {
    const selectedValues: Option[] = [];
    const values = (value || []) as OptionValue[];
    for (const value of values) {
      let option = options.find((o) => o.value === value);
      option = option ? option : { label: value.toString(), value: value };
      selectedValues.push(option);
    }
    return selectedValues;
  }
  return options.find((o) => o.value === value) ?? null;
};

export const onSelectedValueChange = (isMulti: boolean, setValue: (value: unknown) => void) => {
  return (option: Option | Option[] | undefined) => {
    if (isMulti) {
      const options = option as Option[] | undefined;
      let values = [];
      if (options) values = options.map((v) => v.value);
      setValue(values);
    } else {
      setValue((option as Option | undefined)?.value);
    }
  };
};

export const useGetStyles = (showError: boolean): Partial<Styles<{}, boolean>> => {
  const { palette } = useTheme();
  return useMemo(() => {
    let styles = baseStyles(palette);
    if (showError) {
      styles = {
        ...styles,
        ...errorStyles(palette),
      };
    }
    return styles;
  }, [showError, palette]);
};
