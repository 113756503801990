import {
  getAdminPortalRoute,
  getAgreementListRoute,
  getAssetListRoute,
  getOverviewRoute,
  getTemplateListRoute,
} from '@monax/aeger/dist/webapp-routes';
import { CapabilityEnum, CustomerTypeEnum } from '@monax/types';
import { selectCurrentOrganizationCustomerType } from 'containers/Organization/Current/state/selectors';
import { useTheme } from 'containers/Theme/useTheme';
import { makeSelectCurrentUserHasPermission } from 'containers/User/Current/Profile/state/selectors';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container } from '../Container';
import { AccountSwitcher } from './AccountSwitcher';
import messages from './messages';
import Notifications from './Notifications';
import { assetScreensEnabled, isCurrentPath } from './utils';

const overviewPath = getOverviewRoute();
const agreementListPath = getAgreementListRoute();
const templateListPath = getTemplateListRoute();
const adminPortalPath = getAdminPortalRoute();
const assetListPath = getAssetListRoute();

const selectCanReadAsset = makeSelectCurrentUserHasPermission(CapabilityEnum.ASSET_READ);
const selectCanAdminOrganizationRead = makeSelectCurrentUserHasPermission(CapabilityEnum.ADMIN_ORGANIZATION_READ);

export const NavbarDesktop: React.FC = () => {
  const { push } = useHistory();
  const { logos, logoOverrides } = useTheme();
  const customerType = useSelector(selectCurrentOrganizationCustomerType);
  const isDefaultLogo = !logoOverrides?.full?.secondaryContrastText;
  const canAdminOrganizationRead = useSelector(selectCanAdminOrganizationRead);
  return (
    <Container className="bg-secondary-main">
      <div className="w-full py-6">
        <div className="flex">
          {logos?.full?.secondaryContrastText && (
            <img
              src={isDefaultLogo ? logos.favicon.secondaryContrastText : logos.full.secondaryContrastText}
              alt="logo"
              className="cursor-pointer"
              style={{ height: '40px' }}
              onClick={() => push(overviewPath)}
            />
          )}
          <MenuItems />
          <Notifications />
          <AccountSwitcher />
          {canAdminOrganizationRead &&
            (customerType === CustomerTypeEnum.PLATFORM_ADMIN_CLM || customerType === CustomerTypeEnum.MANAGED_CLM) && (
              <MenuAdminItem />
            )}
        </div>
      </div>
    </Container>
  );
};

const MenuItems: React.FC = () => {
  const intl = useIntl();
  const {
    location: { pathname },
    push,
  } = useHistory();

  const assetsEnabled = assetScreensEnabled();
  const canCanReadAsset = useSelector(selectCanReadAsset);

  return (
    <div className="flex flex-1 items-center pl-4">
      <MenuItem
        selected={isCurrentPath(pathname, agreementListPath)}
        label={intl.formatMessage(messages.agreementsLabel)}
        onClick={() => push(agreementListPath)}
      />
      <MenuItem
        selected={isCurrentPath(pathname, templateListPath)}
        label={intl.formatMessage(messages.templatesLabel)}
        onClick={() => push(templateListPath)}
      />
      {assetsEnabled && canCanReadAsset && (
        <MenuItem
          selected={isCurrentPath(pathname, assetListPath)}
          label={intl.formatMessage(messages.assetsLabel)}
          onClick={() => push(assetListPath)}
        />
      )}
    </div>
  );
};

const MenuAdminItem: React.FC = () => {
  const intl = useIntl();
  const {
    location: { pathname },
    push,
  } = useHistory();

  return (
    <div className="flex items-center pl-4">
      <MenuItem
        selected={pathname === adminPortalPath}
        label={intl.formatMessage(messages.adminLabel)}
        onClick={() => push(adminPortalPath)}
      />
    </div>
  );
};

const MenuItem: React.FC<MenuItemProps> = ({ selected, label, onClick }) => (
  <div
    onClick={onClick}
    className={`text-base px-4 py-2 mx-2 rounded-lg cursor-pointer ${
      selected ? 'bg-primary-main text-primary-contrastText' : 'text-secondary-contrastText'
    }`}
  >
    {label}
  </div>
);

type MenuItemProps = {
  selected: boolean;
  label: string;
  onClick: () => void;
};
