export const bufferToDataURL = (data: Buffer | number[], mime: string) =>
  URL.createObjectURL(new Blob([new Uint8Array(data)], { type: mime }));

export const bufferToString = (data: Buffer | number[] | ArrayBuffer, encoding = 'utf-8') =>
  new TextDecoder(encoding).decode(new Uint8Array(data));

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
