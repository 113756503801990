import { getPersistStorage } from 'configs';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import { IntegrationState } from './types';
import zapierReducer from './Zapier/state/reducer';
import { ZapierState } from './Zapier/types';

const zapierConfig: PersistConfig<ZapierState> = {
  key: 'integrations-zapier',
  storage: getPersistStorage(),
};

const reducer = combineReducers<IntegrationState>({
  zapier: persistReducer(zapierConfig, zapierReducer),
});

export default reducer;
