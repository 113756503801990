import React from 'react';
import { useIntl } from 'react-intl';
import { messages } from '../messages';
import { CreateOptions } from './CreateOptions';

export const HeaderMobile: React.FC = () => {
  const intl = useIntl();

  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <span className="text-xl md:text-2xl text-secondary-main font-bold">{intl.formatMessage(messages.wallet)}</span>
      </div>
      <div className="flex items-center">
        <div>
          <CreateOptions />
        </div>
      </div>
    </div>
  );
};
