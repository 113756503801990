import React from 'react';
import { autoOverflowClassName, cardClassName } from './utils';

type Props = {
  autoOverflow?: boolean;
  className?: string;
};

export const Card: React.FC<Props> = ({ children, autoOverflow, className }) => {
  return (
    <div className={`${cardClassName} ${autoOverflow ? autoOverflowClassName : ''} ${className ? className : ''}`}>
      {children}
    </div>
  );
};
