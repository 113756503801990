import React, { CSSProperties, MouseEventHandler, ReactNode } from 'react';

type Props = {
  type?: 'button' | 'submit';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  icon: ReactNode;
  variant: 'info' | 'error' | 'success';
  disabled?: boolean;
};

const style: CSSProperties = {
  height: '33px',
  width: '33px',
  minWidth: '33px',
};

const classes: Record<Props['variant'], string> = {
  info: 'border border-gray-500 rounded bg-gray-200 text-info-main',
  error: 'border border-error-light rounded bg-error-extraLight text-error-main',
  success: 'border border-success-light rounded bg-success-extraLight text-success-main',
};

export const SquareIconButton: React.FC<Props> = ({ icon, type = 'button', onClick, variant, disabled }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`rounded flex items-center justify-center text-base ${classes[variant]}`}
      style={style}
      disabled={disabled}
    >
      {icon}
    </button>
  );
};
