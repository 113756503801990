import { MemberOrganization } from '@monax/types';
import { getPersistStorage } from 'configs';
import { OrganizationState } from 'containers/Organization/types';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import beaconReducer from './Beacon/reducer';
import currentReducer from './Current/state/reducer';
import listReducer from './List/state/reducer';
import manageReducer from './Manage/state/reducer';
import resourceBalanceReducer from './ResourceBalance/state/reducer';
import switchReducer from './Switch/state/reducer';

const currentConfig: PersistConfig<MemberOrganization> = {
  key: 'organization-current',
  storage: getPersistStorage(),
};

const reducer = combineReducers<OrganizationState>({
  current: persistReducer(currentConfig, currentReducer),
  list: listReducer,
  manage: manageReducer,
  resourceBalance: resourceBalanceReducer,
  switch: switchReducer,
  beacon: beaconReducer,
});

export default reducer;
