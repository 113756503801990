import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import configs from 'configs';
import isIpfs from 'is-ipfs';
import { useCallback } from 'react';

const gatewayTools = new IPFSGatewayTools();

export const useIPFS = () => {
  const containsCID = useCallback((hashOrUrl: string) => {
    return gatewayTools.containsCID(hashOrUrl).containsCid;
  }, []);
  const getIpfsUrl = useCallback((hashOrUrl: string) => {
    if (isIpfs.cid(hashOrUrl)) {
      hashOrUrl = `ipfs://${hashOrUrl}`;
    }
    return gatewayTools.convertToDesiredGateway(hashOrUrl, configs.integrations.curatorUrl);
  }, []);
  return {
    containsCID,
    getIpfsUrl,
  };
};
