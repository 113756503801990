import { AddUserForeignAddress, ForeignChainId, UpdateUserForeignAddress, UserForeignAddress } from '@monax/types';
import { RequestError } from 'types';

export type LoadForeignAddressesAction = ReturnType<typeof loadForeignAddresses>;
export type LoadForeignAddressesSuccessAction = ReturnType<typeof loadForeignAddressesSuccess>;
export type AddForeignAddressAction = ReturnType<typeof addForeignAddress>;
export type AddForeignAddressSuccessAction = ReturnType<typeof addForeignAddressSuccess>;
export type UpdateForeignAddressAction = ReturnType<typeof updateForeignAddress>;
export type UpdateForeignAddressSuccessAction = ReturnType<typeof updateForeignAddressSuccess>;
export type RemoveForeignAddressAction = ReturnType<typeof removeForeignAddress>;
export type RemoveForeignAddressSuccessAction = ReturnType<typeof removeForeignAddressSuccess>;
export type ReceiveEventPoliciesErrorAction = ReturnType<typeof receiveUserForeignAddressError>;

export type ForeignAddressAction =
  | LoadForeignAddressesAction
  | UpdateForeignAddressAction
  | UpdateForeignAddressSuccessAction
  | LoadForeignAddressesSuccessAction
  | AddForeignAddressAction
  | AddForeignAddressSuccessAction
  | RemoveForeignAddressAction
  | RemoveForeignAddressSuccessAction
  | ReceiveEventPoliciesErrorAction;

export const loadForeignAddresses = () => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/LOAD_FOREIGN_ADDRESSES',
  };
};

export const loadForeignAddressesSuccess = (addresses: UserForeignAddress[]) => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/LOAD_FOREIGN_ADDRESSES_SUCCESS',
    addresses,
  };
};

export const addForeignAddress = (address: AddUserForeignAddress, onSuccess: () => void) => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/ADD_FOREIGN_ADDRESS',
    address,
    onSuccess,
  };
};

export const addForeignAddressSuccess = () => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/ADD_FOREIGN_ADDRESS_SUCCESS',
  };
};

export const updateForeignAddress = (
  address: string,
  chainId: ForeignChainId,
  update: UpdateUserForeignAddress,
  onSuccess: () => void,
) => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/UPDATE_FOREIGN_ADDRESS',
    address,
    chainId,
    update,
    onSuccess,
  };
};

export const updateForeignAddressSuccess = () => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/UPDATE_FOREIGN_ADDRESS_SUCCESS',
  };
};

export const removeForeignAddress = (address: string, chainId: ForeignChainId, onSuccess: () => void) => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/REMOVE_FOREIGN_ADDRESS',
    address,
    chainId,
    onSuccess,
  };
};

export const removeForeignAddressSuccess = () => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/REMOVE_FOREIGN_ADDRESS_SUCCESS',
  };
};

export const receiveUserForeignAddressError = (error: RequestError) => {
  return <const>{
    type: 'app/User/Current/ForeignAddresses/RECEIVE_FOREIGN_ADDRESS_ERROR',
    error,
  };
};
