import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroupMD from '@material-ui/core/RadioGroup';
import { useField } from 'formik';
import React from 'react';
import { FormItem } from '../FormItem';
import { useInputState } from '../Hooks';
import { InputConfig } from '../types';
import { RadioOption } from './types';

const radioStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[400],
  },
}));

const disabledLabelStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.grey[400],
  },
}));

type Props = {
  radios: RadioOption[];
  name: string;
  id?: string;
  className?: string;
  label?: React.ReactNode;
  row?: boolean;
  inputConfig?: InputConfig;
};

export const RadioGroup: React.FC<Props> = ({ name, id, className, label, row, radios, inputConfig }) => {
  const [input, meta, helpers] = useField(name);
  const { value, onBlur } = input;
  const inputState = useInputState(inputConfig, meta);
  const radioClasses = radioStyles();
  const disabledLabelClasses = disabledLabelStyles();

  return (
    <FormItem label={label} inputState={inputState}>
      <RadioGroupMD
        className={className}
        onChange={(_e, v) => {
          helpers.setValue(v);
        }}
        onBlur={onBlur}
        value={value}
        name={name}
        color="primary"
        row={row}
      >
        {radios.map((radio) => (
          <FormControlLabel
            key={radio.value.toString()}
            htmlFor={`${id || name}.${radio.value}`}
            value={radio.value}
            classes={radio.value !== value ? disabledLabelClasses : undefined}
            control={
              <Radio
                id={`${id || name}.${radio.value}`}
                name={name}
                classes={radioClasses}
                size="small"
                disabled={radio.disabled}
              />
            }
            label={radio.label}
          />
        ))}
      </RadioGroupMD>
    </FormItem>
  );
};
