import { CircularProgress } from '@material-ui/core';
import React from 'react';

export const LoadingFooter: React.FC = () => {
  return (
    <div className="w-full text-center pt-5 pb-5">
      <CircularProgress />
    </div>
  );
};
