import produce from 'immer';
import { ActivityAgreementAction } from './actions';
import { ActivityAgreementState } from './types';

export const initialState: ActivityAgreementState = {
  viewedDocuments: {},
};

const reducer = (state: ActivityAgreementState = initialState, action: ActivityAgreementAction) =>
  produce(
    state,
    (draftState): ActivityAgreementState => {
      switch (action.type) {
        case 'app/Activity/Agreement/RECEIVE_VIEWED_DOCUMENT':
          draftState.viewedDocuments[action.agreementAddress] = {
            ...draftState.viewedDocuments[action.agreementAddress],
            [action.grant]: true,
          };
          return draftState;
        case 'app/Activity/Agreement/CLEAR_VIEWED_DOCUMENTS':
          delete draftState.viewedDocuments[action.agreementAddress];
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;
