import produce from 'immer';
import { mergeBody } from '../../util/form';
import { getInitialValues } from '../constants';
import { AgreementFormState, PanelReviewState } from '../types';
import { PreChainFormAction } from './actions';

export const initialReview: PanelReviewState = {
  parties: false,
  terms: false,
  dates: false,
  events: false,
  settings: false,
  foreignChain: false,
};

export const initialState: AgreementFormState = {
  form: null,
  editing: false,
  reviewSection: 'documents',
  reviewed: initialReview,
  reviewedDocuments: {},
  termManagementDialog: false,
  termRemovalDialog: null,
};

const reducer = (state: AgreementFormState = initialState, action: PreChainFormAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Agreement_v4/Form/RECEIVE_FORM':
        draftState.form = action.form;
        return draftState;
      case 'app/Agreement_v4/Form/UPDATE_FORM':
        draftState.form = action.update ? mergeBody(draftState.form || getInitialValues(), action.update) : null;
        return draftState;
      case 'app/Agreement_v4/Form/TOGGLE_TERMS_MANAGEMENT_DIALOG':
        draftState.termManagementDialog = action.shouldOpen;
        return draftState;
      case 'app/Agreement_v4/Form/TOGGLE_TERM_REMOVAL_DIALOG':
        draftState.termRemovalDialog = action.toRemove;
        return draftState;
      case 'app/Agreement_v4/Form/TOGGLE_REVIEW_SECTION':
        draftState.reviewSection = action.section;
        draftState.editing = false;
        return draftState;
      case 'app/Agreement_v4/Form/ADD_PANEL_REVIEW':
        draftState.reviewed[action.section] = true;
        return draftState;
      case 'app/Agreement_v4/Form/REMOVE_PANEL_REVIEW':
        draftState.reviewed[action.section] = false;
        return draftState;
      case 'app/Agreement_v4/Form/ADD_DOCUMENT_REVIEW':
        draftState.reviewedDocuments[action.grant] = true;
        return draftState;
      case 'app/Agreement_v4/Form/REMOVE_DOCUMENT_REVIEW':
        delete draftState.reviewedDocuments[action.grant];
        return draftState;
      case 'app/Agreement_v4/Form/RESET_REVIEWS':
        draftState.reviewedDocuments = {};
        draftState.reviewed = initialReview;
        return draftState;
      case 'app/Agreement_v4/Form/TOGGLE_EDIT_STATE':
        draftState.editing = action.editing;
        return draftState;
    }
    return draftState;
  });

export default reducer;
