import { FormikErrors, FormikHandlers, FormikHelpers, FormikTouched } from 'formik';
import { get } from 'lodash';

export type SetFieldValue = FormikHelpers<any>['setFieldValue'];
export type SetFieldTouched = FormikHelpers<any>['setFieldTouched'];
export type HandleBlur = FormikHandlers['handleBlur'];
export type HandleChange = FormikHandlers['handleChange'];

export const getTouched = <T = unknown, F = unknown>(
  touched: FormikTouched<F>,
  path: string,
): FormikTouched<T> | null => (!!touched ? get(touched, path) : null);

export const getError = <T = unknown, F = unknown>(errors: FormikErrors<F>, path: string): FormikErrors<T> | null =>
  !!errors ? get(errors, path) : null;

export const getErrorIfString = <T = unknown, F = unknown>(errors: FormikErrors<F>, path: string): string | null => {
  const error = getError<T, F>(errors, path);
  if (typeof error !== 'string') return null;
  return error;
};

export const getValue = <T = unknown, F = unknown>(values: F, path: string): T => get(values, path);

export const getErrorIfTouched = <T = unknown, F = unknown>(
  touched: FormikTouched<F>,
  errors: FormikErrors<F>,
  path: string,
): FormikErrors<T> | null => (!getTouched<T, F>(touched, path) ? null : getError<T, F>(errors, path));

export const hasErrors = <T>(errors: string | string[] | FormikErrors<any> | FormikErrors<any>[]): boolean =>
  errors && Object.keys(errors).length > 0;
export const isTouched = <T>(touched: string | FormikTouched<T>): boolean => touched && Object.keys(touched).length > 0;
