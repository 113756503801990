import React from 'react';
import { InputState } from './Hooks';
import { Label } from './Label';

type Props = {
  label?: React.ReactNode;
  labelAddon?: React.ReactNode;
  infoText?: string;
  inputState?: InputState;
  emptyLabelSpacer?: boolean;
};

export const FormItem: React.FC<Props> = ({ label, labelAddon, infoText, inputState, emptyLabelSpacer, children }) => (
  <div className={`block relative ${inputState?.noFormItemMargin ? '' : 'mb-8'}`}>
    <Label label={label} addon={labelAddon} infoText={infoText} optional={inputState?.optional} />
    {!label && emptyLabelSpacer && <div className="mb-7" />}
    <div>{children}</div>
    {inputState?.errorTextVisible && (
      <div className="max-w-full truncate text-error-dark font-medium text-xs absolute mt-1">
        {inputState?.errorMessage}
      </div>
    )}
  </div>
);
