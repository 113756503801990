import { ResponsiveGrid } from 'components/Common';
import { useBreakpoint } from 'lib/hooks/useBreakpoint';
import React, { ReactNode, ReactNodeArray } from 'react';
import { FormItem } from './FormItem';
import { InputConfig } from './types';

type Props = {
  label?: ReactNode;
  labelAddon?: ReactNode;
  children: ReactNodeArray;
  layout: '1' | '1-1' | '3-2' | '1-1-1';
  mdLayout?: '1' | '1-1' | '3-2' | '1-1-1';
  optional?: boolean;
};

export const pairedInputConfig: InputConfig = { noFormItemMargin: true };
const optionalConfig = { optional: true };
export const PairedFields: React.FC<Props> = ({ label, labelAddon, layout, mdLayout, children, optional }) => {
  const { isAtLeast } = useBreakpoint();
  const responsiveLayout = isAtLeast('md') ? mdLayout || layout : layout;
  const inputs = (
    <div className={`paired-fields w-full flex items-end layout-${responsiveLayout}`}>
      <ResponsiveGrid className="w-full" layout={layout} mdLayout={mdLayout}>
        {children}
      </ResponsiveGrid>
    </div>
  );
  if (label) {
    return (
      <FormItem label={label} labelAddon={labelAddon} inputState={optional ? optionalConfig : undefined}>
        {inputs}
      </FormItem>
    );
  }
  return inputs;
};
