import { combineReducers } from 'redux';
import draftReducer from './Draft/state/reducer';
import templateReducer from './Template/state/reducer';
import { AgreementPreChainState } from './types';

const reducer = combineReducers<AgreementPreChainState>({
  template: templateReducer,
  draft: draftReducer,
});

export default reducer;
