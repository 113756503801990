import { MuiThemeProvider } from '@material-ui/core/styles';
import { sha256Hash } from '@monax/aeger/dist/crypto';
import { LogosDocument, ThemeOverrides } from '@monax/types';
import axios, { AxiosError } from 'axios';
import { b64toBlob } from 'containers/Agreement/Documents/util';
import { selectAgreementThemeOverrides } from 'containers/Agreement/PostChain/Record/state/selectors';
import {
  selectCurrentOrganizationAddress,
  selectCurrentOrganizationThemeOverrides,
} from 'containers/Organization/Current/state/selectors';
import { useAPIRequest, useGetImageURI } from 'lib/hooks';
import { merge } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { parseQueryString } from 'utils/queryString';
import getMuiTheme from './muiTheme';
import { DEFAULT_LOGOS, DEFAULT_NAME, DEFAULT_PALETTE, Logos, Palette, PaletteKey } from './types';
import { getShades } from './util';

export const ThemeContext = React.createContext<{
  themeName: string;
  palette: Palette;
  logos: Logos;
  logoOverrides: ThemeOverrides['logos'];
  loading: boolean;
}>({
  themeName: DEFAULT_NAME,
  palette: DEFAULT_PALETTE,
  logos: DEFAULT_LOGOS,
  logoOverrides: {},
  loading: false,
});

export const ThemeWrapper: React.FC = ({ children }) => {
  const currentOrganizationOverrides = useSelector(selectCurrentOrganizationThemeOverrides);
  const agreementOverrides = useSelector(selectAgreementThemeOverrides);

  const overrideSource = useOverrideSource();

  const [CSS, setCSS] = useState<string>(null);
  const [cssError, setCSSError] = useState<AxiosError>(null);

  const currentOrganizationPalette = useMemo(() => getPalette(currentOrganizationOverrides?.palette), [
    currentOrganizationOverrides,
  ]);
  const agreementPalette = useMemo(() => getPalette(agreementOverrides?.palette), [agreementOverrides]);

  const { palette, logo } = useTheme();

  let paletteOverrides: Palette;
  if (cssError) {
    paletteOverrides = null;
  } else if (overrideSource === 'subdomain') {
    paletteOverrides = palette;
  } else if (overrideSource === 'agreement') {
    paletteOverrides = agreementPalette;
  } else if (overrideSource === 'organization') {
    paletteOverrides = currentOrganizationPalette;
  }
  const themePalette = paletteOverrides || DEFAULT_PALETTE;

  const paletteHash = sha256Hash(paletteOverrides || {});

  useEffect(() => {
    if (paletteOverrides) {
      const cached = localStorage.getItem(paletteHash);
      if (cached) {
        setCSS(cached);
      } else {
        setCSS(null);
      }
      axios
        .post('/custom-css', paletteOverrides, {
          responseType: 'text',
        })
        .then(({ data }) => {
          setCSS(data);
          localStorage.setItem(paletteHash, data);
          setCSSError(null);
        })
        .catch((err) => {
          console.error(err.response);
          setCSSError(err);
        });
    } else {
      setCSS(null);
    }
  }, [paletteOverrides]);

  let logoOverrides: Logos | ThemeOverrides['logos'];
  if (overrideSource === 'subdomain') {
    logoOverrides = logo;
  } else if (overrideSource === 'agreement') {
    logoOverrides = agreementOverrides?.logos;
  } else if (overrideSource === 'organization') {
    logoOverrides = logo;
  }
  //Right now only if there is a subdomain in the url this uses the response from subdomainTheme, if not it calls getImageURI with the grant store in themeOverrides
  const logos = useLogos(logoOverrides);

  const muiTheme = useMemo(() => getMuiTheme(themePalette), [themePalette]);

  const loading = (paletteOverrides && !cssError && !CSS) || !logos;

  const history = useHistory();
  const subdomain = new URLSearchParams(history.location.search).get('subdomain');

  return (
    <MuiThemeProvider theme={muiTheme}>
      <ThemeContext.Provider
        value={{
          themeName: subdomain || DEFAULT_NAME,
          palette: themePalette || DEFAULT_PALETTE,
          logos: logos || DEFAULT_LOGOS,
          logoOverrides: logoOverrides,
          loading,
        }}
      >
        {children}
      </ThemeContext.Provider>
      {CSS && <style type="text/css">{CSS}</style>}
      {/* Uncomment below to check color palette */}
      {/* {Object.keys(palette).map((key) => (
        <div key={key}>
          {Object.keys(palette[key]).map((shade) => (
            <div key={shade} style={{ minWidth: '100px', minHeight: '100px', backgroundColor: palette[key][shade] }}>
              {key} {shade}
            </div>
          ))}
        </div>
      ))} */}
    </MuiThemeProvider>
  );
};

/**
 * Helpers
 */

function useTheme(): {
  name: string;
  palette: Palette;
  logo: Logos;
} {
  const [theme, setTheme] = useState({
    name: DEFAULT_NAME,
    palette: DEFAULT_PALETTE,
    logo: DEFAULT_LOGOS,
  });
  const history = useHistory();
  const { makeRequest } = useAPIRequest('getOrganizationThemeOverrides');
  const currentOrganizationAddress = useSelector(selectCurrentOrganizationAddress);
  useMemo(() => {
    const subdomain = parseQueryString(window.location.search)?.subdomain;
    (!!subdomain || !!currentOrganizationAddress) &&
      makeRequest(subdomain, currentOrganizationAddress)
        .then((res) => {
          setTheme({
            name: subdomain,
            palette: getPalette(res.data.themeOverrides.palette),
            logo: logosDocumentToLogo(res.data.logosDocument),
          });
        })
        .catch((err) => console.error(err.response));
  }, [history, name]);

  return theme;
}

function useOverrideSource(): 'subdomain' | 'agreement' | 'organization' | 'none' {
  const history = useHistory();
  const subdomain = new URLSearchParams(history.location.search).get('subdomain');
  if (subdomain) {
    return 'subdomain';
  } else if (history.location.pathname.match(/\/agreements\/[0-9A-F]{40}/)) {
    return 'agreement';
  } else if (history.location.pathname.match(/\/login/) || history.location.pathname.match(/\/register/)) {
    return 'none';
  } else {
    return 'organization';
  }
}

function getPalette(overrides: ThemeOverrides['palette']): Palette {
  if (!overrides) {
    return null;
  }

  let palette: Palette = null;
  Object.keys(overrides).forEach((key: PaletteKey) => {
    if (key === 'gray' || !overrides[key] || overrides[key].main === DEFAULT_PALETTE[key].main) {
      // no changes necessary
      // explicitly skipping gray for now since we don't accept overrides for it yet anyway and it requires values for shades 100~900 which messes up our typing
      return;
    }
    const shades = getShades(overrides[key].main, overrides[key].contrastText);
    palette ||= merge({}, DEFAULT_PALETTE);
    palette[key] = shades;
  });
  return palette;
}

function logosDocumentToLogo(logos: LogosDocument): Logos {
  const fullSecondaryLogo: string = URL.createObjectURL(
    b64toBlob([logos?.full?.secondary?.data], logos?.full?.secondary?.mime),
  );
  const fullSecondaryContrastTextLogo: string = URL.createObjectURL(
    b64toBlob([logos?.full?.secondaryContrastText?.data], logos?.full?.secondaryContrastText?.mime),
  );
  const faviconSecondaryLogo: string = URL.createObjectURL(
    b64toBlob([logos?.favicon?.secondary?.data], logos?.favicon?.secondary?.mime),
  );
  const faviconSecondaryContrastTextLogo: string = URL.createObjectURL(
    b64toBlob([logos?.favicon?.secondaryContrastText?.data], logos?.favicon?.secondaryContrastText?.mime),
  );

  return fullSecondaryLogo || fullSecondaryContrastTextLogo || faviconSecondaryLogo || faviconSecondaryContrastTextLogo
    ? {
        full: {
          secondary: fullSecondaryLogo || DEFAULT_LOGOS.full.secondary,
          secondaryContrastText: fullSecondaryContrastTextLogo || DEFAULT_LOGOS.full.secondaryContrastText,
        },
        favicon: {
          secondary: faviconSecondaryLogo || DEFAULT_LOGOS.favicon.secondary,
          secondaryContrastText: faviconSecondaryContrastTextLogo || DEFAULT_LOGOS.favicon.secondaryContrastText,
        },
      }
    : DEFAULT_LOGOS;
}

function useLogos(overrides: ThemeOverrides['logos']): Logos {
  const fullSecondary = useGetImageURI(overrides?.full?.secondary, DEFAULT_LOGOS.full.secondary);
  const fullSecondaryContrastText = useGetImageURI(
    overrides?.full?.secondaryContrastText,
    DEFAULT_LOGOS.favicon.secondary,
  );
  const faviconSecondary = useGetImageURI(overrides?.favicon?.secondary, DEFAULT_LOGOS.favicon.secondary);
  const faviconSecondaryContrastText = useGetImageURI(
    overrides?.favicon?.secondaryContrastText,
    DEFAULT_LOGOS.favicon.secondaryContrastText,
  );

  const [fullSecondaryLogo, setFullSecondaryLogo] = useState<string>(null);
  const [fullSecondaryContrastTextLogo, setFullSecondaryContrastTextLogo] = useState<string>(null);
  const [faviconSecondaryLogo, setFaviconSecondaryLogo] = useState<string>(null);
  const [faviconSecondaryContrastTextLogo, setFaviconSecondaryContrastTextLogo] = useState<string>(null);

  useEffect(() => {
    if (!overrides?.full?.secondary?.startsWith('blob:') && !overrides?.full?.secondary?.startsWith('/images/')) {
      fullSecondary.makeRequest().then((dataURI) => setFullSecondaryLogo(dataURI));
    } else if (overrides?.full?.secondary) {
      setFullSecondaryLogo(overrides.full.secondary);
    }
    if (
      !overrides?.full?.secondaryContrastText?.startsWith('blob:') &&
      !overrides?.full?.secondaryContrastText?.startsWith('/images/')
    ) {
      fullSecondaryContrastText.makeRequest().then((dataURI) => setFullSecondaryContrastTextLogo(dataURI));
    } else if (overrides?.full?.secondaryContrastText) {
      setFullSecondaryContrastTextLogo(
        overrides?.full?.secondaryContrastText !== DEFAULT_LOGOS.full.secondaryContrastText
          ? overrides?.full?.secondaryContrastText
          : DEFAULT_LOGOS.favicon.secondary,
      );
    }
    if (!overrides?.favicon?.secondary?.startsWith('blob:') && !overrides?.favicon?.secondary?.startsWith('/images/')) {
      faviconSecondary.makeRequest().then((dataURI) => setFaviconSecondaryLogo(dataURI));
    } else if (overrides?.favicon?.secondary) {
      setFaviconSecondaryLogo(overrides.favicon.secondary);
    }
    if (
      !overrides?.favicon?.secondaryContrastText?.startsWith('blob:') &&
      !overrides?.favicon?.secondaryContrastText?.startsWith('/images/')
    ) {
      faviconSecondaryContrastText.makeRequest().then((dataURI) => setFaviconSecondaryContrastTextLogo(dataURI));
    } else if (overrides?.favicon?.secondaryContrastText) {
      setFaviconSecondaryContrastTextLogo(overrides.favicon.secondaryContrastText);
    }
  }, [overrides]);

  useEffect(() => {
    const headEls = window.document.head.children;
    for (let i = 0; i < headEls.length; i++) {
      const el = headEls[i];
      if (el.tagName === 'LINK' && el.getAttribute('rel') === 'icon') {
        el.setAttribute('href', faviconSecondaryLogo);
      }
    }
  }, [faviconSecondaryLogo]);

  return fullSecondaryLogo && fullSecondaryContrastTextLogo && faviconSecondaryLogo && faviconSecondaryContrastTextLogo
    ? {
        full: {
          secondary: fullSecondaryLogo,
          secondaryContrastText: fullSecondaryContrastTextLogo,
        },
        favicon: {
          secondary: faviconSecondaryLogo,
          secondaryContrastText: faviconSecondaryContrastTextLogo,
        },
      }
    : DEFAULT_LOGOS;
}
