import { createAvatar, StyleOptions } from '@dicebear/avatars';
import * as jdenticon from '@dicebear/avatars-jdenticon-sprites';
import React from 'react';
import InlineSVG from 'svg-inline-react';

type IdenticonProps = {
  seed: string;
  size?: number;
};

export const Identicon: React.FC<IdenticonProps> = ({ seed, size = 30 }) => {
  const iconStyleOptions: StyleOptions<{}> = {
    seed,
    width: size,
    height: size,
  };

  const svg = createAvatar(jdenticon, iconStyleOptions);

  return <InlineSVG src={svg} />;
};
