import { FormControlLabel, FormGroup } from '@material-ui/core';
import MUISwitch from '@material-ui/core/Switch';
import { useField } from 'formik';
import React from 'react';
import { InputEndAdornment } from './common';
import { FormItem } from './FormItem';
import { useInputState } from './Hooks';
import { InputConfig, LabelPlacement } from './types';

type Props = {
  name: string; // make name required
  label?: string;
  labelPlacement?: LabelPlacement;
  formItemLabel?: string;
  inputConfig?: InputConfig;
  id?: string;
  disabled?: boolean;
  size?: 'small' | 'medium';
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const Switch: React.FC<Props> = (props) => {
  const [input, meta] = props.name ? useField(props.name) : [];

  const { label, formItemLabel, inputConfig, labelPlacement, ...rest } = props;

  const inputState = useInputState(inputConfig, meta);

  return (
    <FormItem label={formItemLabel} inputState={inputState}>
      <FormGroup row>
        <SwitchControl
          {...input}
          {...rest}
          checked={input.value}
          id={input.name}
          label={label}
          labelPlacement={labelPlacement}
        />
        <FormControlLabel control={<InputEndAdornment inputState={inputState} />} label={<></>} />
      </FormGroup>
    </FormItem>
  );
};

type SwitchControlProps = Omit<Props, 'formItemLabel' | 'inputConfig' | 'name'> & {
  name?: string;
  checked: boolean;
};

export const SwitchControl: React.FC<SwitchControlProps> = (props) => {
  const { label, labelPlacement, ...rest } = props;

  return (
    <FormControlLabel
      disabled={props.disabled}
      control={<MUISwitch {...rest} />}
      label={label}
      labelPlacement={labelPlacement}
    />
  );
};
