import { lazy } from 'react';
import { LoadFailure } from './LoadFailure';

export const LazyLoader =
  (lazyComponent: () => Promise<unknown>): React.LazyExoticComponent<React.ComponentType<any>> =>
    lazy(() => new Promise((resolve) => {
      lazyComponent()
        .then(resolve)
        .catch((error) => {
          resolve({
            default: LoadFailure,
          });
      });
}));
