import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';

export const useDeepEffect = (callback: () => void, deps: unknown[]) => {
  const isFirst = useRef<boolean>(true);
  const prevDeps = useRef<unknown[]>(deps);

  useEffect(() => {
    const isSame = prevDeps.current.every((obj, index) => isEqual(obj, deps[index]));

    if (isFirst.current || !isSame) {
      callback();
    }

    isFirst.current = false;
    prevDeps.current = deps;
  }, deps);
};
