import { createSelector } from 'reselect';
import { ApplicationState } from 'types';

const rootSelector = (state: ApplicationState) => state.users.current.resourceFilters;

export const selectResourceFilters = createSelector(rootSelector, (state) => state.resourceFilters);

export const selectTemplateResourceFilters = createSelector(selectResourceFilters, (resourceFilters) =>
  resourceFilters.filter((r) => r.resource === 'template'),
);
export const selectAgreementResourceFilters = createSelector(selectResourceFilters, (resourceFilters) =>
  resourceFilters.filter((r) => r.resource === 'agreement'),
);

export const selectResourceFilterRequesting = createSelector(rootSelector, (state) => state.requesting);
