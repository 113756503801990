import { combineReducers } from 'redux';
import recordReducer from './Record/state/reducer';
import setupReducer from './Setup/state/reducer';
import taskReducer from './Task/reducer';
import { AgreementPostChainState } from './types';

const reducer = combineReducers<AgreementPostChainState>({
  record: recordReducer,
  task: taskReducer,
  setup: setupReducer,
});

export default reducer;
