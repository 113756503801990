import { useCallback, useEffect } from 'react';

export const useOnClickOutside = (
  ref: React.MutableRefObject<any>,
  onClickOutside: () => void,
  ignoreIds: string[] = [],
  dependancies: (string | number)[] = [],
) => {
  const handleClick = useCallback((e) => {
    if (ref.current.contains(e.target) || ignoreIds.includes(e.target.id)) {
      // ignore inside click or specified targets
      return;
    }
    for (let i = 0; i < ignoreIds.length; i++) {
      // ignore clicks in specified elements
      const el = document.getElementById(ignoreIds[i]);
      if (el && el.contains(e.target)) {
        return;
      }
    }
    // handle outside click
    onClickOutside();
  }, dependancies);
  useEffect(() => {
    window.document.addEventListener('mousedown', handleClick);
    return () => {
      window.document.removeEventListener('mousedown', handleClick);
    };
  }, dependancies);
};
