import produce from 'immer';
import { SubscriptionState } from '../types';
import { EventAction } from './actions';

export const initialState: SubscriptionState = {
  eventSubscriptions: [],
  error: null,
  loadingEventSubscriptions: false,
  savingEventSubscription: false,
  savingEventSubscriptions: false,
};

const reducer = (state: SubscriptionState = initialState, action: EventAction) =>
  produce(
    state,
    (draftState): SubscriptionState => {
      switch (action.type) {
        case 'app/Event/Subscription/LOAD_EVENT_SUBSCRIPTIONS':
          draftState.loadingEventSubscriptions = true;
          draftState.error = null;
          return draftState;
        case 'app/Event/Subscription/LOAD_EVENT_SUBSCRIPTIONS_SUCCESS':
          draftState.eventSubscriptions = action.eventSubscriptions;
          draftState.loadingEventSubscriptions = false;
          return draftState;
        case 'app/Event/Subscription/SAVE_EVENT_SUBSCRIPTION':
          draftState.savingEventSubscription = true;
          draftState.error = null;
          return draftState;
        case 'app/Event/Subscription/SAVE_EVENT_SUBSCRIPTION_SUCCESS':
          draftState.savingEventSubscription = false;
          return draftState;
        case 'app/Event/Subscription/SAVE_EVENT_SUBSCRIPTIONS':
          draftState.savingEventSubscriptions = true;
          draftState.error = null;
          return draftState;
        case 'app/Event/Subscription/SAVE_EVENT_SUBSCRIPTIONS_SUCCESS':
          draftState.savingEventSubscriptions = false;
          return draftState;
        case 'app/Event/Subscription/RECEIVE_EVENT_SUBSCRIPTIONS_ERROR':
          draftState.loadingEventSubscriptions = false;
          draftState.savingEventSubscription = false;
          draftState.savingEventSubscriptions = false;
          draftState.error = action.error;
          return draftState;
        default:
          return draftState;
      }
    },
  );

export default reducer;
