import { LazyLoader } from 'components/LazyLoader';
import { Route } from 'containers/App/components/Route';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';

const SubmitNotice = LazyLoader(() => import('./Completion/Obligation/SubmitNotice'));

// This is purely here for backward compatibility (eg. straggling links in emails)
// Can most probably safely remove from June 2021
const TaskRoutes: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Agreement Tasks</title>
      </Helmet>
      <Switch>
        <Route exact path="/tasks/completion/:agreementAddress/obligations/:obligationId" component={SubmitNotice} />
      </Switch>
    </React.Fragment>
  );
};

export { TaskRoutes };
