import { makeStyles, Tab as MuiTab, TabProps, Theme, withStyles } from '@material-ui/core';
import * as React from 'react';

export type TabColor = 'primary' | 'info';
export type TabSize = 's' | 'm';

type Props = {
  color?: TabColor;
  size?: TabSize;
} & TabProps;

const useStyles = makeStyles<Theme, Props>({
  root: {
    fontSize: (props) => (props.size === 's' ? '0.875rem' : undefined),
    minHeight: (props) => (props.size === 's' ? 50 : undefined),
  },
});

const TabSecondary = withStyles((theme) => ({
  root: {
    '&:hover': {
      color: theme.palette.info.main,
    },
    '&$selected': {
      color: theme.palette.info.main,
    },
    '&:focus': {
      color: theme.palette.info.main,
    },
  },
  selected: {},
  textColorInherit: {
    color: theme.palette.grey[700],
  },
}))((props: TabProps) => <MuiTab {...props} />);

export const Tab: React.FC<Props> = (props) => {
  const { color = 'primary', size = 'm', ...rest } = props;
  const { root } = useStyles(props);

  const classes = `${root}`;

  if (color === 'primary') return <MuiTab className={classes} {...rest} />;
  return <TabSecondary className={classes} {...rest} />;
};
