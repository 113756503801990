import produce from 'immer';
import { AuthState } from '../types';
import { AuthAction } from './actions';
import { INIT_CONFIG } from './constants';

export const initialState: AuthState = {
  token: null,
  idTokenPayload: null,
  authHistory: {},
  error: null,
  config: {
    clientID: INIT_CONFIG.client_id,
    domain: INIT_CONFIG.domain,
    audience: INIT_CONFIG.audience,
    redirectUri: INIT_CONFIG.redirect_uri,
    responseType: INIT_CONFIG.responseType,
    scope: INIT_CONFIG.scope,
  },
};

const authReducer = (state = initialState, action: AuthAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Auth/RECEIVE_AUTH_TOKEN':
        draftState.error = null;
        draftState.token = action.token;
        draftState.idTokenPayload = action.idTokenPayload;
        if (action.idTokenPayload?.email) {
          draftState.authHistory = {
            ...draftState.authHistory,
            [action.idTokenPayload.email]: {
              date: new Date().toISOString(),
              idTokenPayload: action.idTokenPayload,
            },
          };
        }
        return draftState;
      case 'app/Auth/CLEAR_AUTH_TOKENS':
        draftState.token = null;
        draftState.idTokenPayload = null;
        return draftState;
      case 'app/Auth/RECEIVE_AUTH_ERROR':
        draftState.error = action.error;
        return draftState;
      default:
        return draftState;
    }
  });

export default authReducer;
