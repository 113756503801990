/*
 * Organization Messages
 *
 * This contains all the text for the Organization component.
 */
import { defineMessages } from 'react-intl';

const scope = 'app.containers.Organization';
export default defineMessages({
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Create',
  },
  id: {
    id: `${scope}.id`,
    defaultMessage: 'Id',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  selectCreateLabel: {
    id: `${scope}.selectCreateLabel`,
    defaultMessage: 'Add organization: {name}',
    description: 'Select option label if allowing option creation',
  },
  enterOrganizationName: {
    id: `${scope}.enterOrganizationName`,
    defaultMessage: 'Enter organization name',
    description: 'Select option label for empty option list',
  },
  loadOrganizationError: {
    id: `${scope}.loadOrganizationError`,
    defaultMessage: 'Failed to load organization data',
  },
});
