/* eslint-disable no-empty */
import { selectAPIClient } from 'containers/App/state/selectors';
import { call, put, select, takeLatest } from 'typed-redux-saga';
import { loadUsersSuccess } from './actions';

/**
 * Worker Sagas
 */

export function* loadUsers() {
  const client = yield* select(selectAPIClient);
  const {
    data: { users },
  } = yield* call(client.listUsers);
  yield put(loadUsersSuccess(users));
}

/**
 * Watcher Sagas
 */

export function* watchLoadUsers() {
  yield takeLatest('app/UserAccount/LOAD_USERS', loadUsers);
}
