import { getEnvironment } from 'configs';
import { FormatDateOptions } from 'react-intl';

export const dateOptions: Record<'monthOnly' | 'monthAndDate' | 'short' | 'long', FormatDateOptions> = {
  monthOnly: { month: 'short' },
  monthAndDate: { month: 'short', day: 'numeric' },
  short: { year: 'numeric', month: 'short', day: 'numeric' },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  },
};

export const longIfDevMode: FormatDateOptions =
  getEnvironment() === 'local' || getEnvironment() === 'develop' ? dateOptions.long : dateOptions.short;
