import {
  AgreementDocument,
  AgreementParameter,
  DocumentAssemblyInfo,
  DocumentCreationResponse,
  DocumentInfo,
  DocumentMeta,
} from '@monax/types';
import { RequestError } from 'types';

export type LoadAssembledDocument = ReturnType<typeof loadAssembledDocument>;
export type LoadAssembledDocumentSuccess = ReturnType<typeof loadAssembledDocumentSuccess>;
export type LoadAssembledDocumentError = ReturnType<typeof loadAssembledDocumentError>;
export type LoadDocumentMeta = ReturnType<typeof loadDocumentMeta>;
export type LoadDocumentMetaSuccess = ReturnType<typeof loadDocumentMetaSuccess>;
export type LoadDocumentMetaError = ReturnType<typeof loadDocumentMetaError>;
export type UploadDocuments = ReturnType<typeof uploadDocuments>;
export type UploadDocumentsSuccess = ReturnType<typeof uploadDocumentsSuccess>;
export type UploadDocumentsError = ReturnType<typeof uploadDocumentsError>;
export type LoadDocument = ReturnType<typeof loadDocument>;
export type LoadDocumentSuccess = ReturnType<typeof loadDocumentSuccess>;
export type LoadDocumentError = ReturnType<typeof loadDocumentError>;
export type UploadAttachment = ReturnType<typeof uploadAttachment>;
export type UploadAttachmentSuccess = ReturnType<typeof uploadAttachmentSuccess>;
export type UploadAttachmentError = ReturnType<typeof uploadAttachmentError>;
export type ReceiveDocumentsExpanded = ReturnType<typeof receiveDocumentsExpanded>;

export type AgreementDocumentAction =
  | LoadAssembledDocument
  | LoadAssembledDocumentSuccess
  | LoadAssembledDocumentError
  | LoadDocumentMeta
  | LoadDocumentMetaSuccess
  | LoadDocumentMetaError
  | UploadDocuments
  | UploadDocumentsSuccess
  | UploadDocumentsError
  | LoadDocument
  | LoadDocumentSuccess
  | LoadDocumentError
  | UploadAttachment
  | UploadAttachmentSuccess
  | UploadAttachmentError
  | ReceiveDocumentsExpanded;

/**
 * LOAD_ASSEMBLED_DOCUMENT
 */

export const loadAssembledDocument = (
  grant: string,
  parameters: AgreementParameter[],
  address?: string,
  signaturesFileReference?: string,
) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_ASSEMBLED_DOCUMENT',
    grant,
    parameters,
    address,
    signaturesFileReference,
  };
};

export const loadAssembledDocumentSuccess = (assembled: DocumentAssemblyInfo) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_ASSEMBLED_DOCUMENT_SUCCESS',
    assembled,
  };
};

export const loadAssembledDocumentError = (error: RequestError, grant: string) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_ASSEMBLED_DOCUMENT_ERROR',
    error,
    grant,
  };
};

/**
 * LOAD_DOCUMENT_META
 */

export const loadDocumentMeta = (grant: string, extractParams?: boolean) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_DOCUMENT_META',
    grant,
    extractParams,
  };
};

export const loadDocumentMetaSuccess = (grant: string, meta: DocumentMeta, params?: string[]) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_DOCUMENT_META_SUCCESS',
    grant,
    meta,
    params,
  };
};

export const loadDocumentMetaError = (error: RequestError, grant: string) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_DOCUMENT_META_ERROR',
    error,
    grant,
  };
};

/**
 * UPLOAD_DOCUMENTS
 */

export const uploadDocuments = (
  documents: File[],
  onUploadSuccess: (documents: { grant?: string; meta?: DocumentMeta; params?: DocumentInfo['params'] }[]) => void,
) => {
  return <const>{
    type: 'app/Agreement/Document/UPLOAD_DOCUMENTS',
    documents,
    onUploadSuccess,
  };
};

export const uploadDocumentsSuccess = (documents: DocumentCreationResponse[]) => {
  return <const>{
    type: 'app/Agreement/Document/UPLOAD_DOCUMENTS_SUCCESS',
    documents,
  };
};

export const uploadDocumentsError = () => {
  return <const>{
    type: 'app/Agreement/Document/UPLOAD_DOCUMENTS_ERROR',
  };
};

/**
 * LOAD_DOCUMENT
 */

export const loadDocument = (grant: string, onSuccess?: () => void) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_DOCUMENT',
    grant,
    onSuccess,
  };
};

export const loadDocumentSuccess = (grant: string, info: DocumentInfo, file: Buffer) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_DOCUMENT_SUCCESS',
    grant,
    file,
    ...info,
  };
};

export const loadDocumentError = (error: RequestError, grant: string) => {
  return <const>{
    type: 'app/Agreement/Document/LOAD_DOCUMENT_ERROR',
    error,
    grant,
  };
};

/**
 * UPLOAD_DOCUMENT
 */

export const uploadAttachment = (attachment: File) => {
  return <const>{
    type: 'app/Agreement/Document/UPLOAD_DOCUMENT',
    attachment,
  };
};

export const uploadAttachmentSuccess = (document: AgreementDocument) => {
  return <const>{
    type: 'app/Agreement/Document/UPLOAD_DOCUMENT_SUCCESS',
    document,
  };
};

export const uploadAttachmentError = () => {
  return <const>{
    type: 'app/Agreement/Document/UPLOAD_DOCUMENT_ERROR',
  };
};

/**
 * DOCUMENTS_EXPANDED
 * @param documentsExpanded Indicates whether document is in fullscreen or normal mode
 */
export const receiveDocumentsExpanded = (documentsExpanded: boolean) => {
  return <const>{
    type: 'app/Agreement/Document/DOCUMENTS_EXPANDED',
    documentsExpanded,
  };
};
