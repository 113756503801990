import { parseAddress } from '@monax/aeger/dist/address';
import { Button } from 'components/Common';
import { Dialog2 } from 'components/Dialog';
import { FormItem, Input, Select } from 'components/Form';
import { addFormattedNotification } from 'containers/App/state/actions';
import { selectAPIClient } from 'containers/App/state/selectors';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import { useWallet, WalletMenu } from 'lib/hooks/useWallet';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { validator } from 'validation/yup';
import { selectCurrentUserOrganizations } from '../../Profile/state/selectors';
import { messages } from '../messages';
import { addForeignAddress, loadForeignAddresses } from '../state/actions';
import { selectForeignAddresses } from '../state/selectors';
import { AddForeignAddressForm } from '../types';
import { addForeignAddressSchema } from '../validation';

const validate = validator(addForeignAddressSchema);

type Props = {
  isOpen: boolean;
  onForeignAddressAdded: () => void;
  onCancel: () => void;
  transparentBackdrop?: boolean;
};

export const AddForeignAddressDialog: React.FC<Props> = ({
  isOpen,
  onForeignAddressAdded,
  onCancel,
  transparentBackdrop,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { wallet, account } = useWallet();
  const [error, setError] = useState<string | undefined>(undefined);
  const organizations = useSelector(selectCurrentUserOrganizations);
  const foreignAddresses = useSelector(selectForeignAddresses);
  const apiClient = useSelector(selectAPIClient);

  const onSubmit = async (
    values: AddForeignAddressForm,
    actions: FormikHelpers<AddForeignAddressForm>,
  ): Promise<void> => {
    if (!account || !wallet) {
      return;
    }
    setError(undefined);

    if (foreignAddresses.some((f) => f.address?.toLowerCase() === account.toLowerCase())) {
      actions.setFieldError('address', intl.formatMessage(messages.foreignAddressAlreadyAdded));
      return;
    }

    actions.setFieldTouched('address');

    const signRes = await wallet.sign(apiClient, dispatch);
    if (!signRes) {
      return;
    }
    dispatch(
      addForeignAddress(
        {
          address: signRes.account,
          chainId: 'ethereum_mainnet',
          signature: signRes.signature,
          name: values.name,
          organizationAddress: parseAddress(values.organizationAddress),
        },
        () => {
          dispatch(addFormattedNotification({ messageDescriptor: messages.foreignAddressAdded }));
          dispatch(loadForeignAddresses()); // Reload addresses
          onForeignAddressAdded();
          actions.resetForm();
        },
      ),
    );
  };

  return (
    <Formik<AddForeignAddressForm>
      initialValues={{
        name: '',
        organizationAddress: '',
        address: account || '',
      }}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize={true}
    >
      {({ isSubmitting }) => (
        <Dialog2
          fullWidth
          maxWidth="md"
          isOpen={isOpen}
          disableEscape={isSubmitting}
          transparentBackdrop={transparentBackdrop}
          title={intl.formatMessage(messages.addForeignAddressHeading)}
          content={
            <div>
              {!account && <div className="mb-4">{intl.formatMessage(messages.connectWallet)}</div>}
              <div className="mb-4">
                <WalletMenu disabled={isSubmitting} />
              </div>
              {account && (
                <div>
                  <FormItem label={intl.formatMessage(messages.addressName)}>
                    <Input name="name" />
                  </FormItem>
                  <FormItem label={intl.formatMessage(messages.addressOrganization)}>
                    <Select
                      name="organizationAddress"
                      options={organizations.map((o) => ({ label: o.name, value: o.address }))}
                    />
                  </FormItem>
                  <FormItem label={intl.formatMessage(messages.connectedForeignAddress)}>
                    <Input name="address" disabled={true} inputConfig={{ showValidAdornment: true }} />
                  </FormItem>
                </div>
              )}
              {error && <div className="text-error-main">{error}</div>}
            </div>
          }
          onClose={onCancel}
          submit={
            <Button type="primary" buttonType="submit" disabled={!account || isSubmitting}>
              {intl.formatMessage(messages.addForeignAddress)}
            </Button>
          }
          ChildrenWrapper={Form}
        />
      )}
    </Formik>
  );
};

const Form: React.FC = ({ children }) => {
  const { handleSubmit } = useFormikContext();
  return <form onSubmit={handleSubmit}>{children}</form>;
};
