import { getPersistStorage } from 'configs';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import localeReducer from './Locale/state/reducer';
import { LocaleState } from './Locale/types';
import organizationReducer from './Organization/state/reducer';
import { OrganizationState } from './Organization/types';
import { PreferencesState } from './types';

const localeConfig: PersistConfig<LocaleState> = {
  key: 'preferences-locale',
  storage: getPersistStorage(),
};

const organizationConfig: PersistConfig<OrganizationState> = {
  key: 'preferences-organization',
  storage: getPersistStorage(),
};

const reducer = combineReducers<PreferencesState>({
  locale: persistReducer(localeConfig, localeReducer),
  organization: persistReducer(organizationConfig, organizationReducer),
});

export default reducer;
