import { ForeignChainId } from '@monax/types';
import { useNotifications } from 'containers/App/hooks/useNotifications';
import React, { createContext, useEffect, useState } from 'react';
import { messages } from './messages';
import { MetamaskWallet } from './MetamaskWallet';
import { Wallet, WalletError, WalletStore, WalletType } from './types';
import { WalletConnectWallet } from './WalletConnectWallet';
import { WalletLinkWallet } from './WalletLinkWallet';

export const WalletContext = createContext<
  {
    wallet: Wallet | null;
    selectWallet: (walletType: WalletType | null) => Wallet;
  } & WalletStore
>({
  wallet: null,
  selectWallet: (walletType: WalletType | null) => undefined,
  account: null,
  chain: null,
  error: null,
});

export const WalletProvider: React.FC = ({ children }) => {
  const [wallet, setWallet] = useState<Wallet | null>(null);

  const [account, accountSet] = useState<string | null>(null);
  const [chain, chainSet] = useState<ForeignChainId | null>(null);
  const [error, errorSet] = useState<WalletError | null>(null);
  const eventEmitters = { accountSet, chainSet, errorSet };

  const selectWallet = (walletType: WalletType | null) => {
    accountSet(null);
    chainSet(null);
    errorSet(null);
    wallet?.disconnect();

    let newWallet: Wallet;
    switch (walletType) {
      case 'metamask':
        newWallet = new MetamaskWallet(eventEmitters);
        break;
      case 'wallet-connect':
        newWallet = new WalletConnectWallet(eventEmitters);
        break;
      case 'wallet-link':
        newWallet = new WalletLinkWallet(eventEmitters);
        break;
      default:
        newWallet = null;
        break;
    }
    setWallet(newWallet);
    return newWallet;
  };

  const { showError } = useNotifications();
  useEffect(() => {
    if (error) {
      switch (error.type) {
        case 'availability':
          showError(messages.error_availability, { error: error.error.message });
          accountSet(null);
          chainSet(null);
          selectWallet(null);
          break;
        case 'connection':
          showError(messages.error_connection, { error: error.error.message });
          accountSet(null);
          chainSet(null);
          selectWallet(null);
          break;
        case 'sign':
          showError(messages.error_sign, { error: error.error.message });
          break;
        case 'signIn':
          showError(messages.error_signIn, { error: error.error.message });
          accountSet(null);
          chainSet(null);
          selectWallet(null);
          break;
        case 'tx':
          showError(messages.error_tx, { error: error.error.message });
          break;
      }
    }
  }, [error]);

  return (
    <WalletContext.Provider
      value={{
        wallet,
        selectWallet,
        account,
        chain,
        error,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};
