import { AuthErrorCode, RegisterQueryParameters } from '@monax/types';
import { INIT_CONFIG } from 'containers/Auth/Auth/state/constants';
import { selectLocale } from 'containers/Preferences/Locale/state/selectors';
import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { ApplicationState } from 'types';
import { track, TrackProps } from 'utils/analytics';
import { v4 as uuid } from 'uuid';
import { getEmailRegisterSchema } from 'validation/login';
import { validator } from 'validation/yup';
import { selectWebAuth } from '../Auth/state/selectors';
import { getAuth0ErrorMessage } from '../utils';
import { EmailFormPanel, RegisteredPanel } from './controls';
import { EmailRegisterForm } from './types';

type Props = {
  params: RegisterQueryParameters;
  registering: boolean;
  setRegistering: (registering: boolean) => void;
  registered: boolean;
  setRegistered: (registered: boolean) => void;
};

const RegisterWithEmailAddressComponent: React.FC<Props & PropsFromState> = ({
  webAuth,
  params,
  registering,
  setRegistering,
  registered,
  setRegistered,
}) => {
  const intl = useIntl();

  // For first time users this will be defaulted to the browser locale (eg navigator.language)
  const browserLocale = useSelector(selectLocale);

  const emailRegisterSchema = getEmailRegisterSchema(intl);
  const validateEmailForm = validator(emailRegisterSchema);

  const { email = '', invitationCode, counterparty, byAgreement, locale: queryStringLocale } = params;

  const handleRegister = (values: EmailRegisterForm, actions: FormikHelpers<EmailRegisterForm>) => {
    if (registering) return;

    setRegistering(true);

    webAuth.signup(
      {
        username: uuid().split('-').slice(0, 3).join(''),
        email: values.email,
        password: values.password,
        connection: INIT_CONFIG.connection,
        userMetadata: {
          invitationCode,
          locale: queryStringLocale ?? browserLocale,
        },
      },
      (error) => {
        if (error) {
          console.error(error);

          // Get translated error message
          const message = getAuth0ErrorMessage(intl, error.code as AuthErrorCode, error.description);

          // Best guess (error to field mapping)
          const fieldName = error.code.indexOf('password') === -1 ? 'username' : 'password';

          actions.setFieldError(fieldName, message);
        } else {
          // Success!
          const trackProps: TrackProps = {
            event: 'application.register',
            payload: {},
          };
          if (counterparty) {
            trackProps.payload.invitedBy = 'counterparty';
          } else {
            trackProps.payload.invitedBy = 'team-member';
          }
          if (byAgreement === 'true') trackProps.payload.byAgreement = true;
          else trackProps.payload.byAgreement = false;
          track(trackProps);
          setRegistered(true);
        }

        setRegistering(false);
      },
    );
  };

  return (
    <Formik<EmailRegisterForm>
      initialValues={{
        email: email,
        password: '',
        confirmPassword: '',
        tosAccepted: false,
      }}
      onSubmit={handleRegister}
      validate={validateEmailForm}
      validateOnMount={true}
    >
      <>
        {!registered && <EmailFormPanel loading={registering} fixedEmail={!!email} />}
        {registered && <RegisteredPanel />}
      </>
    </Formik>
  );
};

type PropsFromState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: ApplicationState) => ({
  webAuth: selectWebAuth(state),
});

export const RegisterWithEmailAddress = connect(mapStateToProps)(RegisterWithEmailAddressComponent);
