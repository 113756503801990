/*
 *
 * Organization actions
 *
 */
import { Address, ThrottledResourceBalance, ThrottledResourceLimits } from '@monax/types';

export type LoadOrganizationResourceLimits = ReturnType<typeof loadOrganizationResourceLimits>;
export type LoadCurrentOrganizationResourceLimits = ReturnType<typeof loadCurrentOrganizationResourceLimits>;
export type LoadOrganizationResourceLimitsSuccess = ReturnType<typeof loadOrganizationResourceLimitsSuccess>;

export type OrganizationResourceLimitsAction =
  | LoadOrganizationResourceLimits
  | LoadCurrentOrganizationResourceLimits
  | LoadOrganizationResourceLimitsSuccess;

/**
 * LOAD_ORGANIZATION_RESOURCE_LIMITS
 */
export function loadOrganizationResourceLimits(address: Address) {
  return <const>{
    type: 'app/Organization/ResourceBalance/LOAD_ORGANIZATION_RESOURCE_LIMITS',
    address,
  };
}

export function loadCurrentOrganizationResourceLimits() {
  return <const>{
    type: 'app/Organization/ResourceBalance/LOAD_CURRENT_ORGANIZATION_RESOURCE_LIMITS',
  };
}

export function loadOrganizationResourceLimitsSuccess(
  limits: ThrottledResourceLimits,
  balance: ThrottledResourceBalance,
) {
  return <const>{
    type: 'app/Organization/ResourceBalance/LOAD_ORGANIZATION_RESOURCE_LIMITS_SUCCESS',
    balance,
    limits,
  };
}
