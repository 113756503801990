import configs from 'configs';
import graphic from 'images/error/error.svg';
import React from 'react';
import { useIntl } from 'react-intl';
import { Action, ErrorLayout } from './controls';
import { messages } from './messages';

export const ClientError: React.FC = () => {
  const intl = useIntl();

  return (
    <ErrorLayout
      graphic={graphic}
      title={intl.formatMessage(messages.serverErrorHeader)}
      description={intl.formatMessage(messages.serverErrorDescription, {
        email: (
          <a className="text-primary-main" href={`mailto:${configs.emails.support}`}>
            {configs.emails.support}
          </a>
        ),
      })}
      actions={<Action href="/">{intl.formatMessage(messages.backToHome)}</Action>}
    />
  );
};
