import { APIClient, ForeignChainId } from '@monax/types';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { handleLoginSuccess, receiveAuthToken } from 'containers/Auth/Auth/state/actions';
import { Dispatch } from 'redux';
import { WalletLinkProvider } from 'walletlink';
import { WalletEvents } from './types';

export async function getNonce(apiClient: APIClient, account: string) {
  const {
    data: { nonce },
  } = await apiClient.getAuthenticationNonceForEthereumAddress(account);
  return nonce;
}

export async function signIn(apiClient: APIClient, dispatch: Dispatch, account: string, signature: string) {
  const {
    data: { accessToken, idToken },
  } = await apiClient.authenticateEthereumAddress({ address: account, signature });
  dispatch(receiveAuthToken(accessToken, idToken));
  dispatch(handleLoginSuccess({}));
}

export async function attachListeners(
  provider: WalletConnectProvider | WalletLinkProvider,
  walletEvents: WalletEvents,
) {
  provider.on('accountsChanged', ([account = null]) => {
    console.log('accountsChanged', account);
    walletEvents.accountSet(account);
  });
  provider.on('chainChanged', (chainHex) => {
    console.log('chainChanged', chainHex);
    walletEvents.chainSet(chainNameFromId(parseInt(chainHex, 16)));
  });
  provider.on('disconnect', (code: number, reason: string) => {
    console.log('Provider received disconnect event:', code, reason);
    walletEvents.accountSet(null);
    walletEvents.chainSet(null);
  });
}

export function chainNameFromId(chainId: number | string): ForeignChainId {
  if (typeof chainId !== 'number' && chainId.length > 9) {
    return 'ethereum_local';
  }
  switch (chainId) {
    case 1:
      return 'ethereum_mainnet';
    case 3:
      return 'ethereum_ropsten';
    default:
      return null;
  }
}
