import { ShapesBackground } from 'components/Background';
import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';

type Props = {
  graphic: string;
  title: React.ReactNode;
  description: React.ReactNode;
  actions: React.ReactNode;
};

export const ErrorLayout: React.FC<Props> = ({ graphic, title, description, actions }) => {
  const { logos } = useTheme();
  return (
    <div className="fixed h-full w-full top-0 left-0">
      <ShapesBackground>
        <button
          style={{ position: 'absolute', height: 'auto', left: 50, top: 50 }}
          className="invisible md:visible self-start cursor-pointer"
          onClick={() => window.location.assign('/')}
        >
          <img src={logos?.full?.secondary} alt="logo" width="160px" />
        </button>
        <div className="w-full flex flex-col justify-center">
          <div className="flex justify-center">
            <div className="w-full md:w-3/12">
              <img src={graphic} className="w-full" />
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-full md:w-2/6 pt-10 text-center">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-full md:w-2/6 pt-5 text-center text-info-main">
              <p>{description}</p>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div className="w-full md:w-2/6 pt-10 text-center z-50 flex justify-center">{actions}</div>
          </div>
        </div>
      </ShapesBackground>
    </div>
  );
};
