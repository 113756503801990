/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */

import { DEFAULT_LOCALE } from 'configs';

import enUSTranslationMessages from '../translations/en-US.json';
import enGBTranslationMessages from '../translations/en-GB.json';
import esESTranslationMessages from '../translations/es-ES.json';

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enUSTranslationMessages) : {};

  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      (!messages[key] || !messages[key].message) && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key].message;
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  'en-US': formatTranslationMessages('en-US', enUSTranslationMessages),
  'en-GB': formatTranslationMessages('en-GB', enGBTranslationMessages),
  'es-ES': formatTranslationMessages('es-ES', esESTranslationMessages),
};
