import produce from 'immer';
import { ListAssetState } from '../types';
import { AssetAction } from './actions';

export const initialState: ListAssetState = {
  loading: true,
  assets: [],
  searchText: '',
};

const agreementsReducer = (state = initialState, action: AssetAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Assets/List/LOAD_ASSETS':
        draftState.loading = true;
        return draftState;

      case 'app/Assets/List/LOAD_ASSETS_SUCCESS':
        draftState.loading = false;
        draftState.assets = action.assets;
        return draftState;

      case 'app/Assets/List/RECEIVE_ASSET_SEARCH_TEXT':
        draftState.searchText = action.searchText;
        return draftState;

      default:
        return draftState;
    }
  });

export default agreementsReducer;
