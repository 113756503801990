import { CapabilityEnum } from '@monax/types';
import { LazyLoader } from 'components/LazyLoader';
import { Layout } from 'containers/App/components/Layout';
import { Route } from 'containers/App/components/Route';
import { permissionsRequired } from 'containers/App/hocs';
import { throttleResources } from 'containers/Billing/hocs';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch } from 'react-router-dom';
import { compose } from 'redux';
import { AGREEMENT_ROUTES } from './constants';

// 4.0 screens
const NewFromScratch = compose(
  permissionsRequired([CapabilityEnum.AGREEMENT_CREATE]),
  throttleResources(['draft', 'agreement']), // Can't know for sure whether they are going to make a draft or an agreement, so if they have either allow access
  // probably need some warning when the form mounts if they can only do either or, then add block as needed on form actions
)(LazyLoader(() => import('./PreChain/Draft/Forms/NewFromScratch')));
const NewFromTemplate = compose(
  permissionsRequired([CapabilityEnum.AGREEMENT_FROM_TEMPLATE_CREATE]),
  throttleResources(['draft', 'agreement']), // same as NewFromScratch
)(LazyLoader(() => import('./PreChain/Draft/Forms/NewFromTemplate')));
const Edit = compose(
  permissionsRequired([CapabilityEnum.AGREEMENT_DRAFT_UPDATE]),
  // no need to throttle here; editing a draft is fine, but need to block later on form action if they try to create agreement without any remaining agreement balance
)(LazyLoader(() => import('./PreChain/Draft/Forms/Edit')));
const Review = compose(
  permissionsRequired([CapabilityEnum.AGREEMENT_DRAFT_READ]),
  // no need to throttle here; agreement draft has already been approved and is now just waiting on counterparties
)(LazyLoader(() => import('./PreChain/Draft/Forms/Review/Review')));
const Approve = compose()(LazyLoader(() => import('./PreChain/Draft/Forms/Approve')));
// No need to check capabilities, user just needs to be the authorized approver for the agreement
// same as Edit- no need to throttle here, but block on form actions as necessary
const AgreementRecord = compose(permissionsRequired([CapabilityEnum.AGREEMENT_READ]))(
  LazyLoader(() => import('./PostChain/Record/AgreementRecord')),
);
const AgreementSetup = LazyLoader(() => import('./PostChain/Setup/AgreementSetup'));
const CompleteObligationRedirect = LazyLoader(() => import('./PostChain/Task/Obligation/CompleteObligationRedirect'));
const AgreementsList = LazyLoader(() => import('./List/Agreement/AgreementList'));

const AgreementRoutes: React.FC = () => (
  <>
    <Helmet>
      <title>Agreements</title>
    </Helmet>
    <Switch>
      <Route
        pageCategory="Drafts"
        pageName="Create (from template)"
        path={AGREEMENT_ROUTES.createFromTemplate}
        component={NewFromTemplate}
      />
      <Route
        pageCategory="Drafts"
        pageName="Create (from scratch)"
        path={AGREEMENT_ROUTES.createFromScratch}
        component={NewFromScratch}
      />
      <Route pageCategory="Drafts" pageName="Edit" path={AGREEMENT_ROUTES.edit} component={Edit} />
      <Route pageCategory="Drafts" pageName="Approve" path={AGREEMENT_ROUTES.approve} component={Approve} />
      <Route pageCategory="Drafts" pageName="Review" path={AGREEMENT_ROUTES.review} component={Review} />
      <Route
        exact
        pageCategory="Agreements"
        pageName="Record"
        path={AGREEMENT_ROUTES.record}
        component={AgreementRecord}
      />
      <Route pageCategory="Agreements" pageName="Setup" path={AGREEMENT_ROUTES.setup} component={AgreementSetup} />
      <Route
        pageCategory="Agreements"
        pageName="Obligation"
        exact
        path={AGREEMENT_ROUTES.obligation}
        component={CompleteObligationRedirect}
      />
      <Route
        layout={Layout}
        pageCategory="Agreements"
        pageName="List"
        exact
        path={AGREEMENT_ROUTES.list}
        component={AgreementsList}
      />
    </Switch>
  </>
);

export default AgreementRoutes;
