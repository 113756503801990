import { DurationUnit, TimestampOffset } from '@monax/types';
import { FormOffset } from '../types';

export function timestampOffsetToFormOffset(timestampOffset?: TimestampOffset): FormOffset {
  if (timestampOffset) {
    const { direction, duration } = timestampOffset;
    let period = Object.keys(duration).map((unit: DurationUnit) => ({
      unit,
      amount: duration[unit],
    }));
    if (!period.length) period = [{ unit: 'days', amount: 0 }];
    return {
      direction,
      ...period[0],
    };
  }
  return {
    direction: 'BEFORE',
    unit: 'days',
    amount: 0,
  };
}
