// TODO- most of the icons here can be considered legacy as we migrate to using react-boostrap-icons, but there are probably a few more
// custom ones that need to stay.

import React from 'react';

const icons = {
  checkbox: {
    selected: (color) => (
      <svg
        width="14"
        height="14"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="checkbox-selected-title"
      >
        <title id="checkbox-selected-title">checkbox-selected</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM6.3 13.29C6.69 13.68 7.32 13.68 7.71 13.29L15.29 5.7C15.68 5.31 15.68 4.68 15.29 4.29C14.9 3.9 14.27 3.9 13.88 4.29L7 11.17L4.12 8.29C3.73 7.9 3.1 7.9 2.71 8.29C2.52275 8.47683 2.41751 8.73048 2.41751 8.995C2.41751 9.25952 2.52275 9.51317 2.71 9.7L6.3 13.29Z"
          fill={color}
        />
      </svg>
    ),
    unSelected: (color) => (
      <svg
        width="14"
        height="14"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="checkbox-unselected-title"
      >
        <title id="checkbox-unselected-title">checkbox-unselected</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0ZM3 16H15C15.55 16 16 15.55 16 15V3C16 2.45 15.55 2 15 2H3C2.45 2 2 2.45 2 3V15C2 15.55 2.45 16 3 16Z"
          fill={color}
        />
      </svg>
    ),
  },
  party: (color) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="party-title"
    >
      <title id="party-title">party</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5C25 19.4036 19.4036 25 12.5 25ZM20.9085 18.3235C22.0552 16.6708 22.7273 14.6639 22.7273 12.5C22.7273 6.85163 18.1484 2.27273 12.5 2.27273C6.85163 2.27273 2.27273 6.85163 2.27273 12.5C2.27273 14.6639 2.94475 16.6708 4.09148 18.3235C5.14031 16.9381 7.35022 16.2012 10.3862 15.9809C8.93062 15.2077 7.95455 13.5156 7.95455 10.7955C7.95455 8.24518 9.74898 6.25 12.5 6.25C15.2434 6.25 17.0455 8.43361 17.0455 11.0227C17.0455 13.6071 16.0717 15.2302 14.6342 15.9824C17.6597 16.2045 19.862 16.9412 20.9085 18.3235ZM19.278 20.1588C18.9455 18.9629 16.5561 18.1818 12.5 18.1818C8.44389 18.1818 6.05455 18.9629 5.72195 20.1588C7.52648 21.757 9.89993 22.7273 12.5 22.7273C15.1001 22.7273 17.4735 21.757 19.278 20.1588ZM10.2273 10.7955C10.2273 13.3742 11.1571 14.2045 12.5 14.2045C13.8383 14.2045 14.7727 13.4073 14.7727 11.0227C14.7727 9.60275 13.8814 8.52273 12.5 8.52273C11.0611 8.52273 10.2273 9.4498 10.2273 10.7955Z"
        fill={color}
      />
    </svg>
  ),
  sign: (color) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="sign-icon-title"
    >
      <title id="sign-icon-title">sign</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.56439 5.46473C4.00448 5.93506 3.17581 5.91266 2.6495 5.39754C2.07839 4.83763 2.07839 3.89698 2.67189 3.35947C3.41097 2.69877 4.4748 1.99329 5.69541 1.99329C6.66965 1.99329 8.50616 2.76596 8.50616 5.19597C8.50616 6.71893 7.92385 7.59239 7.05039 8.86899C6.54647 9.60807 5.37066 11.5902 4.97872 12.8108C4.57559 14.0314 4.87794 14.9608 5.38186 14.9608C5.84346 14.9608 6.30506 14.4051 6.58909 14.0632C6.60135 14.0484 6.61328 14.0341 6.62486 14.0202C6.88242 13.7514 8.53975 11.7917 9.18925 10.9742C10.0403 9.93282 12.2016 7.79396 14.7212 7.79396C18.0134 7.79396 19.0661 10.6495 19.234 12.4972H20.6002C21.3729 12.4972 22 13.1243 22 13.897C22 14.6697 21.3729 15.2968 20.6002 15.2968H19.2452C18.7973 20.6383 15.8186 22.1277 13.9933 22.1277C12.0112 22.1277 10.3987 20.5711 10.3987 18.6674C10.3987 16.7637 12.1904 13.3707 16.4233 12.654C16.4153 12.5976 16.4078 12.5402 16.4002 12.4821C16.2897 11.6393 16.16 10.6495 14.4524 10.6495C13.0526 10.6495 11.2385 12.8332 9.88354 14.5017L9.86935 14.5192C8.63303 16.0451 7.65053 17.2578 6.45689 17.6036C5.44905 17.9059 4.34043 17.7156 3.50056 17.0885C2.53751 16.3718 2 15.1848 2 13.7514C2 11.3915 4.21645 8.13988 4.97392 7.02865C5.08627 6.86382 5.16653 6.74609 5.20269 6.68534C5.53863 6.13662 6.09854 5.20717 5.53863 4.98321C5.33707 4.89362 4.97872 5.10639 4.56439 5.46473ZM13.1981 18.6001C13.1981 19.1153 13.6796 19.4064 14.0268 19.4064C14.8106 19.4064 16.076 18.5218 16.412 15.5094C14.0156 16.1365 13.1981 17.9282 13.1981 18.6001Z"
        fill={color}
      />
    </svg>
  ),
  connection: (color) => (
    <svg width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1731 0.446856L28.7665 5.05675C29.0805 5.37069 29.0805 5.89942 28.75 6.21336L24.1566 10.8233C23.6444 11.352 22.7522 10.9885 22.7522 10.245V7.28735H12.8219C11.9131 7.28735 11.1696 6.54382 11.1696 5.63505C11.1696 4.72629 11.9131 3.98276 12.8219 3.98276H22.7687V1.02516C22.7687 0.298149 23.6609 -0.0818778 24.1731 0.446856ZM1.23545 15.9705L5.82883 11.3606C6.34104 10.8319 7.23327 11.1954 7.23327 11.9389V14.8965H17.1636C18.0723 14.8965 18.8158 15.6401 18.8158 16.5488C18.8158 17.4576 18.0723 18.2011 17.1636 18.2011H7.23327V21.1752C7.23327 21.9023 6.34104 22.2823 5.82883 21.7535L1.23545 17.1436C0.921516 16.8132 0.921516 16.301 1.23545 15.9705Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
    </svg>
  ),
  fulfillObligation: (color) => (
    <svg width="15" height="20" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12007 0.894192C7.12007 0.710791 6.8995 0.618696 6.77004 0.748041L0.513159 6.99923L0.417091 7.10432C0.148797 7.3978 0 7.7812 0 8.17902V21.9406C0 23.1312 0.975001 24.1053 2.16667 24.1053H17.3333C18.525 24.1053 19.5 23.1312 19.5 21.9406V2.66469C19.5 1.47411 18.525 0.5 17.3333 0.5H9.88325C9.28742 0.5 8.79992 0.987056 8.79992 1.58235C8.79992 2.17764 9.28742 2.66469 9.88325 2.66469H16.25C16.8458 2.66469 17.3333 3.15175 17.3333 3.74704V20.8582C17.3333 21.4535 16.8458 21.9406 16.25 21.9406H3.25C2.65417 21.9406 2.16667 21.4535 2.16667 20.8582V8.29454C2.16667 7.91848 2.47122 7.61362 2.8469 7.61362H5.807C6.52919 7.61362 7.12007 7.02328 7.12007 6.30175V0.894192ZM8.66716 13.7897C8.57862 13.8783 8.43516 13.8783 8.34663 13.7897L6.53531 11.9782C6.05026 11.4931 5.2643 11.4931 4.77926 11.9782C4.29361 12.4639 4.29361 13.2518 4.77926 13.7375L7.54532 16.504C8.07652 17.0352 8.93727 17.0352 9.46847 16.504L14.7201 11.2516C15.2058 10.7659 15.2058 9.97794 14.7201 9.49223C14.2351 9.00712 13.4491 9.00712 12.9641 9.49223L8.66716 13.7897Z"
        fill={color}
      />
    </svg>
  ),
  approval: (color) => (
    <svg width="20" height="14" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.35972 0.5H17.6569C18.4047 0.5 19.0166 1.11187 19.0166 1.85972C19.0166 2.60757 18.4047 3.21944 17.6569 3.21944H1.35972C0.611874 3.21944 0 2.60757 0 1.85972C0 1.11187 0.611874 0.5 1.35972 0.5ZM1.35972 6.93887H17.6569C18.4047 6.93887 19.0166 7.55075 19.0166 8.29859C19.0166 9.04644 18.4047 9.65831 17.6569 9.65831H1.35972C0.611874 9.65831 0 9.04644 0 8.29859C0 7.55075 0.611874 6.93887 1.35972 6.93887ZM1.235 16.0972H8.645C9.32425 16.0972 9.88 15.4853 9.88 14.7375C9.88 13.9896 9.32425 13.3778 8.645 13.3778H1.235C0.55575 13.3778 0 13.9896 0 14.7375C0 15.4853 0.55575 16.0972 1.235 16.0972ZM26.6022 11.0662L26.4798 10.9439C25.9495 10.4136 25.0793 10.4136 24.5626 10.9575L18.0632 17.4705L14.9358 14.3432C14.4055 13.8129 13.5489 13.8129 13.0186 14.3432L12.8962 14.4655C12.6416 14.7196 12.4985 15.0645 12.4985 15.4241C12.4985 15.7838 12.6416 16.1287 12.8962 16.3827L17.0978 20.5843C17.6281 21.1146 18.4847 21.1146 19.015 20.5843L26.6022 12.9834C27.1325 12.4531 27.1325 11.5965 26.6022 11.0662Z"
        fill={color}
      />
    </svg>
  ),
  circleSlash: (color) => (
    <svg width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3891 10.3891C11.4205 9.35764 12 7.95869 12 6.5C12 5.04131 11.4205 3.64236 10.3891 2.61091C9.35764 1.57946 7.95869 1 6.5 1C5.04131 1 3.64236 1.57946 2.61091 2.61091M10.3891 10.3891L2.61091 2.61091M10.3891 10.3891C9.35764 11.4205 7.95869 12 6.5 12C5.04131 12 3.64236 11.4205 2.61091 10.3891C1.57946 9.35764 1 7.95869 1 6.5C1 5.04131 1.57946 3.64236 2.61091 2.61091"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  blockchain: () => (
    <svg width="20" height="20" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3406 18.019V6.32056L20.4717 12.1694V23.8678L10.3406 18.019Z" fill="#3B82F6" />
      <path d="M10.3406 6.32052L20.4717 0.471695L30.6028 6.32052L20.4717 12.1694" fill="#1E3A8A" />
      <path d="M20.4717 12.1694L30.6028 6.32056V18.019L20.4717 23.8678" fill="#93C5FD" />
      <path d="M0.209351 35.5663V23.8678L10.3404 29.7166V41.4151L0.209351 35.5663Z" fill="#3B82F6" />
      <path d="M0.209351 23.8677L10.3404 18.0189L20.4715 23.8677L10.3404 29.7165" fill="#1E3A8A" />
      <path d="M10.3406 29.7166L20.4717 23.8678V35.5663L10.3406 41.4151" fill="#93C5FD" />
      <path d="M20.4717 35.5663V23.8678L30.6028 29.7166V41.4151L20.4717 35.5663Z" fill="#3B82F6" />
      <path d="M20.4717 23.8677L30.6028 18.0189L40.7339 23.8677L30.6028 29.7165" fill="#1E3A8A" />
      <path d="M30.6028 29.7166L40.7339 23.8678V35.5663L30.6028 41.4151" fill="#93C5FD" />
    </svg>
  ),
};

export const { checkbox } = icons;
export const { connection } = icons;
export const { party } = icons;
export const { sign } = icons;
export const { fulfillObligation } = icons;
export const { approval } = icons;
export const { circleSlash } = icons;
export const { blockchain } = icons;

export default icons;
