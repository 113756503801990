import { defineMessages } from 'react-intl';

const scope = 'app.containers.Billing';

export const messages = defineMessages({
  customerType: {
    id: `${scope}.customerType`,
    defaultMessage: 'Subscription Tier',
  },
  customerType_COUNTERPARTY_CLM: {
    id: `${scope}.customerType_COUNTERPARTY_CLM`,
    defaultMessage: 'Counterparty CLM',
  },
  customerType_SME_CLM: {
    id: `${scope}.customerType_SME_CLM`,
    defaultMessage: 'Small and Medium Enterprises CLM',
  },
  customerType_ENTERPRISE_CLM: {
    id: `${scope}.customerType_ENTERPRISE_CLM`,
    defaultMessage: 'Enterprise CLM',
  },
  customerType_MANAGED_CLM: {
    id: `${scope}.customerType_MANAGED_CLM`,
    defaultMessage: 'Managed CLM',
  },
  customerType_PLATFORM_ADMIN_CLM: {
    id: `${scope}.customerType_PLATFORM_ADMIN_CLM`,
    defaultMessage: 'Platform Admin CLM',
  },
  restrictedFeatureError_snackbar: {
    id: `${scope}.restrictedFeatureError_snackbar`,
    defaultMessage: 'Please upgrade your account to {feature}',
  },
  feature_addDates: {
    id: `${scope}.feature_addDates`,
    defaultMessage: 'add custom dates and renewals',
  },
  feature_addEvents: {
    id: `${scope}.feature_addEvents`,
    defaultMessage: 'add events',
  },
  feature_updateOrganizationStructure: {
    id: `${scope}.feature_updateOrganizationStructure`,
    defaultMessage: 'add and manage members and teams',
  },
  feature_configureEvents: {
    id: `${scope}.feature_configureEvents`,
    defaultMessage: 'configure events',
  },
  feature_viewDashboard: {
    id: `${scope}.feature_viewDashboard`,
    defaultMessage: 'view dashboard',
  },
  feature_addSigningURL: {
    id: `${scope}.feature_addSigningURL`,
    defaultMessage: 'add a custom signing URL',
  },
  feature_addWhiteLabeling: {
    id: `${scope}.feature_addWhiteLabeling`,
    defaultMessage: 'add white labeling',
  },
  feature_addApiKey: {
    id: `${scope}.feature_addApiKey`,
    defaultMessage: 'add api key',
  },
  feature_createOrganizations: {
    id: `${scope}.feature_createOrganizations`,
    defaultMessage: 'create organizations',
  },
  feature_adminGetOrganization: {
    id: `${scope}.feature_adminGetOrganization`,
    defaultMessage: 'get organization as admin',
  },
  feature_manageOrganizations: {
    id: `${scope}.feature_manageOrganizations`,
    defaultMessage: 'manage organizations',
  },
  feature_archiveOrganizations: {
    id: `${scope}.feature_archiveOrganizations`,
    defaultMessage: 'archive organizations',
  },
  feature_getBillingReports: {
    id: `${scope}.feature_getBillingReports`,
    defaultMessage: 'get billing reports',
  },
  feature_addUserToOrganization: {
    id: `${scope}.feature_addUserToOrganization`,
    defaultMessage: 'add or remove user from organization',
  },
  feature_addUserRolesOnOrganization: {
    id: `${scope}.feature_addUserRolesOnOrganization`,
    defaultMessage: 'add or remove user roles on organization',
  },
  feature_adminGetUser: {
    id: `${scope}.feature_adminGetUser`,
    defaultMessage: 'get user as admin',
  },
  feature_adminUpdateBeaconOperators: {
    id: `${scope}.feature_adminUpdateBeaconOperators`,
    defaultMessage: 'update beacon operators',
  },
  feature_blockAccounts: {
    id: `${scope}.feature_blockAccounts`,
    defaultMessage: 'block or unblock users',
  },
  feature_mergeAccounts: {
    id: `${scope}.feature_mergeAccounts`,
    defaultMessage: 'merge two user accounts',
  },
  featureCTA_description: {
    id: `${scope}.featureCTA_description`,
    defaultMessage: 'This feature allows you to {featureDescription}',
  },
  featureCTA_description_addDates: {
    id: `${scope}.featureCTA_description_addDates`,
    defaultMessage:
      'track various dates that come up during the lifecycle of your agreement and set up automated renewal workflows.',
  },
  featureCTA_description_addEvents: {
    id: `${scope}.featureCTA_description_addEvents`,
    defaultMessage: 'trigger events like notifications to occur on specified dates on your agreement.',
  },
  featureCTA_description_updateOrganizationStructure: {
    id: `${scope}.featureCTA_description_updateOrganizationStructure`,
    defaultMessage: 'fine-tune management of your organization with teams and roles.',
  },
  featureCTA_description_configureEvents: {
    id: `${scope}.featureCTA_description_configureEvents`,
    defaultMessage: 'customize your agreement events.',
  },
  featureCTA_description_viewDashboard: {
    id: `${scope}.featureCTA_description_viewDashboard`,
    defaultMessage: "see detailed overviews of your and your team members' agreements.",
  },
  featureCTA_description_addWhiteLabeling: {
    id: `${scope}.featureCTA_description_addWhiteLabeling`,
    defaultMessage: "customize our interface and message templates to match your company's brand.",
  },
  featureCTA_description_addApiKey: {
    id: `${scope}.featureCTA_description_addApiKey`,
    defaultMessage: 'generate API keys to grant external applications access to our API',
  },
  featureCTA_description_addSigningURL: {
    id: `${scope}.featureCTA_description_addSigningURL`,
    defaultMessage: 'connect your own custom signing application.',
  },
  featureCTA_description_createOrganizations: {
    id: `${scope}.featureCTA_description_createOrganizations`,
    defaultMessage: 'create organizations on behalf of customers.',
  },
  featureCTA_description_adminGetOrganization: {
    id: `${scope}.featureCTA_description_adminGetOrganization`,
    defaultMessage: 'get organization as an admin user.',
  },
  featureCTA_description_manageOrganizations: {
    id: `${scope}.featureCTA_description_manageOrganizations`,
    defaultMessage: 'manage organizations on behalf of customers.',
  },
  featureCTA_description_archiveOrganizations: {
    id: `${scope}.featureCTA_description_archiveOrganizations`,
    defaultMessage: 'archive organizations on behalf of customers.',
  },
  featureCTA_description_getBillingReports: {
    id: `${scope}.featureCTA_description_getBillingReports`,
    defaultMessage: 'retrieve billing reports.',
  },
  featureCTA_description_addUserToOrganization: {
    id: `${scope}.featureCTA_description_addUserToOrganization`,
    defaultMessage: 'add or remove users from customer organizations.',
  },
  featureCTA_description_addUserRolesOnOrganization: {
    id: `${scope}.featureCTA_description_addUserRolesOnOrganization`,
    defaultMessage: 'add or remove user roles from customer organizations.',
  },
  featureCTA_description_adminGetUser: {
    id: `${scope}.featureCTA_description_adminGetUser`,
    defaultMessage: 'get user as an admin user.',
  },
  featureCTA_description_blockAccounts: {
    id: `${scope}.featureCTA_description_blockAccounts`,
    defaultMessage: 'block or unblock users.',
  },
  featureCTA_description_adminUpdateBeaconOperators: {
    id: `${scope}.featureCTA_description_adminUpdateBeaconOperators`,
    defaultMessage: 'update beacon operators.',
  },
  featureCTA_description_mergeAccounts: {
    id: `${scope}.featureCTA_description_mergeAccounts`,
    defaultMessage: 'merge users.',
  },
  featureCTA_billingButton: {
    id: `${scope}.featureCTA_billingButton`,
    defaultMessage: 'Upgrade to unlock',
  },
  featureCTA_ignoreButton: {
    id: `${scope}.featureCTA_ignoreButton`,
    defaultMessage: "Don't show me this again.",
  },
  throttledResource_template: {
    id: `${scope}.throttledResource_template`,
    defaultMessage: 'templates',
  },
  throttledResource_agreement: {
    id: `${scope}.throttledResource_agreement`,
    defaultMessage: 'agreements',
  },
  throttledResource_draft: {
    id: `${scope}.throttledResource_draft`,
    defaultMessage: 'drafts',
  },
  throttledResource_user: {
    id: `${scope}.throttledResource_user`,
    defaultMessage: 'users',
  },
  throttledResource_asset: {
    id: `${scope}.throttledResource_asset`,
    defaultMessage: 'assets',
  },
  throttledResource_agreement_date: {
    id: `${scope}.throttledResource_agreement_date`,
    defaultMessage: 'dates',
  },
  throttledResource_agreement_document: {
    id: `${scope}.throttledResource_agreement_document`,
    defaultMessage: 'documents',
  },
  throttledResource_agreement_event: {
    id: `${scope}.throttledResource_agreement_event`,
    defaultMessage: 'events',
  },
  throttledResource_agreement_party: {
    id: `${scope}.throttledResource_agreement_party`,
    defaultMessage: 'parties',
  },
  throttledResource_agreement_term: {
    id: `${scope}.throttledResource_agreement_term`,
    defaultMessage: 'terms',
  },
  throttledResource_organization: {
    id: `${scope}.throttledResource_organization`,
    defaultMessage: 'organizations',
  },
  throttledResourceError_snackbar_list: {
    id: `${scope}.throttledResourceError_snackbar_list`,
    defaultMessage: "You've used up your {resource} quota. Please upgrade your account for more.",
  },
  throttledResourceError_snackbar: {
    id: `${scope}.throttledResourceError_snackbar`,
    defaultMessage: "You've used up your {resource} quota, which is limited to {limit} {period}",
  },
  throttledResourceError_perYear: {
    id: `${scope}.throttledResourceError_perYear`,
    defaultMessage: 'per year',
  },
  throttledResourceError_perMonth: {
    id: `${scope}.throttledResourceError_perMonth`,
    defaultMessage: 'per month',
  },
  throttledResourceError_perAgreement: {
    id: `${scope}.throttledResourceError_perAgreement`,
    defaultMessage: 'per agreement',
  },
  throttledResourceError_page: {
    id: `${scope}.throttledResourceError_page`,
    defaultMessage:
      "Hi, there. It looks like you've ran over-quota for the creation of {resource}. Please contact your organization admin(s) if you'd like to upgrade your account to increase your quota.",
  },
});
