import produce from 'immer';
import { RecordAssetState } from '../types';
import { AssetRecordAction } from './actions';

export const initialState: RecordAssetState = {
  loading: false,
  assetTokenData: undefined,
  assetTokenMeta: undefined,
  assetTokenTransations: undefined,
  assetTokenUri: undefined,
  assetTokenOwner: undefined,
};

const agreementsReducer = (state = initialState, action: AssetRecordAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Assets/Record/LOAD_ASSET_TOKEN_DATA':
        draftState.loading = true;
        draftState.assetTokenOwner = undefined;
        draftState.assetTokenUri = undefined;
        draftState.assetTokenData = undefined;
        draftState.assetTokenMeta = undefined;
        draftState.assetTokenTransations = undefined;
        return draftState;
      case 'app/Assets/Record/CLEAR_ASSET_TOKEN_DATA':
        draftState.assetTokenOwner = undefined;
        draftState.assetTokenUri = undefined;
        draftState.assetTokenData = undefined;
        draftState.assetTokenMeta = undefined;
        draftState.assetTokenTransations = undefined;
        return draftState;
      case 'app/Assets/Record/LOAD_ASSET_TOKEN_DATA_SUCCESS':
        draftState.loading = false;
        draftState.assetTokenOwner = action.assetTokenOwner;
        draftState.assetTokenUri = action.assetTokenUri;
        draftState.assetTokenData = action.assetTokenData;
        draftState.assetTokenMeta = action.assetTokenMeta;
        draftState.assetTokenTransations = action.assetTokenTransations;
        return draftState;
      default:
        return draftState;
    }
  });

export default agreementsReducer;
