import { ShapesBackground } from 'components/Background/ShapesBackground';
import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';

type Props = {
  header: string;
  title: string;
  rightPanel: React.ReactNode;
  headerAddon?: React.ReactNode;
};

export const AuthLayout: React.FC<Props> = (props) => {
  const { logos } = useTheme();
  return (
    <div className="flex h-full">
      {/* LEFT PANEL */}
      <div className="flex items-center justify-center w-full lg:w-1/3 bg-white">
        <div className="w-3/4">
          <div className="flex flex-col">
            <div className="pb-10 flex justify-between">
              <div>
                <img src={logos.full.secondary} alt="logo" width="180px" />
              </div>
              {props.headerAddon && <div>{props.headerAddon}</div>}
            </div>
            <div className="uppercase pt-10 text-xs font-bold text-primary-main">{props.title}</div>
            <div className="pt-1 pb-6">
              <h2>{props.header}</h2>
            </div>
            {props.children}
          </div>
        </div>
      </div>

      {/* RIGHT PANEL */}
      <div className="hidden lg:block flex-1">
        <ShapesBackground>{props.rightPanel}</ShapesBackground>
      </div>
    </div>
  );
};
