import { selectAPIClient } from 'containers/App/state/selectors';
import { selectCurrentOrganizationAddress } from 'containers/Organization/Current/state/selectors';
import { put, takeLatest } from 'redux-saga/effects';
import { call, select } from 'typed-redux-saga';
import {
  LoadCurrentOrganizationResourceLimits,
  LoadOrganizationResourceLimits,
  loadOrganizationResourceLimits as loadOrganizationResourceLimitsAction,
  loadOrganizationResourceLimitsSuccess,
} from './actions';

/**
 * Worker Sagas
 */
export function* loadOrganizationResourceLimits({ address }: LoadOrganizationResourceLimits) {
  const client = yield* select(selectAPIClient);
  const {
    data: { balance, limits },
  } = yield* call(client.getOrganizationResourceLimits, address);

  yield put(loadOrganizationResourceLimitsSuccess(limits, balance));
}

export function* loadCurrentOrganizationResourceLimits() {
  const address = yield* select(selectCurrentOrganizationAddress);
  yield put(loadOrganizationResourceLimitsAction(address));
}

/**
 * Watcher Sagas
 */
export function* watchLoadOrganizationResourceLimits() {
  yield takeLatest<LoadOrganizationResourceLimits>(
    'app/Organization/ResourceBalance/LOAD_ORGANIZATION_RESOURCE_LIMITS',
    loadOrganizationResourceLimits,
  );
  yield takeLatest<LoadCurrentOrganizationResourceLimits>(
    'app/Organization/ResourceBalance/LOAD_CURRENT_ORGANIZATION_RESOURCE_LIMITS',
    loadCurrentOrganizationResourceLimits,
  );
}
