import { InputAdornment } from '@material-ui/core';
import React, { useState } from 'react';
import { EyeFill, EyeSlashFill } from 'react-bootstrap-icons';
import { Input } from './Input';
import { InputConfig } from './types';

type Props = {
  name: string; // make name required
  label?: string;
  infoText?: string;
  inputConfig?: InputConfig;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
};

export const PasswordInput: React.FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Input
      {...props}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          {showPassword ? (
            <EyeFill className="cursor-pointer text-gray-600" onClick={() => setShowPassword(false)} />
          ) : (
            <EyeSlashFill className="cursor-pointer text-gray-400" onClick={() => setShowPassword(true)} />
          )}
        </InputAdornment>
      }
    />
  );
};
