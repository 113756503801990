import { Badge } from '@material-ui/core';
import { loadTasks } from 'containers/Task/List/actions';
import { watchLoadTasks } from 'containers/Task/List/saga';
import { selectUserTaskCount } from 'containers/Task/List/selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { Bell, BellFill } from 'react-bootstrap-icons';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import messages from '../messages';
import PendingList from './PendingList';

function Notifications({ taskCount, history }: StateProps & RouteComponentProps) {
  const [openMenu, setOpenMenu] = useState(false);

  const dispatch = useDispatch();
  const load = useCallback(() => dispatch(loadTasks()), []);

  useEffect(() => {
    load();
  }, [history.location.pathname]);

  const toggleMenu = useCallback(
    (e) => {
      e.preventDefault();
      if (e.target === 'task-list-overlay') {
        setOpenMenu(false);
      } else {
        setOpenMenu(!openMenu);
      }
    },
    [openMenu],
  );

  const iconClasses = 'text-secondary-contrastText text-2xl';

  return (
    <div className="pr-10 relative">
      <div className="h-full flex items-center cursor-pointer">
        {taskCount > 0 ? (
          <Badge badgeContent={taskCount} color="primary" max={99}>
            <BellFill onClick={toggleMenu} className={iconClasses} />
          </Badge>
        ) : (
          <Bell onClick={toggleMenu} className={iconClasses} />
        )}
      </div>
      {openMenu ? (
        <>
          <div className="absolute bg-white h-auto left-0 md:-left-32 md:w-64 mt-1 rounded-xl shadow-md w-full z-30">
            <div className="bg-primary-main flex justify-center items-center text-sm rounded-t-md text-center uppercase font-semibold text-primary-contrastText h-12">
              <FormattedMessage {...messages.notificationsMenuHeading} />
            </div>
            <div className="task-list overflow-y-auto">
              <PendingList closeMenu={toggleMenu} />
            </div>
          </div>
          {/* eslint-disable jsx-a11y/click-events-have-key-events */}
          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
          <div onClick={toggleMenu} className="fixed top-0 left-0 w-screen h-screen z-20" id="task-list-overlay" />
          {/* eslint-enable jsx-a11y/click-events-have-key-events */}
          {/* eslint-enable jsx-a11y/no-static-element-interactions */}
        </>
      ) : null}
    </div>
  );
}

type StateProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  taskCount: selectUserTaskCount(state),
});
const withConnect = connect(mapStateToProps);

const withWatchLoadTasks = injectSaga({ key: 'load-tasks', saga: watchLoadTasks });

export default compose(withWatchLoadTasks, withRouter, withConnect)(Notifications);
