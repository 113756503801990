import logoFullSecondary from 'images/logos/monax-dark.svg';
import logoFavicon from 'images/logos/monax-square.svg';
import logoFullSecondaryContrastText from 'images/logos/monax-white.svg';
import getColors from './colors';

const colors = getColors('ignoreCustomThemes');

export type Palette = typeof colors;
export type PaletteKey = keyof typeof colors;
export type Shade = 'main' | 'light' | 'extraLight' | 'dark' | 'contrastText';
export type Scale = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';

export type Logos = {
  full: {
    secondary: string;
    secondaryContrastText: string;
  };
  favicon: {
    secondary: string;
    secondaryContrastText: string;
  };
};
export const DEFAULT_LOGOS: Logos = {
  full: {
    secondary: logoFullSecondary,
    secondaryContrastText: logoFullSecondaryContrastText,
  },
  favicon: {
    secondary: logoFavicon,
    secondaryContrastText: logoFavicon,
  },
};

export const DEFAULT_PALETTE: Palette = colors;

export const DEFAULT_NAME: string = 'Suscribo';
