import Avatar from '@material-ui/core/Avatar';
import { Identicon } from 'components/Identicon';
import { useTheme } from 'containers/Theme/useTheme';
import React from 'react';

type Props = {
  userIconSrc?: string;
  userEmail: string;
  style?: React.CSSProperties;
};

export const UserAvatar: React.FC<Props> = ({ userIconSrc, userEmail, style = {} }) => {
  const { palette } = useTheme();

  const styles: React.CSSProperties = {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: palette.gray[300],
    ...style,
  };

  return (
    <Avatar key={userEmail} style={styles}>
      {userIconSrc ? <UserIcon src={userIconSrc} /> : <Identicon seed={userEmail} size={28} />}
    </Avatar>
  );
};

type UserIconProps = {
  src?: string;
  size?: number;
};

const UserIcon: React.FC<UserIconProps> = ({ src, size }) => {
  return (
    <img src={src} style={{ width: size ? size : '100%', height: size ? size : '100%' }} className="rounded-full" />
  );
};
