import { APIClient, newAPIClient, OrganizationAddress } from '@monax/types';
import axios from 'axios';
import config from 'configs';

export const generateAPIClient = (token: string, organizationAddress: OrganizationAddress): APIClient => {
  return newAPIClient({ accessToken: token, organizationAddress: organizationAddress }, config.urls.api, axios);
};

export type RequestOptions = {
  disable404Redirect?: boolean;
};

export const getRequestOptions = (options: Partial<RequestOptions>): RequestOptions => {
  return {
    ...options,
  };
};
