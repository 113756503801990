import { combineReducers } from 'redux';
import activityLogsReducer from './ActivityLog/state/reducer';
import agreementCreationCountsReducer from './AgreementCreationCounts/state/reducer';
import obligationReducer from './Obligation/state/reducer';
import { OverviewState } from './types';

const reducer = combineReducers<OverviewState>({
  obligation: obligationReducer,
  agreementCreationCounts: agreementCreationCountsReducer,
  activityLogs: activityLogsReducer,
});

export default reducer;
