import produce from 'immer';
import { ObligationState } from '../types';
import { ObligationAction } from './actions';

export const INITIAL_PAGE_SIZE = 20;

export const initialState: ObligationState = {
  obligations: [],
  pageSize: INITIAL_PAGE_SIZE,
  requesting: false,
  typeFilter: undefined,
};

const reducer = (state: ObligationState = initialState, action: ObligationAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Overview/Obligation/LOAD_OBLIGATIONS':
        draftState.requesting = true;
        return draftState;
      case 'app/Overview/Obligation/LOAD_OBLIGATIONS_SUCCESS':
        draftState.requesting = false;
        draftState.obligations = action.obligations;
        return draftState;
      case 'app/Overview/Obligation/LOAD_MORE_OBLIGATIONS':
        if (draftState.obligations.length > draftState.pageSize) draftState.pageSize += 10;
        return draftState;
      case 'app/Overview/Obligation/RECEIVE_TYPE_FILTER':
        draftState.typeFilter = action.typeFilter;
        return draftState;
      default:
        return draftState;
    }
  });

export default reducer;
