import { Address } from '@monax/types';

export type ReceiveSwitchingOrganization = ReturnType<typeof receiveSwitchingOrganization>;
export type SwitchOrganization = ReturnType<typeof switchOrganization>;

export type SwitchAction = ReceiveSwitchingOrganization | SwitchOrganization;

/**
 * SWITCH_ORGANIZATION
 */
 export function switchOrganization(address: Address, redirectURL?: string, domain?: string) {
  return <const>{
    type: 'app/Organization/Switch/SWITCH_ORGANIZATION',
    address,
    redirectURL,
    domain
  };
}

/**
 * SWITCHING_ORGANIZATION
 */
export function receiveSwitchingOrganization(switching: boolean) {
  return <const>{
    type: 'app/Organization/Switch/SWITCHING_ORGANIZATION',
    switching,
  };
}
