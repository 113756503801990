import { ButtonMenu } from 'components/Menu';
import { MenuOption } from 'components/Menu/types';
import { Tooltip } from 'components/Tooltip';
import walletLinkIcon from 'images/social/coinbase.png';
import metamaskIcon from 'images/social/metamask.svg';
import walletConnectIcon from 'images/social/wallet-connect.png';
import { MetamaskWallet, useWallet, WalletType } from 'lib/hooks/useWallet';
import React, { useState } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { messages } from './messages';

type Props = {
  disabled: boolean;
  fullWidth?: boolean;
};

export const WalletMenu: React.FC<Props> = ({ disabled, fullWidth }) => {
  const intl = useIntl();

  const { wallet, account, selectWallet } = useWallet();
  const [loading, setLoading] = useState(false);

  const connect = async (walletType: WalletType) => {
    setLoading(true);
    selectWallet(walletType)
      .connect()
      .finally(() => setLoading(false));
  };

  const walletOptions: MenuOption[] = [
    {
      label: intl.formatMessage(messages.walletConnect),
      onClick: () => connect('wallet-connect'),
      icon: <img src={walletConnectIcon} alt="WalletConnect" className="h-6" />,
    },
    {
      label: intl.formatMessage(messages.walletLink),
      onClick: () => connect('wallet-link'),
      icon: <img src={walletLinkIcon} alt="WalletConnect" className="h-6" />,
    },
  ];

  if (MetamaskWallet.isAvailable()) {
    walletOptions.push({
      label: intl.formatMessage(messages.metamask),
      onClick: () => connect('metamask'),
      icon: <img src={metamaskIcon} alt="metamask" className="h-6" />,
    });
  }

  // for when things get out of sync
  walletOptions.push({
    label: (
      <Tooltip title={intl.formatMessage(messages.resetSessionsInfo)}>
        <span className="text-error-main ml-8">{intl.formatMessage(messages.resetSessions)}</span>
      </Tooltip>
    ),
    onClick: () => {
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:Addresses');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:session:id');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:session:secret');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:session:linked');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:version');
      window.localStorage.removeItem('-walletlink:https://www.walletlink.org:walletUsername');

      window.localStorage.removeItem('walletconnect');
      selectWallet(null);
    },
  });

  const showChainIdSwitcher = wallet?.type === 'wallet-link';

  return (
    <div className="flex items-center">
      <ButtonMenu
        id="wallet-menu"
        buttonSize="s"
        buttonText={
          wallet && account
            ? intl.formatMessage(messages.walletSelected, {
                wallet:
                  wallet.type === 'metamask'
                    ? intl.formatMessage(messages.metamask)
                    : wallet.type === 'wallet-connect'
                    ? intl.formatMessage(messages.walletConnect)
                    : intl.formatMessage(messages.walletLink),
              })
            : intl.formatMessage(messages.selectWallet)
        }
        buttonType="gray-light-outlined"
        buttonIconEnd={<ChevronDown size={20} />}
        buttonClassName={`${showChainIdSwitcher ? 'mr-3' : ''} ${!showChainIdSwitcher && fullWidth ? 'w-full' : ''}`}
        options={walletOptions}
        buttonDisabled={disabled || loading}
      />
    </div>
  );
};
