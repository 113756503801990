import config from 'configs';

export const INIT_CONFIG = {
  domain: config.integrations.authHost,
  client_id: config.integrations.authClientID,
  redirect_uri: `${window.location.origin}/callback`,
  audience: config.integrations.authAudienceAPI,
  connection: config.integrations.authConnection,
  scope: 'openid profile email',
  responseType: 'token id_token',
};
