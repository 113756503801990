import { Invitation } from '@monax/types';

export type LoadUserInvitationsAction = ReturnType<typeof loadUserInvitations>;
export type LoadUserInvitationsSuccessAction = ReturnType<typeof loadUserInvitationsSuccess>;
export type AcceptInvitationAction = ReturnType<typeof acceptInvitation>;
export type AcceptInvitationSuccessAction = ReturnType<typeof acceptInvitationSuccess>;
export type DeclineInvitationAction = ReturnType<typeof declineInvitation>;
export type DeclineInvitationSuccessSuccessAction = ReturnType<typeof declineInvitationSuccess>;

export type UserInvitationAction =
  | LoadUserInvitationsAction
  | LoadUserInvitationsSuccessAction
  | AcceptInvitationAction
  | AcceptInvitationSuccessAction
  | DeclineInvitationAction
  | DeclineInvitationSuccessSuccessAction;

export const loadUserInvitations = () => {
  return <const>{
    type: 'app/User/Current/UserInvitations/LOAD_USER_INVITATIONS',
  };
};

export const loadUserInvitationsSuccess = (invitations: Invitation[]) => {
  return <const>{
    type: 'app/User/Current/UserInvitations/LOAD_USER_INVITATIONS_SUCCESS',
    invitations,
  };
};

export const acceptInvitation = (invitationCode: string, onSuccess: () => void) => {
  return <const>{
    type: 'app/User/Current/UserInvitations/ACCEPT_USER_INVITATION',
    invitationCode,
    onSuccess,
  };
};

export const acceptInvitationSuccess = () => {
  return <const>{
    type: 'app/User/Current/UserInvitations/ACCEPT_USER_INVITATION_SUCCESS',
  };
};

export const declineInvitation = (invitationCode: string, onSuccess: () => void) => {
  return <const>{
    type: 'app/User/Current/UserInvitations/DECLINE_USER_INVITATION',
    invitationCode,
    onSuccess,
  };
};

export const declineInvitationSuccess = () => {
  return <const>{
    type: 'app/User/Current/UserInvitations/DECLINE_USER_INVITATION_SUCCESS',
  };
};
