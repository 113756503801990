import { bufferToDataURL } from 'containers/Agreement/Documents/util';
import { selectAPIClient } from 'containers/App/state/selectors';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAPIRequest } from './useAPIRequest';

export function useGetImageURI(
  grant: string,
  defaultURI: string,
): { loading: boolean; makeRequest: () => Promise<string> } {
  const client = useSelector(selectAPIClient); // need to depend on client, this is so we can 're-get' the makeRequest methods if the client change (eg, if the client now has an auth token)
  const { loading: loadingDocument, makeRequest: getDocumentRequest } = useAPIRequest('getDocument');
  const { loading: loadingMeta, makeRequest: getMetaRequest } = useAPIRequest('getDocumentInfo');

  const makeRequest = useCallback(() => {
    if (grant) {
      return getMetaRequest(grant, false, true)
        .then(({ data: { meta } }) =>
          getDocumentRequest(grant, {
            responseType: 'arraybuffer',
          }).then(({ data }) => bufferToDataURL(Buffer.from(data), meta.mime)),
        )
        .catch((err) => {
          console.error(err.message);
          return defaultURI;
        });
    }
    return Promise.resolve(defaultURI);
  }, [grant, defaultURI, client]);

  return {
    loading: loadingDocument || loadingMeta,
    makeRequest,
  };
}
