import React from 'react';

type Props = {
  image: string;
  header: string;
  description: string;
};

export const EmptyGraphic: React.FC<Props> = ({ image, header, description }) => {
  return (
    <div className="flex h-full justify-center items-center px-4">
      <div className="flex flex-col">
        <div className="flex justify-center pb-6">
          <img src={image} />
        </div>
        <div className="text-info-main text-lg font-semibold text-center pb-2">{header}</div>
        <div className="text-gray-dark text-center">{description}</div>
      </div>
    </div>
  );
};
