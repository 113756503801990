export type SendResetPasswordRequestEmail = ReturnType<typeof sendResetPasswordRequestEmail>;

export type ResetPasswordRequestAction = SendResetPasswordRequestEmail;

export const sendResetPasswordRequestEmail = (usernameOrEmail: string, onSuccess: () => void, onAlways: () => void) => {
  return <const>{
    type: 'app/Auth/RESET_PASSWORD_REQUEST/SEND_RESET_PASSWORD_REQUEST_EMAIL',
    usernameOrEmail,
    onSuccess,
    onAlways,
  };
};
