import React, { ReactNode } from 'react';
import icons from './icons';
import { SupportedLocale } from './types';

const { US, GB, ES } = icons;

export const InputOptions: {
  value: SupportedLocale;
  label: ReactNode;
  icon: ReactNode;
  text: string;
}[] = [
  {
    value: 'en-GB',
    label: <span className="country-wrapper">{GB} English (UK)</span>,
    icon: <span className="country-wrapper">{GB}</span>,
    text: 'English (UK)',
  },
  {
    value: 'en-US',
    label: <span className="country-wrapper">{US} English (US)</span>,
    icon: <span className="country-wrapper">{US}</span>,
    text: 'English (US)',
  },
  {
    value: 'es-ES',
    label: <span className="country-wrapper">{ES} Español (ES)</span>,
    icon: <span className="country-wrapper">{ES}</span>,
    text: 'Español (ES)',
  },
];
