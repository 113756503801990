import React from 'react';

export const Divider: React.FC = (props) => {
  return (
    <div className="flex items-center">
      <div className="border-t-2 border-gray-200 w-full" />
      <span className="py-2 whitespace-nowrap text-xs text-gray-400 px-2">{props.children}</span>
      <div className="border-t-2 border-gray-200 w-full" />
    </div>
  );
};
