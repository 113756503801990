import { createSelector } from 'reselect';
import { ApplicationState } from 'types';

const rootSelector = (root: ApplicationState) => root.organizations.list;

export const selectOrganizations = createSelector(rootSelector, (organizations) => organizations);

export const selectOrganizationsOptionsList = createSelector(selectOrganizations, (organizations) =>
  Object.values(organizations).map((org) => ({ label: org.name, value: org.address })),
);
