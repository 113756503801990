import { getAssetCreateRoute } from '@monax/aeger/dist/webapp-routes';
import { CapabilityEnum } from '@monax/types';
import { ButtonMenu } from 'components/Menu';
import { MenuOption } from 'components/Menu/types';
import { useThrottleResourceOnClick } from 'containers/Billing/hooks';
import { makeSelectCurrentUserHasPermission } from 'containers/User/Current/Profile/state/selectors';
import React, { useState } from 'react';
import { PlusCircle } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { messages } from '../messages';
import { CreateAssetsFromForeignChainDialog } from './CreateAssetsFromForeignChainDialog';

const selectCanCreateAsset = makeSelectCurrentUserHasPermission(CapabilityEnum.ASSET_CREATE);

export const CreateOptions: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();

  const throttleTemplateCreate = useThrottleResourceOnClick(['asset']);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const canCanCreateAsset = useSelector(selectCanCreateAsset);

  if (!canCanCreateAsset) return <></>;

  const options: MenuOption[] = [];

  options.push({
    label: intl.formatMessage(messages.createNewAsset),
    onClick: throttleTemplateCreate(() => history.push(getAssetCreateRoute())),
  });

  options.push({
    label: intl.formatMessage(messages.importFromBlockchain),
    onClick: () => setIsDialogOpen(true),
  });

  return (
    <>
      <ButtonMenu
        id="create-menu"
        buttonType="primary"
        buttonText={intl.formatMessage(messages.create)}
        options={options}
        buttonSize="s"
        buttonIconEnd={<PlusCircle />}
      />
      <CreateAssetsFromForeignChainDialog isOpen={isDialogOpen} closeDialog={() => setIsDialogOpen(false)} />
    </>
  );
};
