/*
 *
 * Organizations reducer
 *
 */

import produce from 'immer';
import { ResourceBalanceState } from '../types';
import { OrganizationResourceLimitsAction } from './actions';

export const initialState: ResourceBalanceState = {
  requesting: false,
  balance: {},
  limits: {},
};

const organizationsReducer = (state = initialState, action: OrganizationResourceLimitsAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case 'app/Organization/ResourceBalance/LOAD_ORGANIZATION_RESOURCE_LIMITS': {
        draftState.requesting = true;
        return draftState;
      }
      case 'app/Organization/ResourceBalance/LOAD_ORGANIZATION_RESOURCE_LIMITS_SUCCESS': {
        draftState.requesting = false;
        draftState.balance = action.balance;
        draftState.limits = action.limits;
        return draftState;
      }
      default:
        return draftState;
    }
  });

export default organizationsReducer;
