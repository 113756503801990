import { RawTimeZone } from '@vvo/tzdb';
import React, { useEffect, useState } from 'react';
import { Select } from './Select';
import { InputConfig } from './types';

type Props = {
  name: string;
  label?: string;
  placeholder: string;
  inputConfig?: InputConfig;
};

export const Timezone: React.FC<Props> = ({ name, label, placeholder, inputConfig }) => {
  const [timezones, setTimezones] = useState<RawTimeZone[]>([]);

  useEffect(() => {
    import(/* webpackChunkName: "@vvo/tzdb" */ '@vvo/tzdb').then(({ rawTimeZones }) => setTimezones(rawTimeZones));
  }, []);

  return (
    <Select
      name={name}
      label={label}
      options={timezones.map((tz) => ({
        label: `${tz.alternativeName} - ${tz.mainCities[0]} (GMT ${tz.rawOffsetInMinutes / 60}:00)`,
        value: tz.name,
      }))}
      placeholder={placeholder}
      inputConfig={inputConfig}
      isClearable={inputConfig?.optional}
    />
  );
};
