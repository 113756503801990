import { watchHandleDocuments } from 'containers/Agreement/Documents/state/saga';
import { watchLoadOrganization } from 'containers/Organization/Current/state/saga';
import { watchLoadOrganizations } from 'containers/Organization/List/state/saga';
import { watchLoadOrganizationResourceLimits } from 'containers/Organization/ResourceBalance/state/saga';
import { watchSwitchOrganization } from 'containers/Organization/Switch/state/saga';
import { watchLoadCurrentUser } from 'containers/User/Current/Profile/state/saga';
import { watchLoadResourceFilters } from 'containers/User/Current/ResourceFilters/state/saga';
import { watchUserInvitations } from 'containers/User/Current/UserInvitations/state/saga';
import { watchLoadUsers } from 'containers/User/List/saga';
import React from 'react';
import { useInjectSaga } from 'utils/injectSaga';

export const GlobalSagas: React.FC = ({ children }) => {
  useInjectSaga({
    key: 'handle-documents',
    saga: watchHandleDocuments,
  });
  useInjectSaga({ key: 'currentUser', saga: watchLoadCurrentUser });
  useInjectSaga({ key: 'load-organization', saga: watchLoadOrganization });
  useInjectSaga({ key: 'load-users', saga: watchLoadUsers });
  useInjectSaga({ key: 'load-organizations', saga: watchLoadOrganizations });
  useInjectSaga({
    key: 'load-organization-resource-limits',
    saga: watchLoadOrganizationResourceLimits,
  });
  useInjectSaga({
    key: 'load-user-invitations',
    saga: watchUserInvitations,
  });
  useInjectSaga({
    key: 'switch-organization',
    saga: watchSwitchOrganization,
  });
  useInjectSaga({
    key: 'resource-filters',
    saga: watchLoadResourceFilters,
  });

  return <>{children}</>;
};
