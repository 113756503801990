import { Theme } from '@material-ui/core';
import { AvatarSize } from './Avatar';

export const getAvatarStyles = (size: AvatarSize, theme: Theme): React.CSSProperties => {
  const spacing = size === 'm' ? 4.2 : 2.85;
  const fontSize = size === 'm' ? '12px' : '10px';

  return {
    width: theme.spacing(spacing),
    height: theme.spacing(spacing),
    fontWeight: 600,
    fontSize: fontSize,
  };
};
