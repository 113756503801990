import { isAddress } from '@monax/aeger/dist/address';
import {
  AgreementDraftDate,
  AgreementDraftParameter,
  AgreementDraftParticipant,
  AgreementParameter,
  OrganizationInfoByAddress,
  ParameterTypeEnum,
  UserInfoByAddress,
} from '@monax/types';
import { FormDate } from 'containers/Agreement/Form/types';
import { getUnixTime } from 'date-fns';

const getSignatoryDisplayValue = (
  signatory: AgreementDraftParticipant,
  organizations: OrganizationInfoByAddress,
  users: UserInfoByAddress,
) => {
  const { value, representative } = signatory;
  if (organizations[value]) {
    return organizations[value].name;
  } else if (value && !isAddress(value)) {
    return value;
  } else {
    switch (representative.type) {
      case 'address':
        return users && users[representative.address]?.name;
      case 'email':
        return representative.name || representative.email;
      case 'foreign_address':
        return representative.address;
      default:
        return '';
    }
  }
};

export const draftParametersToChainParameters = (
  parameters: AgreementDraftParameter[],
  signatories: AgreementDraftParticipant[],
  organizations: OrganizationInfoByAddress,
  dates: FormDate[] | AgreementDraftDate[],
  users?: UserInfoByAddress,
): AgreementParameter[] => {
  const formattedParams: AgreementParameter[] = [];
  signatories.forEach((signatory: AgreementDraftParticipant) => {
    if (signatory.label) {
      formattedParams.push({
        name: signatory.label,
        value: signatory.value,
        type: ParameterTypeEnum.SIGNING_PARTY,
        displayValue: getSignatoryDisplayValue(signatory, organizations, users),
      });
    }
  });
  dates.forEach((date) => {
    if (date.name && date.type === 'FIXED' && date.data.value) {
      formattedParams.push({
        name: date.name,
        value: getUnixTime(new Date(date.data.value as string)),
        type: ParameterTypeEnum.DATE,
      });
    }
  });
  parameters.forEach((param) => {
    switch (param.type) {
      case ParameterTypeEnum.BOOLEAN:
        formattedParams.push({
          name: param.name,
          type: ParameterTypeEnum.BOOLEAN,
          value: Boolean(param.value),
          visibility: param.visibility,
          assignee: param.assignee,
        });
        break;
      case ParameterTypeEnum.STRING:
      case ParameterTypeEnum.LARGE_TEXT:
        formattedParams.push({
          name: param.name,
          type: param.type,
          value: param.value ? `${param.value}` : '',
          visibility: param.visibility,
          assignee: param.assignee,
        });
        break;
      case ParameterTypeEnum.NUMBER:
      case ParameterTypeEnum.MONETARY_AMOUNT:
        formattedParams.push({
          name: param.name,
          type: param.type,
          value: typeof param.value === 'number' ? param.value : null,
          visibility: param.visibility,
          assignee: param.assignee,
        });
        break;
      default:
        break;
    }
  });
  return formattedParams;
};
