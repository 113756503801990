import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';

export const useOnResizeCallback = (callback) => {
  const handleResize = useCallback(
    debounce(() => {
      callback(window.innerWidth);
    }, 500),
    [],
  );
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};
