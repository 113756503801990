import { UserForeignAddress } from '@monax/types';
import { createSelector, OutputSelector } from 'reselect';
import { ApplicationState } from 'types';
import { ForeignAddressesState } from '../types';

const rootSelector = (state: ApplicationState) => state.users.current.foreignAddresses;

export const selectForeignAddresses: OutputSelector<
  ApplicationState,
  UserForeignAddress[],
  (state: ForeignAddressesState) => UserForeignAddress[]
> = createSelector(rootSelector, (state) => state.addresses);

export const selectActionInProgress: OutputSelector<
  ApplicationState,
  boolean,
  (state: ForeignAddressesState) => boolean
> = createSelector(rootSelector, (state) => state.requesting);
