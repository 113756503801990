// This is a .js file so tailwind config can read it

module.exports = (check) => {
  if (check !== 'ignoreCustomThemes') {
    throw new Error(
      `Don't use colors.js if the colors should be be overridden by the user's custom theme. Use the useTheme hook instead.`,
    );
  }
  return {
    primary: {
      // light blue
      main: '#3366CC', // 600
      light: '#6094FD', // 400
      extraLight: '#DCE8FF', // 100
      dark: '#0E3E80', // 800
      contrastText: '#FFFFFF',
    },
    secondary: {
      // dark blue
      main: '#001C43', // lightBlue 900, not 600
      light: '#002C6A', // not in palette
      extraLight: '#EBF0FA', // not part of palette
      dark: '#00112A', // not part of palette
      contrastText: '#FFFFFF',
    },
    info: {
      // dark blue
      main: '#001C43', // lightBlue 900, not 600
      light: '#002C6A', // not in palette
      extraLight: '#EBF0FA', // not part of palette
      dark: '#00112A', // not part of palette
      contrastText: '#FFFFFF',
    },
    success: {
      // green
      main: '#478F4E', // 600
      light: '#83CF8C', // 400
      extraLight: '#E2F5E4', // 100
      dark: '#2D6432', // 800
      contrastText: '#FFFFFF',
    },
    error: {
      // red
      main: '#D84910', // 600
      light: '#F58A5E', // 400
      extraLight: '#FBEDE7', // 800
      dark: '#B03120', // 100
      contrastText: '#FFFFFF',
    },
    warning: {
      // orange
      main: '#F5BB14', // 600
      light: '#FFDB65', // 400
      extraLight: '#FFF4D2', // 100
      dark: '#CF9A00', // 800
      contrastText: '#FFFFFF',
    },
    gray: {
      // maybe rename- neutral, ...?
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      400: '#9CA3AF',
      500: '#6B7280',
      600: '#4B5563',
      700: '#374151',
      800: '#1F2937',
      900: '#111827',
      // for the sake of consistent types/shades for each color (maybe migrate all colors to number system eventually)
      main: '#4B5563',
      light: '#9CA3AF',
      extraLight: '#F3F4F6',
      dark: '#1F2937',
      contrastText: '#111827',
    },
    accent1: {
      // purple
      main: '#8061D3', // 600
      light: '#B69DF9', // 400
      extraLight: '#F0EAFF', // 100
      dark: '#51329E', // 800
      contrastText: '#FFFFFF',
    },
    accent2: {
      // teal
      main: '#319795', // 600
      light: '#4FD1C5', // 400
      extraLight: '#E6FFFA', // 100
      dark: '#176661', // 800
      contrastText: '#FFFFFF',
    },
    accent3: {
      // bright blue
      main: '#2563EB', // 600
      light: '#60A5FA', // 400
      extraLight: '#DBEAFE', // 100
      dark: '#1E40AF', // 800
      contrastText: '#FFFFFF',
    },
  };
};
