/**
 *
 * FileInput
 *
 */

import { DocumentType } from '@monax/aeger/dist/document';
import { Button, ButtonSize } from 'components/Common';
import { useOnFileChange } from 'lib/hooks/useOnFileChange';
import React, { useRef } from 'react';
import { Paperclip } from 'react-bootstrap-icons';
import { useIntl } from 'react-intl';
import messages from './messages';

type Props = {
  name: string;
  acceptMimes: DocumentType[];
  acceptExts: string[];
  uploadFiles: (files: File[]) => void;
  maxFiles: number;
  multiple?: boolean;
  buttonSize?: ButtonSize;
};

export const FileInput: React.FC<Props> = ({
  name,
  acceptMimes,
  acceptExts,
  uploadFiles,
  maxFiles = 10,
  multiple = true,
  buttonSize = 'm',
}) => {
  const intl = useIntl();
  const ref = useRef(null);

  const onChange = useOnFileChange(acceptMimes, uploadFiles, maxFiles);

  return (
    <div className="w-full">
      <input
        id={name}
        onChange={onChange}
        value={undefined}
        style={{ display: 'none' }}
        ref={ref}
        type="file"
        multiple={multiple}
        accept={acceptExts.join(',')}
      />
      <Button
        type="outlined"
        buttonType="button"
        icon={<Paperclip />}
        size={buttonSize}
        onClick={() => {
          ref.current.click();
        }}
      >
        {intl.formatMessage(messages.uploadFile, { count: multiple ? 2 : 0 })}
      </Button>
    </div>
  );
};
