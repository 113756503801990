import { LinkButton } from 'components/Common';
import { addNotification } from 'containers/App/state/actions';
import { AuthLayout } from 'containers/Auth/controls';
import messages from 'containers/Auth/messages';
import { DEFAULT_NAME } from 'containers/Theme/types';
import { useTheme } from 'containers/Theme/useTheme';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RESTART_ON_REMOUNT } from 'utils/constants';
import { useInjectSaga } from 'utils/injectSaga';
import { parseQueryString } from 'utils/queryString';
import { getResetPasswordSchema } from 'validation/login';
import { validator } from 'validation/yup';
import { WhiteLabelAuthLayout } from '../controls/WhiteLabelAuthLayout';
import { getAuth0ErrorMessage } from '../utils';
import { FormImagePanel, FormPanel } from './controls';
import { resetPassword } from './state/actions';
import { watchResetPassword } from './state/saga';
import { ResetPasswordForm } from './types';

export const ResetPassword: React.FC = () => {
  const intl = useIntl();
  const history = useHistory();
  const { themeName } = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const queryString = parseQueryString(window.location.search);

  const resetPasswordSchema = getResetPasswordSchema(intl);
  const validateForm = validator(resetPasswordSchema);

  useInjectSaga({
    key: 'reset-password',
    saga: watchResetPassword,
    mode: RESTART_ON_REMOUNT,
  });

  const handleResetPassword = (values: ResetPasswordForm, actions: FormikHelpers<ResetPasswordForm>) => {
    setLoading(true);

    dispatch(
      resetPassword(
        queryString.token,
        values.password,
        // on success
        () => {
          history.push('/login');
        },
        // on error
        (errorCode, errorMessage) => {
          // Get translated error message
          const message = getAuth0ErrorMessage(intl, errorCode, errorMessage);

          if (errorCode === 'reset_password_request_used' || errorCode === 'reset_password_request_expired')
            dispatch(addNotification({ message: message, className: 'error' }));
          else actions.setFieldError('password', message);
        },
        // on always
        () => {
          setLoading(false);
        },
      ),
    );
  };

  const title = intl.formatMessage(messages.confirmNewPassword);

  const backButton = (
    <LinkButton to="/login" size="s" type="outlined">
      {intl.formatMessage(messages.back)}
    </LinkButton>
  );

  const Wrapper = themeName === DEFAULT_NAME ? AuthLayout : WhiteLabelAuthLayout;
  //This should change to a subdomain check probably.

  return (
    <Wrapper header={null} title={title} headerAddon={backButton} rightPanel={<FormImagePanel />}>
      <Formik<ResetPasswordForm>
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        onSubmit={handleResetPassword}
        validate={validateForm}
        validateOnMount={true}
      >
        <>
          <FormPanel loading={loading} email={queryString.email as string} />
        </>
      </Formik>
    </Wrapper>
  );
};
